import { Modal } from "antd";
import React from "react";
import { IShift } from "shared/interfaces";
import NewBatchForm from "./NewBatchForm";
import useShift from "../../hooks/useShift";

const NewBatchModal: React.FC<{
  shiftId: NonNullable<IShift["id"]>;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ shiftId, open, setOpen }) => {
  const { data: shift, refetch: refetchShift } = useShift(shiftId);
  return (
    <Modal
      open={open}
      title={"Create new batch"}
      maskClosable={false}
      footer={null}
      destroyOnClose={true}
      width={1250}
      onCancel={() => setOpen(false)}
    >
      <NewBatchForm
        onCancel={async () => setOpen(false)}
        onSuccess={async () => {
          await refetchShift();
          setOpen(false);
        }}
        shiftId={shiftId}
      />
    </Modal>
  );
};

export default NewBatchModal;
