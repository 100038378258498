import { Button, DatePicker, Modal, Table } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { DateTime } from "luxon";
import { Outlet, useNavigate, useParams } from "react-router";
import useTrails from "../../../hooks/useTrails";
import "./AuditTrailTable.css";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { ArrayParam, NumberParam, useQueryParams } from "use-query-params";
import { ZoomContext } from "../../../App";
import { nicelyDisplayDateFromISOString } from "shared/utils";
import { SortOrder } from "antd/es/table/interface";
import useWindowResize from "../../../hooks/useWindowResize";
import CSVDownloadButton from "../../UI/CSVDownloadButton";
import { ColumnsType } from "antd/es/table";

const AuditTrailTable = () => {
  const { shiftId } = useParams();
  const navigate = useNavigate();
  const [zoom] = React.useContext(ZoomContext);
  const zoomRatio = parseInt(zoom) / 100;

  const getSortOrder = (field): SortOrder => {
    const sortParam = query.sortParams?.find((s) => s?.startsWith(field));
    if (sortParam) {
      return (sortParam.endsWith("asc") ? "ascend" : "descend") as SortOrder;
    }
    return null as SortOrder;
  };

  const { height } = useWindowResize();

  const [tableHeaderHeight, setTableHeaderHeight] = useState(0);
  useEffect(() => {
    // Function to update state with the height of the th element
    const updateHeight = () => {
      const thElement = document.querySelector("th");
      if (thElement) {
        setTableHeaderHeight(thElement.clientHeight);
      }
    };

    // Set up ResizeObserver
    const resizeObserver = new ResizeObserver(updateHeight);
    const thElement = document.querySelector("th");
    if (thElement) {
      resizeObserver.observe(thElement);
    }

    // Clean up observer on component unmount
    return () => {
      if (thElement) {
        resizeObserver.unobserve(thElement);
      }
    };
  }, []);

  // Replace local state with useQueryParams
  const [query, setQuery] = useQueryParams({
    page: NumberParam,
    pageSize: NumberParam,
    total: NumberParam,
    from: NumberParam,
    to: NumberParam,
    method: ArrayParam,
    statusCode: ArrayParam,
    sortParams: ArrayParam,
  });

  // Default times to one week ago if not set
  const [defaultTimeSpan] = useState([dayjs().subtract(1, "week").valueOf(), dayjs().valueOf()]);
  const timeSpan = query.from && query.to ? [query.from, query.to] : defaultTimeSpan;

  const [requestText, setRequestText] = React.useState<string | null>(null);
  const [responseText, setResponseText] = React.useState<string | null>(null);

  const buildStatusCodeFilter = (statusCode) => {
    if (!statusCode || statusCode.length === 0) return {};

    // Create an array of $startsWith conditions for each status code prefix
    const orConditions = statusCode.map((code) => {
      const prefix = code.slice(0, 1); // Assuming codes like "4xx"
      return { statusCode: { $gte: prefix * 100, $lt: prefix * 100 + 100 } };
    });

    return orConditions.length > 1 ? { $or: orConditions } : { ...orConditions[0] };
  };

  const sortParams = query.sortParams || [];

  if (!sortParams.find((s) => s?.startsWith("createdAt"))) {
    sortParams.push("createdAt:desc");
  }

  const trailQuery = {
    page: query.page || 1,
    pageSize: query.pageSize || 100,
    filters: {
      ...(timeSpan && timeSpan[0] && timeSpan[1]
        ? {
            createdAt: {
              $gte: timeSpan[0].valueOf(),
              $lte: timeSpan[1].valueOf(),
            },
          }
        : {}),
      ...(query.method ? { method: { $in: query.method } } : {}),
      ...buildStatusCodeFilter(query.statusCode),
    },
    ...(sortParams ? { sort: sortParams } : {}),
  };

  const { data } = useTrails(trailQuery);
  const { data: auditTrails = [] } = data || {};

  const total = data?.meta?.pagination?.total;
  const page = data?.meta?.pagination?.page;
  const pageSize = data?.meta?.pagination?.pageSize;

  const columns: ColumnsType<any> = [
    {
      title: "Creation date",
      dataIndex: "createdAt",
      render: (createdAt) => nicelyDisplayDateFromISOString(createdAt),
      sorter: { multiple: 1 },
      width: 160 * Math.max(1, zoomRatio),
      defaultFilteredValue: timeSpan,
      filterDropdown: () => (
        <DatePicker.RangePicker
          value={[dayjs(timeSpan[0]), dayjs(timeSpan[1])]}
          showTime={false}
          onChange={(dates) => {
            if (dates && dates.length > 1 && dates[0] && dates[1]) {
              const from = DateTime.fromJSDate(dates[0].toDate()).startOf("day").toMillis();
              const to = DateTime.fromJSDate(dates[1].toDate()).endOf("day").toMillis();
              setQuery({ from: from, to: to, page: 1 });
            }
          }}
          disabledDate={(current) => current && current > dayjs()}
          allowClear={false}
        />
      ),
      defaultSortOrder: getSortOrder("createdAt"),
    },
    {
      title: "User",
      ellipsis: true,
      dataIndex: ["author", "email"],
      sorter: { multiple: 1 },
      width: 200 * Math.max(1, zoomRatio),
      defaultSortOrder: getSortOrder("author.email"),
    },
    {
      title: "Method",
      dataIndex: "method",
      sorter: { multiple: 1 },
      width: 100 * Math.max(1, zoomRatio),
      defaultSortOrder: getSortOrder("method"),
      filters: [
        { text: "POST", value: "POST" },
        { text: "PUT", value: "PUT" },
        { text: "DELETE", value: "DELETE" },
      ],
    },
    {
      title: "Action",
      width: 100 * Math.max(1, zoomRatio),
      dataIndex: "action",
      defaultSortOrder: getSortOrder("action"),
      sorter: { multiple: 1 },
    },
    {
      title: "Route",
      dataIndex: "route",
      width: 150 * Math.max(1, zoomRatio),
      ellipsis: true,
      defaultSortOrder: getSortOrder("route"),
      sorter: { multiple: 1 },
    },
    {
      title: "Status code",
      dataIndex: "statusCode",
      width: 100 * Math.max(1, zoomRatio),
      sorter: { multiple: 1 },
      filters: [
        { text: "2xx", value: "2xx" },
        { text: "4xx", value: "4xx" },
        { text: "5xx", value: "5xx" },
      ],
      defaultSortOrder: getSortOrder("statusCode"),
      defaultFilteredValue: query?.statusCode?.filter((sc): sc is string => sc !== null) || [],
    },
    {
      title: "Request",
      dataIndex: "request",
      width: 100 * Math.max(1, zoomRatio),
      render: (request) => {
        return <Button onClick={() => setRequestText(JSON.stringify(request, null, 2))} icon={<EyeOutlined />} />;
      },
    },
    {
      title: "Response",
      dataIndex: "response",
      width: 100 * Math.max(1, zoomRatio),
      render: (response) => {
        return <Button onClick={() => setResponseText(JSON.stringify(response, null, 2))} icon={<EyeOutlined />} />;
      },
    },
  ];

  return (
    <div style={{ padding: 20 }} className={"audit-trail-table"}>
      <CSVDownloadButton fetchDataConfig={trailQuery} queryHook={useTrails} filename={"audit_trail.csv"} />
      <Table
        size={"small"}
        onChange={(pagination, filters, sorter) => {
          const sorterArray = (Array.isArray(sorter) ? sorter : [sorter]).filter((s) => s.column);
          const newSortParams = sorterArray
            .filter((s) => s.order)
            .filter((s) => s.field)
            .map((s) => {
              return `${s.field && s.field.toString().replace(/,/, ".")}${s.order ? ":" + (s.order === "ascend" ? "asc" : "desc") : ""}`;
            })
            .reverse();

          if (!newSortParams.find((s) => s.startsWith("createdAt"))) {
            newSortParams.push("createdAt:desc");
          }

          setQuery({
            page: pagination.current,
            pageSize: pagination.pageSize,
            sortParams: newSortParams,
            ...filters,
          });
        }}
        scroll={{ x: 1200 * Math.max(1, zoomRatio), ...(height ? { y: height - 125 - 25 * zoomRatio - tableHeaderHeight } : {}) }}
        pagination={{
          current: page || 1,
          total: total || 0,
          pageSize: pageSize || 100,
        }}
        columns={columns}
        dataSource={auditTrails}
        bordered={true}
      />
      {/* Modal components for displaying shift details, request and response details */}
      <Modal open={!!shiftId} onCancel={() => navigate("/overview")} footer={null} closable>
        <Outlet />
      </Modal>
      <Modal title={"Request"} open={!!requestText} onCancel={() => setRequestText(null)} onOk={() => setRequestText(null)} width={"80vw"} closable>
        <SyntaxHighlighter language="json" style={docco}>
          {requestText}
        </SyntaxHighlighter>
      </Modal>
      <Modal
        title={"Response"}
        open={!!responseText}
        onOk={() => setResponseText(null)}
        onCancel={() => setResponseText(null)}
        width={"80vw"}
        closable
      >
        <SyntaxHighlighter language="json" style={docco}>
          {responseText}
        </SyntaxHighlighter>
      </Modal>
    </div>
  );
};

export default AuditTrailTable;
