import { DatePicker, Form, InputNumber, Modal, notification, Row } from "antd";
import React, { useEffect } from "react";
import useBatch from "../../hooks/useBatch";
import getAPI from "../../services/api";
import TextArea from "antd/es/input/TextArea";
import { nowDateTimeISOString } from "shared/utils";
import dayjs from "dayjs";

const NewMetalDetectionModal: React.FC<{
  batchId: number | undefined;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ open, setOpen, batchId }) => {
  const { data: batch, refetch: refetchBatch } = useBatch(batchId);

  const [form] = Form.useForm();

  useEffect(() => {
    if (open) {
      form.setFieldsValue({
        closedAt: dayjs(nowDateTimeISOString()),
      });
    }
  }, [open, form]);

  const submit = async (values: any) => {
    try {
      const api = await getAPI();

      if (values?.closedAt) values.closedAt = values.closedAt.toISOString();

      await api.post(`/batches/${batchId}/metalDetection`, { data: values });

      notification.success({
        message: "Success",
        description: "The metal detection was successfully logged!",
      });
      await refetchBatch();
      setOpen(false);
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: `${error.message}`,
      });
    }
  };

  return (
    <>
      {batch && (
        <Modal
          okText={"Submit"}
          title="Document a metal detection"
          open={open}
          onOk={async () => {
            if (form.getFieldsValue().palletId == null) {
              notification.warning({
                message: "Pallet id missing or not a number",
              });
              return;
            }
            await form.submit();
            setOpen(false);
          }}
          onCancel={() => {
            setOpen(false);
          }}
          maskClosable={false}
          destroyOnClose
          width={600}
        >
          <Row justify="center">
            <Form
              form={form}
              name="basic"
              labelCol={{ span: 10 }}
              wrapperCol={{ span: 14 }}
              style={{ width: "100%" }}
              onFinish={submit}
              autoComplete="off"
            >
              <Form.Item label="Pallet id" name="palletId">
                <InputNumber min={0} max={9999999} controls={false} inputMode="numeric" />
              </Form.Item>
              <Form.Item label="Finished at" name="closedAt">
                <DatePicker showTime format="YYYY-MM-DD HH:mm" allowClear={false} />
              </Form.Item>
              <Form.Item label="Comment" name="comment">
                <TextArea />
              </Form.Item>
            </Form>
          </Row>
        </Modal>
      )}
    </>
  );
};

export default NewMetalDetectionModal;
