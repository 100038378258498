"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.flowPackageInspectionValidate = exports.flowPackageInspectionGetFlowName = exports.flowPackageInspectionGetGenericLabel = exports.flowPackageInspectionGetLabel = void 0;
const index_1 = require("./index");
function flowPackageInspectionGetLabel(flow) {
    let label = flowPackageInspectionGetGenericLabel();
    if (flow.shiftInspectionNumber) {
        label += ` #${flow.shiftInspectionNumber}`;
    }
    return label;
}
exports.flowPackageInspectionGetLabel = flowPackageInspectionGetLabel;
function flowPackageInspectionGetGenericLabel() {
    return "Package Quality Assurance Inspection";
}
exports.flowPackageInspectionGetGenericLabel = flowPackageInspectionGetGenericLabel;
function flowPackageInspectionGetFlowName() {
    return "FlowPackageInspection";
}
exports.flowPackageInspectionGetFlowName = flowPackageInspectionGetFlowName;
function flowPackageInspectionValidate(flow) {
    let errors = [];
    let warnings = [];
    const labelProduct = "Product";
    const labelBagLegible = "(Bags) Inkjet printing and/or stencil legibility";
    const labelBagMatch = "(Bags) Barcode label and/or stencil matching";
    const labelBagClean = "(Bags) Pallet and slip sheet clean";
    const labelBagBatchNumber = "(Bags) Batch number";
    const labelBagPalletId = "(Bags) Pallet id";
    const labelBagPictures = "(Bags) Pictures";
    const labelSSLegible = "(SS) Inkjet printing and/or stencil legibility";
    const labelSSMatch = "(SS) Barcode label and/or stencil matching";
    const labelSSClean = "(SS) Pallet and slip sheet clean";
    const labelSSBatchNumber = "(SS) Batch number";
    const labelSSPalletId = "(SS) Pallet id";
    const labelSSPictures = "(SS) Pictures";
    if (!flow.product) {
        warnings.push({
            property: "product",
            message: `${labelProduct} is missing`,
        });
        return {
            errors,
            warnings,
        };
    }
    if (flow.bagSelected) {
        warnings = (0, index_1.validationMissingValue)(warnings, flow.bagLegible, "bagLegible", labelBagLegible);
        warnings = (0, index_1.validationMissingValue)(warnings, flow.bagMatch, "bagMatch", labelBagMatch);
        warnings = (0, index_1.validationMissingValue)(warnings, flow.bagClean, "bagClean", labelBagClean);
        warnings = (0, index_1.validationMissingValue)(warnings, flow.bagBatchNumber, "bagBatchNumber", labelBagBatchNumber);
        warnings = (0, index_1.validationMissingValue)(warnings, flow.bagPalletId, "bagPalletId", labelBagPalletId);
        warnings = (0, index_1.validationNAValue)(warnings, flow.bagLegible, true, "bagLegible", labelBagLegible);
        warnings = (0, index_1.validationNAValue)(warnings, flow.bagMatch, true, "bagMatch", labelBagMatch);
        warnings = (0, index_1.validationNAValue)(warnings, flow.bagClean, true, "bagClean", labelBagClean);
        if (!flow.bagPictures || flow.bagPictures.length === 0) {
            warnings.push({
                property: "bagPictures",
                message: `Pictures are missing for Bags (you need at least one)`,
            });
        }
    }
    if (flow.ssSelected) {
        warnings = (0, index_1.validationMissingValue)(warnings, flow.ssLegible, "ssLegible", labelSSLegible);
        warnings = (0, index_1.validationMissingValue)(warnings, flow.ssMatch, "ssMatch", labelSSMatch);
        warnings = (0, index_1.validationMissingValue)(warnings, flow.ssClean, "ssClean", labelSSClean);
        warnings = (0, index_1.validationMissingValue)(warnings, flow.ssBatchNumber, "ssBatchNumber", labelSSBatchNumber);
        warnings = (0, index_1.validationMissingValue)(warnings, flow.ssPalletId, "ssPalletId", labelSSPalletId);
        warnings = (0, index_1.validationNAValue)(warnings, flow.ssLegible, true, "ssLegible", labelSSLegible);
        warnings = (0, index_1.validationNAValue)(warnings, flow.ssMatch, true, "ssMatch", labelSSMatch);
        warnings = (0, index_1.validationNAValue)(warnings, flow.ssClean, true, "ssClean", labelSSClean);
        if (!flow.ssPictures || flow.ssPictures.length === 0) {
            warnings.push({
                property: "ssPictures",
                message: `Pictures are missing for SS (you need at least one)`,
            });
        }
    }
    return {
        errors,
        warnings,
    };
}
exports.flowPackageInspectionValidate = flowPackageInspectionValidate;
