import { combineReducers } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

import slices from "./slices";
import { configureStore } from "@reduxjs/toolkit";

const reducers = {
  ...(Object.fromEntries(
    Object.entries(slices).map(([name, slice]) => {
      return [name, slice.reducer];
    })
  ) as unknown as {
    [K in keyof typeof slices]: typeof slices[K]["reducer"];
  }),
};

const persistConfig = {
  key: "root",
  storage,
};
const persistedReducer = persistReducer(persistConfig, combineReducers(reducers));

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const actions = Object.fromEntries(
  Object.entries(slices).map(([name, slice]) => {
    return [name, slice.actions];
  })
) as unknown as {
  [K in keyof typeof slices]: typeof slices[K]["actions"];
};

export default store;
