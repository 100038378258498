import { CheckCircleFilled, WarningFilled } from "@ant-design/icons";
import { Button, Checkbox, Form, InputNumber, notification, Spin, Table, Modal, Input, Space } from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { formatPalletId } from "../../../helpers/format";
import getAPI from "../../../services/api";
import { FlowType, IBatch, flowGetStatus, IBatchRegular, batchGetOpenBatchLine } from "shared/interfaces";
import useBatch from "../../../hooks/useBatch";
import { useParams } from "react-router";
import useShift from "../../../hooks/useShift";
import { Status } from "shared/interfaces";
import ScaleReadingEditModal from "../ScaleReadingEditModal";
import FlagsPopover from "../../UI/FlagsPopover";
import { ZoomContext } from "../../../App";
import { getScaleReadingLimitLow, getScaleReadingLimitHigh } from "shared/utils";
import { isTemporarySS } from "shared/materialRules";

const { TextArea } = Input;

const ScaleReadingTable: React.FC<{
  batchId: IBatch["id"];
}> = ({ batchId }) => {
  const [zoom] = React.useContext(ZoomContext);
  const zoomRatio = parseInt(zoom) / 100;

  const ref = useRef<HTMLDivElement>(null);
  const {
    data: batch,
    isLoading,
    isError,
    refetch: refetchBatch,
  } = useBatch(batchId) as { data: IBatchRegular; isLoading: boolean; isError: boolean; refetch: () => void };

  const [isCommentModalOpen, setIsCommentModalOpen] = React.useState(false);
  const { shiftId } = useParams();
  const { data: shift } = useShift(shiftId);
  const flowCheckOut = (shift?.flows || []).find((flow) => flow.type === FlowType.shiftCheckOut);
  const shiftCheckOutDone = flowCheckOut && flowGetStatus(flowCheckOut) === Status.confirmed;
  const [scaleReadingEditModalOpen, setScaleReadingEditModalOpen] = useState(false);

  const scrollBottom = useCallback(() => {
    if (!ref.current) return;
    const body = ref.current.querySelector(".ant-table-body") as HTMLDivElement;
    if (!body) return;
    body.scrollTop = body.scrollHeight;
  }, [ref]);

  useEffect(() => {
    if (!ref.current) return;
    const body = ref.current.querySelector(".scale-reading-table") as HTMLDivElement;
    if (!body) return;
    scrollBottom();
    body.style.overflowY = "auto";
  }, [ref, scrollBottom]);

  const submitForm = async (values) => {
    try {
      const api = await getAPI();
      values.totalWeight = values.weightEmptyPallet + values.weightFilledSS;
      await api.post(`/batches/${batchId}/scaleReading`, { data: values });
      await refetchBatch();
      form.resetFields();
      hasWarnings && setHasWarnings(false);
    } catch (e: any) {
      notification.error({ message: "Error", description: e.message });
    }
  };

  const palletId = batch && batch.scaleReadings && (batch.scaleReadings.sort((a, b) => (b.palletId ?? 0) - (a.palletId ?? 0))[0]?.palletId ?? 0) + 1;
  const dataSource = [...((batch && (batch.scaleReadings || []).sort((a, b) => (a.palletId ?? 0) - (b.palletId ?? 0))) || [])];

  if (batch && !batch.closedAt && batchGetOpenBatchLine(batch)) {
    dataSource.push({
      palletId: Math.max(1, Math.max(...dataSource.map((entry) => entry.palletId ?? 0)) + 1),
      // @ts-ignore
      type: "form-entry",
    });
  }
  const [form] = Form.useForm();
  const checkWeightMandatory = palletId && (palletId - 5) % 20 === 0;

  const [hasWarnings, setHasWarnings] = React.useState(false);

  useEffect(() => {
    const table = document.querySelector(".scale-reading-table .ant-table-body");
    if (table) {
      table.scrollTop = table.scrollHeight;
    }
  }, [dataSource]);

  return !batch || isLoading || isError ? (
    <Spin />
  ) : (
    <Form
      form={form}
      disabled={!!batch.closedAt || shiftCheckOutDone}
      onFinish={async (values) => {
        if (!values.weightEmptyPallet || !values.weightFilledSS) {
          notification.error({
            message: "Error",
            description: "Please fill all the fields",
          });
          return;
        }
        if (!values.checkWeight && checkWeightMandatory) {
          setIsCommentModalOpen(true);
          return;
        } else {
          await submitForm(values);
        }
      }}
    >
      <Table
        className="scale-reading-table"
        dataSource={dataSource}
        rowKey="palletId"
        pagination={false}
        scroll={{ y: 200 }}
        {...(batch && !batch.closedAt && batchGetOpenBatchLine(batch)
          ? {
              footer: () => (
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", color: "grey" }}>
                  <span style={{ visibility: isTemporarySS(batch.material?.number ?? 0) ? "hidden" : "visible" }}>
                    limits: {getScaleReadingLimitLow(batch.material?.unitWeight ?? 0)} - {getScaleReadingLimitHigh(batch.material?.unitWeight ?? 0)}
                  </span>
                  <Space>
                    <Button
                      type="primary"
                      ghost
                      onClick={() => {
                        setScaleReadingEditModalOpen(true);
                      }}
                    >
                      Edit
                    </Button>
                    <Button type="primary" htmlType="submit">
                      {hasWarnings ? <>Save scale reading with warning</> : <>Save scale reading</>}
                    </Button>
                  </Space>
                </div>
              ),
            }
          : {})}
        columns={[
          {
            title: "Pallet Id",
            dataIndex: "palletId",
            width: 100,
            render: (palletId) => {
              return formatPalletId(palletId);
            },
          },
          {
            title: "Seal",
            dataIndex: "seal",
            width: 80,
            render: (seal, record) => {
              // @ts-ignore
              if (record.type === "form-entry") {
                return (
                  <Form.Item style={{ margin: 0 }} name="seal" valuePropName="checked">
                    <Checkbox />
                  </Form.Item>
                );
              }
              return seal ? <CheckCircleFilled /> : "";
            },
          },
          {
            title: "Pallet Wt. (lbs)",
            dataIndex: "weightEmptyPallet",
            render: (weight, record) => {
              // @ts-ignore
              if (record.type === "form-entry") {
                return (
                  <Form.Item style={{ margin: 0 }} name="weightEmptyPallet" className="required-input">
                    <InputNumber controls={false} min={0} inputMode="numeric" />
                  </Form.Item>
                );
              }
              return weight;
            },
          },
          {
            title: "SS Net Wt. (lbs)",
            dataIndex: "weightFilledSS",
            render: (weight, record) => {
              // @ts-ignore
              if (record.type === "form-entry") {
                return (
                  <Form.Item style={{ margin: 0 }} name="weightFilledSS" className="required-input">
                    <InputNumber controls={false} min={0} inputMode="numeric" />
                  </Form.Item>
                );
              }
              return weight;
            },
          },
          {
            title: "Check Wt. (lbs)",
            dataIndex: "checkWeight",
            render: (weight, record) => {
              // @ts-ignore
              if (record.type === "form-entry") {
                return (
                  <Form.Item style={{ margin: 0 }} name="checkWeight" className={checkWeightMandatory ? "required-input" : ""}>
                    <InputNumber controls={false} min={0} inputMode="numeric" />
                  </Form.Item>
                );
              }
              return (
                <div style={{ display: "flex", alignItems: "center" }}>
                  {weight || "-"}
                  {record.flags && (
                    <div style={{ marginLeft: "10px" }}>
                      <FlagsPopover flags={record.flags} zoomRatio={zoomRatio} batchId={batch.id} />
                    </div>
                  )}
                </div>
              );
            },
          },
        ]}
      />
      <Modal
        title={
          <Space>
            <WarningFilled style={{ color: "#ffec3d" }} />
            Warning
          </Space>
        }
        open={isCommentModalOpen}
        destroyOnClose={true}
        onCancel={() => setIsCommentModalOpen(false)}
        onOk={async () => {
          if (form.getFieldValue("comment")) {
            await submitForm(form.getFieldsValue());
            setIsCommentModalOpen(false);
          } else {
            notification.error({ message: "Error", description: "Comment is required" });
          }
        }}
      >
        <p>Check weight is required. Please enter a comment to continue despite the warning</p>
        <Form.Item
          name="comment"
          rules={[
            () => ({
              validator(_, value) {
                if (!isCommentModalOpen || (isCommentModalOpen && value)) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("Comment is required"));
              },
            }),
          ]}
        >
          <TextArea />
        </Form.Item>
      </Modal>
      <ScaleReadingEditModal open={scaleReadingEditModalOpen} setOpen={setScaleReadingEditModalOpen} batchId={batch?.id} />
    </Form>
  );
};
export default ScaleReadingTable;
