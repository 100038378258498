import { Form, Modal, notification, Row, Button } from "antd";
import React from "react";
import useBatch from "../../hooks/useBatch";
import getAPI from "../../services/api";
import TextArea from "antd/es/input/TextArea";

const BatchManualFlagModal: React.FC<{
  batchId: number | undefined;
  open: boolean;
  readonly: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ open, setOpen, batchId, readonly }) => {
  const { data: batch, refetch: refetchBatch } = useBatch(batchId);

  const [form] = Form.useForm();

  const submit = async (values: any) => {
    try {
      const api = await getAPI();

      await api.post(`/batches/${batchId}/manualFlag`, { data: { comment: values.comment } });

      notification.success({
        message: "Success",
        description: "The batch flag was successfully created",
      });
      await refetchBatch();
      setOpen(false);
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: `${error.message}`,
      });
    }
  };

  const customFooter = () =>
    !readonly && (
      <Row justify="end">
        <Button key="cancel" onClick={() => setOpen(false)}>
          Cancel
        </Button>
        <Button key="submit" type="primary" onClick={() => form.submit()}>
          Create
        </Button>
      </Row>
    );

  return (
    <>
      {batch && (
        <Modal
          title="Batch flag"
          open={open}
          onOk={async () => {
            await form.submit();
            setOpen(false);
          }}
          onCancel={() => {
            setOpen(false);
          }}
          maskClosable={false}
          destroyOnClose
          width={600}
          footer={customFooter()}
        >
          <Row justify="center">
            <Form
              form={form}
              name="basic"
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 20 }}
              style={{ width: "100%" }}
              onFinish={submit}
              autoComplete="off"
              disabled={readonly}
            >
              <Form.Item label="Comment" name="comment" initialValue={""}>
                <TextArea rows={6} />
              </Form.Item>
            </Form>
          </Row>
        </Modal>
      )}
    </>
  );
};

export default BatchManualFlagModal;
