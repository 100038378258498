import { Button, Card, Col, DatePicker, Descriptions, Form, Input, InputNumber, notification, Row, Select, Space, Spin } from "antd";
import { CloseOutlined, SendOutlined, CheckCircleFilled } from "@ant-design/icons";
import { useEffect, useState } from "react";
import {
  IMaterial,
  IBlendPossible,
  ISourcePossible,
  PkgType,
  Product,
  IShift,
  batchValidate,
  IBatchLine,
  batchLineValidate,
  BatchLineType,
} from "shared/interfaces";
import getAPI from "../../services/api";
import useBlends from "../../hooks/useBlends";
import useMaterials from "../../hooks/useMaterials";
import useSources from "../../hooks/useSources";
import useShift from "../../hooks/useShift";
import useReasonsForMovement from "../../hooks/useReasonsForMovement";
import useBatchLatestUsedValues from "../../hooks/useBatchLatestUsedValues";
import BatchNumberInput from "../UI/BatchNumberInput";
import dayjs from "dayjs";

const { TextArea } = Input;

const NewReworkForm: React.FC<{
  shiftId: NonNullable<IShift["id"]>;
  onSuccess: () => void;
  onCancel: () => void;
}> = ({ shiftId, onSuccess, onCancel }) => {
  const { data: shift, refetch: refetchShift } = useShift(shiftId);
  const [materialId, setMaterialId] = useState<IMaterial["id"]>();
  const { data: sources } = useSources({ product: shift?.product, materialId });
  const [sourceId, setSourceId] = useState<ISourcePossible["id"] | undefined>(undefined);
  const { data: blends } = useBlends({ product: shift?.product, sourceId: sourceId });
  const [blendId, setBlendId] = useState<IBlendPossible["id"] | undefined>(undefined);
  const [initialFormRendered, setInitialFormRendered] = useState<boolean>(false);

  const [isToPalletHidden, setIsToPalletHidden] = useState<boolean>(false);
  const [fromPallet, setFromPallet] = useState<number | undefined>(1);

  const { data: materials } = useMaterials({ rework: true, product: shift?.product });
  const { data: reasonsForMovement } = useReasonsForMovement();

  const [form] = Form.useForm();
  const [hasWarnings, setHasWarnings] = useState<boolean>(false);
  const { data: latestUsedValues, isFetching: isFetchingLatestUsedValues } = useBatchLatestUsedValues();

  const [pkgType, setPkgType] = useState<PkgType>();
  if (latestUsedValues) latestUsedValues.materialId = latestUsedValues.materialId || materials?.[0]?.id;

  useEffect(() => {
    // If the user has not selected a material, select the first material in the list
    // Get the package type from the material
    let defaultMaterial = materials && materials.find((material) => material.id === latestUsedValues?.materialId);
    if (pkgType && defaultMaterial?.pkgType !== pkgType) {
      defaultMaterial = materials?.filter((material) => material.pkgType === pkgType)[0];
    } else if (!pkgType) {
      defaultMaterial = materials?.[0];
    }

    if (latestUsedValues && !initialFormRendered) {
      setPkgType(defaultMaterial?.pkgType);
      setMaterialId(defaultMaterial?.id);

      form.setFieldValue("materialId", defaultMaterial?.id);
      form.setFieldValue("pkgType", defaultMaterial?.pkgType);

      setSourceId(latestUsedValues?.sourceId);
      setBlendId(latestUsedValues?.blendId);
    }

    if (hasWarnings) {
      const fieldIssues = form.getFieldsError();
      if (fieldIssues.reduce((acc, { warnings }) => acc + warnings.length, 0) === 0) {
        setHasWarnings(false);
      }
    }

    if (materials && blends && sources && materialId && latestUsedValues && sourceId && blendId && !initialFormRendered) {
      setInitialFormRendered(true);
    }
  }, [
    latestUsedValues,
    sources,
    materials,
    blends,
    sourceId,
    blendId,
    pkgType,
    isFetchingLatestUsedValues,
    initialFormRendered,
    form,
    hasWarnings,
    materialId,
  ]);

  const material = materials?.find((m) => m.id === materialId);
  if (((!materials || !blends || !sources || !materialId || !latestUsedValues) && !initialFormRendered) || !material) return <Spin spinning={true} />;

  return (
    <Form
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      labelAlign={"left"}
      form={form}
      initialValues={latestUsedValues}
      onFinish={async (values) => {
        values.batchNumber = !values.batchNumber ? "" : values.batchNumber;

        if (values?.closedAt) values.closedAt = values.closedAt.toISOString();
        if (values?.openedAt) values.openedAt = values.openedAt.toISOString();

        const api = await getAPI();

        let { warnings } = await batchValidate(values);

        const batchLine: IBatchLine = {
          type: BatchLineType.regular,
          source: sources?.find((s) => s.id === values.sourceId),
          blend: blends?.find((b) => b.id === values.blendId),
          blendNumber: values.blendNumber,
        };

        const { warnings: batchLineWarnings } = await batchLineValidate(batchLine, material, true);
        warnings = warnings.concat(batchLineWarnings);

        if (values.toPallet - values.fromPallet + 1 !== values.qtyPallets && !hasWarnings) {
          if (values.qtyPallets !== 0) {
            warnings.push({
              message: "The pallet quantity should match pallet ids range",
              property: "qtyPallets",
            });
          }
        }

        if (values.qtyPallets === 0) {
          values.toPallet = null;
          values.fromPallet = null;
          setFromPallet(undefined);
        }

        if (warnings.length > 0 && !hasWarnings) {
          const fieldWarnings: { name: string; warnings: string[] }[] = [];

          fieldWarnings.push(
            ...warnings.map((warning) => ({
              name: replaceNAProperties(warning.property),
              warnings: [warning.message],
            }))
          );

          form.setFields(fieldWarnings);
          setHasWarnings(true);
          return;
        } else {
          setHasWarnings(false);
        }

        await api
          .post("/batches/rework", {
            data: values,
          })
          .then((res) => {
            if (res.status === 200) {
              notification.success({
                message: "Rework created",
              });
              setHasWarnings(false);
              onSuccess && onSuccess();
            }
          })
          .then(() => {
            refetchShift();
          })
          .catch((err) => {
            notification.error({
              message: "Error creating rework",
              description: err.message,
            });
          });
      }}
      onFinishFailed={async ({ values }) => {
        // we reset the warnings if the user has not set the comment
        let { warnings } = await batchValidate(values);

        const batchLine: IBatchLine = {
          type: BatchLineType.regular,
          source: sources?.find((s) => s.id === values.sourceId),
          blend: blends?.find((b) => b.id === values.blendId),
          blendNumber: values.blendNumber,
        };

        const { warnings: batchLineWarnings } = await batchLineValidate(batchLine, material, true);
        warnings = warnings.concat(batchLineWarnings);

        if (warnings.length > 0) {
          !hasWarnings && setHasWarnings(true);
          const fieldWarnings: { name: string; warnings: string[] }[] = [];

          fieldWarnings.push(
            ...warnings.map((warning) => ({
              name: replaceNAProperties(warning.property),
              warnings: [warning.message],
            }))
          );

          form.setFields(fieldWarnings);
        }
      }}
      onValuesChange={async (changedValues, allValues) => {
        if (changedValues.pkgType) {
          setPkgType(changedValues.pkgType);
        }

        if (sources && changedValues.sourceId) {
          setSourceId(changedValues.sourceId);
        }

        if (changedValues.blendId) {
          setBlendId(changedValues.blendId);
        }

        if (materials && changedValues.materialId) {
          setMaterialId(changedValues.materialId);
        }

        const batch = form.getFieldsValue();
        // reset warnings
        Object.keys(batch).forEach((key) => {
          form.setFields([
            {
              name: key,
              warnings: [],
            },
          ]);
        });
        // if we have errors (or rather warnings), we set them in the form, the user can still submit)
        let { warnings } = await batchValidate(allValues);

        const batchLine: IBatchLine = {
          type: BatchLineType.regular,
          source: sources?.find((s) => s.id === allValues.sourceId),
          blend: blends?.find((b) => b.id === allValues.blendId),
          blendNumber: allValues.blendNumber,
        };

        const { warnings: batchLineWarnings } = await batchLineValidate(batchLine, material, true);
        warnings = warnings.concat(batchLineWarnings);

        if ("toPallet" in changedValues) {
          const qtyPallets = changedValues.toPallet - allValues.fromPallet + 1;
          form.setFieldValue("qtyPallets", qtyPallets >= 0 ? qtyPallets : undefined);
        }

        if ("qtyPallets" in changedValues) {
          if (form.getFieldValue("qtyPallets") === 0) {
            setIsToPalletHidden(true);
          } else {
            setIsToPalletHidden(false);
          }
          if (allValues.toPallet - allValues.fromPallet + 1 !== changedValues.qtyPallets) {
            warnings.push({
              message: "The pallet quantity should match pallet ids range",
              property: "qtyPallets",
            });
          }
        }

        if ("fromPallet" in changedValues) {
          setFromPallet(changedValues.fromPallet);
        }

        if (warnings.length > 0 && hasWarnings) {
          const fieldWarnings: { name: string; warnings: string[] }[] = [];

          fieldWarnings.push(
            ...warnings.map((warning) => ({
              name: replaceNAProperties(warning.property),
              warnings: [warning.message],
            }))
          );
          form.setFields(fieldWarnings);
        } else {
          setHasWarnings(false);
          form.setFields([
            {
              name: "comment",
              errors: [],
            },
          ]);
        }
      }}
    >
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Reason for movement" name="reasonForMovementId">
            <Select
              showSearch
              filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
              options={[
                { label: "", value: null },
                ...(reasonsForMovement || []).map(({ id, reason }) => ({
                  value: id,
                  label: reason,
                })),
              ]}
            />
          </Form.Item>
          <Form.Item label="Pkg type" initialValue={material?.pkgType} name="pkgType">
            <Select
              options={[
                { label: "SS", value: "SS" },
                { label: "Bag", value: "Bag" },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Material"
            name="materialId"
            rules={[
              {
                required: true,
                message: "Please select a material",
              },
            ]}
          >
            <Select
              showSearch
              filterOption={(input, option) => (option?.label ?? "").toString().toLowerCase().includes(input.toLowerCase())}
              onDropdownVisibleChange={(open) => {
                return !open;
              }}
              options={materials
                ?.filter((m) => m.product === shift?.product)
                .filter((m) => (pkgType ? m.pkgType === pkgType : true))
                .map((material) => ({
                  label: material.number + (material.description ? " - " + material.description : ""),
                  value: material.id,
                }))}
            />
          </Form.Item>
          <Form.Item name="shiftId" hidden initialValue={shiftId}>
            <Input hidden />
          </Form.Item>
          <Form.Item name="sapProcessOrder" label="Process Order">
            {/*TODO: Prefill with previous process order*/}
            <Input />
          </Form.Item>
          <Form.Item label="Batch number" name="batchNumber">
            <BatchNumberInput />
          </Form.Item>
          <Form.Item name="sourceId" label="Source">
            <Select
              showSearch
              filterOption={(input, option) => (option?.reference ?? "").toLowerCase().includes(input.toLowerCase())}
              onDropdownVisibleChange={(open) => {
                return !open;
              }}
              options={[
                { label: "", value: null, reference: "" },
                ...(sources || []).map(({ id, reference, possible }) => {
                  return {
                    value: id,
                    reference,
                    label: (
                      <>
                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                          <span>{reference}</span>
                          {possible && <CheckCircleFilled style={{ fontSize: "0.7rem" }} />}
                        </div>
                      </>
                    ),
                  };
                }),
              ]}
            />
          </Form.Item>

          <Form.Item label="Path" name="blendId">
            <Select
              showSearch
              filterOption={(input, option) => (option?.reference ?? "").toLowerCase().includes(input.toLowerCase())}
              onDropdownVisibleChange={(open) => {
                return !open;
              }}
              options={[
                { label: "", value: null, reference: "" },
                ...(blends || []).map(({ id, reference, possible }) => {
                  return {
                    value: id,
                    reference,
                    label: (
                      <>
                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                          <span>{reference}</span>
                          {possible && <CheckCircleFilled style={{ fontSize: "0.7rem" }} />}
                        </div>
                      </>
                    ),
                  };
                }),
              ]}
            />
          </Form.Item>
          {shift?.product === Product.Ca && (
            <Form.Item label="Blend number" name="blendNumber">
              <Input />
            </Form.Item>
          )}

          <Form.Item label="From pallet" name="fromPallet" hidden={isToPalletHidden} initialValue={1}>
            <InputNumber min={0} inputMode="numeric" />
          </Form.Item>
          <Form.Item
            label="To pallet"
            name="toPallet"
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("fromPallet") <= value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("The last pallet must be greater than the previously registered last pallet!"));
                },
              }),
            ]}
            hidden={isToPalletHidden}
            initialValue={1}
          >
            <InputNumber min={fromPallet} inputMode="numeric" />
          </Form.Item>
          <Form.Item label="Pallet quantity" name="qtyPallets" initialValue={1}>
            <InputNumber controls={false} min={0} inputMode="numeric" />
          </Form.Item>

          <Form.Item label="Started at" name="openedAt" initialValue={dayjs()}>
            <DatePicker showTime format="YYYY-MM-DD HH:mm" allowClear={false} />
          </Form.Item>
          <Form.Item label="Finished at" name="closedAt" initialValue={dayjs()}>
            <DatePicker showTime format="YYYY-MM-DD HH:mm" allowClear={false} />
          </Form.Item>

          <Form.Item label="Comment" name="comment" rules={[{ required: hasWarnings, message: "Please add a comment to explain warnings" }]}>
            <TextArea />
          </Form.Item>
        </Col>
        <Col span={12}>
          {materialId && (
            // eslint-disable-next-line react/jsx-no-undef
            <Card>
              <Descriptions title="Rework Material Details" column={1} size="small">
                <Descriptions.Item label="Material description">{material.description}</Descriptions.Item>
                <Descriptions.Item label="Product code">{material.productCode}</Descriptions.Item>
                <Descriptions.Item label="Packaging Type">{material.pkgType}</Descriptions.Item>
                <Descriptions.Item label="Unit Weight">{material.unitWeight}</Descriptions.Item>
                <Descriptions.Item label="Package Ref">{material.packageRef}</Descriptions.Item>
                <Descriptions.Item label="Package Description">{material.packageDescr}</Descriptions.Item>
                <Descriptions.Item label="Pallet Ref.">{material.palletRef}</Descriptions.Item>
                <Descriptions.Item label="Pallet Description">{material.palletDescr}</Descriptions.Item>
                <Descriptions.Item label="Slip Sheet Ref">{material.slipSheetRef}</Descriptions.Item>
                <Descriptions.Item label="Slip Sheet Description">{material.slipSheetDescr}</Descriptions.Item>
                <Descriptions.Item label="Expiry Format">{material.expiryFormat}</Descriptions.Item>
              </Descriptions>
            </Card>
          )}
        </Col>
        <div style={{ display: "flex", justifyContent: "right", width: "100%", marginTop: 8 }}>
          <Space>
            <Button
              size="large"
              icon={<CloseOutlined />}
              onClick={async () => {
                onCancel && onCancel();
              }}
            >
              Cancel
            </Button>
            <Button size="large" icon={<SendOutlined />} htmlType="submit" type="primary">
              {hasWarnings ? "Submit with warnings" : "Submit"}
            </Button>
          </Space>
        </div>
      </Row>
    </Form>
  );
};

export default NewReworkForm;

let replacements = {
  source: "sourceId",
  blend: "blendId",
};

function replaceNAProperties(property: string) {
  let replacedProperty = property;
  for (let [key, value] of Object.entries(replacements)) {
    let regex = new RegExp(`\\b${key}\\b`, "g"); // 'g' flag for global replacement
    replacedProperty = replacedProperty.replace(regex, value);
  }
  return replacedProperty;
}
