import { Button, Checkbox, Form, Input, notification, Result, Select, Space, Spin, Popconfirm } from "antd";
import React, { useEffect } from "react";
import getAPI from "../../../services/api";
import { useNavigate, useSearchParams } from "react-router-dom";
import useFlow from "../../../hooks/useFlow";
import { SaveOutlined, SendOutlined } from "@ant-design/icons";
import {
  Area,
  BusinessRole,
  flowGetStatus,
  flowIsEditable,
  flowLogQuestionOptions,
  flowLogQuestionTagMap,
  flowLogTagOptions,
  flowValidateFE as flowValidate,
  IFlow,
  IFlowLog,
  Product,
  Status,
  LogType,
} from "shared/interfaces";
import YesNoSwitch from "../../UI/YesNoSwitch";
import FilePictureVideoUpload from "../../UI/AssetUpload";
import { useUser } from "../../../services/auth";
import FlowStatus from "../../UI/FlowStatus";

const { TextArea } = Input;

interface LogFlowProps {
  flowId: IFlow["id"];
  version?: IFlow["version"];
  readonly: boolean;
  onSuccess: (flow) => void;
  setFormHasUnsavedChanges?: (hasUnsavedChanges: boolean) => void;
}

const LogFlowForm: React.FC<LogFlowProps> = ({ flowId, version, readonly: propReadonly = false, onSuccess, setFormHasUnsavedChanges }) => {
  const user = useUser();
  const [readonly, setReadonly] = React.useState(propReadonly);
  const navigate = useNavigate();
  const { data: flowData, isLoading: isFlowLoading, refetch: refetchFlow, error: flowError } = useFlow(flowId, version);
  const flow = flowData as IFlowLog;
  const [form] = Form.useForm();
  const [hasWarnings, setHasWarnings] = React.useState<boolean>(false);
  const initialValues: any = flow;
  let [searchParams] = useSearchParams();
  const supervisorMode = searchParams.get("mode") === "supervisor";
  const [alreadyTriedToSubmit, setAlreadyTriedToSubmit] = React.useState<boolean>(false);

  const [selectedTags, setSelectedTags] = React.useState<string[]>([]);

  useEffect(() => {
    flowLogTagOptions.forEach((tag) => {
      if (initialValues?.[tag.value]) {
        setSelectedTags((prev) => {
          if (!prev.includes(tag.value)) {
            return [...prev, tag.value];
          }
          return prev;
        });
      }
    });
  }, [flow, initialValues]);

  useEffect(() => {
    for (const question of flowLogQuestionOptions) {
      if (selectedTags.includes(flowLogQuestionTagMap.get(question.value) || "")) {
        form.setFieldValue(question.value, form.getFieldValue(question.value) || (question.type === "boolean" ? false : ""));
      }
    }
  }, [selectedTags, form]);

  useEffect(() => {
    if (flow) {
      const userIsReader =
        user?.businessRole === BusinessRole.Reader || (flow.logType === LogType.log && user?.businessRole === BusinessRole.Quality);
      setReadonly(
        (propReadonly ||
          userIsReader ||
          (flow.logType === LogType.dailyInstruction &&
            user?.businessRole !== BusinessRole.Supervisor &&
            user?.businessRole !== BusinessRole.Quality)) ??
          false
      );
    }
  }, [propReadonly, user, flow]);

  if (initialValues) {
    initialValues.tags = [
      flow.tagEnvironmental ? "tagEnvironmental" : "",
      flow.tagSafety ? "tagSafety" : "",
      flow.tagQuality ? "tagQuality" : "",
      flow.tagMaintenance ? "tagMaintenance" : "",
      flow.tag5MinSafety ? "tag5MinSafety" : "",
    ].filter((tag) => tag !== "");

    for (const question of flowLogQuestionOptions) {
      if (selectedTags.includes(flowLogQuestionTagMap.get(question.value) || "")) {
        initialValues[question.value] = initialValues[question.value] || (question.type === "boolean" ? false : "");
      }
    }

    initialValues.flagToSupervisor = initialValues.flagToSupervisor || false;
  }

  if (flowError) {
    return (
      <Result
        status="500"
        title="Mmmmh... something went wrong."
        subTitle="More luck next time?"
        extra={
          <Button type="primary" onClick={() => navigate("/")}>
            Back Home
          </Button>
        }
      />
    );
  }

  if (isFlowLoading || !flow) {
    return <Spin>Loading...</Spin>;
  }

  // @ts-ignore
  return (
    <Form
      disabled={readonly}
      form={form}
      wrapperCol={{ span: 12 }}
      labelCol={{ span: 6 }}
      labelAlign={"left"}
      onFinish={async (values) => {
        setAlreadyTriedToSubmit(true);

        flowLogQuestionTagMap.forEach((tag, question) => {
          if (!selectedTags.includes(tag)) {
            delete values[question];
          }
        });
        const { tags, ...cleanedValues } = values;

        flowLogTagOptions.forEach((tag) => {
          cleanedValues[tag.value] = selectedTags.includes(tag.value);
        });

        const logFlow: IFlowLog = {
          ...flow,
          ...cleanedValues,
        };

        const { warnings } = await flowValidate(logFlow);

        if (warnings.length > 0 && !hasWarnings) {
          setHasWarnings(true);
          notification.warning({
            message: "The form has warnings",
            description: "Please check the form for warnings and enter a comment to submit despite warnings",
          });
          !hasWarnings && setHasWarnings(true);
          form.setFields(
            warnings.map((warning) => ({
              name: warning.property,
              warnings: warning.messages,
            }))
          );
          return;
        }
        try {
          const api = await getAPI();

          await api.put(`/flows/${flowId}`, {
            data: logFlow,
          });
          await api.put(`/flows/${flowId}/submit`);
          await refetchFlow();
          onSuccess && onSuccess(flow);
          notification.success({
            message: "The log was submitted successfully",
          });
        } catch (error: any) {
          notification.error({
            message: "There was an error submitting the form",
            description: error?.message,
          });
        }
      }}
      onValuesChange={async (changedValues, allValues) => {
        setFormHasUnsavedChanges && setFormHasUnsavedChanges(true);
        if (alreadyTriedToSubmit) {
          // reset warnings
          Object.keys(allValues).forEach((key) => {
            form.setFields([
              {
                name: key,
                warnings: [],
              },
            ]);
          });

          flowLogQuestionTagMap.forEach((tag, question) => {
            if (!selectedTags.includes(tag)) {
              delete allValues[question];
            }
          });
          const { tags, ...cleanedValues } = allValues;
          flowLogTagOptions.forEach((tag) => {
            cleanedValues[tag.value] = selectedTags.includes(tag.value);
          });

          const logFlow: IFlowLog = {
            ...flow,
            ...cleanedValues,
          };

          const { warnings } = await flowValidate(logFlow);

          if (warnings.length > 0) {
            setHasWarnings(true);
            form.setFields(
              warnings.map((warning) => ({
                name: warning.property,
                warnings: warning.messages,
              }))
            );
          } else if (warnings.length === 0) {
            setHasWarnings(false);
          }
        }
      }}
      initialValues={initialValues}
    >
      <Form.Item label="Area" name="area">
        <Select options={Object.keys(Area).map((key) => ({ label: key, value: Area[key] }))} />
      </Form.Item>
      <Form.Item label="Product" name="product">
        <Select
          options={Object.keys(Product).map((key) => ({
            label: key,
            value: Product[key],
          }))}
        />
      </Form.Item>

      <Form.Item label="Tags" name="tags">
        <Select
          mode="multiple"
          disabled={readonly}
          onChange={(values) => {
            setSelectedTags(values);
          }}
          options={flowLogTagOptions}
        />
      </Form.Item>
      <Form.Item name="text" label="Log text" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
        <TextArea disabled={readonly} />
      </Form.Item>

      {flowLogQuestionOptions.map((question) => {
        if (selectedTags.includes(flowLogQuestionTagMap.get(question.value) || "")) {
          return (
            <Form.Item label={question.label} name={question.value}>
              {question.type === "boolean" ? <YesNoSwitch disabled={readonly} /> : <Input disabled={readonly} />}
            </Form.Item>
          );
        }
        return null;
      })}

      <Form.Item name="files">
        <FilePictureVideoUpload disabled={readonly} />
      </Form.Item>
      <Form.Item name="flagToSupervisor" label="Flag to supervisor" valuePropName="checked">
        <Checkbox />
      </Form.Item>
      <Form.Item
        name="comment"
        label="Comment"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        rules={[{ required: hasWarnings, message: "Please add a comment to explain warnings" }]}
      >
        <TextArea disabled={readonly} />
      </Form.Item>
      <Space direction="vertical" style={{ width: "100%" }} size="large">
        {!readonly && (
          <div style={{ display: "flex", justifyContent: "right" }}>
            <Space>
              {supervisorMode ? (
                <Form.Item>
                  <Popconfirm
                    title="Save your change"
                    description="To make a change, you must be trained to do so and you MUST add an explanation in the comment box, below the existing comments. If you have done so, you can click on Save."
                    onConfirm={async () => {
                      try {
                        const values = form.getFieldsValue();

                        flowLogQuestionTagMap.forEach((tag, question) => {
                          if (!selectedTags.includes(tag)) {
                            delete values[question];
                          }
                        });

                        const { tags, ...cleanedValues } = values;

                        flowLogTagOptions.forEach((tag) => {
                          cleanedValues[tag.value] = selectedTags.includes(tag.value);
                        });
                        const api = await getAPI();
                        await api.put(`/flows/${flowId}/edit`, {
                          data: {
                            ...flow,
                            ...cleanedValues,
                          },
                        });
                        await refetchFlow();
                        notification.success({
                          message: "Changes saved",
                        });
                        setFormHasUnsavedChanges && setFormHasUnsavedChanges(false);
                      } catch (error: any) {
                        notification.error({
                          message: "Error saving changes",
                          description: error?.message,
                        });
                      }
                    }}
                    okText="Save"
                    cancelText="Cancel"
                  >
                    <Button size="large" icon={<SaveOutlined />} type="primary">
                      Save changes
                    </Button>
                  </Popconfirm>
                </Form.Item>
              ) : (
                <>
                  <Form.Item>
                    <Button
                      disabled={flowGetStatus(flow) === Status.confirmed}
                      size="large"
                      icon={<SaveOutlined />}
                      onClick={async () => {
                        const values = form.getFieldsValue();

                        flowLogQuestionTagMap.forEach((tag, question) => {
                          if (!selectedTags.includes(tag)) {
                            delete values[question];
                          }
                        });

                        const { tags, ...cleanedValues } = values;

                        flowLogTagOptions.forEach((tag) => {
                          cleanedValues[tag.value] = selectedTags.includes(tag.value);
                        });

                        try {
                          const api = await getAPI();
                          await api.put(`/flows/${flowId}`, {
                            data: {
                              ...flow,
                              ...cleanedValues,
                            },
                          });
                          await refetchFlow();
                          notification.success({
                            message: "Log draft saved",
                          });
                          setFormHasUnsavedChanges && setFormHasUnsavedChanges(false);
                        } catch (error: any) {
                          notification.error({
                            message: `Error saving log draft: ${error?.message}`,
                          });
                        }
                      }}
                    >
                      Save Draft
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <Button size="large" icon={<SendOutlined />} htmlType="submit" type="primary" disabled={!flowIsEditable(flow) || readonly}>
                      {hasWarnings ? "Submit with warnings" : "Submit"}
                    </Button>
                  </Form.Item>
                </>
              )}
            </Space>
          </div>
        )}
        <FlowStatus flow={flow} />
      </Space>
    </Form>
  );
};
export default LogFlowForm;
