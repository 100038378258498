"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.flowSafetyShowerValidate = exports.flowSafetyShowerGetLabelPeriod = exports.flowSafetyShowerGetFlowName = exports.flowSafetyShowerGetGenericLabel = exports.flowSafetyShowerGetLabel = void 0;
const utils_1 = require("../utils");
const luxon_1 = require("luxon");
const index_1 = require("./index");
function flowSafetyShowerGetLabel(flow) {
    return `${flowSafetyShowerGetGenericLabel()}`;
}
exports.flowSafetyShowerGetLabel = flowSafetyShowerGetLabel;
function flowSafetyShowerGetGenericLabel() {
    return `Weekly Eye Wash and Safety Shower Inspections`;
}
exports.flowSafetyShowerGetGenericLabel = flowSafetyShowerGetGenericLabel;
function flowSafetyShowerGetFlowName() {
    return "FlowSafetyShower";
}
exports.flowSafetyShowerGetFlowName = flowSafetyShowerGetFlowName;
function flowSafetyShowerGetLabelPeriod(flow) {
    let labelPeriod = "";
    if (flow.year && flow.periodNumber && flow.period) {
        let date = luxon_1.DateTime.fromObject({ year: flow.year, month: 1, day: 1 });
        date = date.set({ weekYear: flow.year, weekNumber: flow.periodNumber });
        labelPeriod = `Week ${flow.periodNumber} (${date.startOf("week").toFormat("LLL d")} - ${date.endOf("week").toFormat("LLL d")}) of ${flow.year}`;
    }
    return labelPeriod;
}
exports.flowSafetyShowerGetLabelPeriod = flowSafetyShowerGetLabelPeriod;
function flowSafetyShowerValidate(flow) {
    let errors = [];
    let warnings = [];
    const labelNoObstruction = "Station free of obstructions";
    const labelSignCondition = "Sign condition";
    const labelLightWorking = "Light working";
    const labelFlowAlarmOperational = "Flow alarm operational";
    const labelEyewashWorking = "Eyewash working properly";
    const labelWaterFlowClear = "Water flow clear";
    const labelShowerWorking = "Shower working properly";
    const labelAnyTieIns = "Any tie-ins";
    const labelPeriod = "Period";
    const labelPeriodNumber = "Period number";
    const labelYear = "Year";
    warnings = (0, index_1.validationMissingValue)(warnings, flow.noObstruction, "noObstruction", labelNoObstruction);
    warnings = (0, index_1.validationMissingValue)(warnings, flow.signCondition, "signCondition", labelSignCondition);
    warnings = (0, index_1.validationMissingValue)(warnings, flow.lightWorking, "lightWorking", labelLightWorking);
    warnings = (0, index_1.validationMissingValue)(warnings, flow.flowAlarmOperational, "flowAlarmOperational", labelFlowAlarmOperational);
    warnings = (0, index_1.validationMissingValue)(warnings, flow.eyewashWorking, "eyewashWorking", labelEyewashWorking);
    warnings = (0, index_1.validationMissingValue)(warnings, flow.waterFlowClear, "waterFlowClear", labelWaterFlowClear);
    warnings = (0, index_1.validationMissingValue)(warnings, flow.showerWorking, "showerWorking", labelShowerWorking);
    warnings = (0, index_1.validationMissingValue)(warnings, flow.anyTieIns, "anyTieIns", labelAnyTieIns);
    warnings = (0, index_1.validationMissingValue)(warnings, flow.period, "period", labelPeriod);
    warnings = (0, index_1.validationMissingValue)(warnings, flow.periodNumber, "periodNumber", labelPeriodNumber);
    warnings = (0, index_1.validationMissingValue)(warnings, flow.year, "year", labelYear);
    warnings = (0, index_1.validationBooleanValue)(warnings, flow.noObstruction, true, "noObstruction", labelNoObstruction);
    warnings = (0, index_1.validationBooleanValue)(warnings, flow.signCondition, true, "signCondition", labelSignCondition);
    warnings = (0, index_1.validationBooleanValue)(warnings, flow.lightWorking, true, "lightWorking", labelLightWorking);
    warnings = (0, index_1.validationBooleanValue)(warnings, flow.flowAlarmOperational, true, "flowAlarmOperational", labelFlowAlarmOperational);
    warnings = (0, index_1.validationBooleanValue)(warnings, flow.eyewashWorking, true, "eyewashWorking", labelEyewashWorking);
    warnings = (0, index_1.validationBooleanValue)(warnings, flow.waterFlowClear, true, "waterFlowClear", labelWaterFlowClear);
    warnings = (0, index_1.validationBooleanValue)(warnings, flow.showerWorking, true, "showerWorking", labelShowerWorking);
    warnings = (0, index_1.validationBooleanValue)(warnings, flow.anyTieIns, true, "anyTieIns", labelAnyTieIns);
    if (flow.year && flow.periodNumber) {
        const now = (0, utils_1.nowDateTime)();
        if (flow.year === now.year && flow.periodNumber > now.weekNumber) {
            warnings.push({
                property: "periodNumber",
                message: `${labelPeriodNumber} is in the future`,
            });
        }
    }
    return {
        errors,
        warnings,
    };
}
exports.flowSafetyShowerValidate = flowSafetyShowerValidate;
