import React, { useEffect, useRef, useState } from "react";
import { Button, Modal, Space, Spin } from "antd";
import "./VideoCaptureModal.css";
import { CameraOutlined } from "@ant-design/icons";
import Webcam from "react-webcam";
import useUpload from "../../../hooks/useUpload";

function VideoCaptureModal({ open, setOpen, setAsset }) {
  const webcamRef = useRef(null);
  const mediaRecorderRef = React.useRef<MediaRecorder | null>(null);
  const [capturing, setCapturing] = React.useState(false);
  const [recordedBlobs, setRecordedBlobs] = React.useState<BlobPart[]>([]);

  const [isWebcamLoading, setIsWebcamLoading] = useState(true);

  useEffect(() => {
    setRecordedBlobs([]);
  }, [open]);

  const handleCaptureClick = async () => {
    if (!capturing) {
      setCapturing(true);
      // @ts-ignore
      mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
        mimeType: "video/webm",
      });
      mediaRecorderRef.current.addEventListener("dataavailable", handleDataAvailable);
      mediaRecorderRef.current.start();
    } else {
      // @ts-ignore
      mediaRecorderRef.current.stop();
      setCapturing(false);
    }
  };

  const handleDataAvailable = async ({ data }) => {
    if (data.size > 0) {
      setRecordedBlobs([data]);
    }
  };

  const uploadVideo = async () => {
    const file = new File(recordedBlobs, "video.webm", {
      type: "video/webm",
    });
    const asset = { rawFile: file, name: "recording_" + Date.now() };
    const { data: uploadedData } = await upload(asset);
    const [video] = uploadedData;
    setAsset(video);
    setOpen(false);
  };

  const [counter, setCounter] = useState(30); // NEW: Counter state

  useEffect(() => {
    let interval;
    if (capturing) {
      interval = setInterval(() => {
        setCounter((prevCounter) => {
          if (prevCounter <= 1) {
            clearInterval(interval);
            handleCaptureClick();
            return 30; // Reset to initial value for next recording
          }

          return prevCounter - 1;
        });
      }, 1000);
    } else {
      clearInterval(interval);
      setCounter(30); // Reset counter when capturing is done
    }
    return () => clearInterval(interval);
  }, [capturing]);

  const { upload } = useUpload();

  const videoBlobUrl = recordedBlobs
    ? URL.createObjectURL(
        new Blob(recordedBlobs, {
          type: "video/webm",
        })
      )
    : null;

  // @ts-ignore
  return (
    <Modal
      className={"video-capture-modal"}
      destroyOnClose={true}
      footer={null}
      styles={{ body: { position: "relative", minWidth: 640 } }}
      title={
        <Space>
          <CameraOutlined />
          Capture a video
        </Space>
      }
      open={open}
      width={"690px"}
      onCancel={() => setOpen(false)}
    >
      {isWebcamLoading && (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: 900, paddingTop: 50 }}>
          <Spin spinning={true} />
        </div>
      )}
      {capturing && (
        <div
          style={{
            position: "absolute",
            textAlign: "center",
            top: 10,
            width: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            padding: "5px 30px",
            borderRadius: "5px",
            color: "white",
          }}
        >
          🔴 {counter}s
        </div>
      )}
      {recordedBlobs.length > 0 && videoBlobUrl && (
        <>
          <video controls width={900} height={600}>
            <source src={videoBlobUrl} />
          </video>
          <Space>
            <Button onClick={() => setRecordedBlobs([])}>Retake</Button>
            <Button type={"primary"} onClick={uploadVideo}>
              Upload
            </Button>
          </Space>
        </>
      )}
      {recordedBlobs.length === 0 && (
        <>
          {
            // @ts-ignore
            <Webcam
              ref={webcamRef}
              audio={false}
              screenshotFormat="image/jpeg"
              videoConstraints={{
                width: 900,
                height: 600,
                facingMode: "environment",
              }}
              onUserMedia={() => {
                setIsWebcamLoading(false);
              }}
            />
          }
          <div className="video-capture-button-container">
            <Button onClick={handleCaptureClick}></Button>
          </div>
        </>
      )}
    </Modal>
  );
}

export default VideoCaptureModal;
