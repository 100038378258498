import { Table } from "antd";
import React, { useEffect, useState } from "react";
import "./BatchHistoryTable.css";
import useBatchHistory from "../../../hooks/useBatchHistory";
import { ColumnsType } from "antd/es/table";
import { useParams } from "react-router-dom";
import { ZoomContext } from "../../../App";
import { ArrayParam, NumberParam, useQueryParams } from "use-query-params";
import useWindowResize from "../../../hooks/useWindowResize";
import { nicelyDisplayDateFromISOString } from "shared/utils";

const BatchHistoryTable = () => {
  const { batchId } = useParams();
  const { height } = useWindowResize();

  const [tableHeaderHeight, setTableHeaderHeight] = useState(0);
  useEffect(() => {
    // Function to update state with the height of the th element
    const updateHeight = () => {
      const thElement = document.querySelector("th");
      if (thElement) {
        setTableHeaderHeight(thElement.clientHeight);
      }
    };

    // Set up ResizeObserver
    const resizeObserver = new ResizeObserver(updateHeight);
    const thElement = document.querySelector("th");
    if (thElement) {
      resizeObserver.observe(thElement);
    }

    // Clean up observer on component unmount
    return () => {
      if (thElement) {
        resizeObserver.unobserve(thElement);
      }
    };
  }, []);

  const [zoom] = React.useContext(ZoomContext);
  const zoomRatio = parseInt(zoom) / 100;

  const [query, setQuery] = useQueryParams({
    page: NumberParam,
    pageSize: NumberParam,
    total: NumberParam,
    sortParams: ArrayParam,
  });

  const sortParams = query.sortParams || [];

  if (!sortParams.find((s) => s?.startsWith("version"))) {
    sortParams.push("version:desc");
  }

  const batchHistoryQueryConfig = {
    page: query.page || 1,
    pageSize: query.pageSize || 100,
    filters: { batch: { id: batchId } },
    ...(sortParams ? { sort: sortParams } : {}),
  };

  const { data } = useBatchHistory(batchHistoryQueryConfig);

  const { data: batchHistory = [] } = data || {};

  const total = data?.meta?.pagination?.total;
  const page = data?.meta?.pagination?.page;
  const pageSize = data?.meta?.pagination?.pageSize;

  const columns: ColumnsType<any> = [
    {
      title: "Batch id",
      dataIndex: ["batch", "id"],
      width: 15 * zoomRatio,
      fixed: "left",
    },
    {
      title: "Version",
      dataIndex: "version",
      width: 15 * zoomRatio,
      fixed: "left",
    },
    {
      title: "Number",
      dataIndex: "batchNumber",
      width: 25 * zoomRatio,
    },
    {
      title: "Material",
      dataIndex: ["material", "number"],
      width: 25 * zoomRatio,
    },
    {
      title: "Process Order",
      dataIndex: "sapProcessOrder",
      width: 25 * zoomRatio,
    },
    {
      title: "Edition date",
      dataIndex: "editedAt",
      width: 30 * zoomRatio,
      render: (editedAt) => (editedAt ? nicelyDisplayDateFromISOString(editedAt) : ""),
    },
    {
      title: "Edited by",
      width: 40 * zoomRatio,
      dataIndex: "editedBy",
      render: (editedBy) => editedBy?.email ?? "",
    },
  ];

  return (
    <div
      style={{
        padding: 20,
      }}
    >
      <Table
        size={"small"}
        className={"batch-history-table"}
        onChange={(pagination) => {
          setQuery({ page: pagination.current || 1 });
          setQuery({ pageSize: pagination.pageSize || 100 });
        }}
        scroll={{ x: 1000 * zoomRatio, ...(height ? { y: height - 125 - 25 * zoomRatio - tableHeaderHeight } : {}) }}
        pagination={{
          current: page,
          total,
          pageSize,
        }}
        columns={columns}
        dataSource={batchHistory}
        bordered={true}
      />
    </div>
  );
};

export default BatchHistoryTable;
