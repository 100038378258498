import { useState } from "react";
import getAPI from "../services/api";
import { useMutation } from "@tanstack/react-query";

const controller = new AbortController();

const uploadAsset = async (asset, onProgress) => {
  const { rawFile, caption, name, alternativeText } = asset;

  const formData = new FormData();

  formData.append(
    "fileInfo",
    JSON.stringify({
      name,
      caption: caption || name,
      alternativeText: alternativeText || name,
    })
  );

  formData.append("files", rawFile);
  const api = await getAPI();

  return api({
    method: "post",
    url: "upload/",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formData,
    signal: controller.signal,
    onUploadProgress({ total, loaded }) {
      if (typeof total === "number") {
        onProgress((loaded / total) * 100);
      }
    },
  });
};

const useUpload = () => {
  const [progress, setProgress] = useState(0);

  const mutation = useMutation(async (asset) => uploadAsset(asset, setProgress), {
    onSuccess: (data) => {},
  });

  const upload = (asset) => mutation.mutateAsync(asset);
  const cancel = () => controller.abort();

  return {
    upload,
    cancel,
    error: mutation.error,
    progress,
    status: mutation.status,
  };
};

export default useUpload;
