import React, { useEffect, useState } from "react";
import { Button, List, Space, Tag } from "antd";
import { CameraOutlined, DeleteOutlined, EyeOutlined, FileOutlined, PictureOutlined } from "@ant-design/icons";
import PhotoCaptureModal from "./PhotoCaptureModal/PhotoCaptureModal";
import PhotoViewModal from "./PhotoViewModal/PhotoViewModal";
import useUpload from "../../hooks/useUpload";

interface PictureUploadProps {
  disabled?: boolean;
  value?: any[];
  onChange?: (value: any[]) => void;
}

const PictureUpload: React.FC<PictureUploadProps> = ({ value = [], onChange, disabled }) => {
  const [isPhotoCaptureModalOpen, setIsPhotoCaptureModalOpen] = useState(false);
  const [assets, setAssets] = useState<any[]>(value);
  const [viewedPhotoId, setViewedPhotoId] = useState(null);

  const { upload } = useUpload();

  useEffect(() => {
    if (value?.[0]?.id) {
      onChange && onChange(value?.map(({ id }) => id));
    }
  }, [value]);

  const tagRender = ({ label, value: id }) => {
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        icon={<PictureOutlined />}
        onMouseDown={onPreventMouseDown}
        onClick={(e) => {
          e.preventDefault();
          setViewedPhotoId(id);
        }}
        onClose={() => {
          onChange && onChange(value?.filter(({ id: assetId }) => assetId !== id));
        }}
        closable={true}
        style={{ marginRight: 3 }}
      >
        <Button type="link">
          <span style={{ textDecoration: "underline", color: "black" }}>{label}</span>
        </Button>
      </Tag>
    );
  };

  const addAsset = (asset) => {
    const updatedAssets = [...assets, asset];
    const updatedValues = [...(assets ? assets.map(({ id }) => id) : []), asset.id];
    setAssets(updatedAssets);
    if (onChange) {
      onChange(updatedValues);
    }
    setIsPhotoCaptureModalOpen(false);
  };

  const removeAsset = (asset) => {
    const updatedAssets = assets.filter(({ id }) => id !== asset.id);
    const updatedValues = updatedAssets.map(({ id }) => id);
    setAssets(updatedAssets);
    if (onChange) {
      onChange(updatedValues);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        gap: 8,
        justifyContent: "space-between",
        alignItems: "stretch",
        position: "relative",
      }}
    >
      {/* Not sure why, but stretch will not work directly on the Button component so wrapping it in a div */}
      <Space direction="vertical">
        <Space>
          <Button icon={<CameraOutlined />} onClick={() => setIsPhotoCaptureModalOpen(true)} style={{ minWidth: 35, height: "100%" }}>
            Take a picture
          </Button>
        </Space>
        <PhotoCaptureModal open={isPhotoCaptureModalOpen} setOpen={setIsPhotoCaptureModalOpen} setAsset={addAsset} />
        <PhotoViewModal open={!!viewedPhotoId} setOpen={setViewedPhotoId} id={viewedPhotoId} />
        <List
          locale={{ emptyText: "No media selected" }}
          style={{
            overflow: "auto",
            maxWidth: 700,
            minWidth: 500,
            marginTop: 10,
            padding: 10,
            border: "1px solid rgba(140, 140, 140, 0.35)",
            borderRadius: 2,
          }}
          itemLayout="horizontal"
          dataSource={assets}
          renderItem={(item: any) => {
            if (!item) return null;
            return (
              <List.Item>
                <List.Item.Meta
                  avatar={
                    item?.formats?.thumbnail?.url || item?.attributes?.formats?.thumbnail?.url ? (
                      item?.formats?.thumbnail?.url ? (
                        <img
                          alt=""
                          src={`${process.env.REACT_APP_STORAGE_URL ?? ""}${item?.formats?.thumbnail?.url}`}
                          style={{
                            width: 50,
                          }}
                        />
                      ) : (
                        <img
                          alt=""
                          src={`${process.env.REACT_APP_STORAGE_URL ?? ""}${item?.attributes?.formats?.thumbnail?.url}`}
                          style={{
                            width: 50,
                          }}
                        />
                      )
                    ) : (
                      <div
                        style={{
                          width: 50,
                          height: 50,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <FileOutlined />
                      </div>
                    )
                  }
                  title={item.attributes?.name || item?.name}
                />
                <Space>
                  {(item.mime === "image/jpeg" || item.attributes?.mime === "image/jpeg") && (
                    <Button
                      disabled={false}
                      icon={<EyeOutlined />}
                      onClick={(e) => {
                        setViewedPhotoId(item.id);
                      }}
                    />
                  )}
                  {!disabled && <Button onClick={() => removeAsset(item)} icon={<DeleteOutlined />} />}
                </Space>
              </List.Item>
            );
          }}
        />
      </Space>
    </div>
  );
};

export default PictureUpload;
