import { Button, Form, Input, notification, Select, Space, Spin } from "antd";
import { CheckCircleFilled, CloseOutlined, SendOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import useBatch from "../../hooks/useBatch";
import useSources from "../../hooks/useSources";
import useBlends from "../../hooks/useBlends";
import getAPI from "../../services/api";
import {
  IBlendPossible,
  ISourcePossible,
  Product,
  batchGetCurrentSource,
  batchGetCurrentBlend,
  batchGetCurrentPkgPalletId,
  IBatchLine,
  batchLineValidate,
  BatchLineType,
} from "shared/interfaces";
import Code128Input from "../UI/Code128Input";

const NewBatchLineForm: React.FC<{
  batchId: number | undefined;
  onSuccess?: () => void;
  onCancel?: () => void;
}> = ({ batchId, onSuccess, onCancel }) => {
  const { data: batch } = useBatch(batchId);
  const [product, setProduct] = useState<Product | undefined>(undefined);
  const [sourceId, setSourceId] = useState<ISourcePossible["id"] | undefined>(undefined);
  const [blendId, setBlendId] = useState<IBlendPossible["id"] | undefined>(undefined);
  const [batchLines, setBatchLines] = useState<IBatchLine[] | undefined>(undefined);
  const { data: sources } = useSources({ product, materialId: batch?.material?.id });
  const { data: blends } = useBlends({ product, sourceId: sourceId });
  const [initialFormRendered, setInitialFormRendered] = useState<boolean>(false);

  const [form] = Form.useForm();
  const [hasWarnings, setHasWarnings] = React.useState<boolean>(false);

  useEffect(() => {
    if (hasWarnings) {
      const fieldIssues = form.getFieldsError();
      if (fieldIssues.reduce((acc, { warnings }) => acc + warnings.length, 0) === 0) {
        setHasWarnings(false);
      }
    }

    if (batch) {
      if (batch.batchLines) {
        setBatchLines(
          batch.batchLines.sort((a, b) => {
            if (a.id === undefined) return -1;
            if (b.id === undefined) return 1;
            return a.id - b.id;
          })
        );
      }
      setProduct(batch?.material?.product);
      setSourceId(batchLines?.[batchLines?.length - 1]?.source?.id);
      setBlendId(batchLines?.[batchLines?.length - 1]?.blend?.id);
    }

    if (sourceId) {
      setSourceId(sourceId);
    }

    if (blends && sources && sourceId && blendId && !initialFormRendered) {
      setInitialFormRendered(true);
    }
  }, [batch, batchLines, sources, blends, sourceId, blendId, initialFormRendered, form, hasWarnings]);

  if (((!blends || !sources) && !initialFormRendered) || !batch) return <Spin spinning={true} />;

  return (
    <Form
      form={form}
      name="basic"
      labelCol={{ span: 10 }}
      wrapperCol={{ span: 14 }}
      style={{ width: "100%" }}
      onFinish={async (values: any) => {
        try {
          const batchLine: IBatchLine = {
            type: BatchLineType.regular,
            source: sources?.find((s) => s.id === values.sourceId),
            blend: blends?.find((b) => b.id === values.blendId),
            pkgPalletId: form.getFieldValue("pkgPalletId") || "",
            blendNumber: values.blendNumber,
          };

          const { warnings } = await batchLineValidate(batchLine, batch?.material);

          if (warnings.length > 0 && !hasWarnings) {
            const fieldWarnings: { name: string; warnings: string[] }[] = [];
            fieldWarnings.push(
              ...warnings.map((warning) => ({
                name: replaceNAProperties(warning.property),
                warnings: [warning.message],
              }))
            );
            form.setFields(fieldWarnings);
            setHasWarnings(true);
            return;
          } else {
            setHasWarnings(false);
          }
          const api = await getAPI();

          await api.put(`/batches/${batchId}/sourceBlendPkgPalletId`, values);

          notification.success({
            message: "Success",
            description: "A new set of pallets has been logged",
          });
          onSuccess && onSuccess();
        } catch (error: any) {
          notification.error({
            message: "Error",
            description: `${error.message}`,
          });
        }
      }}
      onFinishFailed={async ({ values }) => {
        const batchLine: IBatchLine = {
          type: BatchLineType.regular,
          source: sources?.find((s) => s.id === values.sourceId),
          blend: blends?.find((b) => b.id === values.blendId),
          pkgPalletId: form.getFieldValue("pkgPalletId") || "",
          blendNumber: values.blendNumber,
        };

        const { warnings } = await batchLineValidate(batchLine, batch?.material);

        if (warnings.length > 0) {
          !hasWarnings && setHasWarnings(true);
          const fieldWarnings: { name: string; warnings: string[] }[] = [];

          fieldWarnings.push(
            ...warnings.map((warning) => ({
              name: replaceNAProperties(warning.property),
              warnings: [warning.message],
            }))
          );

          form.setFields(fieldWarnings);
        }
      }}
      onValuesChange={async (changedValues, allValues) => {
        if (sources && changedValues.sourceId) {
          setSourceId(changedValues.sourceId);
        }

        if (changedValues.blendId) {
          setBlendId(changedValues.blendId);
        }

        // reset warnings
        Object.keys(allValues).forEach((key) => {
          form.setFields([
            {
              name: key,
              warnings: [],
            },
          ]);
        });
        // if we have errors (or rather warnings), we set them in the form, the user can still submit)
        const batchLine: IBatchLine = {
          type: BatchLineType.regular,
          source: sources?.find((s) => s.id === allValues.sourceId),
          blend: blends?.find((b) => b.id === allValues.blendId),
          pkgPalletId: form.getFieldValue("pkgPalletId") || "",
          blendNumber: allValues.blendNumber,
        };

        const { warnings } = await batchLineValidate(batchLine, batch?.material);

        if (warnings.length > 0 && hasWarnings) {
          const fieldWarnings: { name: string; warnings: string[] }[] = [];

          fieldWarnings.push(
            ...warnings.map((warning) => ({
              name: replaceNAProperties(warning.property),
              warnings: [warning.message],
            }))
          );

          form.setFields(fieldWarnings);
        } else if (warnings.length === 0 && hasWarnings) {
          setHasWarnings(false);
          form.setFields([
            {
              name: "comment",
              errors: [],
            },
          ]);
        }
      }}
      autoComplete="off"
      initialValues={{
        sourceId: batchGetCurrentSource(batch)?.id,
        blendId: batchGetCurrentBlend(batch)?.id,
        pkgPalletId: batchGetCurrentPkgPalletId(batch),
        blendNumber: batchLines?.[batchLines?.length - 1]?.blendNumber,
      }}
    >
      <Form.Item label="Source" name="sourceId">
        <Select
          showSearch
          filterOption={(input, option) => (option?.reference ?? "").toLowerCase().includes(input.toLowerCase())}
          onDropdownVisibleChange={(open) => {
            return !open;
          }}
          options={[
            { label: "", value: null, reference: "" },
            ...(sources || []).map(({ id, reference, possible }) => ({
              value: id,
              reference,
              label: (
                <>
                  <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                    <span>{reference}</span>
                    {possible && <CheckCircleFilled style={{ fontSize: "0.7rem" }} />}
                  </div>
                </>
              ),
            })),
          ]}
        />
      </Form.Item>
      <Form.Item label="Path" name="blendId">
        <Select
          showSearch
          filterOption={(input, option) => (option?.reference ?? "").toLowerCase().includes(input.toLowerCase())}
          onDropdownVisibleChange={(open) => {
            return !open;
          }}
          options={[
            { label: "", value: null, reference: "" },
            ...(blends || []).map(({ id, reference, possible }) => ({
              value: id,
              reference,
              label: (
                <>
                  <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                    <span>{reference}</span>
                    {possible && <CheckCircleFilled style={{ fontSize: "0.7rem" }} />}
                  </div>
                </>
              ),
            })),
          ]}
        />
      </Form.Item>
      {product === Product.Ca && (
        <Form.Item label="Blend number" name="blendNumber">
          <Input />
        </Form.Item>
      )}
      <Form.Item label="Pkg supply pallet id" name="pkgPalletId">
        <Code128Input />
      </Form.Item>
      <Form.Item label="Comment" name="comment" rules={[{ required: hasWarnings, message: "Please input a comment!" }]}>
        <Input.TextArea />
      </Form.Item>
      <div style={{ display: "flex", justifyContent: "right", width: "100%" }}>
        <Space>
          <Button
            size="large"
            icon={<CloseOutlined />}
            onClick={async () => {
              onCancel && onCancel();
            }}
          >
            Cancel
          </Button>
          <Button size="large" icon={<SendOutlined />} htmlType="submit" type="primary">
            {hasWarnings ? "Submit with warnings" : "Submit"}
          </Button>
        </Space>
      </div>
    </Form>
  );
};

export default NewBatchLineForm;

let replacements = {
  source: "sourceId",
  blend: "blendId",
};

function replaceNAProperties(property: string) {
  let replacedProperty = property;
  for (let [key, value] of Object.entries(replacements)) {
    let regex = new RegExp(`\\b${key}\\b`, "g"); // 'g' flag for global replacement
    replacedProperty = replacedProperty.replace(regex, value);
  }
  return replacedProperty;
}
