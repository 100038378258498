import { Button, Card, Col, Descriptions, notification, Row, Space, Tag, Typography } from "antd";
import React, { useState, useEffect } from "react";
import ScaleReadingTable from "./ScaleReadingTable";
import BatchLineTable from "./BatchLineTable";
import {
  BatchType,
  PkgType,
  flowGetStatus,
  Status,
  FlowType,
  batchHasOpenBatchLine,
  BusinessRole,
  shiftIsSigned,
  shiftIsSignedBySupervisorAndQuality,
} from "shared/interfaces";
import useBatch from "../../../hooks/useBatch";
import NewBatchLineModal from "../NewBatchLineModal";
import CloseBatchLineModal from "../CloseBatchLineModal";
import NewMetalDetectionModal from "../NewMetalDetectionModal";
import BatchCommentModal from "../BatchCommentModal";
import BatchManualFlagModal from "../BatchManualFlagModal";
import MaterialDetailsModal from "../MaterialDetailsModal";
import CloseBatchModal from "../CloseBatchModal";
import getAPI from "../../../services/api";
import { useParams } from "react-router";
import useShift from "../../../hooks/useShift";
import { ArrowLeftOutlined, EyeOutlined, RedoOutlined, EditOutlined, FileSyncOutlined, CheckOutlined } from "@ant-design/icons";
import BatchRecordTableModal from "./BatchRecordTableModal";
import { useUser } from "../../../services/auth";
import { useNavigate } from "react-router-dom";
import BatchStatus from "../../UI/BatchStatus";
import BatchEditModal from "../../SupervisorScreen/Batches/BatchEditModal";
const { Paragraph } = Typography;

interface BatchDetailsProps {
  batchId?: number;
  readonly?: boolean;
  showBack?: boolean;
}

const BatchDetails: React.FC<BatchDetailsProps> = ({ batchId, readonly: propReadonly = false, showBack = false }) => {
  const user = useUser();
  const [readonly, setReadonly] = useState(propReadonly);
  const { batchId: batchIdString } = useParams();
  batchId = batchId || Number(batchIdString);
  const { data: batch } = useBatch(batchId);
  const [closeBatchLineModalOpen, setCloseBatchLineModalOpen] = useState(false);
  const [newBatchLineModalOpen, setNewBatchLineModalOpen] = useState(false);
  const { shiftId } = useParams();
  const { data: shift, refetch: refetchShift } = useShift(shiftId);
  const flowCheckOut = (shift?.flows || []).find((flow) => flow.type === FlowType.shiftCheckOut);
  const shiftCheckOutDone = !!flowCheckOut && flowGetStatus(flowCheckOut) === Status.confirmed;
  const [flowId, setFlowId] = useState<number | undefined>(undefined);
  const [closeBatchModalOpen, setCloseBatchModalOpen] = useState(false);
  const [metalDetectionModalOpen, setMetalDetectionModalOpen] = useState(false);
  const [batchCommentModalOpen, setBatchCommentModalOpen] = useState(false);
  const [batchManualFlagModalOpen, setBatchManualFlagModalOpen] = useState(false);
  const [batchRecordTableModalOpen, setBatchRecordTableModalOpen] = useState(false);
  const [materialDetailsModalOpen, setMaterialDetailsModalOpen] = useState(false);
  const [batchRecordAlreadyDone, setBatchRecordAlreadyDone] = useState<boolean>(false);
  const [isBatchEditModalOpen, setIsBatchEditModalOpen] = useState(false);
  const [canEdit, setCanEdit] = useState(false);

  useEffect(() => {
    const userIsReader = user?.businessRole === BusinessRole.Reader || user?.businessRole === BusinessRole.Quality;
    setReadonly((propReadonly || userIsReader) ?? false);
  }, [propReadonly, user]);

  useEffect(() => {
    const isBatchRecordAlreadyDone =
      shift?.flows?.some((flow) => {
        return flow.type === FlowType.batchRecord && flow.batch?.id === batchId && flowGetStatus(flow) === Status.confirmed;
      }) ?? false;

    setBatchRecordAlreadyDone(isBatchRecordAlreadyDone);
  }, [shift, batchId]);

  useEffect(() => {
    if (user && shift) {
      if (!shiftIsSigned(shift)) {
        setCanEdit(user?.businessRole === BusinessRole.Supervisor || user?.businessRole === BusinessRole.Operator);
      } else if (shiftIsSignedBySupervisorAndQuality(shift)) {
        setCanEdit(false);
      } else {
        setCanEdit(user?.businessRole === BusinessRole.Supervisor);
      }
    }
  }, [user, shift]);

  const handleCloseBatchLineSuccess = () => {
    refetchShift();
  };

  const navigate = useNavigate();

  return (
    <>
      {(shift || readonly) && batch && (
        <Row gutter={[16, 24]}>
          <Col span={batch.type !== BatchType.regular || batch.material?.pkgType !== PkgType.SS ? 12 : 10}>
            <Descriptions
              className="batch-descriptions"
              title={
                <Space>
                  {showBack && (
                    <Button
                      type="link"
                      onClick={() => {
                        navigate(-1);
                      }}
                      style={{ color: "#000" }}
                      icon={<ArrowLeftOutlined />}
                    ></Button>
                  )}
                  Batch {batch.batchNumber}
                  {batch.type === BatchType.rework && (
                    <Tag style={{ position: "absolute", top: 0 }} color="orange">
                      <RedoOutlined style={{ marginRight: 5, fontSize: "0.9em" }} />
                      Rework
                    </Tag>
                  )}
                </Space>
              }
            >
              {batch.type === BatchType.rework && (
                <Descriptions.Item style={{ display: "block", height: "min-content" }} label="Reason for movement">
                  {batch.reasonForMovement?.reason ?? ""}
                </Descriptions.Item>
              )}
              <Descriptions.Item style={{ display: "block", height: "min-content" }} label="Process order number">
                {batch.sapProcessOrder}
              </Descriptions.Item>
              <Descriptions.Item style={{ display: "block", height: "min-content" }} label="Material">
                {batch.material?.number ?? ""}{" "}
                <Button
                  type="link"
                  style={{ marginLeft: 8, height: 0, padding: 0, color: "#70bf45" }}
                  icon={<EyeOutlined />}
                  onClick={() => setMaterialDetailsModalOpen(true)}
                />
              </Descriptions.Item>
              <Descriptions.Item style={{ display: "block", height: "min-content" }} label="Material description">
                {batch.material?.description ?? ""}
              </Descriptions.Item>
              {batch.type === BatchType.regular && (
                <Descriptions.Item style={{ display: "block", height: "min-content" }} label="Expiry format">
                  {batch.material?.expiryFormat ?? ""}
                </Descriptions.Item>
              )}
              {batch.type === BatchType.regular && batch.material?.pkgType === PkgType.SS && (
                <Descriptions.Item style={{ display: "block", height: "min-content" }} label="Comment">
                  <Paragraph ellipsis={{ rows: 4 }}>{batch.comment ?? ""}</Paragraph>
                  <Button
                    type="link"
                    style={{ marginLeft: 8, height: 0, padding: 0, color: "#70bf45" }}
                    icon={<EyeOutlined />}
                    onClick={() => setBatchCommentModalOpen(true)}
                  />
                </Descriptions.Item>
              )}
            </Descriptions>
            <BatchStatus batch={batch} />
            <Space wrap={true}>
              {!batch.closedAt && batchHasOpenBatchLine(batch) && !shiftCheckOutDone && !readonly && (
                <Button
                  type="primary"
                  ghost
                  onClick={() => {
                    setCloseBatchLineModalOpen(true);
                  }}
                >
                  Log latest set of pallets
                </Button>
              )}
              {!batch.closedAt && !batchHasOpenBatchLine(batch) && !shiftCheckOutDone && !readonly && !batchRecordAlreadyDone && (
                <Button
                  type="primary"
                  ghost
                  onClick={() => {
                    setNewBatchLineModalOpen(true);
                  }}
                >
                  New set of pallets
                </Button>
              )}
              {!batch.closedAt && !shiftCheckOutDone && !readonly && (
                <Button
                  type="primary"
                  ghost
                  onClick={() => {
                    setMetalDetectionModalOpen(true);
                  }}
                >
                  Metal detection
                </Button>
              )}
              {batch.type === BatchType.regular && (
                <Button
                  type="primary"
                  ghost
                  onClick={() => {
                    setBatchRecordTableModalOpen(true);
                  }}
                >
                  Batch records <FileSyncOutlined />
                </Button>
              )}

              {!batch.closedAt && !batchHasOpenBatchLine(batch) && !shiftCheckOutDone && !readonly && (
                <Button
                  type="primary"
                  ghost
                  onClick={async () => {
                    const api = await getAPI();
                    try {
                      // trying to get a batch record flow
                      try {
                        const resp = await api.post(`/flows/${shiftId}/flowBatchRecord`, { data: { batchId } });
                        const flow = resp.data;
                        setFlowId(flow?.id);
                      } catch (e) {
                        // if it already exists, get it
                        await refetchShift;
                        shift?.flows?.forEach((flow) => {
                          if (flow.type === FlowType.batchRecord && flow.batch?.id === batchId) {
                            const flowStatus = flowGetStatus(flow);
                            if (flowStatus === Status.draft || flowStatus === Status.toDo) {
                              setFlowId(flow?.id);
                            }
                          }
                        });
                      }
                      // TODO: get this cleanly from the API
                      // if it doesn't exist, id will be undefined and the batch record step will be skipped
                      setCloseBatchModalOpen(true);
                    } catch (e) {
                      // @ts-ignore
                      notification.error({
                        message: "Error",
                        description: `${e}`,
                      });
                    }
                  }}
                >
                  Close batch <CheckOutlined />
                </Button>
              )}

              {!readonly && (user?.businessRole === BusinessRole.Supervisor || user?.businessRole === BusinessRole.Operator) && (
                <Button
                  type="primary"
                  ghost
                  onClick={() => {
                    setBatchManualFlagModalOpen(true);
                  }}
                >
                  Create flag
                </Button>
              )}

              {canEdit && (
                <Button
                  type="link"
                  icon={<EditOutlined />}
                  style={{ color: "#70bf45" }}
                  onClick={() => {
                    setIsBatchEditModalOpen(true);
                  }}
                />
              )}
            </Space>
          </Col>
          {(batch.type !== BatchType.regular || batch.material?.pkgType === PkgType.Bag) && (
            <Col span={12}>
              <Descriptions className="batch-descriptions" style={{ marginTop: "50px" }}>
                <Descriptions.Item style={{ display: "block", height: "min-content" }} label="Comment">
                  <Paragraph ellipsis={{ rows: 6 }}>{batch.comment ?? ""}</Paragraph>
                  <Button
                    type="link"
                    style={{ marginLeft: 8, height: 0, padding: 0, color: "#70bf45" }}
                    icon={<EyeOutlined />}
                    onClick={() => setBatchCommentModalOpen(true)}
                  />
                </Descriptions.Item>
              </Descriptions>
            </Col>
          )}
          {batch.type === BatchType.regular && batch.material?.pkgType === PkgType.SS && (
            <Col span={14} style={{ textAlign: "right" }}>
              <Card
                // style={{ border: "1px solid #AAA" }}
                title={<div style={{ display: "flex", justifyContent: "space-between" }}>Scale Readings</div>}
              >
                <ScaleReadingTable batchId={batch.id} />
              </Card>
            </Col>
          )}
          <Col span={24} style={{ textAlign: "right" }}>
            <BatchLineTable batchId={batch.id} readonly={readonly} />
          </Col>
        </Row>
      )}
      <CloseBatchLineModal
        open={closeBatchLineModalOpen}
        setOpen={setCloseBatchLineModalOpen}
        batchId={batch?.id}
        onCloseSuccess={() => handleCloseBatchLineSuccess()}
      />
      <CloseBatchModal open={closeBatchModalOpen} setOpen={setCloseBatchModalOpen} batchId={batch?.id} flowId={flowId} />
      <NewBatchLineModal open={newBatchLineModalOpen} setOpen={setNewBatchLineModalOpen} batchId={batch?.id} />
      <NewMetalDetectionModal open={metalDetectionModalOpen} setOpen={setMetalDetectionModalOpen} batchId={batch?.id} />
      <BatchCommentModal
        open={batchCommentModalOpen}
        setOpen={setBatchCommentModalOpen}
        batchId={batch?.id}
        readonly={readonly || !!batch?.closedAt || shiftCheckOutDone}
      />
      <BatchManualFlagModal open={batchManualFlagModalOpen} setOpen={setBatchManualFlagModalOpen} batchId={batch?.id} readonly={readonly} />
      <BatchRecordTableModal open={batchRecordTableModalOpen} setOpen={setBatchRecordTableModalOpen} batchId={batch?.id} readonly={readonly} />
      <MaterialDetailsModal open={materialDetailsModalOpen} setOpen={setMaterialDetailsModalOpen} batchId={batch?.id} />
      <BatchEditModal open={isBatchEditModalOpen} setOpen={setIsBatchEditModalOpen} batchId={batch?.id} />
    </>
  );
};

export default BatchDetails;
