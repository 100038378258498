"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Line = exports.InspectedType = exports.WeightUnit = exports.YesNoNA = exports.PkgType = void 0;
var PkgType;
(function (PkgType) {
    PkgType["SS"] = "SS";
    PkgType["Bag"] = "Bag";
    PkgType["Drum"] = "Drum";
})(PkgType = exports.PkgType || (exports.PkgType = {}));
var YesNoNA;
(function (YesNoNA) {
    YesNoNA["Yes"] = "Yes";
    YesNoNA["No"] = "No";
    YesNoNA["NA"] = "N/A";
})(YesNoNA = exports.YesNoNA || (exports.YesNoNA = {}));
var WeightUnit;
(function (WeightUnit) {
    WeightUnit["KG"] = "KG";
    WeightUnit["LB"] = "LB";
})(WeightUnit = exports.WeightUnit || (exports.WeightUnit = {}));
var InspectedType;
(function (InspectedType) {
    InspectedType["magnet"] = "magnet";
    InspectedType["screen"] = "screen";
})(InspectedType = exports.InspectedType || (exports.InspectedType = {}));
var Line;
(function (Line) {
    Line["line1"] = "line1";
    Line["line2"] = "line2";
    Line["other"] = "other";
})(Line = exports.Line || (exports.Line = {}));
