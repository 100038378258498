import { Button, Form, Input, notification, Result, Space, Spin, Popconfirm, Select } from "antd";
import React from "react";
import getAPI from "../../../services/api";
import { useNavigate, useSearchParams } from "react-router-dom";
import useFlow from "../../../hooks/useFlow";
import { SaveOutlined, SendOutlined } from "@ant-design/icons";
import { BusinessRole, flowGetStatus, flowIsEditable, flowValidateFE as flowValidate, IFlow, IFlowSafetyShower, Status } from "shared/interfaces";
import { useUser } from "../../../services/auth";
import PictureUpload from "../../UI/PictureUpload";
import FlowStatus from "../../UI/FlowStatus";
import { ZoomContext } from "../../../App";
import { nowDateTime } from "shared/utils";
import YesNoSwitch from "../../UI/YesNoSwitch";

const { TextArea } = Input;

interface SafetyShowerFlowProps {
  flowId: IFlow["id"];
  version?: IFlow["version"];
  readonly: boolean;
  onSuccess: () => void;
  setFormHasUnsavedChanges?: (hasUnsavedChanges: boolean) => void;
}

const SafetyShowerFlowForm: React.FC<SafetyShowerFlowProps> = ({
  flowId,
  version,
  readonly: propReadonly = false,
  onSuccess,
  setFormHasUnsavedChanges,
}) => {
  const user = useUser();
  const [readonly, setReadonly] = React.useState(propReadonly);
  const navigate = useNavigate();
  const { data, isLoading: isFlowLoading, refetch: refetchFlow, error: flowError } = useFlow(flowId, version);
  const flow = data as IFlowSafetyShower;
  const [zoom] = React.useContext(ZoomContext);
  const zoomRatio = parseInt(zoom) / 100;

  const [form] = Form.useForm();
  const [hasWarnings, setHasWarnings] = React.useState<boolean>(false);

  let initialValues = flow;
  let [searchParams] = useSearchParams();
  const supervisorMode = searchParams.get("mode") === "supervisor";

  const now = nowDateTime();
  const optionsPeriod = Array.from({ length: 4 }, (_, i) => {
    const weekStart = now.minus({ weeks: i }).startOf("week").toFormat("LLL d");
    const weekEnd = now.minus({ weeks: i }).endOf("week").toFormat("LLL d");
    const weekNumber = now.minus({ weeks: i }).weekNumber;
    return {
      label: `${weekNumber} (${weekStart} - ${weekEnd})`,
      value: weekNumber,
    };
  });
  const optionsYear = Array.from({ length: 2 }, (_, i) => {
    const year = now.minus({ years: i }).year;
    return {
      label: `${year}`,
      value: year,
    };
  });

  React.useEffect(() => {
    const userIsReader = user?.businessRole === BusinessRole.Reader;
    setReadonly((propReadonly || userIsReader) ?? false);
  }, [propReadonly, user]);

  if (flowError) {
    return (
      <Result
        status="500"
        title="Mmmmh... something went wrong."
        subTitle="More luck next time?"
        extra={
          <Button type="primary" onClick={() => navigate("/")}>
            Back Home
          </Button>
        }
      />
    );
  }

  if (isFlowLoading || !flow) {
    return <Spin>Loading...</Spin>;
  }

  // @ts-ignore
  return (
    <Form
      initialValues={initialValues}
      disabled={readonly}
      form={form}
      labelCol={{ span: zoomRatio > 1.0 ? 5 : 4 }}
      labelAlign={"left"}
      onFinish={async (values) => {
        const safetyShowerFlow: IFlowSafetyShower = {
          ...flow,
          ...values,
        };

        const { warnings } = await flowValidate(safetyShowerFlow);

        if (warnings.length > 0 && !hasWarnings) {
          setHasWarnings(true);
          notification.warning({
            message: "The form has warnings",
            description: "Please check the form for warnings and enter a comment to submit despite warnings",
          });
          !hasWarnings && setHasWarnings(true);
          form.setFields(
            warnings.map((warning) => ({
              name: warning.property,
              warnings: warning.messages,
            }))
          );
          return;
        }
        try {
          const api = await getAPI();
          await api.put(`/flows/${flowId}`, {
            data: {
              ...flow,
              ...values,
            },
          });
          await api.put(`/flows/${flowId}/submit`);
          await refetchFlow();
          onSuccess && flow && onSuccess();
          notification.success({
            message: "The safety shower flow was submitted successfully",
          });
        } catch (error: any) {
          notification.error({
            message: "There was an error submitting the form",
            description: error?.message,
          });
        }
      }}
      onValuesChange={async (changedValues, allValues) => {
        setFormHasUnsavedChanges && setFormHasUnsavedChanges(true);
        if (hasWarnings) {
          // reset warnings
          Object.keys(allValues).forEach((key) => {
            form.setFields([
              {
                name: key,
                warnings: [],
              },
            ]);
          });

          const safetyShowerFlow: IFlowSafetyShower = {
            ...flow,
            ...allValues,
          };

          const { warnings } = await flowValidate(safetyShowerFlow);

          // if we have warnings or errors, we set them in the form, the user can still submit)
          if (warnings.length > 0) {
            form.setFields(
              warnings.map((warning) => ({
                name: warning.property,
                warnings: warning.messages,
              }))
            );
            setHasWarnings(true);
          } else if (warnings.length === 0) {
            setHasWarnings(false);
          }
        }
      }}
    >
      <Form.Item label={flow.period} name="periodNumber" wrapperCol={{ span: 5 }}>
        {readonly ? <Input disabled={true} className="non-editable-input" /> : <Select options={optionsPeriod}></Select>}
      </Form.Item>
      <Form.Item label="Year" name="year" wrapperCol={{ span: 5 }}>
        {readonly ? <Input disabled={true} className="non-editable-input" /> : <Select options={optionsYear}></Select>}
      </Form.Item>
      <Form.Item label="Station free of obstructions" name="noObstruction">
        <YesNoSwitch checkedChildren={"Pass"} unCheckedChildren={"Fail"} disabled={readonly} />
      </Form.Item>
      <Form.Item label="Sign condition" name="signCondition">
        <YesNoSwitch checkedChildren={"Pass"} unCheckedChildren={"Fail"} disabled={readonly} />
      </Form.Item>
      <Form.Item label="Light working" name="lightWorking">
        <YesNoSwitch checkedChildren={"Pass"} unCheckedChildren={"Fail"} disabled={readonly} />
      </Form.Item>
      <Form.Item label="Flow alarm operational" name="flowAlarmOperational">
        <YesNoSwitch checkedChildren={"Pass"} unCheckedChildren={"Fail"} disabled={readonly} />
      </Form.Item>
      <Form.Item label="Eyewash working properly" name="eyewashWorking">
        <YesNoSwitch checkedChildren={"Pass"} unCheckedChildren={"Fail"} disabled={readonly} />
      </Form.Item>
      <Form.Item label="Water flow clear" name="waterFlowClear">
        <YesNoSwitch checkedChildren={"Pass"} unCheckedChildren={"Fail"} disabled={readonly} />
      </Form.Item>
      <Form.Item label="Shower working properly" name="showerWorking">
        <YesNoSwitch checkedChildren={"Pass"} unCheckedChildren={"Fail"} disabled={readonly} />
      </Form.Item>
      <Form.Item label="Any tie-ins?" name="anyTieIns">
        <YesNoSwitch checkedChildren={"Pass"} unCheckedChildren={"Fail"} disabled={readonly} />
      </Form.Item>
      <Form.Item label="Work request generated" name="workRequest" wrapperCol={{ span: 5 }}>
        <Input disabled={readonly} />
      </Form.Item>
      <Form.Item label="Pictures" name="pictures" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
        <PictureUpload disabled={readonly} />
      </Form.Item>

      <Form.Item
        name="comment"
        label="Comment"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        rules={[{ required: hasWarnings, message: "Please add a comment to explain warnings" }]}
      >
        <TextArea disabled={readonly} />
      </Form.Item>
      <Space direction="vertical" style={{ width: "100%" }} size="large">
        {!readonly && (
          <div style={{ display: "flex", justifyContent: "right" }}>
            <Space>
              {supervisorMode ? (
                <Form.Item>
                  <Popconfirm
                    title="Save your change"
                    description="To make a change, you must be trained to do so and you MUST add an explanation in the comment box, below the existing comments. If you have done so, you can click on Save."
                    onConfirm={async () => {
                      try {
                        const values = form.getFieldsValue();
                        const api = await getAPI();
                        await api.put(`/flows/${flowId}/edit`, {
                          data: {
                            ...flow,
                            ...values,
                          },
                        });
                        await refetchFlow();
                        notification.success({
                          message: "Changes saved",
                        });
                        setFormHasUnsavedChanges && setFormHasUnsavedChanges(false);
                      } catch (error: any) {
                        notification.error({
                          message: "Error saving changes",
                          description: error?.message,
                        });
                      }
                    }}
                    okText="Save"
                    cancelText="Cancel"
                  >
                    <Button size="large" icon={<SaveOutlined />} type="primary">
                      Save changes
                    </Button>
                  </Popconfirm>
                </Form.Item>
              ) : (
                <>
                  <Form.Item>
                    <Button
                      disabled={flowGetStatus(flow) === Status.confirmed}
                      size="large"
                      icon={<SaveOutlined />}
                      onClick={async () => {
                        const values = form.getFieldsValue();
                        try {
                          const api = await getAPI();
                          await api.put(`/flows/${flowId}`, {
                            data: {
                              ...flow,
                              ...values,
                            },
                          });
                          await refetchFlow();
                          notification.success({
                            message: "Safety shower flow draft saved",
                          });
                          setFormHasUnsavedChanges && setFormHasUnsavedChanges(false);
                        } catch (error: any) {
                          notification.error({
                            message: `Error saving safety shower flow draft: ${error?.message}`,
                          });
                        }
                      }}
                    >
                      Save Draft
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <Button size="large" icon={<SendOutlined />} htmlType="submit" type="primary" disabled={!flowIsEditable(flow) || readonly}>
                      {hasWarnings ? "Submit with warnings" : "Submit"}
                    </Button>
                  </Form.Item>
                </>
              )}
            </Space>
          </div>
        )}
        <FlowStatus flow={flow} />
      </Space>
    </Form>
  );
};
export default SafetyShowerFlowForm;
