import React from "react";
import Markdown from "react-markdown";

interface MarkdownHelpProps {
  help: string;
}

const MarkdownHelp: React.FC<MarkdownHelpProps> = ({ help }) => {
  return (
    <Markdown
      children={help}
      components={{
        img: ({ node, ...props }) => <img style={{ maxWidth: "800px", maxHeight: "600px" }} alt="img" {...props} />,
        a: ({ node, children, ...props }) => (
          <a target="_blank" rel="noopener noreferrer" {...props}>
            {children || "Link"}
          </a>
        ),
      }}
    />
  );
};

export default MarkdownHelp;
