import { useQuery, UseQueryResult } from "@tanstack/react-query";
import getAPI from "../services/api";
import { IMaterial } from "shared/interfaces";

const useMaterials = (filters?): UseQueryResult<IMaterial[]> => {
  const { rework, product } = filters || {};
  return useQuery(["materials", rework], async () => {
    const api = await getAPI();
    const filters = { rework: !!rework };
    if (product) {
      filters["product"] = product;
    }
    // version 1 was old version. beg of July 2024, we used both v1 & 2, to transition (REACT_APP_MATERIAL_VERSION === 0).
    // Then only version 2 (SAP material codes)
    if (process.env.REACT_APP_MATERIAL_VERSION && process.env.REACT_APP_MATERIAL_VERSION !== "0") {
      filters["version"] = process.env.REACT_APP_MATERIAL_VERSION ?? 2;
    }
    const { data } = await api.get("/materials", {
      params: {
        pagination: { pageSize: 1000 },
        filters,
      },
    });
    return data.data.sort((a, b) => Number(a.number) - Number(b.number));
  });
};

export default useMaterials;
