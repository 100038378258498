import { useQuery } from "@tanstack/react-query";
import { IFlow } from "shared/interfaces";
import getAPI from "../services/api";

function useFlow(flowId?: number | string, version?: number | string | undefined) {
  const FLOW_QUERY_KEY = ["flow", flowId, version];
  const { isLoading, data, error, refetch } = useQuery(
    FLOW_QUERY_KEY,
    async () => {
      const api = await getAPI();
      if (version) {
        const { data } = await api.get("/flow-histories", {
          params: {
            filters: { flow: { id: flowId }, version: version },
          },
        });
        return data?.data?.[0];
      } else {
        const { data } = await api.get<IFlow>(`/flows/${flowId}`);
        return data;
      }
    },
    {
      enabled: !!flowId,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchInterval: false,
    }
  );

  return {
    isLoading,
    data,
    error,
    refetch,
  };
}

export default useFlow;
