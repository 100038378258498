import React, { useEffect, useState } from "react";
import { Button, Tag, Upload, Space, List } from "antd";
import {
  CameraOutlined,
  EyeOutlined,
  PictureOutlined,
  DownloadOutlined,
  UploadOutlined,
  VideoCameraOutlined,
  DeleteOutlined,
  FileOutlined,
} from "@ant-design/icons";
import PhotoCaptureModal from "./PhotoCaptureModal/PhotoCaptureModal";
import VideoCaptureModal from "./VideoCaptureModal/VideoCaptureModal";
import PhotoViewModal from "./PhotoViewModal/PhotoViewModal";
import VideoViewModal from "./VideoViewModal/VideoViewModal";
import useUpload from "../../hooks/useUpload";

interface AssetUploadProps {
  disabled?: boolean;
  noVideo?: boolean;
  value?: any[];
  onChange?: (value: any[]) => void;
}

const AssetUpload: React.FC<AssetUploadProps> = ({ value = [], onChange, disabled, noVideo }) => {
  const [isPhotoCaptureModalOpen, setIsPhotoCaptureModalOpen] = useState(false);
  const [isVideoCaptureModalOpen, setIsVideoCaptureModalOpen] = useState(false);
  const [assets, setAssets] = useState<any[]>(value);
  const [viewedPhotoId, setViewedPhotoId] = useState(null);
  const [viewedVideoId, setViewedVideoId] = useState(null);

  const { upload } = useUpload();

  useEffect(() => {
    if (value?.[0]?.id) {
      onChange && onChange(value?.map(({ id }) => id));
    }
  }, [value, onChange]);

  const tagRender = ({ label, value: id }) => {
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        icon={<PictureOutlined />}
        onMouseDown={onPreventMouseDown}
        onClick={(e) => {
          e.preventDefault();
          setViewedPhotoId(id);
        }}
        onClose={() => {
          onChange && onChange(value?.filter(({ id: assetId }) => assetId !== id));
        }}
        closable={true}
        style={{ marginRight: 3 }}
      >
        <Button type="link">
          <span style={{ textDecoration: "underline", color: "black" }}>{label}</span>
        </Button>
      </Tag>
    );
  };

  const addAsset = (asset) => {
    const updatedAssets = [...assets, asset];
    const updatedValues = [...(assets ? assets.map(({ id }) => id) : []), asset.id];
    setAssets(updatedAssets);
    if (onChange) {
      onChange(updatedValues);
    }
    setIsPhotoCaptureModalOpen(false);
    setIsVideoCaptureModalOpen(false);
  };

  const removeAsset = (asset) => {
    const updatedAssets = assets.filter(({ id }) => id !== asset.id);
    const updatedValues = updatedAssets.map(({ id }) => id);
    setAssets(updatedAssets);
    if (onChange) {
      onChange(updatedValues);
    }
  };

  const handleInputChange = (updatedValues) => {
    if (onChange) {
      if (updatedValues.length < assets.length) {
        onChange(updatedValues);
      }
    }
  };

  return (
    <div
      style={{
        display: "flex",
        gap: 8,
        justifyContent: "space-between",
        alignItems: "stretch",
        position: "relative",
      }}
    >
      {/* Not sure why, but stretch will not work directly on the Button component so wrapping it in a div */}
      <Space direction="vertical">
        <Space>
          <Button icon={<CameraOutlined />} onClick={() => setIsPhotoCaptureModalOpen(true)} style={{ minWidth: 35, height: "100%" }}>
            Take a picture
          </Button>
          {!noVideo && (
            <Button icon={<VideoCameraOutlined />} onClick={() => setIsVideoCaptureModalOpen(true)} style={{ minWidth: 35, height: "100%" }}>
              Record a video
            </Button>
          )}
          <Upload
            action={async (file) => {
              const { data } = await upload({ rawFile: file, name: file.name });
              const [asset] = data;
              addAsset(asset);
              return "";
            }}
            onChange={() => {}}
            multiple={true}
            fileList={[]}
          >
            <Button icon={<UploadOutlined />}>Upload</Button>
          </Upload>
        </Space>
        <PhotoCaptureModal open={isPhotoCaptureModalOpen} setOpen={setIsPhotoCaptureModalOpen} setAsset={addAsset} />
        {!noVideo && <VideoCaptureModal open={isVideoCaptureModalOpen} setOpen={setIsVideoCaptureModalOpen} setAsset={addAsset} />}
        <PhotoViewModal open={!!viewedPhotoId} setOpen={setViewedPhotoId} id={viewedPhotoId} />
        {!noVideo && <VideoViewModal open={!!viewedVideoId} setOpen={setViewedVideoId} id={viewedVideoId} />}
        <List
          locale={{ emptyText: "No media selected" }}
          style={{
            overflow: "auto",
            maxWidth: 500,
            minWidth: 200,
            marginTop: 10,
            padding: 10,
            border: "1px solid rgba(140, 140, 140, 0.35)",
            borderRadius: 2,
          }}
          itemLayout="horizontal"
          dataSource={assets}
          renderItem={(item: any) => {
            if (!item) return null;
            return (
              <List.Item>
                <List.Item.Meta
                  style={{ alignItems: "center" }}
                  avatar={
                    item?.formats?.thumbnail?.url || item?.attributes?.formats?.thumbnail?.url ? (
                      item?.formats?.thumbnail?.url ? (
                        <img
                          alt=""
                          src={`${process.env.REACT_APP_STORAGE_URL ?? ""}${item?.formats?.thumbnail?.url}`}
                          style={{
                            width: 50,
                          }}
                        />
                      ) : (
                        <img
                          alt=""
                          src={`${process.env.REACT_APP_STORAGE_URL ?? ""}${item?.attributes?.formats?.thumbnail?.url}`}
                          style={{
                            width: 50,
                          }}
                        />
                      )
                    ) : (
                      <div
                        style={{
                          width: 50,
                          height: 50,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <FileOutlined />
                      </div>
                    )
                  }
                  title={item.attributes?.name || item?.name}
                />
                <Space>
                  {(item.mime === "image/jpeg" || item.attributes?.mime === "image/jpeg") && (
                    <Button
                      disabled={false}
                      icon={<EyeOutlined />}
                      onClick={(e) => {
                        setViewedPhotoId(item.id);
                      }}
                    />
                  )}
                  {(item.mime === "video/webm" || item.attributes?.mime === "video/webm") && (
                    <Button
                      icon={<EyeOutlined />}
                      onClick={(e) => {
                        setViewedVideoId(item.id);
                      }}
                    />
                  )}
                  <Button
                    disabled={false}
                    icon={<DownloadOutlined />}
                    onClick={(e) => {
                      const a = document.createElement("a");
                      const url = item.url || item.attributes?.url;
                      a.href = process.env.REACT_APP_STORAGE_URL ? `${process.env.REACT_APP_STORAGE_URL}${url}` : url;
                      a.download = url;
                      a.target = "_blank";
                      document.body.appendChild(a);
                      a.click();
                      document.body.removeChild(a);
                    }}
                  />
                  {!disabled && <Button onClick={() => removeAsset(item)} icon={<DeleteOutlined />} />}
                </Space>
              </List.Item>
            );
          }}
        />
      </Space>
    </div>
  );
};

export default AssetUpload;
