import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import useShift from "../../hooks/useShift";
import { Button, Modal, notification, Space, Tooltip } from "antd";
import getAPI from "../../services/api";
import { useMachine } from "@xstate/react";
import { machine } from "../../machine";
import { BatchType, flowGetStatus, Status, Team, FlowType, batchHasOpenBatchLine } from "shared/interfaces";
import { getShiftDayForNextShift } from "shared/utils";
import { LeftOutlined, RightOutlined, WarningOutlined } from "@ant-design/icons";
import axios, { AxiosError } from "axios";
import AnimatedIcon from "../UI/AnimatedIcon";

const ShiftHeader: React.FC = () => {
  const { shiftId } = useParams();
  const { data: shift } = useShift(shiftId);
  const shiftName = shift ? `${shift?.day} ${shift?.team} ${shift?.area} ${shift?.product}` : "";
  const navigate = useNavigate();
  const [current, send] = useMachine(machine);
  const checkedIn = current.matches("checkedIn");
  const flowCheckIn = (shift?.flows || []).find((flow) => flow.type === FlowType.shiftCheckIn);
  const flowCheckOut = (shift?.flows || []).find((flow) => flow.type === FlowType.shiftCheckOut);
  const flowCheckInId = flowCheckIn?.id;
  const flowCheckInDone = flowCheckIn && flowGetStatus(flowCheckIn) === Status.confirmed;
  const flowCheckOutDone = flowCheckOut && flowGetStatus(flowCheckOut) === Status.confirmed;
  checkedIn && send("CHECKIN");

  const goToShift = async (shiftData) => {
    const api = await getAPI();

    try {
      const response = await api.get(`/shifts/specific/${shiftData.day}/${shiftData.team}/${shiftData.area}/${shiftData.product}`);
      const { data } = response;

      if (data && data.id) {
        navigate(`/shift/${data.id}?workerMode=true`);
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 404) {
        // Shift not found, prompt the user
        Modal.confirm({
          title: "Confirm shift creation",
          content:
            "Selected shift does not exist yet. Are you sure you want to create it? (Creating a shift has consequences: batch continuation, flow creation,...)",
          okText: "Confirm",
          cancelText: "Cancel",
          onOk: async () => {
            try {
              const postResponse = await api.post("/shifts", { data: shiftData });
              const { data: postData } = postResponse;

              if (postData && postData.id) {
                navigate(`/shift/${postData.id}?workerMode=true`);
              }
            } catch (postError) {
              handleApiError(postError);
            }
          },
        });
      } else {
        // Handle other API errors
        handleApiError(error);
      }
    }
  };

  const handleApiError = (error) => {
    if (axios.isAxiosError(error)) {
      const axiosError: AxiosError = error;
      notification.error({
        message: "Error",
        description: `${axiosError.message}`,
      });
    } else {
      notification.error({
        message: "Error",
        description: `${error}`,
      });
    }
  };

  return (
    <>
      <Space>
        <Button
          type="primary"
          ghost
          onClick={async () => {
            let { day, team, area, product } = shift || {};
            if (!shift) return;

            if (team === Team.D) {
              day = getShiftDayForNextShift(day!, true);
              team = Team.N;
            } else if (team === Team.N) {
              team = Team.D;
            }

            await goToShift({ day, team, area, product });
          }}
        >
          <LeftOutlined />
          <span>Previous shift</span>
        </Button>
        <Button
          type="primary"
          ghost
          onClick={async () => {
            if (!shift) return;
            let { day, team, area, product } = shift || {};

            if (team === Team.N) {
              day = getShiftDayForNextShift(day!, false);
              team = Team.D;
            } else if (team === Team.D) {
              team = Team.N;
            }

            await goToShift({ day, team, area, product });
          }}
        >
          <span>Next shift</span>
          <RightOutlined />
        </Button>
        <h1>Shift {shiftName}</h1>
      </Space>
      {shift && (
        <Space style={{ marginLeft: 50 }}>
          {!flowCheckInDone && (
            <Button
              type="primary"
              onClick={() => {
                flowCheckInId && navigate(`flow/${flowCheckInId}`);
              }}
            >
              <Space>
                Check in
                {flowCheckIn &&
                  flowGetStatus(flowCheckIn) !== Status.confirmed &&
                  flowCheckIn?.shouldBeSubmittedByDate &&
                  new Date(flowCheckIn?.shouldBeSubmittedByDate) < new Date() && (
                    <Tooltip title="Shift should be checked-in" defaultOpen>
                      <AnimatedIcon icon={WarningOutlined} style={{ color: "yellow" }} />
                    </Tooltip>
                  )}
              </Space>
            </Button>
          )}
          {flowCheckInDone && !flowCheckOutDone && (
            <Button
              type="primary"
              onClick={async () => {
                let abort = false;
                shift.batches &&
                  shift?.batches.forEach((batch) => {
                    if (batch.type === BatchType.rework) return;
                    if (batchHasOpenBatchLine(batch)) {
                      notification.error({
                        message: "Error",
                        description: `Batch ${batch.batchNumber} has still an open set of pallets`,
                      });
                      abort = true;
                    }
                  });
                if (abort) return;
                const checkoutFlow = shift.flows?.find((flow) => flow.type === FlowType.shiftCheckOut);
                checkoutFlow && navigate(`flow/${checkoutFlow?.id}`);
              }}
            >
              Check out
            </Button>
          )}
          <Button type="link" style={{ color: "var(--prayon-primary-color)" }} onClick={() => navigate("/shift-picker")}>
            Change shift
          </Button>
        </Space>
      )}
    </>
  );
};

export default ShiftHeader;
