import { useQuery, UseQueryResult } from "@tanstack/react-query";
import getAPI from "../services/api";

const useFlows = ({
  page = 1,
  pageSize = 100,
  filters = {},
  sort = {},
  enabled = true,
  csv = false,
}: {
  page?: number;
  pageSize?: number;
  filters?: any;
  sort?: any;
  csv?: boolean;
  enabled?: boolean;
}): UseQueryResult<any> => {
  return useQuery(
    ["flows", page, pageSize, filters, sort, csv],
    async () => {
      const api = await getAPI();
      const { data } = await api.get("/flows", {
        params: {
          csv,
          pagination: { pageSize, page },
          filters,
          sort,
        },
      });
      return data;
    },
    { enabled }
  );
};

export default useFlows;
