"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.flowLogValidate = exports.flowLogNeedDisplayQuestion = exports.flowLogQuestionTagMap = exports.flowLogGetFlowName = exports.flowLogGetGenericLabel = exports.flowLogGetLabel = exports.LogType = exports.flowLogQuestionOptions = exports.flowLogTagOptions = void 0;
const index_1 = require("./index");
var flowLogTagLabels;
(function (flowLogTagLabels) {
    flowLogTagLabels["tagEnvironmental"] = "Environmental";
    flowLogTagLabels["tagSafety"] = "Safety";
    flowLogTagLabels["tagQuality"] = "Quality & Food safety";
    flowLogTagLabels["tagMaintenance"] = "Maintenance";
    flowLogTagLabels["tag5MinSafety"] = "5 min for safety";
})(flowLogTagLabels || (flowLogTagLabels = {}));
exports.flowLogTagOptions = [
    {
        label: flowLogTagLabels.tagEnvironmental,
        value: "tagEnvironmental",
    },
    {
        label: flowLogTagLabels.tagSafety,
        value: "tagSafety",
    },
    {
        label: flowLogTagLabels.tagQuality,
        value: "tagQuality",
    },
    {
        label: flowLogTagLabels.tagMaintenance,
        value: "tagMaintenance",
    },
    {
        label: flowLogTagLabels.tag5MinSafety,
        value: "tag5MinSafety",
    },
];
exports.flowLogQuestionOptions = [
    {
        label: "Minor spill / emission",
        value: "questionMinorSpill",
        type: "boolean",
    },
    {
        label: "Reportable spill / emission",
        value: "questionReportableSpill",
        type: "boolean",
    },
    {
        label: "Out of specification",
        value: "questionOutOfSpec",
        type: "boolean",
    },
    {
        label: "Metal / other contaminant",
        value: "questionContaminant",
        type: "boolean",
    },
    {
        label: "Safety catch",
        value: "questionSafetyCatch",
        type: "boolean",
    },
    {
        label: "Near miss",
        value: "questionNearMiss",
        type: "boolean",
    },
    {
        label: "First aid / injury",
        value: "questionFirstAidInjury",
        type: "boolean",
    },
    {
        label: "SAP Notif Number",
        value: "questionSAPNotifNumber",
        type: "string",
    },
];
var LogType;
(function (LogType) {
    LogType["log"] = "log";
    LogType["dailyInstruction"] = "dailyInstruction";
})(LogType = exports.LogType || (exports.LogType = {}));
function flowLogGetLabel(flow, short = false) {
    var _a;
    let label = flowLogGetGenericLabel((_a = flow.logType) !== null && _a !== void 0 ? _a : LogType.log);
    const charactersLimit = short ? 30 : 200;
    if (flow.text) {
        if (flow.tagEnvironmental) {
            label += ` [${flowLogTagLabels.tagEnvironmental}] `;
        }
        if (flow.tagSafety) {
            label += ` [${flowLogTagLabels.tagSafety}] `;
        }
        if (flow.tagQuality) {
            label += ` [${flowLogTagLabels.tagQuality}] `;
        }
        if (flow.tagMaintenance) {
            label += ` [${flowLogTagLabels.tagMaintenance}] `;
        }
        if (flow.tag5MinSafety) {
            label += ` [${flowLogTagLabels.tag5MinSafety}] `;
        }
        label += " - " + flow.text.substring(0, charactersLimit);
        if (flow.text.length > charactersLimit) {
            label += " ...";
        }
    }
    return label;
}
exports.flowLogGetLabel = flowLogGetLabel;
function flowLogGetGenericLabel(logType) {
    if (logType === LogType.dailyInstruction) {
        return `Daily Instruction`;
    }
    return `Log`;
}
exports.flowLogGetGenericLabel = flowLogGetGenericLabel;
function flowLogGetFlowName(flow) {
    if (flow.logType === LogType.dailyInstruction) {
        return `FlowLogDailyInstruction`;
    }
    return "FlowLog";
}
exports.flowLogGetFlowName = flowLogGetFlowName;
exports.flowLogQuestionTagMap = new Map([
    ["questionMinorSpill", "tagEnvironmental"],
    ["questionReportableSpill", "tagEnvironmental"],
    ["questionOutOfSpec", "tagQuality"],
    ["questionContaminant", "tagQuality"],
    ["questionSafetyCatch", "tagSafety"],
    ["questionNearMiss", "tagSafety"],
    ["questionFirstAidInjury", "tagSafety"],
    ["questionSAPNotifNumber", "tagMaintenance"],
]);
function flowLogNeedDisplayQuestion(flow, question) {
    const tag = exports.flowLogQuestionTagMap.get(question);
    if (!tag) {
        return false; // if the question isn't in the map
    }
    return Boolean(flow === null || flow === void 0 ? void 0 : flow[tag]);
}
exports.flowLogNeedDisplayQuestion = flowLogNeedDisplayQuestion;
function flowLogValidate(flow) {
    let errors = [];
    let warnings = [];
    const labelProduct = "Product";
    const labelArea = "Area";
    const labelText = "Log text";
    const labelFlagToSupervisor = "Flag to supervisor";
    const labelQuestionMinorSpill = "Minor Spill / Emission";
    const labelQuestionReportableSpill = "Reportable Spill / Emission";
    const labelQuestionOutOfSpec = "Out of Specification";
    const labelQuestionContaminant = "Metal / Other Contaminant";
    const labelQuestionSafetyCatch = "Safety Catch";
    const labelQuestionNearMiss = "Near Miss";
    const labelQuestionFirstAidInjury = "Fist Aid / Injury";
    const labelQuestionSAPNotifNumber = "SAP Notification number";
    warnings = (0, index_1.validationMissingValue)(warnings, flow.product, "product", labelProduct);
    warnings = (0, index_1.validationMissingValue)(warnings, flow.area, "area", labelArea);
    warnings = (0, index_1.validationMissingValue)(warnings, flow.text, "text", labelText);
    warnings = (0, index_1.validationStringNotEmpty)(warnings, flow.text, "text", labelText);
    warnings = (0, index_1.validationMissingValue)(warnings, flow.flagToSupervisor, "flagToSupervisor", labelFlagToSupervisor);
    if (flow.flagToSupervisor === true) {
        warnings.push({
            property: "flagToSupervisor",
            message: `${labelFlagToSupervisor} is checked`,
        });
    }
    if (flowLogNeedDisplayQuestion(flow, "questionMinorSpill")) {
        warnings = (0, index_1.validationMissingValue)(warnings, flow.questionMinorSpill, "questionMinorSpill", labelQuestionMinorSpill);
        warnings = (0, index_1.validationBooleanValue)(warnings, flow.questionMinorSpill, false, "questionMinorSpill", labelQuestionMinorSpill);
    }
    if (flowLogNeedDisplayQuestion(flow, "questionReportableSpill")) {
        warnings = (0, index_1.validationMissingValue)(warnings, flow.questionReportableSpill, "questionReportableSpill", labelQuestionReportableSpill);
        warnings = (0, index_1.validationBooleanValue)(warnings, flow.questionReportableSpill, false, "questionReportableSpill", labelQuestionReportableSpill);
    }
    if (flowLogNeedDisplayQuestion(flow, "questionOutOfSpec")) {
        warnings = (0, index_1.validationMissingValue)(warnings, flow.questionOutOfSpec, "questionOutOfSpec", labelQuestionOutOfSpec);
        warnings = (0, index_1.validationBooleanValue)(warnings, flow.questionOutOfSpec, false, "questionOutOfSpec", labelQuestionOutOfSpec);
    }
    if (flowLogNeedDisplayQuestion(flow, "questionContaminant")) {
        warnings = (0, index_1.validationMissingValue)(warnings, flow.questionContaminant, "questionContaminant", labelQuestionContaminant);
        warnings = (0, index_1.validationBooleanValue)(warnings, flow.questionContaminant, false, "questionContaminant", labelQuestionContaminant);
    }
    if (flowLogNeedDisplayQuestion(flow, "questionSafetyCatch")) {
        warnings = (0, index_1.validationMissingValue)(warnings, flow.questionSafetyCatch, "questionSafetyCatch", labelQuestionSafetyCatch);
        warnings = (0, index_1.validationBooleanValue)(warnings, flow.questionSafetyCatch, false, "questionSafetyCatch", labelQuestionSafetyCatch);
    }
    if (flowLogNeedDisplayQuestion(flow, "questionNearMiss")) {
        warnings = (0, index_1.validationMissingValue)(warnings, flow.questionNearMiss, "questionNearMiss", labelQuestionNearMiss);
        warnings = (0, index_1.validationBooleanValue)(warnings, flow.questionNearMiss, false, "questionNearMiss", labelQuestionNearMiss);
    }
    if (flowLogNeedDisplayQuestion(flow, "questionFirstAidInjury")) {
        warnings = (0, index_1.validationMissingValue)(warnings, flow.questionFirstAidInjury, "questionFirstAidInjury", labelQuestionFirstAidInjury);
        warnings = (0, index_1.validationBooleanValue)(warnings, flow.questionFirstAidInjury, false, "questionFirstAidInjury", labelQuestionFirstAidInjury);
    }
    if (flowLogNeedDisplayQuestion(flow, "questionSAPNotifNumber")) {
        warnings = (0, index_1.validationMissingValue)(warnings, flow.questionSAPNotifNumber, "questionSAPNotifNumber", labelQuestionSAPNotifNumber);
        warnings = (0, index_1.validationStringNotEmpty)(warnings, flow.questionSAPNotifNumber, "questionSAPNotifNumber", labelQuestionSAPNotifNumber);
    }
    return {
        errors,
        warnings,
    };
}
exports.flowLogValidate = flowLogValidate;
