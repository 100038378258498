import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import "./App.css";
import "antd/dist/reset.css";
import { routes } from "./routes";
import { Login } from "./components/Login/Login";
import { useUser } from "./services/auth";
import { Provider } from "react-redux";
import store, { persistor } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import { ConfigProvider } from "antd";
import { usePersistedState } from "./components/Utils/usePersistedState";

export const queryClient = new QueryClient();

const router = createBrowserRouter(routes);
export const ZoomContext = React.createContext<[string, (v: string) => void]>(["100%", (v: string) => {}]);

const App = () => {
  const user = useUser();
  const zoomState = usePersistedState("zoom", "100%");
  const [zoom] = zoomState;

  if (user === null) return <Login />;
  if (user)
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ZoomContext.Provider value={zoomState}>
            <ConfigProvider
              theme={{
                token: {
                  // Seed Token
                  fontFamily: "PT Sans, sans-serif",
                  fontSize: 14 * (parseInt(zoom) / 100),
                  colorPrimary: "#70bf45",
                  borderRadius: 2,
                  controlHeightSM: 24 * Math.max(1, parseInt(zoom) / 100),
                  fontSizeHeading1: 18 * (parseInt(zoom) / 100),
                },
                components: {
                  List: {
                    descriptionFontSize: 12 * (parseInt(zoom) / 100),
                  },
                },
              }}
            >
              <QueryClientProvider client={queryClient}>
                <RouterProvider router={router} />
                {window.location.hostname === "localhost" && <ReactQueryDevtools />}
              </QueryClientProvider>
            </ConfigProvider>
          </ZoomContext.Provider>
        </PersistGate>
      </Provider>
    );
  return null;
};
export default App;
