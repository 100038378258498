import { Button, DatePicker, Form, Input, InputNumber, notification, Row, Space } from "antd";
import React, { useEffect } from "react";
import useBatch from "../../hooks/useBatch";
import useBatchLineClosingInfo from "../../hooks/useBatchLineClosingInfo";
import dayjs from "dayjs";
import { PkgType, batchGetOpenBatchLine } from "shared/interfaces";
import getAPI from "../../services/api";
import { useParams } from "react-router";
import axios from "axios";

const CloseBatchLineForm = ({
  onSuccess,
  onCancel,
  submitText = "Submit",
}: {
  onSuccess?: () => void;
  onCancel?: () => void;
  submitText?: string;
}) => {
  const { batchId } = useParams();
  const { data: batch, refetch: refetchBatch } = useBatch(Number(batchId));
  const batchLine = batch && batchGetOpenBatchLine(batch);
  const batchLineId = batchLine?.id;
  const pkgType = batch && batch.material && batch.material.pkgType;
  const [form] = Form.useForm();

  const {
    data: closeBatchLineInfos,
    isFetching: isCloseBatchLineInfosFetching,
    isLoading: isCloseBatchLineInfosLoading,
  } = useBatchLineClosingInfo({
    batchLineId,
    batchId: Number(batchId),
  });
  const [isToPalletHidden, setIsToPalletHidden] = React.useState(false);
  const formattedCloseBatchLineInfos = {
    ...closeBatchLineInfos,
    openedAt: dayjs(closeBatchLineInfos?.openedAt),
    closedAt: dayjs(closeBatchLineInfos?.closedAt),
    qtyBags:
      ((closeBatchLineInfos?.qtyPallets || 0) - 1) * (closeBatchLineInfos?.qtyBagsPerPallet || 0) + (closeBatchLineInfos?.qtyBagsLastPallet || 0),
  };
  const [hasWarnings, setHasWarnings] = React.useState(false);

  useEffect(() => {
    if (formattedCloseBatchLineInfos) {
      const fields = Object.keys(formattedCloseBatchLineInfos);
      const notTouchedFields = fields.filter((key) => !form.isFieldTouched(key));
      const values = Object.fromEntries(notTouchedFields.map((key) => [key, formattedCloseBatchLineInfos[key]]));
      form.setFieldsValue(values);
    }
  }, [formattedCloseBatchLineInfos, isCloseBatchLineInfosLoading, isCloseBatchLineInfosFetching]);

  return (
    <Form
      initialValues={formattedCloseBatchLineInfos}
      form={form}
      name="close-batch-line-form"
      labelCol={{ span: 10 }}
      labelAlign={"left"}
      wrapperCol={{ span: 14 }}
      style={{ width: "100%" }}
      onFinish={async (values) => {
        if (values?.closedAt) values.closedAt = values.closedAt.toISOString();
        if (values?.openedAt) values.openedAt = values.openedAt.toISOString();

        if (values.toPallet - values.fromPallet + 1 !== values.qtyPallets && !hasWarnings) {
          if (values.qtyPallets !== 0) {
            form.setFields([
              {
                name: "qtyPallets",
                warnings: ["The pallet quantity should match pallet ids range"],
              },
            ]);
            setHasWarnings(true);
            return;
          }
        }

        if (values.qtyPallets === 0) {
          values.toPallet = null;
          values.fromPallet = null;
        }

        try {
          const api = await getAPI();
          batchLine && (await api.put(`/batches/${batch.id}/batchLine/${batchLine.id}/close`, { data: values }));
          await refetchBatch();
          notification.success({
            message: "Success",
            description: "The set of pallets was logged successfully!",
          });
          onSuccess && onSuccess();
        } catch (e) {
          if (axios.isAxiosError(e)) {
            notification.error({
              message: "Error",
              description: `${e.message}`,
            });
          }
        }
      }}
      onFinishFailed={(errorInfo) => {
        notification.error({
          message: "Error",
          description: `${errorInfo.errorFields.map((field) => field.errors.join(", "))}`,
        });
      }}
      autoComplete="off"
      onFieldsChange={(changedFields, allFields) => {
        const changedFieldNames = changedFields.reduce((previous, current) => {
          // @ts-ignore
          return previous.concat(current.name);
        }, [] as string[]);

        const values = form.getFieldsValue();

        if (changedFieldNames.length === 1 && changedFieldNames.includes("qtyPallets")) {
          if (form.getFieldValue("qtyPallets") === 0) {
            setIsToPalletHidden(true);
          } else {
            setIsToPalletHidden(false);
          }
        }
        pkgType === PkgType.Bag &&
          form.setFieldValue(
            "qtyBags",
            (allFields.find((datum) => datum.name[0] === "qtyPallets")!.value - 1) *
              allFields.find((datum) => datum.name[0] === "qtyBagsPerPallet")!.value +
              allFields.find((datum) => datum.name[0] === "qtyBagsLastPallet")!.value
          );

        if (changedFieldNames.length === 1 && changedFieldNames.includes("toPallet")) {
          const qtyPallets = form.getFieldValue("toPallet") - form.getFieldValue("fromPallet") + 1;
          form.setFieldValue("qtyPallets", qtyPallets >= 0 ? qtyPallets : undefined);
        }

        if (
          hasWarnings &&
          changedFieldNames.length === 1 &&
          changedFieldNames.includes("qtyPallets") &&
          values.toPallet - values.fromPallet + 1 === values.qtyPallets
        ) {
          form.setFields([
            {
              name: "qtyPallets",
              warnings: [],
            },
          ]);
          setHasWarnings(false);
        } else if (hasWarnings && changedFieldNames.includes("qtyPallets") && values.toPallet - values.fromPallet + 1 !== values.qtyPallets) {
          if (values.qtyPallets !== 0) {
            form.setFields([
              {
                name: "qtyPallets",
                warnings: ["The pallet quantity should match pallet ids range"],
              },
            ]);
          }
        }
      }}
    >
      <Form.Item
        label="Pallet ids"
        name="toPallet"
        rules={[
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("fromPallet") <= value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error("The last pallet must be greater than the previously registered last pallet!"));
            },
          }),
        ]}
        hidden={isToPalletHidden}
      >
        <InputNumber
          controls={false}
          prefix={<>{closeBatchLineInfos?.fromPallet} →</>}
          style={{ width: 100 }}
          min={closeBatchLineInfos?.fromPallet}
          inputMode="numeric"
        />
      </Form.Item>
      <Form.Item label="Pallet quantity" name="qtyPallets">
        <InputNumber controls={false} min={0} inputMode="numeric" />
      </Form.Item>
      <Form.Item label="From pallet" name="fromPallet" hidden>
        <InputNumber controls={false} min={0} inputMode="numeric" hidden />
      </Form.Item>
      {pkgType === PkgType.Bag && (
        <>
          <Form.Item label="Bags per pallet" name="qtyBagsPerPallet">
            <InputNumber controls={false} min={0} inputMode="numeric" />
          </Form.Item>
          <Form.Item label="Bags in the last pallet" name="qtyBagsLastPallet">
            <InputNumber controls={false} min={0} inputMode="numeric" />
          </Form.Item>
          <Form.Item label="Bag quantity" name="qtyBags">
            <InputNumber controls={false} inputMode="numeric" disabled />
          </Form.Item>
        </>
      )}

      <Form.Item label="Started at" name="openedAt">
        <DatePicker showTime format="YYYY-MM-DD HH:mm" allowClear={false} />
      </Form.Item>
      <Form.Item label="Finished at" name="closedAt">
        <DatePicker showTime format="YYYY-MM-DD HH:mm" allowClear={false} />
      </Form.Item>

      <Form.Item label="Comment" name="comment" rules={[{ required: hasWarnings, message: "Please input a comment!" }]}>
        <Input.TextArea />
      </Form.Item>
      <Row justify="end">
        <Space>
          <Button
            type="primary"
            ghost
            onClick={async () => {
              onCancel && onCancel();
            }}
          >
            Cancel
          </Button>
          <Button type="primary" htmlType="submit">
            {submitText} {hasWarnings && "with warnings"}
          </Button>
        </Space>
      </Row>
    </Form>
  );
};

export default CloseBatchLineForm;
