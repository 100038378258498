import { Button, Modal, notification, Space, Table } from "antd";
import React from "react";
import "./BatchTable.css";
import useBatch from "../../../hooks/useBatch";
import { useParams } from "react-router";
import useShift from "../../../hooks/useShift";
import BatchRecordFlowForm from "../FlowForms/BatchRecordFlowForm";
import getAPI from "../../../services/api";
import { flowGetStatus, Status, FlowType, batchHasOpenBatchLine, BusinessRole, flowBatchRecordGetFlowName, YesNoNA } from "shared/interfaces";
import PhotoViewModal from "../../UI/PhotoViewModal/PhotoViewModal";
import { PictureOutlined } from "@ant-design/icons";
import useBatchRecords from "../../../hooks/useBatchRecords";
import { useUser } from "../../../services/auth";
import MarkdownHelp from "../../UI/MarkdownHelp";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useLocalizedText } from "../../../hooks/LocalizedText";
import { nicelyDisplayDateFromISOString } from "shared/utils";

interface BatchRecordTableProps {
  batchId: number | undefined;
  readonly?: boolean;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const BatchRecordTableModal: React.FC<BatchRecordTableProps> = ({ batchId, readonly: propReadonly = false, open, setOpen }) => {
  const user = useUser();
  const [readonly, setReadonly] = React.useState(propReadonly);

  const [flowId, setFlowId] = React.useState<number | undefined>(undefined);
  const { data: batchRecords, refetch: refetchBatchRecords } = useBatchRecords({ batchId, latest: true });
  const { data: batch } = useBatch(batchId);
  const { shiftId } = useParams();
  const { data: shift, refetch: refetchShift } = useShift(shiftId);
  const flowCheckOut = (shift?.flows || []).find((flow) => flow.type === FlowType.shiftCheckOut);
  const shiftCheckOutDone = flowCheckOut && flowGetStatus(flowCheckOut) === Status.confirmed;
  const [viewedPictureId, setViewedPictureId] = React.useState<number | null>(null);

  const help = useLocalizedText(flowBatchRecordGetFlowName());

  React.useEffect(() => {
    const userIsReader = user?.businessRole === BusinessRole.Reader || user?.businessRole === BusinessRole.Quality;
    setReadonly((propReadonly || userIsReader) ?? false);
  }, [propReadonly, user]);

  // @ts-ignore
  return (
    <>
      <Modal
        title={
          <Space>
            {flowId ? "New batch record" : `Batch records${batch?.batchNumber ? " - " + batch.batchNumber : ""}`}
            {flowId && help && (
              <Button
                type="link"
                style={{ marginLeft: 10 }}
                onClick={() => {
                  Modal.info({
                    title: "",
                    content: MarkdownHelp({ help }),
                    width: "80vw",
                  });
                }}
                icon={<InfoCircleOutlined />}
              />
            )}
          </Space>
        }
        open={open}
        destroyOnClose={true}
        onCancel={() => {
          setOpen(false);
          setFlowId(undefined);
        }}
        footer={null}
        width={1000}
      >
        {!flowId && (
          <>
            <Table
              className={"batch-record-table"}
              style={{ width: "100%" }}
              scroll={{ x: 100 }}
              pagination={false}
              dataSource={batchRecords}
              columns={[
                {
                  title: "Created at",
                  dataIndex: "openedAt",
                  key: "openedAt",
                  render: (openedAt) => openedAt && nicelyDisplayDateFromISOString(openedAt),
                },
                {
                  title: "Pallets",
                  dataIndex: "fromPallet",
                  key: "fromPallet",
                  render: (fromPallet, { toPallet }) => {
                    if (!fromPallet) return "-";
                    return `${fromPallet} → ${toPallet}`;
                  },
                },
                {
                  title: "Proper hooders",
                  dataIndex: "properHooders",
                  render: (item) => (item === YesNoNA.Yes ? "✓" : item === YesNoNA.No ? "❌" : "N/A"),
                },
                {
                  title: "Proper package",
                  dataIndex: "properPackage",
                  render: (item) => (item === YesNoNA.Yes ? "✓" : item === YesNoNA.No ? "❌" : "N/A"),
                },
                {
                  title: "Proper stenciling",
                  dataIndex: "properStenciling",
                  render: (item) => (item === YesNoNA.Yes ? "✓" : item === YesNoNA.No ? "❌" : "N/A"),
                },
                {
                  title: "Proper date",
                  dataIndex: "properDate",
                  render: (item) => (item === YesNoNA.Yes ? "✓" : item === YesNoNA.No ? "❌" : "N/A"),
                },
                {
                  title: "Proper pallets",
                  dataIndex: "properPallets",
                  render: (item) => (item === YesNoNA.Yes ? "✓" : item === YesNoNA.No ? "❌" : "N/A"),
                },
                {
                  title: "Leakage",
                  dataIndex: "noLeakage",
                  render: (item) => (item === YesNoNA.Yes ? "✓" : item === YesNoNA.No ? "❌" : "N/A"),
                },
                {
                  title: "Damage",
                  dataIndex: "noDamage",
                  render: (item) => (item === YesNoNA.Yes ? "✓" : item === YesNoNA.No ? "❌" : "N/A"),
                },
                {
                  title: "Pallets in Bin",
                  dataIndex: "palletsInBin",
                  render: (item) => (item === YesNoNA.Yes ? "✓" : item === YesNoNA.No ? "❌" : "N/A"),
                },
                {
                  title: "No Metal Detector Rejects",
                  dataIndex: "noMetalDetectorRejects",
                  render: (item) => (item === YesNoNA.Yes ? "✓" : item === YesNoNA.No ? "❌" : "N/A"),
                },
                {
                  title: "Samples to Lab",
                  dataIndex: "samplesToLab",
                  render: (item) => (item === YesNoNA.Yes ? "✓" : item === YesNoNA.No ? "❌" : "N/A"),
                },
                {
                  title: "Bin Number",
                  dataIndex: "binNumber",
                },
                {
                  title: "LT24",
                  dataIndex: "lt24Files",
                  render: (lt24Files) => {
                    if (!lt24Files) return "-";
                    return (
                      <Space>
                        {lt24Files.map((file) => (
                          <>{file.name}</>
                        ))}
                      </Space>
                    );
                  },
                },
                {
                  title: "Photos",
                  dataIndex: "files",
                  render: (files) => {
                    if (!files) return "-";
                    return (
                      <Space>
                        {files.map((file) => (
                          <Button icon={<PictureOutlined />} onClick={() => setViewedPictureId(file.id)}>
                            {file.name}
                          </Button>
                        ))}
                      </Space>
                    );
                  },
                },
              ]}
            />
            <div style={{ display: "flex", justifyContent: "right", marginTop: 16 }}>
              <Space>
                <Button
                  type={"primary"}
                  ghost={true}
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  Close
                </Button>
                {!readonly && batch && !batch.closedAt && !batchHasOpenBatchLine(batch) && !shiftCheckOutDone && (
                  <Button
                    type={"primary"}
                    onClick={async () => {
                      const api = await getAPI();
                      let data;
                      try {
                        // trying to get a batch record flow
                        try {
                          const resp = await api.post(`/flows/${shiftId}/flowBatchRecord`, { data: { batchId } });
                          data = resp.data;
                        } catch (e) {
                          // if it already exists, get it
                          shift?.flows?.forEach((flow) => {
                            if (flow.type === FlowType.batchRecord && flow.batch?.id === batchId) {
                              data = flow;
                            }
                          });
                        }
                        const flow = data;
                        if (flowGetStatus(flow) === Status.confirmed) {
                          notification.error({
                            message: "Error",
                            description: `Batch record already done`,
                          });
                          return;
                        }
                        setFlowId(flow.id);
                        await refetchShift();
                      } catch (e) {
                        // @ts-ignore
                        notification.error({
                          message: "Error",
                          description: `${e}`,
                        });
                      }
                    }}
                  >
                    New batch record
                  </Button>
                )}
              </Space>
            </div>
          </>
        )}
        {flowId && (
          <BatchRecordFlowForm
            flowId={flowId}
            onSuccess={async () => {
              setFlowId(undefined);
              await refetchBatchRecords();
              await refetchShift();
            }}
            onCancel={() => setFlowId(undefined)}
          />
        )}
      </Modal>
      <PhotoViewModal open={!!viewedPictureId} setOpen={setViewedPictureId} id={viewedPictureId} />
    </>
  );
};

export default BatchRecordTableModal;
