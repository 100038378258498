import { Form, Modal, notification, Row, InputNumber, Checkbox } from "antd";
import React from "react";
import useBatch from "../../hooks/useBatch";
import getAPI from "../../services/api";

const ScaleReadingEditModal: React.FC<{
  batchId: number | undefined;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ open, setOpen, batchId }) => {
  const { refetch: refetchBatch } = useBatch(batchId);

  const [form] = Form.useForm();

  const submit = async (values: any) => {
    const palletId = values.palletId;
    const weightEmptyPallet = values.weightEmptyPallet;
    const weightFilledSS = values.weightFilledSS;
    if (palletId == null || weightEmptyPallet == null || weightFilledSS == null) {
      notification.error({
        message: "Error",
        description: `Pallet id, weight empty pallet and weight filled SS are all required`,
      });
      return;
    }
    const data = {
      seal: values.seal != null ? values.seal : false,
      weightEmptyPallet,
      weightFilledSS,
    } as any;
    if (values.checkWeight != null) {
      data.checkWeight = values.checkWeight;
    }

    try {
      const api = await getAPI();
      await api.put(`/batches/${batchId}/scaleReading/${palletId}`, {
        data: data,
      });
      notification.success({
        message: "Success",
        description: "The scale reading was successfully edited",
      });
      await refetchBatch();
      setOpen(false);
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: `${error.message}`,
      });
    }
  };

  return (
    <>
      {batchId && (
        <Modal
          title="Edit Scale Reading"
          open={open}
          cancelText="Cancel"
          okText="Update"
          onOk={async () => {
            await form.submit();
          }}
          onCancel={() => {
            setOpen(false);
          }}
          maskClosable={false}
          destroyOnClose
          width={600}
        >
          <Row justify="center">
            <Form
              form={form}
              name="basic"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 20 }}
              style={{ width: "100%" }}
              onFinish={submit}
              autoComplete="off"
            >
              <Form.Item label="Pallet id" name="palletId">
                <InputNumber min={0} max={9999999} controls={false} inputMode="numeric" />
              </Form.Item>
              <Form.Item name="seal" label="Seal" valuePropName="checked">
                <Checkbox />
              </Form.Item>
              <Form.Item label="Pallet Wt. (lbs)" name="weightEmptyPallet">
                <InputNumber min={0} max={9999999} controls={false} inputMode="numeric" />
              </Form.Item>
              <Form.Item label="SS Net Wt. (lbs)" name="weightFilledSS">
                <InputNumber min={0} max={9999999} controls={false} inputMode="numeric" />
              </Form.Item>
              <Form.Item label="Check Wt. (lbs)" name="checkWeight">
                <InputNumber min={0} max={9999999} controls={false} inputMode="numeric" />
              </Form.Item>
            </Form>
          </Row>
        </Modal>
      )}
    </>
  );
};

export default ScaleReadingEditModal;
