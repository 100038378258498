import { Button, DatePicker, Space, Table } from "antd";

import { CheckOutlined, EditOutlined, EyeOutlined, FilterFilled, HistoryOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { DateTime } from "luxon";
import "./FlowTable.css";
import useFlows from "../../../hooks/useFlows";
import { Area, BusinessRole, flowGetLabel, FlowType, InspectedType, Product, Status, Team } from "shared/interfaces";
import { ColumnsType } from "antd/es/table";
import { useNavigate } from "react-router";
import { ZoomContext } from "../../../App";
import { useUser } from "../../../services/auth";
import { ArrayParam, NumberParam, StringParam, useQueryParams } from "use-query-params";
import FlagsPopover from "../../UI/FlagsPopover";
import { nicelyDisplayDateFromISOString, camelCaseToSentence, shiftDayDateTimeToString } from "shared/utils";
import useWindowResize from "../../../hooks/useWindowResize";
import CSVDownloadButton from "../../UI/CSVDownloadButton";
import { FilterDropdown, FilterIcon } from "../UI/FilterId";

const FlowTable = () => {
  const user = useUser();
  const navigate = useNavigate();
  const [canEdit, setCanEdit] = useState(false);
  const [zoom] = React.useContext(ZoomContext);
  const zoomRatio = parseInt(zoom) / 100;
  const { height } = useWindowResize();
  const [tableHeaderHeight, setTableHeaderHeight] = useState(0);

  useEffect(() => {
    // Function to update state with the height of the th element
    const updateHeight = () => {
      const thElement = document.querySelector("th");
      if (thElement) {
        setTableHeaderHeight(thElement.clientHeight);
      }
    };

    // Set up ResizeObserver
    const resizeObserver = new ResizeObserver(updateHeight);
    const thElement = document.querySelector("th");
    if (thElement) {
      resizeObserver.observe(thElement);
    }

    // Clean up observer on component unmount
    return () => {
      if (thElement) {
        resizeObserver.unobserve(thElement);
      }
    };
  }, []);

  const getSortOrder = (field) => {
    const sortParam = query.sortParams?.find((s) => s?.startsWith(field));
    if (sortParam) {
      return sortParam.endsWith("asc") ? "ascend" : "descend";
    }
    return null;
  };

  // Refactor to use useQueryParams
  const [query, setQuery] = useQueryParams({
    id: NumberParam,
    page: NumberParam,
    pageSize: NumberParam,
    flags: ArrayParam,
    type: ArrayParam,
    status: ArrayParam,
    edited: ArrayParam,
    team: ArrayParam,
    product: ArrayParam,
    area: ArrayParam,
    shiftDayFrom: StringParam,
    shiftDayTo: StringParam,
    sortParams: ArrayParam,
    from: NumberParam,
    to: NumberParam,
  });

  // Default times to one week ago if not set
  const [defaultTimes] = useState([dayjs().subtract(1, "week").valueOf(), dayjs().valueOf()]);
  const timeSpan = query.from && query.to ? [query.from, query.to] : defaultTimes;

  useEffect(() => {
    if (user) {
      setCanEdit(user?.businessRole === BusinessRole.Supervisor);
    }
  }, [user]);

  const sortParams = query.sortParams || [];

  if (!sortParams.find((s) => s?.startsWith("createdAt"))) {
    sortParams.push("createdAt:desc");
  }

  // add magnet and screen inspection sub types to the type filter
  const generateFlowTypeFilters = () => {
    const standardTypes = Object.keys(FlowType).filter((type) => type !== FlowType.inspection);
    const inspectionTypes = flows.some((flow) => flow.type === FlowType.inspection && flow.typeInspected)
      ? [`${InspectedType.magnet} ${FlowType.inspection}`, `${InspectedType.screen} ${FlowType.inspection}`]
      : [];

    return [...standardTypes, ...inspectionTypes].map((type) => ({
      value: type,
      text: camelCaseToSentence(type),
    }));
  };
  let orConditionsFilterTypes: any[] = [];
  let filterTypes = {};
  if (query.type && query.type.length) {
    query.type.forEach((type) => {
      if (type === `${InspectedType.magnet} ${FlowType.inspection}`) {
        orConditionsFilterTypes.push({ type: FlowType.inspection, typeInspected: InspectedType.magnet });
      } else if (type === `${InspectedType.screen} ${FlowType.inspection}`) {
        orConditionsFilterTypes.push({ type: FlowType.inspection, typeInspected: InspectedType.screen });
      } else {
        orConditionsFilterTypes.push({ type });
      }
    });
    filterTypes = { $or: orConditionsFilterTypes };
  }

  const flowQueryConfig = {
    page: query.page || 1,
    pageSize: query.pageSize || 100,
    filters: {
      ...(query.id ? { id: query.id } : {}),
      ...(timeSpan && timeSpan[0] && timeSpan[1]
        ? {
            createdAt: {
              $gte: timeSpan[0].valueOf(),
              $lte: timeSpan[1].valueOf(),
            },
          }
        : {}),
      ...filterTypes,
      ...(query.flags && query.flags.length === 1 ? { flags: { id: { $null: query.flags[0] === "false" } } } : {}),
      ...(query.edited && query.edited.length === 1 ? { edited: { [query.edited[0] === "true" ? "$eq" : "$null"]: "true" } } : {}),
      ...((query.team && query.team.length) || (query.area && query.area.length) || (query.product && query.product.length) || query.shiftDayFrom
        ? {
            shift: {
              ...(query.team && query.team.length ? { team: { $in: query.team } } : {}),
              ...(query.area && query.area.length ? { area: { $in: query.area } } : {}),
              ...(query.product && query.product.length ? { product: { $in: query.product } } : {}),
              ...(query.shiftDayFrom && query.shiftDayTo ? { day: { $gte: query.shiftDayFrom, $lte: query.shiftDayTo } } : {}),
            },
          }
        : {}),
      ...(query.status && query.status.length ? { status: { $in: query.status } } : {}),
    },
    ...(sortParams ? { sort: sortParams } : {}),
  };

  const { data } = useFlows(flowQueryConfig);

  const { data: flows = [] } = data || {};

  const total = data?.meta?.pagination?.total;
  const page = data?.meta?.pagination?.page;
  const pageSize = data?.meta?.pagination?.pageSize;

  const columns: ColumnsType<any> = [
    {
      title: "Flow id",
      dataIndex: "id",
      width: 75 * Math.max(1, zoomRatio),
      sorter: { multiple: 1 },
      fixed: "left",
      onHeaderCell: (column) => {
        if (query.id) {
          return {
            className: "ant-table-column-sort",
          };
        }
        return {};
      },
      filterIcon: (filtered) => <FilterIcon queryItem={query.id} />,
      filterDropdown: FilterDropdown({
        queryKey: "id",
        queryValue: query.id,
        setQuery: setQuery,
        resetQuery: () => setQuery({ id: null }),
        isString: false,
      }),
      defaultSortOrder: getSortOrder("id"),
    },
    {
      title: "Creation date",
      dataIndex: "createdAt",
      render: (createdAt) => nicelyDisplayDateFromISOString(createdAt),
      sorter: { multiple: 1 },
      width: 160 * Math.max(1, zoomRatio),
      defaultFilteredValue: timeSpan,
      filterDropdown: () => (
        <DatePicker.RangePicker
          value={[dayjs(timeSpan[0]), dayjs(timeSpan[1])]}
          showTime={false}
          onChange={(dates) => {
            if (dates && dates.length > 1 && dates[0] && dates[1]) {
              const from = DateTime.fromJSDate(dates[0].toDate()).startOf("day").toMillis();
              const to = DateTime.fromJSDate(dates[1].toDate()).endOf("day").toMillis();
              setQuery({ from: from, to: to, page: 1 });
            }
          }}
          disabledDate={(current) => current && current > dayjs()}
          allowClear={false}
        />
      ),
      defaultSortOrder: getSortOrder("createdAt"),
    },
    {
      title: "Created by",
      width: 200 * Math.max(1, zoomRatio),
      dataIndex: ["openedBy", "email"],
      sorter: { multiple: 1 },
      defaultSortOrder: getSortOrder("openedBy.email"),
    },

    {
      title: "Flow",
      dataIndex: "type",
      sorter: { multiple: 1 },
      ellipsis: true,
      width: 200 * Math.max(1, zoomRatio),
      render: (_, record) => {
        return flowGetLabel(record);
      },
      filters: generateFlowTypeFilters(),
      defaultFilteredValue: query?.type?.filter((type): type is string => type !== null) || [],
      defaultSortOrder: getSortOrder("type"),
    },
    {
      title: "Latest version",
      dataIndex: "version",
      width: 75 * Math.max(1, zoomRatio),
      sorter: { multiple: 1 },
    },
    {
      title: "Day",
      dataIndex: ["shift", "day"],
      width: 100 * Math.max(1, zoomRatio),
      sorter: { multiple: 1 },
      defaultSortOrder: getSortOrder("shift.day"),
      filterIcon: (filtered) => (
        <span>{query.shiftDayFrom && query.shiftDayTo ? <FilterFilled style={{ color: "var(--prayon-primary-color)" }} /> : <FilterFilled />}</span>
      ),
      filterDropdown: () => (
        <DatePicker.RangePicker
          onChange={(dates) => {
            if (dates && dates.length > 1 && dates[0] && dates[1]) {
              setQuery({
                shiftDayFrom: shiftDayDateTimeToString(DateTime.fromJSDate(dates[0].toDate())),
                shiftDayTo: shiftDayDateTimeToString(DateTime.fromJSDate(dates[1].toDate())),
              });
            } else {
              setQuery({ shiftDayFrom: null, shiftDayTo: null });
            }
          }}
          disabledDate={(current) => current && current > dayjs()}
          allowClear={true}
        />
      ),
    },
    {
      title: "Team",
      width: 80 * Math.max(1, zoomRatio),
      dataIndex: ["shift", "team"],
      sorter: { multiple: 1 },
      filters: [
        {
          value: Team.D,
          text: Team.D,
        },
        {
          value: Team.N,
          text: Team.N,
        },
      ],
      defaultFilteredValue: query?.team?.filter((team): team is string => team !== null) || [],
      defaultSortOrder: getSortOrder("shift.team"),
    },
    {
      title: "Area",
      width: 80 * Math.max(1, zoomRatio),
      dataIndex: ["shift", "area"],
      sorter: { multiple: 1 },
      filters: Object.keys(Area).map((area) => {
        return {
          value: area,
          text: area,
        };
      }),
      defaultFilteredValue: query?.area?.filter((area): area is string => area !== null) || [],
      defaultSortOrder: getSortOrder("shift.area"),
    },
    {
      title: "Product",
      width: 90 * Math.max(1, zoomRatio),
      dataIndex: ["shift", "product"],
      sorter: { multiple: 1 },
      filters: Object.keys(Product).map((product) => {
        return {
          value: product,
          text: product,
        };
      }),
      defaultFilteredValue: query?.product?.filter((product): product is string => product !== null) || [],
      defaultSortOrder: getSortOrder("shift.product"),
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: { multiple: 1 },
      width: 150 * Math.max(1, zoomRatio),
      render: (status, record) => {
        let statusString = camelCaseToSentence(status);
        if (record.edited) {
          statusString += " (edited)";
        }
        return statusString;
      },
      filters: Object.keys(Status).map((status) => {
        return {
          value: status,
          text: camelCaseToSentence(status),
        };
      }),
      defaultFilteredValue: query?.status?.filter((status): status is string => status !== null) || [],
      defaultSortOrder: getSortOrder("status"),
    },
    {
      title: "Last modified by",
      dataIndex: "statuses",
      width: 200 * Math.max(1, zoomRatio),
      render: (statuses) => (statuses?.length ? statuses[statuses.length - 1].byUser.email : ""),
    },
    {
      title: "Last modified date",
      dataIndex: "updatedAt",
      sorter: { multiple: 1 },
      width: 155 * Math.max(1, zoomRatio),
      render: (updatedAt) => nicelyDisplayDateFromISOString(updatedAt),
      defaultSortOrder: getSortOrder("updatedAt"),
    },
    {
      title: "Flags",
      dataIndex: "flags",
      width: 70 * Math.max(1, zoomRatio),
      render: (flags, record) => {
        return (
          <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
            <FlagsPopover flags={flags} zoomRatio={zoomRatio} flowId={record.id} />
          </div>
        );
      },
      filters: [
        { text: "Yes", value: "true" },
        { text: "No", value: "false" },
      ],
      defaultFilteredValue: query?.flags?.filter((flags): flags is string => flags !== null) || [],
    },
    {
      title: "Manually created",
      dataIndex: "manuallyCreated",
      width: 80 * Math.max(1, zoomRatio),
      render: (manual) =>
        manual ? (
          <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
            <CheckOutlined />
          </div>
        ) : (
          ""
        ),
    },
    {
      title: "Edited",
      dataIndex: "edited",
      width: 100 * Math.max(1, zoomRatio),
      sorter: { multiple: 1 },
      filters: [
        { text: "Yes", value: "true" },
        { text: "No", value: "false" },
      ],
      defaultFilteredValue: query?.edited?.filter((edited): edited is string => edited !== null) || [],
      render: (edited) =>
        edited ? (
          <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
            <CheckOutlined />
          </div>
        ) : (
          ""
        ),
    },
    {
      title: "Edited by",
      width: 130 * Math.max(1, zoomRatio),
      dataIndex: ["editedBy", "email"],
      sorter: { multiple: 1 },
      defaultSortOrder: getSortOrder("editedBy.email"),
    },
    {
      title: "Edited date",
      dataIndex: "editedAt",
      sorter: { multiple: 1 },
      width: 160 * Math.max(1, zoomRatio),
      render: (editedAt) => (editedAt ? nicelyDisplayDateFromISOString(editedAt) : ""),
      defaultSortOrder: getSortOrder("editedAt"),
    },
    {
      title: "Details",
      width: 125 * Math.max(1.2, zoomRatio),
      dataIndex: "id",
      fixed: "right",
      render: (id) => {
        return (
          <Space>
            <Button
              type="link"
              icon={<EyeOutlined />}
              onClick={() => {
                navigate("/flow/" + id + "?readonly=true");
              }}
            />
            {canEdit && (
              <Button
                type="link"
                icon={<EditOutlined />}
                onClick={() => {
                  navigate(`/flow/${id}?mode=supervisor`);
                }}
              />
            )}
            <Button
              type="link"
              icon={<HistoryOutlined />}
              onClick={() => {
                navigate(id + "/history");
              }}
            />
          </Space>
        );
      },
    },
  ];

  return (
    <div
      style={{
        padding: 20,
      }}
      className={"flow-table"}
    >
      <CSVDownloadButton fetchDataConfig={flowQueryConfig} queryHook={useFlows} filename={"flows.csv"} />
      <Table
        size={"small"}
        onChange={(pagination, filters, sorter) => {
          const sorterArray = Array.isArray(sorter) ? sorter : [sorter];
          const newSortParams = sorterArray
            .filter((s) => s.field)
            .filter((s) => s.order)
            .map((s) => {
              return `${s.field && s.field.toString().replace(/,/, ".")}${s.order ? ":" + (s.order === "ascend" ? "asc" : "desc") : ""}`;
            })
            .reverse();

          if (!newSortParams.find((s) => s.startsWith("createdAt"))) {
            newSortParams.push("createdAt:desc");
          }

          filters.team = filters["shift.team"];
          delete filters["shift.team"];
          filters.area = filters["shift.area"];
          delete filters["shift.area"];
          filters.product = filters["shift.product"];
          delete filters["shift.product"];

          setQuery({
            page: pagination.current,
            pageSize: pagination.pageSize,
            sortParams: newSortParams,
            ...filters,
          });
        }}
        scroll={{ x: 1800 * Math.max(1, zoomRatio), ...(height ? { y: height - 125 - 25 * zoomRatio - tableHeaderHeight } : {}) }}
        pagination={{
          current: page,
          total,
          pageSize,
        }}
        columns={columns}
        dataSource={flows}
        bordered={true}
      />
    </div>
  );
};

export default FlowTable;
