import React, { useEffect, useRef, useState } from "react";
import { Button } from "antd";

interface OverflowCellProps {
  content: React.ReactNode;
  onMore?: (content: React.ReactNode) => void;
}

const OverflowCell: React.FC<OverflowCellProps> = ({ content, onMore }) => {
  const [isOverflowed, setIsOverflowed] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkOverflow = () => {
      const contentCurrent = contentRef.current;
      const containerCurrent = containerRef.current;
      if (contentCurrent && containerCurrent && contentCurrent.clientWidth > containerCurrent.clientWidth) {
        setIsOverflowed(true);
      } else {
        setIsOverflowed(false);
      }
    };

    checkOverflow();
    window.addEventListener("resize", checkOverflow);
    return () => window.removeEventListener("resize", checkOverflow);
  }, []);

  return (
    <div
      ref={containerRef}
      style={{
        width: "100%",
        overflow: "hidden",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div ref={contentRef} style={{ whiteSpace: "nowrap", visibility: "hidden", position: "absolute" }}>
        {content}
      </div>
      <div style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", width: "100%", overflow: "hidden" }}>{content}</div>
      {isOverflowed && (
        <Button
          onClick={() => {
            if (onMore) {
              onMore(content);
            }
          }}
          type={"link"}
          style={{
            fontWeight: "bold",
            zIndex: 1,
            color: "black",
            padding: "0px 3px",
          }}
        >
          more
        </Button>
      )}
    </div>
  );
};

export default OverflowCell;
