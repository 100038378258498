"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validationNumberIsPositive = exports.validationStringNotEmpty = exports.validationBooleanValue = exports.validationNAValue = exports.validationMissingValue = exports.flowValidateFE = exports.flowValidate = void 0;
const index_1 = require("./index");
const utils_1 = require("../utils");
function flowValidate(flow, shift) {
    switch (flow.type) {
        case index_1.FlowType.inventoryReading:
            return (0, index_1.flowInventoryReadingValidate)(flow);
        case index_1.FlowType.packageInspection:
            return (0, index_1.flowPackageInspectionValidate)(flow);
        case index_1.FlowType.log:
            return (0, index_1.flowLogValidate)(flow);
        case index_1.FlowType.masterSanitation:
            return (0, index_1.flowMasterSanitationValidate)(flow);
        case index_1.FlowType.safetyShower:
            return (0, index_1.flowSafetyShowerValidate)(flow);
        case index_1.FlowType.scaleTest:
            return (0, index_1.flowScaleTestValidate)(flow);
        case index_1.FlowType.dustCollector:
            return (0, index_1.flowDustCollectorValidate)(flow);
        case index_1.FlowType.shiftCheckOut:
            return (0, index_1.flowShiftCheckOutValidate)(flow, shift);
        case index_1.FlowType.shiftCheckIn:
            return (0, index_1.flowShiftCheckInValidate)(flow);
        case index_1.FlowType.metalDetector:
            return (0, index_1.flowMetalDetectorValidate)(flow);
        case index_1.FlowType.batchRecord:
            return (0, index_1.flowBatchRecordValidate)(flow);
        case index_1.FlowType.inspection:
            return (0, index_1.flowInspectionValidate)(flow);
        default:
            return {
                errors: [],
                warnings: [],
            };
    }
}
exports.flowValidate = flowValidate;
function flowValidateFE(flow, shift) {
    const flowValidationResult = flowValidate(flow, shift);
    const transformIssues = (issues) => {
        const transformed = {};
        issues.forEach((issue) => {
            // Create a unique key for each property, including subPropertyId if available.
            const key = issue.subPropertyId !== undefined ? `${issue.property}-${issue.subPropertyId}` : issue.property;
            if (!transformed[key]) {
                transformed[key] = {
                    property: issue.property,
                    messages: [],
                };
                // If there's a subPropertyId, add it to the transformed object.
                if (issue.subPropertyId !== undefined) {
                    transformed[key].subPropertyId = issue.subPropertyId;
                }
            }
            transformed[key].messages.push(issue.message);
        });
        return Object.values(transformed);
    };
    return {
        errors: transformIssues(flowValidationResult.errors),
        warnings: transformIssues(flowValidationResult.warnings),
    };
}
exports.flowValidateFE = flowValidateFE;
function validationMissingValue(warnings, value, property, labelProperty) {
    if (value == null) {
        // null or undefined
        warnings.push({
            property,
            message: `${labelProperty} is missing`,
        });
    }
    return warnings;
}
exports.validationMissingValue = validationMissingValue;
function validationNAValue(warnings, value, shouldBeYes, property, labelProperty, yesValue = "Yes", noValue = "No") {
    if (value != null) {
        if (shouldBeYes) {
            if (value === index_1.YesNoNA.No) {
                warnings.push({
                    property,
                    message: `${labelProperty} is '${noValue}'`,
                });
            }
        }
        else {
            if (value === index_1.YesNoNA.Yes) {
                warnings.push({
                    property,
                    message: `${labelProperty} is '${yesValue}'`,
                });
            }
        }
    }
    return warnings;
}
exports.validationNAValue = validationNAValue;
function validationBooleanValue(warnings, value, shouldBeTrue, property, labelProperty) {
    if (value != null) {
        if (shouldBeTrue) {
            if (!value) {
                warnings.push({
                    property,
                    message: `${labelProperty} is 'No'`,
                });
            }
        }
        else {
            if (value) {
                warnings.push({
                    property,
                    message: `${labelProperty} is 'Yes'`,
                });
            }
        }
    }
    return warnings;
}
exports.validationBooleanValue = validationBooleanValue;
function validationStringNotEmpty(warnings, value, property, labelProperty) {
    if (value != null) {
        if (value === "") {
            warnings.push({
                property,
                message: `${labelProperty} is empty`,
            });
        }
    }
    return warnings;
}
exports.validationStringNotEmpty = validationStringNotEmpty;
function validationNumberIsPositive(warnings, value, property, labelProperty) {
    if (value != null) {
        try {
            (0, utils_1.validateNumericValues)(value);
        }
        catch (_a) {
            warnings.push({
                property,
                message: `${labelProperty} is not a number`,
            });
            return warnings;
        }
        if (value < 0) {
            warnings.push({
                property,
                message: `${labelProperty} is negative`,
            });
        }
    }
    return warnings;
}
exports.validationNumberIsPositive = validationNumberIsPositive;
