import { useQuery, UseQueryResult } from "@tanstack/react-query";
import getAPI from "../services/api";
import { IRequiredSafetyShower } from "shared/interfaces";
import { useAppSelector } from "./redux";

export const useRequiredSafetyShower = (): UseQueryResult<IRequiredSafetyShower> => {
  const { area, product } = useAppSelector((state) => state.shift);
  return useQuery(["safety-shower", area, product], async () => {
    const api = await getAPI();
    const { data } = await api.get("/flows/requiredSafetyShower", { params: { product, area } });
    return data;
  });
};

export default useRequiredSafetyShower;
