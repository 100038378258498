import { useQuery, UseQueryResult } from "@tanstack/react-query";
import getAPI from "../services/api";
import { ISourcePossible } from "shared/interfaces";

export const useSources = (filters): UseQueryResult<ISourcePossible[]> => {
  const { materialId, product } = filters;

  return useQuery(["sources", product, materialId], async () => {
    const api = await getAPI();
    if (materialId === undefined || product === undefined) return [];
    const { data } = await api.get(`/sources/forProductAndMaterial/${product}/${materialId}`);
    const transformedData = data.sources.map((source) => {
      return {
        ...source, // Spread operator to copy all properties of the source
        possible: data.possibleSources.includes(source.id),
      };
    });
    return transformedData?.sort((a, b) => b.reference.localeCompare(a.reference))?.sort((a, b) => Number(b.possible) - Number(a.possible)) || [];
  });
};

export default useSources;
