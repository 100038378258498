import React from "react";
import { Layout } from "antd";

import { useParams } from "react-router";
import BatchDetails from "../../ShiftScreen/BatchFrame/BatchDetails";

const { Content } = Layout;

const BatchPage = () => {
  const { batchId } = useParams();

  return (
    <>
      <Content
        style={{
          height: "calc(100vh - 64px)",
          overflowY: "auto",
          position: "relative",
          padding: "24px",
        }}
      >
        <BatchDetails batchId={Number(batchId)} readonly={true} showBack={true} />
      </Content>
    </>
  );
};

export default BatchPage;
