import React, { useState } from "react";
import { Checkbox, InputNumber, InputNumberProps, Space } from "antd";

type InputNumberNAProps = {
  value?: any;
  onChange?: (value: any) => void;
  disabled?: boolean;
} & Omit<InputNumberProps, "disabled" | "value" | "onChange">;

const InputNumberNA: React.FC<InputNumberNAProps> = ({ onChange, value, disabled, ...props }) => {
  const [isNA, setIsNA] = useState(value === "NA");

  const [savedValue, setSavedValue] = useState(value);

  const handleInputChange = (inputValue) => {
    setSavedValue(inputValue);
    onChange && onChange(inputValue);
  };

  const handleCheckboxChange = (e) => {
    setIsNA(e.target.checked);
    if (e.target.checked) {
      onChange && onChange("NA");
    } else {
      onChange && onChange(savedValue);
    }
  };

  return (
    <Space>
      <InputNumber
        onChange={handleInputChange}
        disabled={disabled || isNA}
        value={isNA ? undefined : savedValue}
        {...props}
        controls={false}
        inputMode="numeric"
      />
      <Checkbox style={{ marginLeft: 8 }} checked={value === "NA"} onChange={handleCheckboxChange} disabled={disabled}>
        N/A
      </Checkbox>
    </Space>
  );
};

export default InputNumberNA;
