import React from "react";
import { parseURLArguments } from "../../helpers/url";
import { Alert } from "antd";
import * as auth from "../../services/auth";
import _ from "lodash";
import unsplash from "../../assets/unsplash.jpg";
import unsplashProd from "../../assets/unsplash-prod.jpg";

export const Login = () => {
  const { search } = window.location;
  const params = parseURLArguments(search);
  function goToLogin() {
    window.location.href = `${process.env.REACT_APP_API_URL}/connect/microsoft`;
  }
  if (params["access_token"]) {
    fetch(`${process.env.REACT_APP_API_URL}/auth/microsoft/callback${search}`)
      .then(async (res) => {
        if (res.status !== 200) {
          throw new Error(`Couldn't login to Strapi. Status: ${res.status}`);
        }
        return res;
      })
      .then((res) => res.json())
      .then(async (res) => {
        await auth.authenticate(res);
        // TODO: get user and set default area and team in redux
        window.location.href = window.location.href.split("?")[0];
      })
      .catch((err) => {
        console.error(err);
        const error = _.get(err, "response.data.message", err.message);
        window.location.href = `${window.location.href.split("?")[0]}?${encodeURIComponent("error[error_description]")}=${encodeURIComponent(error)}`;
      });
    return <div style={{ padding: 20 }}>Redirecting...</div>;
  }
  return (
    <div
      style={{
        backgroundImage: `url(${process.env.REACT_APP_ENV === "prod" ? unsplashProd : unsplash})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          margin: 20,
        }}
      >
        <div
          style={{
            backgroundColor: "#FFFFFFCC",
            padding: "20px",
            boxShadow: "5px 5px 10px #000000AA",
            textShadow: "1px 1px gray",
            letterSpacing: "0.2em",
            textAlign: "center",
            maxWidth: "80%",
            width: "30%",

            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: 20,
          }}
        >
          <h1>Prayon DigiLog</h1>
          <button
            style={{
              backgroundColor: "#eeeeee",
              padding: "0.5em 1em",
              border: "none",
              cursor: "pointer",
            }}
            onClick={goToLogin}
          >
            <img
              src="https://s3-eu-west-1.amazonaws.com/cdn-testing.web.bas.ac.uk/scratch/bas-style-kit/ms-pictogram/ms-pictogram.svg"
              style={{
                height: "1em",
                width: "1em",
                position: "relative",
                marginRight: ".5em",
                fontSize: 16,
              }}
              alt="Microsoft Logo"
            />
            Sign In with Microsoft
          </button>
          {params["error[error_description]"] && (
            <Alert
              style={{ margin: 20 }}
              type="error"
              message={
                <>
                  <div style={{ padding: 10 }}>{params["error[error_description]"]}</div>
                </>
              }
              closable={false}
            />
          )}
        </div>
      </div>
    </div>
  );
};
