import { Button, DatePicker, Form, Input, Modal, notification, Table, Space } from "antd";
import { CheckCircleFilled, CloseCircleOutlined, EyeOutlined, SafetyCertificateOutlined, FilterFilled } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { DateTime } from "luxon";
import "./FlagTable.css";
import useFlags from "../../../hooks/useFlags";
import { ColumnsType } from "antd/es/table";
import { ZoomContext } from "../../../App";
import getAPI from "../../../services/api";
import useBatches from "../../../hooks/useBatches";
import useFlows from "../../../hooks/useFlows";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../../services/auth";
import { BusinessRole, flowGetLabel, Team, Product, Area } from "shared/interfaces";
import { ArrayParam, NumberParam, useQueryParams, StringParam } from "use-query-params";
import OverflowCell from "../../UI/OverflowCell";
import { nicelyDisplayDateFromISOString, camelCaseToSentence, shiftDayDateTimeToString } from "shared/utils";
import useWindowResize from "../../../hooks/useWindowResize";
import CSVDownloadButton from "../../UI/CSVDownloadButton";
import { FilterDropdown, FilterIcon } from "../UI/FilterId";

const FlagTable = () => {
  const user = useUser();
  const [comment, setComment] = React.useState<string | null>(null);
  const [constraint, setConstraint] = React.useState<string | null>(null);
  const navigate = useNavigate();
  const [canResolve, setCanResolve] = React.useState(false);

  const [isResolveModalVisible, setIsResolveModalVisible] = React.useState(false);
  const [newResolutionComment, setNewResolutionComment] = React.useState("");
  const [resolutionComment, setResolutionComment] = React.useState<string | null>(null);
  const [currentResolutionRecordId, setCurrentResolutionRecordId] = React.useState(null);
  const [zoom] = React.useContext(ZoomContext);
  const zoomRatio = parseInt(zoom) / 100;
  const { height } = useWindowResize();
  const [tableHeaderHeight, setTableHeaderHeight] = useState(0);

  useEffect(() => {
    // Function to update state with the height of the th element
    const updateHeight = () => {
      const thElement = document.querySelector("th");
      if (thElement) {
        setTableHeaderHeight(thElement.clientHeight);
      }
    };

    // Set up ResizeObserver
    const resizeObserver = new ResizeObserver(updateHeight);
    const thElement = document.querySelector("th");
    if (thElement) {
      resizeObserver.observe(thElement);
    }

    // Clean up observer on component unmount
    return () => {
      if (thElement) {
        resizeObserver.unobserve(thElement);
      }
    };
  }, []);

  const handleConfirmResolve = async (recordId) => {
    try {
      const api = await getAPI();
      await api.put(`/flags/${recordId}/resolve`, { data: { comment: newResolutionComment } });
      await refetch();
      setNewResolutionComment("");
      setIsResolveModalVisible(false);
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: `${error.message}`,
      });
    }
  };

  React.useEffect(() => {
    setCanResolve(user?.businessRole === BusinessRole.Supervisor);
  }, [user]);

  const [query, setQuery] = useQueryParams({
    id: NumberParam,
    page: NumberParam,
    pageSize: NumberParam,
    total: NumberParam,
    flagType: ArrayParam,
    flowId: NumberParam,
    batchId: NumberParam,
    from: NumberParam,
    to: NumberParam,
    resolved: ArrayParam,
    sortParams: ArrayParam,
    team: ArrayParam,
    product: ArrayParam,
    area: ArrayParam,
    shiftDayFrom: StringParam,
    shiftDayTo: StringParam,
  });

  // Default times to one week ago if not set
  const [defaultTimeSpan] = useState([dayjs().subtract(1, "week").valueOf(), dayjs().valueOf()]);
  const timeSpan = query.from && query.to ? [query.from, query.to] : defaultTimeSpan;

  const getSortOrder = (field) => {
    const sortParam = query.sortParams?.find((s) => s?.startsWith(field));
    if (sortParam) {
      return sortParam.endsWith("asc") ? "ascend" : "descend";
    }
    return null;
  };

  const sortParams = query.sortParams || [];

  if (!sortParams.find((s) => s?.startsWith("createdAt"))) {
    sortParams.push("createdAt:desc");
  }

  const flagQueryConfig = {
    page: query.page || 0,
    pageSize: query.pageSize || 100,
    filters: {
      ...(query.resolved ? { resolved: { $in: query.resolved } } : {}),
      ...(timeSpan && timeSpan[0] && timeSpan[1]
        ? {
            createdAt: {
              $gte: timeSpan[0].valueOf(),
              $lte: timeSpan[1].valueOf(),
            },
          }
        : {}),
      ...(query.id ? { id: query.id } : {}),
      ...(query.batchId ? { batch: { id: { $eq: query.batchId } } } : {}),
      ...(query.flowId ? { flow: { id: { $eq: query.flowId } } } : {}),
      ...(query.flagType && query.flagType.length > 0
        ? {
            $or: query.flagType.reduce<{ [x: string]: { id: { $notNull: boolean } } }[]>(
              (acc, type) => [...acc, { [type || ""]: { id: { $notNull: true } } }],
              []
            ),
          }
        : { $or: [{ batch: { id: { $notNull: true } } }, { flow: { id: { $notNull: true } } }] }),
      ...((query.team && query.team.length) || (query.area && query.area.length) || (query.product && query.product.length) || query.shiftDayFrom
        ? {
            flow: {
              shift: {
                ...(query.team && query.team.length ? { team: { $in: query.team } } : {}),
                ...(query.area && query.area.length ? { area: { $in: query.area } } : {}),
                ...(query.product && query.product.length ? { product: { $in: query.product } } : {}),
                ...(query.shiftDayFrom && query.shiftDayTo ? { day: { $gte: query.shiftDayFrom, $lte: query.shiftDayTo } } : {}),
              },
            },
          }
        : {}),
    },
    ...(sortParams ? { sort: sortParams } : {}),
  };

  // @ts-ignore
  const { data, refetch } = useFlags(flagQueryConfig);

  const flowIds = data?.data?.map((flag) => flag?.flow?.id).filter((id) => id);
  const batchIds = data?.data?.map((flag) => flag?.batch?.id).filter((id) => id);

  const { data: flowsData } = useFlows({
    page: 1,
    pageSize: 100,
    filters: {
      ...(flowIds?.length > 0 ? { id: { $in: flowIds } } : {}),
    },
    enabled: !!flowIds?.length,
  });

  const { data: batchesData } = useBatches({
    page: 1,
    pageSize: 100,
    filters: {
      ...(batchIds?.length > 0 ? { id: { $in: batchIds } } : {}),
    },
    enabled: !!batchIds?.length,
  });

  const flows = flowsData?.data;
  const batches = batchesData?.data;

  const total = data?.meta?.pagination?.total;
  const page = data?.meta?.pagination?.page;
  const pageSize = data?.meta?.pagination?.pageSize;

  const { data: auditTrails = [] } = data || {};

  const columns: ColumnsType<any> = [
    {
      title: "Flag id",
      dataIndex: "id",
      fixed: "left",
      width: 75 * Math.max(1, zoomRatio),
      sorter: { multiple: 1 },
      onHeaderCell: (column) => {
        if (query.id) {
          return {
            className: "ant-table-column-sort",
          };
        }
        return {};
      },
      filterIcon: (filtered) => <FilterIcon queryItem={query.id} />,
      filterDropdown: FilterDropdown({
        queryKey: "id",
        queryValue: query.id,
        setQuery: setQuery,
        resetQuery: () => setQuery({ id: null }),
        isString: false,
      }),
      defaultSortOrder: getSortOrder("id"),
    },
    {
      title: "Creation date",
      dataIndex: "createdAt",
      sorter: { multiple: 1 },
      width: 160 * Math.max(1, zoomRatio),
      defaultFilteredValue: timeSpan,
      filterDropdown: () => (
        <DatePicker.RangePicker
          value={[dayjs(timeSpan[0]), dayjs(timeSpan[1])]}
          showTime={false}
          onChange={(dates) => {
            if (dates && dates.length > 1 && dates[0] && dates[1]) {
              const from = DateTime.fromJSDate(dates[0].toDate()).startOf("day").toMillis();
              const to = DateTime.fromJSDate(dates[1].toDate()).endOf("day").toMillis();
              setQuery({ from: from, to: to, page: 1 });
            }
          }}
          disabledDate={(current) => current && current > dayjs()}
          allowClear={false}
        />
      ),
      defaultSortOrder: getSortOrder("createdAt"),
      render: (createdAt) => nicelyDisplayDateFromISOString(createdAt),
    },

    {
      title: "Property",
      dataIndex: "column",
      sorter: { multiple: 1 },
      width: 140 * Math.max(1, zoomRatio),
      defaultSortOrder: getSortOrder("column"),
      render: (column) => {
        return camelCaseToSentence(column);
      },
    },
    {
      title: "Constraint",
      dataIndex: "message",
      sorter: { multiple: 1 },
      width: 200 * Math.max(1, zoomRatio),
      defaultSortOrder: getSortOrder("message"),
      render: (value) => {
        return value ? <OverflowCell content={value} onMore={() => setConstraint(value)} /> : null;
      },
    },
    {
      title: "Area",
      dataIndex: "area",
      sorter: { multiple: 1 },
      width: 75 * Math.max(1, zoomRatio),
      render: (_, record) => {
        const area =
          flows?.find((f) => f.id === record?.flow?.id)?.shift.area || batches?.find((b) => b.id === record?.batch?.id)?.batchLines[0]?.shift?.area;
        return area;
      },
      filters: Object.keys(Area).map((area) => {
        return {
          value: area,
          text: area,
        };
      }),
    },
    {
      title: "Product",
      dataIndex: "product",
      sorter: { multiple: 1 },
      width: 95 * Math.max(1, zoomRatio),
      render: (_, record) => {
        const product =
          flows?.find((f) => f.id === record?.flow?.id)?.shift.product ||
          batches?.find((b) => b.id === record?.batch?.id)?.batchLines[0]?.shift?.product;
        return product;
      },
      filters: Object.keys(Product).map((product) => {
        return {
          value: product,
          text: product,
        };
      }),
    },
    {
      title: "Team",
      dataIndex: "team",
      sorter: { multiple: 1 },
      width: 80 * Math.max(1, zoomRatio),
      render: (_, record) => {
        const team =
          flows?.find((f) => f.id === record?.flow?.id)?.shift.team || batches?.find((b) => b.id === record?.batch?.id)?.batchLines[0]?.shift?.team;
        return team;
      },
      filters: Object.keys(Area).map((area) => {
        return {
          value: area,
          text: area,
        };
      }),
    },
    {
      title: "Day",
      dataIndex: "day",
      width: 100 * Math.max(1, zoomRatio),
      sorter: { multiple: 1 },
      render: (_, record) => {
        const day =
          flows?.find((f) => f.id === record?.flow?.id)?.shift.day || batches?.find((b) => b.id === record?.batch?.id)?.batchLines[0]?.shift?.day;
        return day;
      },
      filterIcon: (filtered) => (
        <span>{query.shiftDayFrom && query.shiftDayTo ? <FilterFilled style={{ color: "var(--prayon-primary-color)" }} /> : <FilterFilled />}</span>
      ),
      filterDropdown: () => (
        <DatePicker.RangePicker
          onChange={(dates) => {
            if (dates && dates.length > 1 && dates[0] && dates[1]) {
              setQuery({
                shiftDayFrom: shiftDayDateTimeToString(DateTime.fromJSDate(dates[0].toDate())),
                shiftDayTo: shiftDayDateTimeToString(DateTime.fromJSDate(dates[1].toDate())),
              });
            } else {
              setQuery({ shiftDayFrom: null, shiftDayTo: null });
            }
          }}
          disabledDate={(current) => current && current > dayjs()}
          allowClear={true}
        />
      ),
    },
    {
      title: "Flow",
      ellipsis: true,
      dataIndex: ["flow", "id"],
      width: 200 * Math.max(1, zoomRatio),
      sorter: { multiple: 1 },
      defaultSortOrder: getSortOrder("flow"),
      render: (flowId, record) => {
        if (flowId && flows) {
          return (
            <Button
              type="link"
              className={"flow-button"}
              style={{
                padding: 0,
                margin: 0,
                width: "100%",
                textOverflow: "ellipsis",
                overflow: "hidden",
                textAlign: "left",
                whiteSpace: "normal", // Allows text wrapping
                height: "auto", // Adjust height as needed
              }}
              onClick={() => {
                const shiftId = flows?.find((f) => f.id === flowId)?.shift.id;
                navigate(`/shift/${shiftId}/flow/${flowId}?readonly=true`);
              }}
            >
              <div>{`(${flowId}) ${flowGetLabel(record.flow)}`}</div>
              <div>{`${flows?.find((f) => f.id === flowId)?.shift?.uniqueId}`}</div>
            </Button>
          );
        } else {
          return null;
        }
      },
    },
    {
      title: "Batch",
      ellipsis: true,
      dataIndex: ["batch", "batchNumber"],
      width: 200 * Math.max(1, zoomRatio),
      sorter: { multiple: 1 },
      defaultSortOrder: getSortOrder("batch.batchNumber"),
      render: (batchNumber, record) => {
        if (typeof batchNumber === "string") {
          return (
            <Button
              type="link"
              className={"batch-button"}
              style={{
                padding: 0,
                margin: 0,
                width: "100%",
                textOverflow: "ellipsis",
                overflow: "hidden",
                textAlign: "left",
              }}
              onClick={() => {
                navigate(`/batch/${record?.batch?.id}`);
              }}
            >
              ({record?.batch?.id}) {batchNumber}
            </Button>
          );
        } else {
          return null;
        }
      },
    },
    {
      title: "Comment",
      dataIndex: "comment",
      width: 200 * Math.max(1, zoomRatio),
      render: (value) => {
        return value ? <OverflowCell content={value} onMore={() => setComment(value)} /> : null;
      },
    },
    {
      title: "Resolved by",
      sorter: { multiple: 1 },
      ellipsis: true,
      width: 150 * Math.max(1, zoomRatio),
      defaultSortOrder: getSortOrder("resolvedBy.email"),
      dataIndex: ["resolvedBy", "email"],
    },
    {
      title: "Resolution date",
      dataIndex: "updatedAt",
      sorter: { multiple: 1 },
      defaultSortOrder: getSortOrder("updatedAt"),
      width: 150 * Math.max(1, zoomRatio),
      render: (updatedAt, record) => (record.resolved ? nicelyDisplayDateFromISOString(updatedAt) : null),
    },
    {
      title: "Resolution comment",
      dataIndex: "resolutionComment",
      width: 200 * Math.max(1, zoomRatio),
      render: (value) => {
        return value ? (
          <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
            <span
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "min-content",
                display: "block",
                whiteSpace: "nowrap",
              }}
            >
              {value}
            </span>
            <Button
              type={"link"}
              style={{ marginLeft: 10, color: "black" }}
              icon={<EyeOutlined />}
              onClick={() => {
                setResolutionComment(value);
              }}
            />
          </div>
        ) : null;
      },
    },
    {
      title: "Resolved",
      dataIndex: "resolved",
      fixed: "right",
      sorter: { multiple: 1 },
      defaultSortOrder: getSortOrder("resolved"),
      width: 100 * Math.max(1, zoomRatio),
      filters: [
        { text: "Yes", value: "true" },
        { text: "No", value: "false" },
      ],
      render: (resolved, record) => {
        return (
          <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
            <Space>
              {resolved ? <CheckCircleFilled style={{ color: "green" }} /> : <CloseCircleOutlined style={{ color: "lightgray" }} />}
              {!resolved && canResolve && (
                <>
                  <Button
                    type={"link"}
                    style={{ color: "green" }}
                    icon={<SafetyCertificateOutlined />}
                    onClick={() => {
                      setCurrentResolutionRecordId(record.id);
                      setIsResolveModalVisible(true);
                    }}
                  />
                </>
              )}
            </Space>
          </div>
        );
      },
    },
  ];

  return (
    <div
      style={{
        padding: 20,
      }}
      className={"flag-table"}
    >
      <CSVDownloadButton fetchDataConfig={flagQueryConfig} queryHook={useFlags} filename={"flags.csv"} />
      <Table
        size={"small"}
        onChange={(pagination, filters, sorter) => {
          const sorterArray = (Array.isArray(sorter) ? sorter : [sorter]).filter((s) => s.column);
          const newSortParams = sorterArray
            .filter((s) => s.order)
            .filter((s) => s.field)
            .map((s) => {
              return `${s.field && s.field.toString().replace(/,/, ".")}${s.order ? ":" + (s.order === "ascend" ? "asc" : "desc") : ""}`;
            })
            .reverse();

          setQuery({
            page: pagination.current,
            pageSize: pagination.pageSize,
            sortParams: newSortParams,
            ...filters,
          });
        }}
        scroll={{ x: 1800 * Math.max(1, zoomRatio), ...(height ? { y: height - 125 - 25 * zoomRatio - tableHeaderHeight } : {}) }}
        pagination={{
          current: page,
          total,
          pageSize,
        }}
        columns={columns}
        dataSource={auditTrails}
        bordered={true}
      />
      <Modal
        open={!!comment}
        footer={null}
        onCancel={() => {
          setComment(null);
        }}
        title={"Comment"}
      >
        <>{comment}</>
      </Modal>
      <Modal
        open={!!resolutionComment}
        footer={null}
        onCancel={() => {
          setResolutionComment(null);
        }}
        title={"Resolution comment"}
      >
        <>{resolutionComment}</>
      </Modal>
      <Modal
        open={!!constraint}
        footer={null}
        onCancel={() => {
          setConstraint(null);
        }}
        title={"Constraint"}
      >
        <>{constraint}</>
      </Modal>
      <Modal
        title={`Resolve the flag (id: ${currentResolutionRecordId})`}
        open={isResolveModalVisible}
        onOk={() => handleConfirmResolve(currentResolutionRecordId)}
        onCancel={() => {
          setIsResolveModalVisible(false);
          setCurrentResolutionRecordId(null);
          setNewResolutionComment("");
        }}
        okText="Confirm"
        cancelText="Cancel"
      >
        <Form layout="vertical">
          <Form.Item label="Comment" required tooltip="This is a required field">
            <Input.TextArea rows={4} value={newResolutionComment} onChange={(e) => setNewResolutionComment(e.target.value)} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default FlagTable;
