"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.flowScaleTestValidate = exports.flowScaleTestGetBagTare = exports.flowScaleTestGetTestWeightValue = exports.flowScaleTestGetTolerance = exports.flowScaleTestGetFlowName = exports.flowScaleTestGetGenericLabel = exports.flowScaleTestGetLabel = exports.labelBagSize = exports.BagSize = void 0;
const index_1 = require("./index");
/**
 * Model definition for FlowScaleTest
 */
var BagSize;
(function (BagSize) {
    BagSize["LBS50"] = "LBS50";
    BagSize["LBS55"] = "LBS55";
})(BagSize = exports.BagSize || (exports.BagSize = {}));
function labelBagSize(bagSize) {
    switch (bagSize) {
        case BagSize.LBS50:
            return "50 lbs";
        case BagSize.LBS55:
            return "25 kg (= 55 lbs)";
    }
}
exports.labelBagSize = labelBagSize;
function flowScaleTestGetLabel() {
    return flowScaleTestGetGenericLabel();
}
exports.flowScaleTestGetLabel = flowScaleTestGetLabel;
function flowScaleTestGetGenericLabel() {
    return "Scale Test";
}
exports.flowScaleTestGetGenericLabel = flowScaleTestGetGenericLabel;
function flowScaleTestGetFlowName() {
    return "FlowScaleTest";
}
exports.flowScaleTestGetFlowName = flowScaleTestGetFlowName;
function flowScaleTestGetTolerance() {
    return 0.06;
}
exports.flowScaleTestGetTolerance = flowScaleTestGetTolerance;
function flowScaleTestGetTestWeightValue(bagSize) {
    return 50.0;
}
exports.flowScaleTestGetTestWeightValue = flowScaleTestGetTestWeightValue;
function flowScaleTestGetBagTare(bagSize, product) {
    switch (bagSize) {
        case BagSize.LBS50:
            return product === index_1.Product.Ca ? 0.5 : 0.3;
        case BagSize.LBS55:
            return product === index_1.Product.Ca ? 0.6 : 0.4;
    }
}
exports.flowScaleTestGetBagTare = flowScaleTestGetBagTare;
function flowScaleTestValidate(flow) {
    let errors = [];
    let warnings = [];
    const labelProduct = "Product";
    const labelBagSize = "Bag Size";
    const labelCleanScale = "Clean Scale";
    const labelZeroScale = "Zero Scale";
    const labelCleanWeight = "Clean Weight";
    const labelTestWeightTagNumber = "Test weight tag number";
    const labelTest1 = "Test 1";
    const labelTest2 = "Test 2";
    const labelTest3 = "Test 3";
    const labelTest4 = "Test 4";
    const labelTest5 = "Test 5";
    const labelPass = "Pass";
    warnings = (0, index_1.validationMissingValue)(warnings, flow.product, "product", labelProduct);
    warnings = (0, index_1.validationMissingValue)(warnings, flow.bagSize, "bagSize", labelBagSize);
    warnings = (0, index_1.validationMissingValue)(warnings, flow.cleanScale, "cleanScale", labelCleanScale);
    warnings = (0, index_1.validationMissingValue)(warnings, flow.zeroScale, "zeroScale", labelZeroScale);
    warnings = (0, index_1.validationMissingValue)(warnings, flow.cleanWeight, "cleanWeight", labelCleanWeight);
    warnings = (0, index_1.validationMissingValue)(warnings, flow.testWeightTagNumber, "testWeightTagNumber", labelTestWeightTagNumber);
    warnings = (0, index_1.validationMissingValue)(warnings, flow.test1, "test1", labelTest1);
    warnings = (0, index_1.validationMissingValue)(warnings, flow.test2, "test2", labelTest2);
    warnings = (0, index_1.validationMissingValue)(warnings, flow.test3, "test3", labelTest3);
    warnings = (0, index_1.validationMissingValue)(warnings, flow.test4, "test4", labelTest4);
    warnings = (0, index_1.validationMissingValue)(warnings, flow.test5, "test5", labelTest5);
    warnings = (0, index_1.validationMissingValue)(warnings, flow.pass, "pass", labelPass);
    warnings = (0, index_1.validationBooleanValue)(warnings, flow.cleanScale, true, "cleanScale", labelCleanScale);
    warnings = (0, index_1.validationBooleanValue)(warnings, flow.zeroScale, true, "zeroScale", labelZeroScale);
    warnings = (0, index_1.validationBooleanValue)(warnings, flow.cleanWeight, true, "cleanWeight", labelCleanWeight);
    warnings = (0, index_1.validationStringNotEmpty)(warnings, flow.testWeightTagNumber, "testWeightTagNumber", labelTestWeightTagNumber);
    warnings = (0, index_1.validationNAValue)(warnings, flow.pass, true, "pass", labelPass);
    warnings = (0, index_1.validationNumberIsPositive)(warnings, flow.test1, "test1", labelTest1);
    warnings = (0, index_1.validationNumberIsPositive)(warnings, flow.test2, "test2", labelTest2);
    warnings = (0, index_1.validationNumberIsPositive)(warnings, flow.test3, "test3", labelTest3);
    warnings = (0, index_1.validationNumberIsPositive)(warnings, flow.test4, "test4", labelTest4);
    warnings = (0, index_1.validationNumberIsPositive)(warnings, flow.test5, "test5", labelTest5);
    let allTestsPass = true;
    const tolerance = flowScaleTestGetTolerance() + 0.005;
    if (flow.test1 && flow.bagSize && Math.abs(flow.test1 - flowScaleTestGetTestWeightValue(flow.bagSize)) > tolerance) {
        warnings.push({
            property: "test1",
            message: `Weight of ${labelTest1} is out of tolerance`,
        });
        allTestsPass = false;
    }
    if (flow.test2 && flow.bagSize && Math.abs(flow.test2 - flowScaleTestGetTestWeightValue(flow.bagSize)) > tolerance) {
        warnings.push({
            property: "test2",
            message: `Weight of ${labelTest2} is out of tolerance`,
        });
        allTestsPass = false;
    }
    if (flow.test3 && flow.bagSize && Math.abs(flow.test3 - flowScaleTestGetTestWeightValue(flow.bagSize)) > tolerance) {
        warnings.push({
            property: "test3",
            message: `Weight of ${labelTest3} is out of tolerance`,
        });
        allTestsPass = false;
    }
    if (flow.test4 && flow.bagSize && Math.abs(flow.test4 - flowScaleTestGetTestWeightValue(flow.bagSize)) > tolerance) {
        warnings.push({
            property: "test4",
            message: `Weight of ${labelTest4} is out of tolerance`,
        });
        allTestsPass = false;
    }
    if (flow.test5 && flow.bagSize && Math.abs(flow.test5 - flowScaleTestGetTestWeightValue(flow.bagSize)) > tolerance) {
        warnings.push({
            property: "test5",
            message: `Weight of ${labelTest5} is out of tolerance`,
        });
        allTestsPass = false;
    }
    if (flow.pass === index_1.YesNoNA.Yes && !allTestsPass) {
        warnings.push({
            property: "pass",
            message: `Some weight values are not within tolerance, and ${labelPass} is set to Yes`,
        });
    }
    return {
        errors,
        warnings,
    };
}
exports.flowScaleTestValidate = flowScaleTestValidate;
