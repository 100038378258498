import { Modal } from "antd";
import React from "react";
import useBatch from "../../hooks/useBatch";
import useBatchLineClosingInfo from "../../hooks/useBatchLineClosingInfo";
import CloseBatchLineForm from "./CloseBatchLineForm";
import { batchGetOpenBatchLine } from "shared/interfaces";

const CloseBatchLineModal: React.FC<{
  batchId: number | undefined;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onCloseSuccess?: () => void;
}> = ({ open, setOpen, batchId, onCloseSuccess }) => {
  const { data: batch } = useBatch(batchId);

  const batchLine = batch && batchGetOpenBatchLine(batch);
  const batchLineId = batchLine?.id;

  const { data: closeBatchLineInfos } = useBatchLineClosingInfo({
    batchLineId,
    batchId,
  });

  return (
    <>
      {batch && (
        <Modal
          okText={"Submit"}
          title="Log latest set of pallets"
          open={open}
          onCancel={() => {
            setOpen(false);
          }}
          footer={null}
          maskClosable={false}
          destroyOnClose
          width={600}
        >
          {closeBatchLineInfos && (
            <CloseBatchLineForm
              onSuccess={() => {
                setOpen(false);
                onCloseSuccess && onCloseSuccess();
              }}
              onCancel={() => setOpen(false)}
            />
          )}
        </Modal>
      )}
    </>
  );
};

export default CloseBatchLineModal;
