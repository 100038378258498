import React, { useState } from "react";
import { Select, List, Checkbox, Button } from "antd";
import { UserDeleteOutlined } from "@ant-design/icons";

interface EmployeeShiftPickerProps {
  value?: any;
  onChange?: (value: any) => void;
  disabled?: boolean;
  options: any[];
}

const EmployeeShiftPicker: React.FC<EmployeeShiftPickerProps> = ({ options, value, onChange, disabled = false }) => {
  const [selectedEmployees, setSelectedEmployees] = useState(value || []);

  const handleChange = (selected) => {
    const newSelectedEmployees = options
      .filter((employee) => selected.includes(employee.id))
      .map((employee) => ({
        ...employee,
        userId: employee.id,
        relief: false,
      }));
    const newArrayEmployees = [...selectedEmployees, ...newSelectedEmployees].sort((a, b) => a.relief - b.relief);
    setSelectedEmployees(newArrayEmployees);
    if (onChange) {
      onChange(newArrayEmployees);
    }
  };

  const toggleRelief = (employee) => {
    const newSelectedEmployees = [...selectedEmployees];
    const index = newSelectedEmployees.findIndex((e) => e.userId === employee.userId);
    newSelectedEmployees[index].relief = !newSelectedEmployees[index].relief;
    const newArrayEmployees = newSelectedEmployees.sort((a, b) => a.relief - b.relief);
    setSelectedEmployees(newArrayEmployees);
    if (onChange) {
      onChange(newArrayEmployees);
    }
  };

  const deleteUser = (employee) => {
    const newSelectedEmployees = selectedEmployees.filter((e) => e.userId !== employee.userId);
    setSelectedEmployees(newSelectedEmployees);
    if (onChange) {
      onChange(newSelectedEmployees);
    }
  };

  return (
    <div>
      {!disabled && (
        <Select
          mode="multiple"
          filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
          placeholder="Select employees"
          style={{ width: 500 }}
          onChange={handleChange}
          value={[]}
          disabled={disabled}
          options={options
            .filter((employee) => !selectedEmployees.find((e) => e.id === employee.id))
            .map((employee) => ({
              value: employee.id,
              label: employee.name,
            }))}
        />
      )}
      <List
        locale={{ emptyText: "No employees selected" }}
        style={{
          overflow: "auto",
          maxWidth: 500,
          marginTop: 10,
          padding: 10,
          border: "1px solid rgba(140, 140, 140, 0.35)",
          borderRadius: 2,
        }}
        itemLayout="horizontal"
        dataSource={selectedEmployees}
        renderItem={(item: { id; name; relief }) => {
          return (
            <List.Item>
              <List.Item.Meta title={item.name} />
              <Checkbox checked={item.relief} onChange={() => toggleRelief(item)}>
                On Relief
              </Checkbox>
              {!disabled && <Button onClick={() => deleteUser(item)} style={{ marginLeft: "10px" }} icon={<UserDeleteOutlined />} />}
            </List.Item>
          );
        }}
      />
    </div>
  );
};

export default EmployeeShiftPicker;
