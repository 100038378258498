import { Button, Form, Input, notification, Result, Select, Space, Spin, Popconfirm } from "antd";
import React from "react";
import getAPI from "../../../services/api";
import { useNavigate, useSearchParams } from "react-router-dom";
import useFlow from "../../../hooks/useFlow";
import useProductFamilies from "../../../hooks/useProductFamilies";
import { SaveOutlined, SendOutlined } from "@ant-design/icons";
import {
  BusinessRole,
  flowGetStatus,
  flowIsEditable,
  flowValidateFE as flowValidate,
  IFlow,
  IFlowInventoryReading,
  Product,
  Status,
} from "shared/interfaces";
import InputNumberNA from "../../UI/InputNumberNA";
import { useUser } from "../../../services/auth";
import FlowStatus from "../../UI/FlowStatus";

const { TextArea } = Input;

interface InventoryReadingFlowProps {
  flowId: IFlow["id"];
  version?: IFlow["version"];
  readonly: boolean;
  onSuccess: (flow) => void;
  setFormHasUnsavedChanges?: (hasUnsavedChanges: boolean) => void;
}

const InventoryReadingFlowForm: React.FC<InventoryReadingFlowProps> = ({
  flowId,
  version,
  readonly: propReadonly = false,
  onSuccess,
  setFormHasUnsavedChanges,
}) => {
  const user = useUser();
  const [readonly, setReadonly] = React.useState(propReadonly);
  const navigate = useNavigate();
  const { data, isLoading: isFlowLoading, refetch: refetchFlow, error: flowError } = useFlow(flowId, version);
  const flow = data as IFlowInventoryReading;
  const [form] = Form.useForm();
  const [hasWarnings, setHasWarnings] = React.useState<boolean>(false);
  const initialValues: any = flow;
  let [searchParams] = useSearchParams();
  const supervisorMode = searchParams.get("mode") === "supervisor";

  if (initialValues) {
    initialValues.caBlendBin1 = initialValues?.caBlendBin1NA ? "NA" : initialValues?.caBlendBin1;
    initialValues.caBlendBin2 = initialValues?.caBlendBin2NA ? "NA" : initialValues?.caBlendBin2;
    initialValues.naKMiddleBinProductReading = initialValues?.naKMiddleBinProductReadingNA ? "NA" : initialValues?.naKMiddleBinProductReading;
    initialValues.naKSSBinProductReading = initialValues?.naKSSBinProductReadingNA ? "NA" : initialValues?.naKSSBinProductReading;
    initialValues.naKBlender = initialValues?.nakBlenderNA ? "NA" : initialValues?.naKBlender;
  }
  const caProductFamilyId = flow?.caProductFamily?.id;
  const naKMiddleBinProductFamilyId = flow?.naKMiddleBinProductFamily?.id;
  const naKSSBinProductFamilyId = flow?.naKSSBinProductFamily?.id;
  const { data: productFamilies, isLoading: isProductFamiliesLoading } = useProductFamilies(flow?.shift?.product);

  React.useEffect(() => {
    const userIsReader = user?.businessRole === BusinessRole.Reader || user?.businessRole === BusinessRole.Quality;
    setReadonly((propReadonly || userIsReader) ?? false);
  }, [propReadonly, user]);

  if (flowError) {
    return (
      <Result
        status="500"
        title="Mmmmh... something went wrong."
        subTitle="More luck next time?"
        extra={
          <Button type="primary" onClick={() => navigate("/")}>
            Back Home
          </Button>
        }
      />
    );
  }

  if (isFlowLoading || isProductFamiliesLoading || !productFamilies || !flow) {
    return <Spin>Loading...</Spin>;
  }

  return (
    <Form
      disabled={readonly}
      form={form}
      wrapperCol={{ span: 8 }}
      labelCol={{ span: 8 }}
      labelAlign="left"
      onFinish={async (values) => {
        const inventoryReadingFlow = flowFromValues(flow, values);

        const { warnings } = await flowValidate(inventoryReadingFlow);

        if (warnings.length > 0 && !hasWarnings) {
          setHasWarnings(true);
          notification.warning({
            message: "The form has warnings",
            description: "Please check the form for warnings and enter a comment to submit despite warnings",
          });
          !hasWarnings && setHasWarnings(true);
          form.setFields(
            warnings.map((warning) => ({
              name: replaceNAProperties(warning.property),
              warnings: warning.messages,
            }))
          );
          return;
        }
        try {
          const api = await getAPI();
          const inventoryReadingFlow = flowFromValues(flow, values);
          await api.put(`/flows/${flowId}`, {
            data: inventoryReadingFlow,
          });
          await api.put(`/flows/${flowId}/submit`);
          await refetchFlow();
          onSuccess && onSuccess(flow);
          notification.success({
            message: "The inventory reading was submitted successfully",
          });
        } catch (error: any) {
          notification.error({
            message: "There was an error submitting the form",
            description: error?.message,
          });
        }
      }}
      onValuesChange={async (changedValues, allValues) => {
        setFormHasUnsavedChanges && setFormHasUnsavedChanges(true);
        if (hasWarnings) {
          // reset warnings
          Object.keys(allValues).forEach((key) => {
            form.setFields([
              {
                name: key,
                warnings: [],
              },
            ]);
          });

          const inventoryReadingFlow = flowFromValues(flow, allValues);

          const { warnings } = await flowValidate(inventoryReadingFlow);

          // if we have warnings or errors, we set them in the form, the user can still submit)
          if (warnings.length > 0) {
            form.setFields(
              warnings.map((warning) => ({
                name: replaceNAProperties(warning.property),
                warnings: warning.messages,
              }))
            );
            setHasWarnings(true);
          } else if (warnings.length === 0) {
            setHasWarnings(false);
          }
        }
      }}
      initialValues={initialValues}
    >
      <Form.Item label="Product" name="product">
        <Input disabled={true} className="non-editable-input" />
      </Form.Item>

      {flow.product === Product.Ca ? (
        // Ca version of the flow
        <>
          <Form.Item label="Product family" name="caProductFamilyId" initialValue={caProductFamilyId}>
            <Select options={productFamilies.map(({ id, reference }) => ({ value: id, label: reference }))} disabled={readonly} />
          </Form.Item>
          <Form.Item label="Blend bin 1" name="caBlendBin1">
            <InputNumberNA disabled={readonly} addonAfter={"lbs"} />
          </Form.Item>
          <Form.Item label="Blend bin 2" name="caBlendBin2">
            <InputNumberNA disabled={readonly} addonAfter={"lbs"} />
          </Form.Item>
        </>
      ) : (
        // NaK version of the flow
        <>
          <Form.Item label="Middle bin product family" name="naKMiddleBinProductFamilyId" initialValue={naKMiddleBinProductFamilyId}>
            <Select options={productFamilies.map(({ id, reference }) => ({ value: id, label: reference }))} disabled={readonly} />
          </Form.Item>
          <Form.Item label="Middle bin product reading" name="naKMiddleBinProductReading">
            <InputNumberNA disabled={readonly} addonAfter={"lbs"} />
          </Form.Item>
          <Form.Item label="SS bin product family" name="naKSSBinProductFamilyId" initialValue={naKSSBinProductFamilyId}>
            <Select options={productFamilies.map(({ id, reference }) => ({ value: id, label: reference }))} disabled={readonly} />
          </Form.Item>
          <Form.Item label="SS bin product reading" name="naKSSBinProductReading">
            <InputNumberNA disabled={readonly} addonAfter={"lbs"} />
          </Form.Item>
          <Form.Item label="Blender" name="naKBlender">
            <InputNumberNA disabled={readonly} addonAfter={"lbs"} />
          </Form.Item>
        </>
      )}
      <Form.Item
        name="comment"
        label="Comment"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        rules={[{ required: hasWarnings, message: "Please add a comment to explain warnings" }]}
      >
        <TextArea disabled={readonly} />
      </Form.Item>
      <Space direction="vertical" style={{ width: "100%" }} size="large">
        {!readonly && (
          <div style={{ display: "flex", justifyContent: "right" }}>
            <Space>
              {supervisorMode ? (
                <Form.Item>
                  <Popconfirm
                    title="Save your change"
                    description="To make a change, you must be trained to do so and you MUST add an explanation in the comment box, below the existing comments. If you have done so, you can click on Save."
                    onConfirm={async () => {
                      try {
                        const values = form.getFieldsValue();
                        const inventoryReadingFlow = flowFromValues(flow, values);
                        const api = await getAPI();
                        await api.put(`/flows/${flowId}/edit`, {
                          data: inventoryReadingFlow,
                        });
                        await refetchFlow();
                        notification.success({
                          message: "Changes saved",
                        });
                        setFormHasUnsavedChanges && setFormHasUnsavedChanges(false);
                      } catch (error: any) {
                        notification.error({
                          message: "Error saving changes",
                          description: error?.message,
                        });
                      }
                    }}
                    okText="Save"
                    cancelText="Cancel"
                  >
                    <Button size="large" icon={<SaveOutlined />} type="primary">
                      Save changes
                    </Button>
                  </Popconfirm>
                </Form.Item>
              ) : (
                <>
                  <Form.Item>
                    <Button
                      disabled={flowGetStatus(flow) === Status.confirmed}
                      size="large"
                      icon={<SaveOutlined />}
                      onClick={async () => {
                        try {
                          const values = form.getFieldsValue();
                          const inventoryReadingFlow = flowFromValues(flow, values);

                          const api = await getAPI();
                          await api.put(`/flows/${flowId}`, {
                            data: inventoryReadingFlow,
                          });

                          await refetchFlow();
                          notification.success({
                            message: "Inventory reading draft saved",
                          });
                          setFormHasUnsavedChanges && setFormHasUnsavedChanges(false);
                        } catch (error: any) {
                          notification.error({
                            message: `Error saving inventory reading draft: ${error?.message}`,
                          });
                        }
                      }}
                    >
                      Save Draft
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <Button size="large" icon={<SendOutlined />} htmlType="submit" type="primary" disabled={!flowIsEditable(flow) || readonly}>
                      {hasWarnings ? "Submit with warnings" : "Submit"}
                    </Button>
                  </Form.Item>
                </>
              )}
            </Space>
          </div>
        )}
        <FlowStatus flow={flow} />
      </Space>
    </Form>
  );
};

export default InventoryReadingFlowForm;

function flowFromValues(flow: IFlowInventoryReading, values: any): IFlowInventoryReading {
  if (flow.product === Product.Ca) {
    return {
      ...flow,
      comment: values?.comment,
      caProductFamily: values.caProductFamilyId ? { id: values?.caProductFamilyId } : undefined,
      caBlendBin1: values.caBlendBin1 === "NA" ? null : values.caBlendBin1,
      caBlendBin2: values.caBlendBin2 === "NA" ? null : values.caBlendBin2,
      caBlendBin1NA: values.caBlendBin1 === "NA",
      caBlendBin2NA: values.caBlendBin2 === "NA",
    };
  } else if (flow.product === Product.NaK) {
    return {
      ...flow,
      comment: values?.comment,
      naKMiddleBinProductFamily: values.naKMiddleBinProductFamilyId ? { id: values?.naKMiddleBinProductFamilyId } : undefined,
      naKSSBinProductFamily: values.naKSSBinProductFamilyId ? { id: values?.naKSSBinProductFamilyId } : undefined,
      naKMiddleBinProductReading: values.naKMiddleBinProductReading === "NA" ? null : values.naKMiddleBinProductReading,
      naKMiddleBinProductReadingNA: values.naKMiddleBinProductReading === "NA",
      naKSSBinProductReading: values.naKSSBinProductReading === "NA" ? null : values.naKSSBinProductReading,
      naKSSBinProductReadingNA: values.naKSSBinProductReading === "NA",
      naKBlender: values.naKBlender === "NA" ? null : values.naKBlender,
      nakBlenderNA: values.naKBlender === "NA",
    };
  } else {
    throw new Error("Unknown product");
  }
}

let replacements = {
  caBlendBin1NA: "caBlendBin1",
  caBlendBin2NA: "caBlendBin2",
  naKMiddleBinProductReadingNA: "naKMiddleBinProductReading",
  naKSSBinProductReadingNA: "naKSSBinProductReading",
  nakBlenderNA: "nakBlender",
  caProductFamily: "caProductFamilyId",
  naKMiddleBinProductFamily: "naKMiddleBinProductFamilyId",
  naKSSBinProductFamily: "naKSSBinProductFamilyId",
};

function replaceNAProperties(property: string) {
  let replacedProperty = property;
  for (let [key, value] of Object.entries(replacements)) {
    let regex = new RegExp(key, "g"); // 'g' flag for global replacement
    replacedProperty = replacedProperty.replace(regex, value);
  }
  return replacedProperty;
}
