import React, { useState, useEffect } from "react";
import { Area, Product, Team } from "shared/interfaces";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { Button, DatePicker, notification, Modal, Badge } from "antd";
import slice from "../../slices/shift";
import { shiftDayDateTimeToString, shiftDayStringToDateTime } from "shared/utils";
import dayjs from "dayjs";
import { useUser } from "../../services/auth";
import getAPI from "../../services/api";
import { useNavigate } from "react-router";
import axios, { AxiosError } from "axios";
import { DateTime } from "luxon";

const ShiftPickerScreen: React.FC = () => {
  const user = useUser();

  const dispatch = useAppDispatch();
  const shift = useAppSelector((state) => state.shift);
  const navigate = useNavigate();
  const [showBadge, setShowBadge] = useState(false);

  useEffect(() => {
    const onShiftInfoChange = async () => {
      setShowBadge(false);
      getAPI()
        .then((api) => {
          api.get(`/shifts/specific/${shift.day}/${shift.team}/${shift.area}/${shift.product}`).catch(() => {
            setShowBadge(true);
          });
        })
        .catch(() => {
          setShowBadge(true);
        });
    };
    onShiftInfoChange();
  }, [shift]);

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: 30,
      }}
    >
      <div>
        <h2>You are logged in as {user?.firstname && user?.lastname ? `${user?.firstname} ${user?.lastname}` : user?.email}</h2>
      </div>
      <div>
        <DatePicker
          placement="topRight"
          allowClear={false}
          value={dayjs(shiftDayStringToDateTime(shift.day!)?.toISO())}
          onChange={(day) => {
            if (day) {
              dispatch(slice.actions.setDay({ day: shiftDayDateTimeToString(DateTime.fromISO(day.toISOString())) }));
            }
          }}
        />
      </div>
      <div style={{ display: "flex", alignItems: "flex-start", justifyContent: "center", gap: 50 }}>
        <div style={{ textAlign: "center" }}>
          <h3>Team</h3>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 10,
            }}
          >
            {Object.values(Team).map((team) => (
              <Button
                key={team}
                size="large"
                type={shift.team === team ? "primary" : "default"}
                onClick={() => {
                  dispatch(slice.actions.setTeam({ team }));
                }}
              >
                {team}
              </Button>
            ))}
          </div>
        </div>
        <div style={{ textAlign: "center" }}>
          <h3>Area</h3>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 10,
            }}
          >
            {Object.values(Area).map((area) => (
              <Button
                key={area}
                size="large"
                type={shift.area === area ? "primary" : "default"}
                onClick={() => {
                  dispatch(slice.actions.setArea({ area }));
                }}
                disabled={area !== Area.Pack}
              >
                {area}
              </Button>
            ))}
          </div>
        </div>
        <div style={{ textAlign: "center" }}>
          <h3>Product</h3>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 10,
            }}
          >
            {Object.values(Product).map((product) => (
              <Button
                key={product}
                size="large"
                type={shift.product === product ? "primary" : "default"}
                onClick={() => {
                  dispatch(slice.actions.setProduct({ product }));
                }}
              >
                {product}
              </Button>
            ))}
          </div>
        </div>
      </div>
      <div>
        <Badge dot={showBadge}>
          <Button
            type="primary"
            style={{ width: 300 }}
            onClick={async () => {
              const api = await getAPI();
              try {
                const response = await api.get(`/shifts/specific/${shift.day}/${shift.team}/${shift.area}/${shift.product}`);
                const { data } = response;

                if (data && data.id) {
                  navigate(`/shift/${data.id}?workerMode=true`);
                }
              } catch (error) {
                if (axios.isAxiosError(error) && error.response?.status === 404) {
                  // Shift not found, prompt the user
                  Modal.confirm({
                    title: "Confirm shift creation",
                    content:
                      "Selected shift does not exist yet. Are you sure you want to create it? (Creating a shift has consequences: batch continuation, flow creation,...)",
                    okText: "Confirm",
                    cancelText: "Cancel",
                    onOk: async () => {
                      try {
                        const postResponse = await api.post("/shifts", { data: shift });
                        const { data: postData } = postResponse;

                        if (postData && postData.id) {
                          navigate(`/shift/${postData.id}?workerMode=true`);
                        }
                      } catch (postError) {
                        handleApiError(postError);
                      }
                    },
                  });
                } else {
                  // Handle other API errors
                  handleApiError(error);
                }
              }
            }}
          >
            Confirm shift
          </Button>
        </Badge>
      </div>
    </div>
  );
};

export const ShouldPickShiftWrapper = ({ children, shouldPickShift }: { children: React.ReactElement; shouldPickShift: boolean }) => {
  if (!shouldPickShift) return <>{children}</>;
  return <ShiftPickerScreen>{children}</ShiftPickerScreen>;
};

export default ShiftPickerScreen;

const handleApiError = (error) => {
  if (axios.isAxiosError(error)) {
    const axiosError: AxiosError = error;
    notification.error({
      message: "Error",
      description: `${axiosError.message}`,
    });
  } else {
    notification.error({
      message: "Error",
      description: `${error}`,
    });
  }
};
