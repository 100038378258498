import React, { useEffect, useState, useRef } from "react";
import { Spin } from "antd";
import Quagga from "@ericblade/quagga2";

function Code128Scanner({ onDetected }) {
  const [loading, setLoading] = useState(true);
  const firstDetectionTime = useRef(new Date().getTime());
  const codeRef = useRef(null);
  const scannerContainerRef = useRef(null);

  const handleDetected = ({ codeResult }) => {
    const now = new Date().getTime();

    if (codeResult?.code) {
      if (codeResult.code === codeRef.current && now > firstDetectionTime.current + 1500) {
        console.info("Detected code: ", codeResult.code);
        onDetected(codeResult.code);
        firstDetectionTime.current = 0;
      } else if (codeRef.current !== codeResult.code || firstDetectionTime.current === 0) {
        codeRef.current = codeResult.code;
        firstDetectionTime.current = now;
      }
    }
  };

  useEffect(() => {
    Quagga.init(
      {
        inputStream: {
          type: "LiveStream",
          target: scannerContainerRef.current || undefined,
        },
        locator: {
          halfSample: true,
          patchSize: "large",
          debug: {
            showCanvas: false,
            showPatches: false,
            showFoundPatches: false,
            showSkeleton: false,
            showLabels: false,
            showPatchLabels: false,
            showRemainingPatchLabels: false,
            boxFromPatches: {
              showTransformed: true,
              showTransformedBox: true,
              showBB: true,
            },
          },
        },
        numOfWorkers: 4,
        decoder: {
          readers: ["code_128_reader"],
          debug: {
            drawBoundingBox: true,
            showFrequency: true,
            drawScanline: true,
            showPattern: true,
          },
        },
        locate: true,
      },
      (err) => {
        if (err) {
          console.error(err);
          return;
        }
        Quagga.start();
      }
    );

    Quagga.onDetected(handleDetected);

    Quagga.onProcessed(function (result) {
      loading && setLoading(false);
    });

    // Return a cleanup function that will stop Quagga when the component unmounts
    return () => {
      Quagga.offDetected(handleDetected); /* This is now working, but leaving it there as  */
      Quagga.stop();
    };
  }, []); // DO NOT REMOVE THE EMPTY ARRAY!!! Empty dependency array ensures this only runs on mount and unmount

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "60%",
          position: "absolute",
          top: 0,
          left: 0,
        }}
      >
        <Spin spinning={loading} />
      </div>
      <div id="scanner-container" ref={scannerContainerRef} />
      {!loading && (
        <div className="mask">
          <div className="line">
            <div className="point"></div>
          </div>
        </div>
      )}
    </>
  );
}

export default Code128Scanner;
