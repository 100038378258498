import React from "react";
import { IBatch } from "shared/interfaces";
import { nicelyDisplayDateFromISOString } from "shared/utils";
import { displayUserName } from "../../hooks/Users";

interface BatchStatusProps {
  batch: IBatch;
}

const BatchStatus: React.FC<BatchStatusProps> = ({ batch }) => {
  if (!batch) return null;
  if (!batch.edited) return null;
  let displayedStatus = `last edited on ${batch.editedAt && nicelyDisplayDateFromISOString(batch.editedAt)} by ${
    batch.editedBy && displayUserName(batch.editedBy)
  }`;

  return <div style={{ display: "flex", justifyContent: "right", color: "darkgray", marginBottom: "20px" }}>({displayedStatus})</div>;
};

export default BatchStatus;
