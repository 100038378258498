import { Button, Modal, Space, Table } from "antd";
import { DiffOutlined, EyeOutlined, CheckOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import "./FlowHistoryTable.css";
import useFlowHistory from "../../../hooks/useFlowHistory";
import { ColumnsType } from "antd/es/table";
import { useNavigate, useParams } from "react-router-dom";
import { ZoomContext } from "../../../App";
import JsonDiffDisplay, { DiffDisplayItemType } from "../../UI/JsonDiffDisplay";
import { ArrayParam, NumberParam, useQueryParams } from "use-query-params";
import useWindowResize from "../../../hooks/useWindowResize";
import { nicelyDisplayDateFromISOString, camelCaseToSentence } from "shared/utils";
import { flowGetLabel } from "shared/interfaces";

const FlowHistoryTable = () => {
  const [isDiffModalOpen, setIsDiffModalOpen] = React.useState<boolean>(false);
  const [currentVersionNumber, setCurrentVersionNumber] = React.useState<number | null>(null);
  const [previousVersionNumber, setPreviousVersionNumber] = React.useState<number | null>(null);
  const { flowId } = useParams();
  const { height } = useWindowResize();

  const [tableHeaderHeight, setTableHeaderHeight] = useState(0);
  useEffect(() => {
    // Function to update state with the height of the th element
    const updateHeight = () => {
      const thElement = document.querySelector("th");
      if (thElement) {
        setTableHeaderHeight(thElement.clientHeight);
      }
    };

    // Set up ResizeObserver
    const resizeObserver = new ResizeObserver(updateHeight);
    const thElement = document.querySelector("th");
    if (thElement) {
      resizeObserver.observe(thElement);
    }

    // Clean up observer on component unmount
    return () => {
      if (thElement) {
        resizeObserver.unobserve(thElement);
      }
    };
  }, []);

  const [zoom] = React.useContext(ZoomContext);
  const zoomRatio = parseInt(zoom) / 100;

  const [query, setQuery] = useQueryParams({
    page: NumberParam,
    pageSize: NumberParam,
    total: NumberParam,
    sortParams: ArrayParam,
  });

  const sortParams = query.sortParams || [];

  if (!sortParams.find((s) => s?.startsWith("version"))) {
    sortParams.push("version:desc");
  }

  const navigate = useNavigate();

  const flowHistoryQueryConfig = {
    page: query.page || 1,
    pageSize: query.pageSize || 100,
    filters: { flow: { id: flowId } },
    ...(sortParams ? { sort: sortParams } : {}),
  };

  const { data } = useFlowHistory(flowHistoryQueryConfig);

  const { data: flowHistory = [] } = data || {};

  const total = data?.meta?.pagination?.total;
  const page = data?.meta?.pagination?.page;
  const pageSize = data?.meta?.pagination?.pageSize;

  const columns: ColumnsType<any> = [
    {
      title: "Flow id",
      dataIndex: ["flow", "id"],
      width: 40 * zoomRatio,
      fixed: "left",
    },
    {
      title: "Version",
      dataIndex: "version",
      width: 40 * zoomRatio,
      fixed: "left",
    },
    {
      title: "Flow",
      dataIndex: "type",
      width: 100 * zoomRatio,
      render: (_, record) => {
        return flowGetLabel(record);
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 90 * Math.max(1, zoomRatio),
      render: (status, record) => {
        let statusString = camelCaseToSentence(status);
        if (record.edited) {
          statusString += " (edited)";
        }
        return statusString;
      },
    },
    {
      title: "Last modified by",
      dataIndex: "statuses",
      width: 110 * Math.max(1, zoomRatio),
      render: (statuses) => (statuses?.length ? statuses[statuses.length - 1].byUser.email : ""),
    },
    {
      title: "Last modified date",
      dataIndex: "updatedAt",
      width: 70 * zoomRatio,
      render: (updatedAt) => nicelyDisplayDateFromISOString(updatedAt),
    },
    {
      title: "Edited",
      dataIndex: "edited",
      width: 30 * Math.max(1, zoomRatio),
      render: (edited) =>
        edited ? (
          <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
            <CheckOutlined />
          </div>
        ) : (
          ""
        ),
    },
    {
      title: "Edition date",
      dataIndex: "editedAt",
      width: 110 * zoomRatio,
      render: (editedAt) => (editedAt ? nicelyDisplayDateFromISOString(editedAt) : ""),
    },
    {
      title: "Edited by",
      width: 110 * zoomRatio,
      dataIndex: "editedBy",
      render: (editedBy) => editedBy?.email ?? "",
    },
    {
      title: "Details",
      dataIndex: ["flow", "id"],
      fixed: "right",
      width: 50 * zoomRatio,
      render: (id, record) => {
        return (
          <Space>
            <Button
              type="link"
              icon={<EyeOutlined />}
              onClick={() => {
                if (record.version === 0) {
                  navigate(`/flow/${id}`);
                } else {
                  navigate(`/flow/${id}/version/${record.version}`);
                }
              }}
            />
            <Button
              type="link"
              icon={<DiffOutlined />}
              onClick={() => {
                setIsDiffModalOpen(true);
                setCurrentVersionNumber(record.version);
                setPreviousVersionNumber(record.version - 1);
              }}
            />
          </Space>
        );
      },
    },
  ];

  return (
    <div
      style={{
        padding: 20,
      }}
    >
      <Table
        size={"small"}
        className={"flow-history-table"}
        onChange={(pagination) => {
          setQuery({ page: pagination.current || 1 });
          setQuery({ pageSize: pagination.pageSize || 100 });
        }}
        scroll={{ x: 1600 * zoomRatio, ...(height ? { y: height - 125 - 25 * zoomRatio - tableHeaderHeight } : {}) }}
        pagination={{
          current: page,
          total,
          pageSize,
        }}
        columns={columns}
        dataSource={flowHistory}
        bordered={true}
      />
      <Modal
        title={"Diff to previous"}
        okText={"Close"}
        open={isDiffModalOpen}
        onCancel={() => setIsDiffModalOpen(false)}
        footer={null}
        width={"60vw"}
        closable
      >
        <JsonDiffDisplay
          currentItem={flowHistory.find(({ version }) => version === currentVersionNumber)}
          previousItem={flowHistory.find(({ version }) => version === previousVersionNumber)}
          type={DiffDisplayItemType.flow}
        />
      </Modal>
    </div>
  );
};

export default FlowHistoryTable;
