import React from "react";
import { Popover, Collapse, Button, Space } from "antd";
import { CheckCircleFilled, CheckCircleOutlined, EyeOutlined, FlagOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { IFlag } from "shared/interfaces";

interface FlagPopoverProps {
  flags: IFlag[];
  zoomRatio: number;
  batchId?: number;
  flowId?: number;
}

const FlagsPopover: React.FC<FlagPopoverProps> = ({ flags, zoomRatio, batchId, flowId }) => {
  const navigate = useNavigate();

  if (flags.length === 0) {
    return null;
  }

  return (
    <Popover
      overlayInnerStyle={{ width: 500 * zoomRatio }}
      content={
        <>
          <Space>
            <h3>Flags</h3>
            <Button
              type={"link"}
              icon={<EyeOutlined />}
              onClick={() => {
                if (batchId) {
                  navigate(`/flags?batchId=${batchId}`);
                } else if (flowId) {
                  navigate(`/flags?flowId=${flowId}`);
                } else {
                  navigate(`/flags`);
                }
              }}
            >
              View table
            </Button>
          </Space>
          <Collapse
            size="small"
            collapsible="icon"
            defaultActiveKey={flags.filter((flag) => flag.resolved).map((flag) => String(flag.id))}
            expandIconPosition="end"
            bordered={true}
            items={flags.map(({ id, message, column, resolved, resolutionComment }) => ({
              key: id,
              label: (
                <Space>
                  {resolved ? (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <CheckCircleFilled style={{ color: "green" }} />
                    </div>
                  ) : (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <CheckCircleOutlined style={{ color: "lightgray" }} />
                    </div>
                  )}
                  <div>
                    {message} ({column})
                  </div>
                </Space>
              ),
              children: resolved ? <div>{resolutionComment}</div> : null,
              showArrow: false,
            }))}
          />
        </>
      }
    >
      {flags.some((flag) => !flag.resolved) ? (
        <FlagOutlined style={{ color: "red" }} />
      ) : (
        <FlagOutlined style={{ color: "var(--prayon-primary-color)" }} />
      )}
    </Popover>
  );
};

export default FlagsPopover;
