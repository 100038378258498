import { Button, Table, Tag, Tooltip, Space } from "antd";
import React, { useEffect, useState } from "react";
import "./BatchTable.css";
import { CheckOutlined, SyncOutlined, EditOutlined } from "@ant-design/icons";
import ChangeBatchFeaturesModal from "../ChangeBatchFeaturesModal";
import {
  BatchLineType,
  flowGetStatus,
  Status,
  BatchType,
  FlowType,
  Product,
  BusinessRole,
  shiftIsSignedBySupervisorAndQuality,
  shiftIsSigned,
} from "shared/interfaces";
import "./BatchLineTable.css";
import useBatch from "../../../hooks/useBatch";
import { useParams } from "react-router";
import useShift from "../../../hooks/useShift";
import { useUser } from "../../../services/auth";
import BatchLineEditModal from "../../SupervisorScreen/BatchLines/BatchLineEditModal";
import { nicelyDisplayDateFromISOString } from "shared/utils";

interface BatchLineTableProps {
  batchId: number | undefined;
  readonly?: boolean;
}

type ColumnType = {
  title: string;
  dataIndex: string | string[];
  key?: string;
  width?: number;
  render?: (value: any, record: any, index?: any) => React.ReactNode;
};

const BatchLineTable: React.FC<BatchLineTableProps> = ({ batchId, readonly: propReadonly = false }) => {
  const user = useUser();
  const [readonly, setReadonly] = useState(propReadonly);
  const [canEdit, setCanEdit] = useState(false);
  const { data: batch, refetch: refetchBatch } = useBatch(batchId);
  const [changeBatchFeaturesModalOpen, setChangeBatchFeaturesModalOpen] = useState<boolean>(false);
  let scaleReadingLastPalletId;
  if (batch && !batch.closedAt && batch.type === BatchType.regular && batch.scaleReadings && batch.scaleReadings.length !== 0) {
    scaleReadingLastPalletId = batch.scaleReadings.sort((a, b) => (b.palletId ?? 0) - (a.palletId ?? 0))[0].palletId;
  }

  const [isBatchLineEditModalOpen, setIsBatchLineEditModalOpen] = useState(false);
  const [batchLineEditId, setBatchLineEditId] = useState(0);

  useEffect(() => {
    const userIsReader = user?.businessRole === BusinessRole.Reader || user?.businessRole === BusinessRole.Quality;
    setReadonly((propReadonly || userIsReader) ?? false);
  }, [propReadonly, user]);

  const { shiftId } = useParams();
  const { data: shift, refetch: refetchShift } = useShift(shiftId);
  const flowCheckOut = (shift?.flows || []).find((flow) => flow.type === FlowType.shiftCheckOut);
  const shiftCheckOutDone = flowCheckOut && flowGetStatus(flowCheckOut) === Status.confirmed;

  const handleEditSuccess = () => {
    refetchBatch();
  };

  const handleChangeFeatureSuccess = () => {
    refetchShift();
  };

  useEffect(() => {
    if (user && shift) {
      if (!shiftIsSigned(shift)) {
        setCanEdit(user?.businessRole === BusinessRole.Supervisor || user?.businessRole === BusinessRole.Operator);
      } else if (shiftIsSignedBySupervisorAndQuality(shift)) {
        setCanEdit(false);
      } else {
        setCanEdit(user?.businessRole === BusinessRole.Supervisor);
      }
    }
  }, [user, shift]);

  let columns: ColumnType[] = [
    {
      title: "",
      dataIndex: "closedAt",
      key: "closedAt",
      width: 40,
      render: (closedAt, record) =>
        !closedAt ? (
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Tooltip title="Ongoing">
              <SyncOutlined />
            </Tooltip>
          </div>
        ) : (
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            {record.type === BatchLineType.regular ? (
              <Tooltip title={`Closed at ${nicelyDisplayDateFromISOString(closedAt)}`}>
                <CheckOutlined />
              </Tooltip>
            ) : (
              <Tag style={{ lineHeight: "1rem", padding: "0 0.5rem" }} color={"grey"}>
                Metal detection
              </Tag>
            )}
          </div>
        ),
    },
    {
      title: "Source",
      dataIndex: ["source", "reference"],
      render: (source, record, index) => {
        // Checking if it's the last row
        if (!readonly && index === 0 && !record.closedAt && !shiftCheckOutDone) {
          return (
            <Button
              type={"link"}
              style={{ padding: 0 }}
              onClick={() => {
                setChangeBatchFeaturesModalOpen(true);
              }}
            >
              {source}
            </Button>
          );
        } else {
          return source;
        }
      },
    },
    {
      title: "Path",
      dataIndex: ["blend", "reference"],
      render: (blend, record, index) => {
        // Checking if it's the last row
        if (!readonly && index === 0 && !record.closedAt && !shiftCheckOutDone) {
          return (
            <Button
              type={"link"}
              style={{ padding: 0 }}
              onClick={() => {
                setChangeBatchFeaturesModalOpen(true);
              }}
            >
              {blend}
              {shift?.product === Product.Ca && record.blendNumber ? ` (${record.blendNumber})` : ""}
            </Button>
          );
        } else {
          return blend + (shift?.product === Product.Ca && record.blendNumber ? ` (${record.blendNumber})` : "");
        }
      },
    },
    {
      title: "Pallets",
      dataIndex: "qtyPallets",
      width: 140,
      render: (qtyPallets, record) => {
        if (record.type === BatchLineType.metalDetection) return record.palletId;
        // return the total number of pallets and the fromPallet and toPallet in the following format: 1 (1 → 1)
        if (record.toPallet) {
          return `${record.fromPallet} → ${record.toPallet} (${qtyPallets})`;
        } else if (record.fromPallet && scaleReadingLastPalletId) {
          if (record.fromPallet <= scaleReadingLastPalletId) {
            return `${record.fromPallet} → ${scaleReadingLastPalletId} (${scaleReadingLastPalletId - record.fromPallet + 1})`;
          } else {
            return `${record.fromPallet} → TBD (${qtyPallets})`;
          }
        } else if (qtyPallets === 0) {
          return `None`;
        }
      },
    },
    {
      title: "Bags",
      dataIndex: "qtyBags",
      width: 100,
    },
    {
      title: "Opened At",
      dataIndex: "openedAt",
      // format the date with the following format: 1/1, 1:00 PM without the year and seconds
      render: (date) => {
        if (!date) return "-";
        return new Date(date).toLocaleString("en-US", {
          month: "numeric",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        });
      },
    },
    {
      title: "Closed At",
      dataIndex: "closedAt",
      render: (date) => {
        if (!date) return "-";
        return new Date(date).toLocaleString("en-US", {
          month: "numeric",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        });
      },
    },
    {
      title: "Edit",
      width: 50,
      dataIndex: "id",
      render: (id) => {
        return (
          <Space>
            {canEdit && (
              <Button
                type="link"
                icon={<EditOutlined />}
                style={{ color: "#70bf45" }}
                onClick={() => {
                  setBatchLineEditId(id);
                  setIsBatchLineEditModalOpen(true);
                }}
              />
            )}
          </Space>
        );
      },
    },
  ];

  // Conditionally add the "Pkg Supply Pallet Id" column
  if (batch?.type === BatchType.regular) {
    columns.splice(3, 0, {
      title: "Pkg Supply Pallet Id",
      dataIndex: "pkgPalletId",
      render: (pkgPalledId, record, index) => {
        // Checking if it's the last row
        if (!readonly && index === 0 && !record.closedAt && !shiftCheckOutDone) {
          return (
            <Button
              type={"link"}
              style={{ padding: 0 }}
              onClick={() => {
                setChangeBatchFeaturesModalOpen(true);
              }}
            >
              {pkgPalledId}
            </Button>
          );
        } else {
          return pkgPalledId;
        }
      },
    });
  }

  return (
    <>
      <Table
        className={"batch-line-table"}
        style={{ width: "100%" }}
        scroll={{ x: 100 }}
        pagination={false}
        dataSource={
          batch?.batchLines
            ? batch.batchLines
                .map((l) => ({
                  ...l,
                  key: l.id,
                }))
                .sort((a, b) => (a.id! < b.id! ? 1 : -1))
            : []
        }
        columns={columns}
      />
      <ChangeBatchFeaturesModal
        open={changeBatchFeaturesModalOpen}
        setOpen={setChangeBatchFeaturesModalOpen}
        batchId={batch?.id}
        onChangeSuccess={() => handleChangeFeatureSuccess()}
      />
      <BatchLineEditModal
        open={isBatchLineEditModalOpen}
        setOpen={setIsBatchLineEditModalOpen}
        batchLineId={batchLineEditId}
        onEditSuccess={() => handleEditSuccess()}
      />
    </>
  );
};

export default BatchLineTable;
