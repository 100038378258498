import React from "react";
import { Button, Card, Col, List, Modal, notification, Row, Space, Spin, Tag } from "antd";
import FlowList from "./FlowList";
import { useParams, useMatches, useOutlet } from "react-router";
import {
  flowDustCollectorGetGenericLabel,
  flowGetStatus,
  flowInspectionGetGenericLabel,
  flowInventoryReadingGetGenericLabel,
  flowLogGetGenericLabel,
  flowMetalDetectorGetGenericLabel,
  flowPackageInspectionGetGenericLabel,
  flowScaleTestGetGenericLabel,
  FlowType,
  IFlowShiftCheckIn,
  InspectedType,
  PkgType,
  Status,
  shiftIsSignedByQuality,
  shiftIsSignedBySupervisor,
  shiftGetInfoSupervisorSignature,
  shiftGetInfoQualitySignature,
  BusinessRole,
  LogType,
  flowMasterSanitationGetLabelPeriod,
  flowSafetyShowerGetLabelPeriod,
} from "shared/interfaces";
import useShift from "../../hooks/useShift";
import Employees from "./Employees";
import BatchTable from "./BatchFrame/BatchTable";
import { CalendarOutlined, PlusOutlined, RedoOutlined, InfoCircleOutlined } from "@ant-design/icons";
import NewBatchModal from "./NewBatchModal";
import NewReworkModal from "./NewReworkModal";
import getAPI from "../../services/api";
import useRequiredMasterSanitations from "../../hooks/useRequiredMasterSanitations";
import useRequiredSafetyShower from "../../hooks/useRequiredSafetyShower";
import { useUser } from "../../services/auth";
import { nicelyDisplayDateFromISOString } from "shared/utils";
import { useLocalizedText } from "../../hooks/LocalizedText";
import MarkdownHelp from "../UI/MarkdownHelp";
import SignatureCard from "../UI/SignatureCard";

const ShiftView: React.FC<{ readonly?: boolean }> = ({ readonly: propReadonly = false }) => {
  const user = useUser();

  const [isNewReworkModalOpen, setIsNewReworkModalOpen] = React.useState(false);
  const [isNewBatchModalOpen, setIsNewBatchModalOpen] = React.useState(false);
  const { shiftId } = useParams();
  const { data: shift, refetch: refetchShift } = useShift(shiftId);
  const flowCheckOut = (shift?.flows || []).find((flow) => flow.type === FlowType.shiftCheckOut);
  const [readonly, setReadonly] = React.useState(propReadonly);
  const [confirmed, setConfirmed] = React.useState(false);
  const [supervisorPermission, setSupervisorPermission] = React.useState(false);
  const [qualityPermission, setQualityPermission] = React.useState(false);
  const { data: requiredMasterSanitations } = useRequiredMasterSanitations();
  const { data: requiredSafetyShower } = useRequiredSafetyShower();

  const helpBatches = useLocalizedText("Batches");
  const helpSignatures = useLocalizedText("Signatures");

  React.useEffect(() => {
    setConfirmed((flowCheckOut && flowGetStatus(flowCheckOut) === Status.confirmed) ?? false);
    const userIsReader = user?.businessRole === BusinessRole.Reader || user?.businessRole === BusinessRole.Quality;
    setReadonly((propReadonly || userIsReader) ?? false);

    setSupervisorPermission(user?.businessRole === BusinessRole.Supervisor);
    setQualityPermission(user?.businessRole === BusinessRole.Quality);
  }, [propReadonly, flowCheckOut, user]);

  // const supervisorPermission = usePermission(Object.values(UserRole) as UserRole[]); // TODO: filter permissions
  const outlet = useOutlet();
  const matches = useMatches();
  const lastMatch = matches[matches.length - 1];

  if (!shift || !requiredMasterSanitations) return <Spin style={{ width: "100%", justifyContent: "center", marginTop: 32 }} />;

  const flowShiftCheckIn = shift.flows?.find((f) => f.type === FlowType.shiftCheckIn) as IFlowShiftCheckIn;

  const addFlowsDatasource = [
    {
      title: flowInspectionGetGenericLabel(InspectedType.magnet),
      onClick: async () => {
        try {
          const api = await getAPI();
          await api.post(`/flows/${shiftId}/flowInspection`, {
            data: {
              typeInspected: InspectedType.magnet,
            },
          });
          notification.success({
            message: "Success",
            description: `${flowInspectionGetGenericLabel(InspectedType.magnet)} added`,
          });
          await refetchShift();
        } catch (error: any) {
          notification.error({
            message: "Error",
            description: `${error.message}`,
          });
        }
      },
    },
    {
      title: flowInspectionGetGenericLabel(InspectedType.screen),
      onClick: async () => {
        try {
          const api = await getAPI();
          await api.post(`/flows/${shiftId}/flowInspection`, {
            data: {
              typeInspected: InspectedType.screen,
            },
          });
          notification.success({
            message: "Success",
            description: `${flowInspectionGetGenericLabel(InspectedType.screen)} added`,
          });
          await refetchShift();
        } catch (error: any) {
          notification.error({
            message: "Error",
            description: `${error.message}`,
          });
        }
      },
    },
    {
      title: flowScaleTestGetGenericLabel(),
      onClick: async () => {
        try {
          const api = await getAPI();
          await api.post(`/flows/${shiftId}/flowScaleTest`, {});
          notification.success({
            message: "Success",
            description: `${flowScaleTestGetGenericLabel()} added`,
          });
          await refetchShift();
        } catch (error: any) {
          notification.error({
            message: "Error",
            description: `${error.message}`,
          });
        }
      },
    },
    {
      title: flowMetalDetectorGetGenericLabel(PkgType.Bag),
      onClick: async () => {
        try {
          const api = await getAPI();
          await api.post(`/flows/${shiftId}/flowMetalDetector`, {
            data: {
              pkgType: PkgType.Bag,
            },
          });
          notification.success({
            message: "Success",
            description: `${flowMetalDetectorGetGenericLabel(PkgType.Bag)} added`,
          });
          await refetchShift();
        } catch (error: any) {
          notification.error({
            message: "Error",
            description: `${error.message}`,
          });
        }
      },
    },
    {
      title: flowMetalDetectorGetGenericLabel(PkgType.SS),
      onClick: async () => {
        try {
          const api = await getAPI();
          await api.post(`/flows/${shiftId}/flowMetalDetector`, {
            data: {
              pkgType: PkgType.SS,
            },
          });
          notification.success({
            message: "Success",
            description: `${flowMetalDetectorGetGenericLabel(PkgType.SS)} added`,
          });
          await refetchShift();
        } catch (error: any) {
          notification.error({
            message: "Error",
            description: `${error.message}`,
          });
        }
      },
    },
    {
      title: flowDustCollectorGetGenericLabel(),
      onClick: async () => {
        try {
          const api = await getAPI();
          await api.post(`/flows/${shiftId}/flowDustCollector`, {});
          notification.success({
            message: "Success",
            description: `${flowDustCollectorGetGenericLabel()} added`,
          });
          await refetchShift();
        } catch (error: any) {
          notification.error({
            message: "Error",
            description: `${error.message}`,
          });
        }
      },
    },
    {
      title: flowInventoryReadingGetGenericLabel(),
      onClick: async () => {
        try {
          const api = await getAPI();
          await api.post(`/flows/${shiftId}/flowInventoryReading`, {});
          notification.success({
            message: "Success",
            description: `${flowInventoryReadingGetGenericLabel()} added`,
          });
          await refetchShift();
        } catch (error: any) {
          notification.error({
            message: "Error",
            description: `${error.message}`,
          });
        }
      },
    },
    {
      title: flowPackageInspectionGetGenericLabel(),
      onClick: async () => {
        try {
          const api = await getAPI();
          await api.post(`/flows/${shiftId}/flowPackageInspection`, {});
          notification.success({
            message: "Success",
            description: `${flowPackageInspectionGetGenericLabel()} added`,
          });
          await refetchShift();
        } catch (error: any) {
          notification.error({
            message: "Error",
            description: `${error.message}`,
          });
        }
      },
    },
    {
      title: flowLogGetGenericLabel(LogType.log),
      onClick: async () => {
        try {
          const api = await getAPI();
          await api.post(`/flows/${shiftId}/flowLog`, {});
          notification.success({
            message: "Success",
            description: `${flowLogGetGenericLabel(LogType.log)} added`,
          });
          await refetchShift();
        } catch (error: any) {
          notification.error({
            message: "Error",
            description: `${error.message}`,
          });
        }
      },
    },
  ];

  if (supervisorPermission || qualityPermission) {
    addFlowsDatasource.push({
      title: flowLogGetGenericLabel(LogType.dailyInstruction),
      onClick: async () => {
        try {
          const api = await getAPI();
          await api.post(`/flows/${shiftId}/flowLogDailyInstruction`, {});
          notification.success({
            message: "Success",
            description: `${flowLogGetGenericLabel(LogType.dailyInstruction)} added`,
          });
          await refetchShift();
        } catch (error: any) {
          notification.error({
            message: "Error",
            description: `${error.message}`,
          });
        }
      },
    });
  }

  return outlet && !lastMatch.id.includes("batch") ? (
    outlet
  ) : (
    <div style={{ padding: 20 }}>
      <Space direction="vertical" style={{ width: "100%" }} size="large">
        <Row>
          <Col span={24}>
            <Card title="On shift">
              <Employees employees={flowShiftCheckIn.onShift || []} />
            </Card>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Card
              title={
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <Space>
                    Batches
                    {helpBatches && (
                      <Button
                        type="link"
                        style={{ marginLeft: 10 }}
                        onClick={() => {
                          Modal.info({
                            title: "",
                            content: MarkdownHelp({ help: helpBatches }),
                            width: "80vw",
                          });
                        }}
                        icon={<InfoCircleOutlined />}
                      />
                    )}
                  </Space>
                  <Space>
                    {!readonly && !confirmed && (
                      <Button onClick={() => setIsNewBatchModalOpen(true)} type={"primary"} icon={<PlusOutlined />}>
                        New batch
                      </Button>
                    )}
                    {!readonly && !confirmed && (
                      <Button onClick={() => setIsNewReworkModalOpen(true)} type={"primary"} icon={<RedoOutlined />}>
                        Rework
                      </Button>
                    )}
                  </Space>
                </div>
              }
            >
              <BatchTable shiftId={Number(shiftId)} readonly={readonly || confirmed} />
            </Card>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={readonly ? 24 : 12} style={{ height: "100%" }}>
            <Space direction="vertical" size="middle" style={{ display: "flex" }}>
              <Card title="Flows">{shift.flows && <FlowList shiftId={shiftId} logs={false} readonly={readonly || confirmed} />}</Card>
              <Card title="Logs">{shift.flows && <FlowList shiftId={shiftId} logs={true} readonly={readonly || confirmed} />}</Card>
              <Row justify={"end"} style={{ padding: 10 }}>
                <Space>
                  {helpSignatures && (
                    <Button
                      type="link"
                      style={{ marginLeft: 10 }}
                      onClick={() => {
                        Modal.info({
                          title: "",
                          content: MarkdownHelp({ help: helpSignatures }),
                          width: "80vw",
                        });
                      }}
                      icon={<InfoCircleOutlined />}
                    />
                  )}
                  {
                    <SignatureCard
                      isSigned={shiftIsSignedBySupervisor(shift)}
                      signatureInfoFunc={() => shiftGetInfoSupervisorSignature(shift)}
                      shiftId={shiftId}
                      onSign={refetchShift}
                      signType={BusinessRole.Supervisor}
                      supervisorPermission={supervisorPermission}
                      qualityPermission={qualityPermission}
                      comment={shift.signSupervisorComment}
                    />
                  }
                  {
                    <SignatureCard
                      isSigned={shiftIsSignedByQuality(shift)}
                      signatureInfoFunc={() => shiftGetInfoQualitySignature(shift)}
                      shiftId={shiftId}
                      onSign={refetchShift}
                      signType={BusinessRole.Quality}
                      supervisorPermission={supervisorPermission}
                      qualityPermission={qualityPermission}
                      comment={shift.signQualityComment}
                    />
                  }
                </Space>
              </Row>
            </Space>
          </Col>
          {!readonly && !confirmed && (
            <Col span={12} style={{ height: "100%", display: "flex", flexDirection: "column" }}>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Card title="Add" style={{ width: "100%", backgroundColor: "transparent" }}>
                    <List
                      size="large"
                      dataSource={addFlowsDatasource}
                      renderItem={({ title, onClick }) => (
                        <List.Item
                          actions={[
                            <Button
                              shape="circle"
                              disabled={readonly}
                              style={{ width: "100%", textOverflow: "ellipsis", overflow: "hidden" }}
                              size="large"
                              icon={<PlusOutlined />}
                              onClick={onClick}
                            />,
                          ]}
                        >
                          <List.Item.Meta title={title} />
                        </List.Item>
                      )}
                    />

                    <Space direction="vertical" style={{ width: "100%", display: "flex" }} size="large"></Space>
                  </Card>
                </Col>
                {requiredSafetyShower && (
                  <Col span={24}>
                    <Card style={{ width: "100%", backgroundColor: "transparent" }}>
                      <Space direction="vertical" style={{ width: "100%", display: "flex" }} size="large">
                        <List
                          size="large"
                          dataSource={[requiredSafetyShower!] || []}
                          renderItem={({ daysUntilDueDate, nextDue, latestFlows }) => (
                            <List.Item
                              actions={[
                                <Button
                                  shape="circle"
                                  disabled={readonly}
                                  style={{ width: "100%", textOverflow: "ellipsis", overflow: "hidden" }}
                                  size="large"
                                  icon={<PlusOutlined />}
                                  onClick={async () => {
                                    try {
                                      const api = await getAPI();
                                      await api.post(`/flows/${shiftId}/flowSafetyShower`, {
                                        data: {},
                                      });
                                      notification.success({
                                        message: "Success",
                                        description: `Safety shower task added`,
                                      });
                                      await refetchShift();
                                    } catch (error: any) {
                                      notification.error({
                                        message: "Error",
                                        description: `${error.message}`,
                                      });
                                    }
                                  }}
                                />,
                              ]}
                            >
                              <List.Item.Meta
                                title={"Eye wash & safety shower"}
                                description={
                                  <>
                                    {daysUntilDueDate <= 0 && <Tag color={"red"}>Overdue</Tag>}
                                    {nextDue} due {daysUntilDueDate >= 0 ? `in ${daysUntilDueDate} days ` : `${-daysUntilDueDate} days ago `}
                                    <Button
                                      onClick={async () => {
                                        Modal.info({
                                          title: "Four last completed tasks",
                                          content: (
                                            <>
                                              {latestFlows.map((flow) => (
                                                <List>
                                                  <List.Item>
                                                    <List.Item.Meta
                                                      title={`✓ ${flowSafetyShowerGetLabelPeriod(flow)} - (${nicelyDisplayDateFromISOString(
                                                        flow.confirmDate
                                                      )})`}
                                                    />
                                                  </List.Item>
                                                </List>
                                              ))}
                                            </>
                                          ),
                                          okText: "Close",
                                        });
                                      }}
                                      type={"link"}
                                      icon={<CalendarOutlined style={{ fontStyle: "0.7rem", color: "grey" }} />}
                                    ></Button>
                                  </>
                                }
                              />
                            </List.Item>
                          )}
                        />
                      </Space>
                    </Card>
                  </Col>
                )}
                <Col span={24}>
                  <Card title="Master sanitation" style={{ width: "100%", backgroundColor: "transparent" }}>
                    <Space direction="vertical" style={{ width: "100%", display: "flex" }} size="large">
                      <List
                        size="large"
                        dataSource={requiredMasterSanitations || []}
                        renderItem={({ mssTask, daysUntilDueDate, nextDue, latestFlows }) => (
                          <List.Item
                            actions={[
                              <Button
                                shape="circle"
                                disabled={readonly}
                                style={{ width: "100%", textOverflow: "ellipsis", overflow: "hidden" }}
                                size="large"
                                icon={<PlusOutlined />}
                                onClick={async () => {
                                  try {
                                    const api = await getAPI();
                                    await api.post(`/flows/${shiftId}/flowMasterSanitation`, {
                                      data: {
                                        mssTaskId: mssTask.id,
                                      },
                                    });
                                    notification.success({
                                      message: "Success",
                                      description: `Master sanitation task added`,
                                    });
                                    await refetchShift();
                                  } catch (error: any) {
                                    notification.error({
                                      message: "Error",
                                      description: `${error.message}`,
                                    });
                                  }
                                }}
                              />,
                            ]}
                          >
                            <List.Item.Meta
                              title={mssTask.title}
                              description={
                                <>
                                  {daysUntilDueDate <= 0 && <Tag color={"red"}>Overdue</Tag>}
                                  {nextDue} due {daysUntilDueDate >= 0 ? `in ${daysUntilDueDate} days ` : `${-daysUntilDueDate} days ago `}
                                  <Button
                                    onClick={async () => {
                                      Modal.info({
                                        title: "Four last completed tasks",
                                        content: (
                                          <>
                                            {latestFlows.map((flow) => (
                                              <List>
                                                <List.Item>
                                                  <List.Item.Meta
                                                    title={`✓ ${flowMasterSanitationGetLabelPeriod(flow)} - (${nicelyDisplayDateFromISOString(
                                                      flow.confirmDate
                                                    )})`}
                                                  />
                                                </List.Item>
                                              </List>
                                            ))}
                                          </>
                                        ),
                                        okText: "Close",
                                      });
                                    }}
                                    type={"link"}
                                    icon={<CalendarOutlined style={{ fontStyle: "0.7rem", color: "grey" }} />}
                                  ></Button>
                                </>
                              }
                            />
                          </List.Item>
                        )}
                      />
                    </Space>
                  </Card>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </Space>
      <NewBatchModal open={isNewBatchModalOpen} setOpen={setIsNewBatchModalOpen} shiftId={shift.id!} />
      <NewReworkModal open={isNewReworkModalOpen} setOpen={setIsNewReworkModalOpen} shiftId={shift.id!} />
    </div>
  );
};

// eslint-disable-next-line import/no-anonymous-default-export
export default ShiftView;
