import { Button, Checkbox, Col, Form, Input, InputNumber, notification, Result, Row, Space, Spin, Typography, Popconfirm } from "antd";
import React, { useEffect } from "react";
import getAPI from "../../../services/api";
import useFlow from "../../../hooks/useFlow";
import { SaveOutlined, SendOutlined } from "@ant-design/icons";
import { useAppSelector } from "../../../hooks/redux";
import YesNoNASwitch from "../../UI/YesNoNASwitch";
import { useNavigate, useSearchParams } from "react-router-dom";

import { BusinessRole, flowGetStatus, flowIsEditable, flowValidateFE as flowValidate, IFlow, IFlowDustCollector, Status } from "shared/interfaces";
import { useUser } from "../../../services/auth";
import FlowStatus from "../../UI/FlowStatus";

const { TextArea } = Input;
const { Text } = Typography;

interface DustCollectorFlowProps {
  flowId: IFlow["id"];
  version?: IFlow["version"];
  readonly: boolean;
  onSuccess: (flow) => void;
  setFormHasUnsavedChanges?: (hasUnsavedChanges: boolean) => void;
}

const DustCollectorFlowForm: React.FC<DustCollectorFlowProps> = ({
  flowId,
  version,
  readonly: propReadonly = false,
  onSuccess,
  setFormHasUnsavedChanges,
}) => {
  const user = useUser();
  const [readonly, setReadonly] = React.useState(propReadonly);
  const navigate = useNavigate();
  const { data, isLoading: isFlowLoading, refetch: refetchFlow, error: flowError } = useFlow(flowId, version);
  const flow = data as IFlowDustCollector;
  const [form] = Form.useForm();
  const [hasWarnings, setHasWarnings] = React.useState<boolean>(false);
  const [listIdsWithWarnings, setListIdsWithWarnings] = React.useState<any>({});
  const initialValues = flow;
  const { includeOpacity } = initialValues || {};
  const { team } = useAppSelector((state) => state.shift);
  const [downIds, setDownIds] = React.useState<(number | undefined)[]>([]);
  let [searchParams] = useSearchParams();
  const supervisorMode = searchParams.get("mode") === "supervisor";

  useEffect(() => {
    if (initialValues?.readings) {
      setDownIds(initialValues?.readings?.filter((reading) => reading.down).map((reading) => reading?.dustCollector?.id));
    }
  }, [initialValues?.readings, listIdsWithWarnings]);

  useEffect(() => {
    const userIsReader = user?.businessRole === BusinessRole.Reader || user?.businessRole === BusinessRole.Quality;
    setReadonly((propReadonly || userIsReader) ?? false);
  }, [propReadonly, user]);

  // Little helper to get the readings from the form in the proper format for the API
  const getFormReadings = (initialValues, values) => {
    const readings = initialValues?.readings?.map((reading) => {
      const dustCollectorId = reading?.dustCollector?.id || "";
      const { id, ...readingWithoutId } = reading;
      if (values[dustCollectorId + "_down"]) {
        return {
          down: true,
          dustCollector: {
            ...reading?.dustCollector,
          },
        };
      }
      return {
        ...readingWithoutId,
        down: false,
        ...(includeOpacity ? { opacityVE: values[dustCollectorId + "_opacityVE"] ?? "No" } : {}),
        pressureReading: values[dustCollectorId + "_pressureReading"],
        dustCollector: {
          ...reading?.dustCollector,
        },
      };
    });
    return readings;
  };

  if (flowError) {
    return (
      <Result
        status="500"
        title="Mmmmh... something went wrong."
        subTitle="More luck next time?"
        extra={
          <Button type="primary" onClick={() => navigate("/")}>
            Back Home
          </Button>
        }
      />
    );
  }

  if (isFlowLoading || !flow) {
    return <Spin>Loading...</Spin>;
  }

  return (
    <Form
      disabled={readonly}
      form={form}
      onFinish={async (values) => {
        const readings = getFormReadings(initialValues, values);
        const dustCollectorFlow: IFlowDustCollector = {
          ...flow,
          ...initialValues,
          readings,
        };
        const { warnings } = await flowValidate(dustCollectorFlow);

        setListIdsWithWarnings({});

        if (warnings.length > 0 && !hasWarnings) {
          setHasWarnings(true);
          notification.warning({
            message: "The form has warnings",
            description: "Please check the form for warnings and enter a comment to submit despite warnings",
          });
          !hasWarnings && setHasWarnings(true);
          const newListIdsWithWarnings = {};
          form.setFields(
            warnings.map((warning) => {
              if (warning.property === "readings" && warning.subPropertyId != null) {
                newListIdsWithWarnings[warning.subPropertyId] = true;
                return {
                  name: `${warning.subPropertyId}_warning`,
                  warnings: warning.messages,
                };
              }
              return {
                name: warning.property,
                warnings: warning.messages,
              };
            })
          );
          setListIdsWithWarnings(newListIdsWithWarnings);
          return;
        } else if (warnings.length === 0 && hasWarnings) {
          setHasWarnings(false);
        }
        try {
          const api = await getAPI();
          await api.put(`/flows/${flowId}`, {
            data: {
              ...flow,
              comment: values.comment,
              readings,
            },
          });

          await api.put(`/flows/${flowId}/submit`);
          await refetchFlow();
          onSuccess && onSuccess(flow);
          notification.success({
            message: "The dust collector check was submitted successfully",
          });
        } catch (error: any) {
          notification.error({
            message: "There was an error submitting the form",
            description: error?.message,
          });
        }
      }}
      onFieldsChange={async (changedFields, allFields) => {
        setFormHasUnsavedChanges && setFormHasUnsavedChanges(true);
        if (changedFields[0].name[0].includes("down")) {
          const dustCollectorId = changedFields[0].name[0].split("_")[0];
          if (changedFields[0].value) {
            setDownIds([...downIds, Number(dustCollectorId)]);
          } else {
            setDownIds(downIds.filter((id) => id !== Number(dustCollectorId)));
          }
        }

        const values = form.getFieldsValue();
        const readings = getFormReadings(initialValues, values);
        readings?.forEach((reading) => {
          if (reading.down) {
            form.setFieldValue(reading.dustCollector.id + "_pressureReading", undefined);
            form.setFieldValue(reading.dustCollector.id + "_opacityVE", undefined);
          }
        });

        if (hasWarnings) {
          // reset warnings
          Object.keys(values)?.forEach((key) => {
            form.setFields([
              {
                name: key,
                warnings: [],
              },
            ]);
          });
          const dustCollectorFlow: IFlowDustCollector = {
            ...flow,
            ...initialValues,
            readings,
          };
          const { warnings } = flowValidate(dustCollectorFlow);
          setListIdsWithWarnings({});
          // if we have warnings, we set them in the form, the user can still submit)
          if (warnings.length > 0) {
            const newListIdsWithWarnings = {};
            form.setFields(
              warnings.map((warning) => {
                if (warning.property === "readings" && warning.subPropertyId != null) {
                  newListIdsWithWarnings[warning.subPropertyId] = true;
                  return {
                    name: `${warning.subPropertyId}_warning`,
                    warnings: warning.messages,
                  };
                }
                return {
                  name: warning.property,
                  warnings: warning.messages,
                };
              })
            );
            setListIdsWithWarnings(newListIdsWithWarnings);
            setHasWarnings(true);
          } else if (warnings.length === 0) {
            setHasWarnings(false);
          }
        }
      }}
      initialValues={initialValues}
    >
      <Row>
        <Col span={24} style={{ margin: "16px 0" }}>
          <p>
            Take Corrective Action Immediately and inform Your Supervisor if ANY CONDITION listed in the info screen is true. Supervisor will notify
            ESH if necessary.
          </p>
          {team === "D" && (
            <>
              <Typography.Text strong>Note</Typography.Text>
              <Typography.Text>
                : Do not record pressure drop or make visual emission observation until unit has been running for at least 2 hours.
              </Typography.Text>
            </>
          )}
        </Col>
        <Col span={8} />
      </Row>
      <Space direction="vertical" style={{ width: "100%" }}>
        <Row gutter={[24, 16]} style={{ marginBottom: 24 }}>
          <Col span={8} offset={8}>
            <label>Pressure reading</label>
          </Col>
          <Col span={8}>{includeOpacity && <label>Opacity</label>}</Col>
          {flow.readings?.map((reading) => {
            const isDown = downIds.includes(Number(reading.dustCollector?.id));
            return (
              <>
                <Col span={8}>
                  <label>{reading.dustCollector?.reference}</label>
                  <br />
                  <label>
                    <Text style={{ fontSize: "0.9rem" }} type="secondary">
                      {reading.dustCollector?.tag}
                    </Text>
                  </label>
                  <Form.Item
                    name={reading.dustCollector?.id + "_warning"}
                    style={{ display: reading.dustCollector?.id && listIdsWithWarnings[reading.dustCollector.id] ? "block" : "none" }}
                  ></Form.Item>
                </Col>
                <Col span={8}>
                  <Space size="large">
                    <Form.Item
                      style={{ display: "inline-block" }}
                      name={reading.dustCollector?.id + "_pressureReading"}
                      initialValue={reading.pressureReading}
                    >
                      <InputNumber controls={false} disabled={readonly || isDown} precision={2} inputMode="numeric" />
                    </Form.Item>
                    <Form.Item
                      valuePropName={"checked"}
                      style={{ display: "inline-block" }}
                      colon={false}
                      name={reading.dustCollector?.id + "_down"}
                      initialValue={reading.down}
                    >
                      <Checkbox disabled={readonly}>down</Checkbox>
                    </Form.Item>
                  </Space>
                </Col>
                <Col span={8}>
                  {includeOpacity && (
                    <Form.Item name={reading.dustCollector?.id + "_opacityVE"} initialValue={reading.opacityVE}>
                      <YesNoNASwitch checkedChildren="VE" unCheckedChildren="NVE" disabled={readonly || isDown} />
                    </Form.Item>
                  )}
                </Col>
              </>
            );
          })}
          {/*Just a placeholder for warnings*/}
          <Form.Item name="readings"></Form.Item>

          {/*Just a placeholder for warnings*/}
          <Form.Item name="noneIsDown"></Form.Item>
        </Row>
        <Form.Item
          name="comment"
          label="Comment"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          rules={[{ required: hasWarnings, message: "Please add a comment to explain warnings" }]}
        >
          <TextArea disabled={readonly} />
        </Form.Item>
      </Space>
      <Space direction="vertical" style={{ width: "100%" }} size="large">
        {!readonly && (
          <div style={{ display: "flex", justifyContent: "right" }}>
            <Space>
              {supervisorMode ? (
                <Popconfirm
                  title="Save your change"
                  description="To make a change, you must be trained to do so and you MUST add an explanation in the comment box, below the existing comments. If you have done so, you can click on Save."
                  onConfirm={async () => {
                    try {
                      const api = await getAPI();
                      await api.put(`/flows/${flowId}/edit`, {
                        data: {
                          ...flow,
                          comment: form.getFieldsValue().comment,
                          readings: getFormReadings(initialValues, form.getFieldsValue()),
                        },
                      });
                      await refetchFlow();
                      notification.success({
                        message: "Changes saved",
                      });
                      setFormHasUnsavedChanges && setFormHasUnsavedChanges(false);
                    } catch (error: any) {
                      notification.error({
                        message: "Error saving changes",
                        description: error?.message,
                      });
                    }
                  }}
                  okText="Save"
                  cancelText="Cancel"
                >
                  <Button size="large" icon={<SaveOutlined />} type="primary">
                    Save changes
                  </Button>
                </Popconfirm>
              ) : (
                <>
                  <Button
                    disabled={flowGetStatus(flow) === Status.confirmed}
                    size="large"
                    icon={<SaveOutlined />}
                    onClick={async () => {
                      try {
                        const updatedFlow = {
                          ...flow,
                          comment: form.getFieldsValue().comment,
                          readings: getFormReadings(initialValues, form.getFieldsValue()),
                        };
                        const api = await getAPI();
                        await api.put(`/flows/${flowId}`, {
                          data: updatedFlow,
                        });
                        await refetchFlow();
                        notification.success({
                          message: "Dust collector reading draft saved",
                        });
                        setFormHasUnsavedChanges && setFormHasUnsavedChanges(false);
                      } catch (error: any) {
                        notification.error({
                          message: "Error saving dust collector reading draft",
                          description: error?.message,
                        });
                      }
                    }}
                  >
                    Save Draft
                  </Button>
                  <Button size="large" icon={<SendOutlined />} htmlType="submit" type="primary" disabled={!flowIsEditable(flow) || readonly}>
                    {hasWarnings ? "Submit with warnings" : "Submit"}
                  </Button>
                </>
              )}
            </Space>
          </div>
        )}
        <FlowStatus flow={flow} />
      </Space>
    </Form>
  );
};

export default DustCollectorFlowForm;
