import React from "react";

const AnimatedIcon = ({ icon, style }) => {
  // The IconComponent will be the passed icon (e.g., WarningOutlined)
  const IconComponent = icon;

  return (
    <>
      <style>
        {`
          @keyframes highlight-effect {
            0%, 100% {
              opacity: 0.25;
              transform: scale(1);
            }
            50% {
              opacity: 0.5;
              transform: scale(1.5);
            }
          }
        `}
      </style>
      <div
        style={{
          position: "relative",
          display: "inline-block",
          ...style, // Spread any additional styles passed to the component
        }}
      >
        <IconComponent style={{ visibility: "hidden", ...style }} />
        <IconComponent style={{ ...style, position: "absolute", top: 0, left: 0, right: 0, bottom: 0, margin: "auto" }} />
        <IconComponent
          style={{
            ...style,
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            margin: "auto",
            opacity: 0.2, // Starting opacity
            transformOrigin: "center 60%", // Adjusting transform origin
            transform: "scale(1)", // Starting scale
            animation: "highlight-effect 2s infinite",
          }}
        />
      </div>
    </>
  );
};

export default AnimatedIcon;
