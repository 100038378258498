import React, { useState, useEffect } from "react";
import { Checkbox, Switch } from "antd";
import { YesNoNA } from "shared/interfaces";

interface YesNoNASwitchProps {
  value?: any;
  onChange?: (value: any) => void;
  disabled?: boolean;
  checkedChildren?: string;
  unCheckedChildren?: string;
}

const YesNoNASwitch: React.FC<YesNoNASwitchProps> = ({
  onChange,
  value,
  disabled,
  checkedChildren = YesNoNA.Yes,
  unCheckedChildren = YesNoNA.No,
}) => {
  const [isNA, setIsNA] = useState(value === YesNoNA.NA);
  const [isYes, setIsYes] = useState(value === YesNoNA.Yes);

  useEffect(() => {
    setIsNA(value === YesNoNA.NA);
    setIsYes(value === YesNoNA.Yes);
  }, [value]);

  const handleSwitchChange = (checked) => {
    setIsYes(checked);
    onChange && onChange(checked ? YesNoNA.Yes : YesNoNA.No);
  };

  const handleCheckboxChange = (e) => {
    setIsNA(e.target.checked);
    if (e.target.checked) {
      onChange && onChange(YesNoNA.NA);
    } else {
      onChange && onChange(isYes ? YesNoNA.Yes : YesNoNA.No);
    }
  };

  return (
    <>
      <Switch
        checkedChildren={checkedChildren}
        unCheckedChildren={unCheckedChildren}
        defaultChecked={isYes}
        checked={isYes && !isNA}
        onChange={handleSwitchChange}
        disabled={disabled || isNA}
      />
      <Checkbox style={{ marginLeft: 8 }} checked={value === YesNoNA.NA} onChange={handleCheckboxChange} disabled={disabled}>
        N/A
      </Checkbox>
    </>
  );
};

export default YesNoNASwitch;
