import React, { useState } from "react";
import { Button, Card, Tooltip } from "antd";
import { BusinessRole } from "shared/interfaces";
import { nicelyDisplayDateFromISOString } from "shared/utils";
import SignatureModal from "./SignatureModal";

const SignatureCard = ({ isSigned, signatureInfoFunc, shiftId, onSign, signType, supervisorPermission, qualityPermission, comment }) => {
  const signatureInfo = isSigned ? signatureInfoFunc() : null;
  const [signatureModalOpen, setSignatureModalOpen] = useState(false);

  if (isSigned && signatureInfo) {
    const { user: signingUser, date: signingDate } = signatureInfo;
    const fullName =
      signingUser.firstname || signingUser.lastname ? `${signingUser.firstname || ""} ${signingUser.lastname || ""}`.trim() : signingUser.email;
    const tooltipTitle = comment ? `Comment: ${comment}` : "No comment added";
    return (
      <Card size="small">
        <Tooltip title={tooltipTitle} placement="top">
          <p>
            <b>Signed</b> by
            <br />
            {fullName}
            <br />({signingDate && nicelyDisplayDateFromISOString(signingDate)})
          </p>
        </Tooltip>
      </Card>
    );
  } else {
    return (
      <>
        <Button
          size="large"
          type="primary"
          disabled={!(signType === BusinessRole.Supervisor ? supervisorPermission : qualityPermission)}
          onClick={() => setSignatureModalOpen(true)}
        >
          Sign ({signType})
        </Button>
        <SignatureModal
          shiftId={shiftId}
          signType={signType}
          open={signatureModalOpen}
          readonly={false}
          onSign={onSign}
          setOpen={setSignatureModalOpen}
        />
      </>
    );
  }
};

export default SignatureCard;
