import React from "react";
import { Modal, Space, Spin } from "antd";
import "./PhotoViewModal.css";
import { PictureOutlined } from "@ant-design/icons";
import useAssetUrl from "../../../hooks/useAssetUrl";

function PhotoViewModal({ open, setOpen, id }) {
  const { data: url, isLoading } = useAssetUrl(id);

  return (
    <Modal
      className={"photo-view-modal"}
      destroyOnClose={true}
      footer={null}
      styles={{ body: { position: "relative" } }}
      title={
        <Space>
          <PictureOutlined />
          Picture
        </Space>
      }
      open={open}
      width={"690px"}
      onCancel={() => setOpen(false)}
    >
      {isLoading && <Spin spinning={true} style={{ position: "absolute", top: "50%", width: "100%" }} />}
      {url && <img id="screenshot" src={url} alt="" />}
    </Modal>
  );
}

export default PhotoViewModal;
