import { Button, Card, Col, Form, Input, InputNumber, notification, Result, Row, Select, Space, Spin, Table, Popconfirm } from "antd";
import React, { useEffect } from "react";
import {
  BagSize,
  flowScaleTestGetBagTare,
  flowScaleTestGetTestWeightValue,
  flowScaleTestGetTolerance,
  flowValidateFE as flowValidate,
  IFlow,
  IFlowScaleTest,
  Product,
  Status,
  flowGetStatus,
  YesNoNA,
  flowIsEditable,
  BusinessRole,
  labelBagSize,
} from "shared/interfaces";
import { SaveOutlined, SendOutlined } from "@ant-design/icons";
import getAPI from "../../../services/api";
import { useNavigate, useSearchParams } from "react-router-dom";
import useFlow from "../../../hooks/useFlow";
import YesNoSwitch from "../../UI/YesNoSwitch";
import YesNoNASwitch from "../../UI/YesNoNASwitch";
import { useUser } from "../../../services/auth";
import FlowStatus from "../../UI/FlowStatus";

const { TextArea } = Input;

interface ScaleTestFlowProps {
  flowId: IFlow["id"];
  version?: IFlow["version"];
  readonly?: boolean;
  onSuccess: (flow) => void;
  setFormHasUnsavedChanges?: (hasUnsavedChanges: boolean) => void;
}

const ScaleTestFlowForm: React.FC<ScaleTestFlowProps> = ({
  flowId,
  version,
  readonly: propReadonly = false,
  onSuccess,
  setFormHasUnsavedChanges,
}) => {
  const user = useUser();
  const [readonly, setReadonly] = React.useState(propReadonly);
  const navigate = useNavigate();
  const { data, isLoading: isFlowLoading, refetch: refetchFlow, error: flowError } = useFlow(flowId, version);
  const flow = data as IFlowScaleTest;
  const [form] = Form.useForm();
  const [hasWarnings, setHasWarnings] = React.useState<boolean>(false);
  const initialValues = flow;
  if (initialValues) {
    initialValues.pass = initialValues.pass || YesNoNA.No;
    initialValues.bagSize = initialValues.bagSize || BagSize.LBS50;
    initialValues.cleanScale = initialValues.cleanScale == null ? false : initialValues.cleanScale;
    initialValues.zeroScale = initialValues.zeroScale == null ? false : initialValues.zeroScale;
    initialValues.cleanWeight = initialValues.cleanWeight == null ? false : initialValues.cleanWeight;
  }
  let [searchParams] = useSearchParams();
  const supervisorMode = searchParams.get("mode") === "supervisor";

  const [variance1, setVariance1] = React.useState<number | undefined>();
  const [variance2, setVariance2] = React.useState<number | undefined>();
  const [variance3, setVariance3] = React.useState<number | undefined>();
  const [variance4, setVariance4] = React.useState<number | undefined>();
  const [variance5, setVariance5] = React.useState<number | undefined>();
  const [bagSize, setBagSize] = React.useState<BagSize | undefined>();
  const [product, setProduct] = React.useState<Product | undefined>();

  useEffect(() => {
    if (initialValues?.bagSize) {
      if (initialValues?.test1) setVariance1(initialValues.test1 - flowScaleTestGetTestWeightValue(initialValues.bagSize));
      if (initialValues?.test2) setVariance2(initialValues.test2 - flowScaleTestGetTestWeightValue(initialValues.bagSize));
      if (initialValues?.test3) setVariance3(initialValues.test3 - flowScaleTestGetTestWeightValue(initialValues.bagSize));
      if (initialValues?.test4) setVariance4(initialValues.test4 - flowScaleTestGetTestWeightValue(initialValues.bagSize));
      if (initialValues?.test5) setVariance5(initialValues.test5 - flowScaleTestGetTestWeightValue(initialValues.bagSize));
    }
    setProduct(initialValues?.product);
    setBagSize(initialValues?.bagSize);
  }, [initialValues]);

  useEffect(() => {
    const userIsReader = user?.businessRole === BusinessRole.Reader || user?.businessRole === BusinessRole.Quality;
    setReadonly((propReadonly || userIsReader) ?? false);
  }, [propReadonly, user]);

  if (flowError) {
    return (
      <Result
        status="500"
        title="Mmmmh... something went wrong."
        subTitle="More luck next time?"
        extra={
          <Button type="primary" onClick={() => navigate("/")}>
            Back Home
          </Button>
        }
      />
    );
  }

  if (isFlowLoading || !flow || !bagSize || !product) {
    return <Spin>Loading...</Spin>;
  }

  return (
    <Form
      initialValues={initialValues}
      disabled={readonly}
      form={form}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      labelAlign={"left"}
      onFinish={async (values) => {
        const scaleTestFlow: IFlowScaleTest = {
          ...flow,
          ...values,
        };

        const { warnings } = await flowValidate(scaleTestFlow);

        if (warnings.length > 0 && !hasWarnings) {
          setHasWarnings(true);
          notification.warning({
            message: "The form has warnings",
            description: "Please check the form for warnings and enter a comment to submit despite warnings",
          });
          !hasWarnings && setHasWarnings(true);
          form.setFields(
            warnings.map((warning) => ({
              name: warning.property,
              warnings: warning.messages,
            }))
          );
          return;
        }

        const api = await getAPI();
        await api.put(`/flows/${flow?.id}`, {
          data: {
            ...flow,
            ...form.getFieldsValue(),
          },
        });
        await api.put(`/flows/${flowId}/submit`);
        await refetchFlow();
        notification.success({
          message: "The scale test was submitted successfully",
        });
        onSuccess && flow && onSuccess(flow);
      }}
      onValuesChange={async (changedValues, allValues) => {
        setFormHasUnsavedChanges && setFormHasUnsavedChanges(true);
        if (changedValues.bagSize) setBagSize(changedValues.bagSize);

        if (changedValues.test1_actual_reading || changedValues.bagSize) {
          if (allValues?.product) {
            const test1 = Number(allValues.test1_actual_reading) + flowScaleTestGetBagTare(allValues?.bagSize, allValues?.product);
            const variance1 = Math.abs(test1 - flowScaleTestGetTestWeightValue(allValues?.bagSize));
            if (!isNaN(variance1)) setVariance1(variance1);
            if (!isNaN(test1)) {
              form.setFieldValue("test1", test1);
            }
          }
        }
        if (changedValues.test2_actual_reading || changedValues.bagSize) {
          if (allValues?.product) {
            const test2 = Number(allValues.test2_actual_reading) + flowScaleTestGetBagTare(allValues?.bagSize, allValues?.product);
            const variance2 = Math.abs(test2 - flowScaleTestGetTestWeightValue(allValues?.bagSize));
            if (!isNaN(variance2)) setVariance2(variance2);
            if (!isNaN(test2)) {
              form.setFieldValue("test2", test2);
            }
          }
        }
        if (changedValues.test3_actual_reading || changedValues.bagSize) {
          if (allValues?.product) {
            const test3 = Number(allValues.test3_actual_reading) + flowScaleTestGetBagTare(allValues?.bagSize, allValues?.product);
            const variance3 = Math.abs(test3 - flowScaleTestGetTestWeightValue(allValues?.bagSize));
            if (!isNaN(variance3)) setVariance3(variance3);
            if (!isNaN(test3)) {
              form.setFieldValue("test3", test3);
            }
          }
        }
        if (changedValues.test4_actual_reading || changedValues.bagSize) {
          if (allValues?.product) {
            const test4 = Number(allValues.test4_actual_reading) + flowScaleTestGetBagTare(allValues?.bagSize, allValues?.product);
            const variance4 = Math.abs(test4 - flowScaleTestGetTestWeightValue(allValues?.bagSize));
            if (!isNaN(variance4)) setVariance4(variance4);
            if (!isNaN(test4)) {
              form.setFieldValue("test4", test4);
            }
          }
        }
        if (changedValues.test5_actual_reading || changedValues.bagSize) {
          if (allValues?.product) {
            const test5 = Number(allValues.test5_actual_reading) + flowScaleTestGetBagTare(allValues?.bagSize, allValues?.product);
            const variance5 = Math.abs(test5 - flowScaleTestGetTestWeightValue(allValues?.bagSize));
            if (!isNaN(variance5)) setVariance5(variance5);
            if (!isNaN(test5)) {
              form.setFieldValue("test5", test5);
            }
          }
        }

        if (hasWarnings) {
          const values = form.getFieldsValue();
          // reset warnings
          Object.keys(values).forEach((key) => {
            form.setFields([
              {
                name: key,
                warnings: [],
              },
            ]);
          });
          const scaleTestFlow: IFlowScaleTest = {
            ...flow,
            ...values,
          };

          const { warnings } = await flowValidate(scaleTestFlow);

          if (warnings.length > 0) {
            !hasWarnings && setHasWarnings(true);
            form.setFields(
              warnings.map((warning) => ({
                name: warning.property,
                warnings: warning.messages,
              }))
            );
            setHasWarnings(true);
          } else if (warnings.length === 0) {
            setHasWarnings(false);
          }
        }
      }}
    >
      <Form.Item name="product" label="Product">
        <Input className="non-editable-input" />
      </Form.Item>
      <Form.Item name="bagSize" label="Bag size">
        <Select
          options={[
            {
              label: labelBagSize(BagSize.LBS50),
              value: BagSize.LBS50,
            },
            {
              label: labelBagSize(BagSize.LBS55),
              value: BagSize.LBS55,
            },
          ]}
        ></Select>
      </Form.Item>
      <Space direction="vertical" style={{ width: "100%" }}>
        <Card title="Pre-verification tasks">
          <Form.Item label="Clean scale" name="cleanScale">
            <YesNoSwitch disabled={readonly} />
          </Form.Item>
          <Form.Item label="Zero scale" name="zeroScale">
            <YesNoSwitch disabled={readonly} />
          </Form.Item>
          <Form.Item label="Clean weight" name="cleanWeight">
            <YesNoSwitch disabled={readonly} />
          </Form.Item>
          <Form.Item label="Test weight tag number" name="testWeightTagNumber" wrapperCol={{ span: 2 }}>
            <Input disabled={readonly} />
          </Form.Item>
        </Card>
        <Card title="Shift test">
          <Space direction="vertical" style={{ width: "100%" }}>
            <Row>
              <Col span={16}>
                <Table
                  columns={[
                    {
                      dataIndex: "key",
                      key: "key",
                    },
                    {
                      title: "Actual reading (lbs)",
                      render: (record) => {
                        return (
                          <Form.Item
                            name={"test" + record.key + "_actual_reading"}
                            style={{ margin: 0, padding: 0 }}
                            initialValue={flow["test" + record.key] - flowScaleTestGetBagTare(bagSize, product)}
                          >
                            <InputNumber controls={false} precision={2} disabled={readonly} inputMode="numeric" />
                          </Form.Item>
                        );
                      },
                    },
                    {
                      title: "Tare (lbs)",
                      render: () => (
                        <InputNumber
                          value={flowScaleTestGetBagTare(bagSize, product)}
                          controls={false}
                          precision={2}
                          disabled={true}
                          className="non-editable-input"
                          inputMode="numeric"
                        />
                      ),
                    },
                    {
                      title: "Actual reading + tare (lbs)",
                      render: (record) => (
                        <Form.Item style={{ margin: 0, padding: 0 }} name={"test" + record.key}>
                          <InputNumber controls={false} precision={2} disabled={true} className="non-editable-input" inputMode="numeric" />
                        </Form.Item>
                      ),
                    },
                    {
                      title: "Variance",
                      dataIndex: "variance",
                      key: "variance",
                    },
                    {
                      title: "Tolerance",
                      dataIndex: "tolerance",
                      key: "tolerance",
                    },
                  ]}
                  dataSource={[
                    {
                      key: 1,
                      tolerance: flowScaleTestGetTolerance(),
                      variance: variance1 == null || isNaN(Number(variance1)) ? "" : variance1.toFixed(2),
                    },
                    {
                      key: 2,
                      tolerance: flowScaleTestGetTolerance(),
                      variance: variance2 == null || isNaN(Number(variance2)) ? "" : variance2.toFixed(2),
                    },
                    {
                      key: 3,
                      tolerance: flowScaleTestGetTolerance(),
                      variance: variance3 == null || isNaN(Number(variance3)) ? "" : variance3.toFixed(2),
                    },
                    {
                      key: 4,
                      tolerance: flowScaleTestGetTolerance(),
                      variance: variance4 == null || isNaN(Number(variance4)) ? "" : variance4.toFixed(2),
                    },
                    {
                      key: 5,
                      tolerance: flowScaleTestGetTolerance(),
                      variance: variance5 == null || isNaN(Number(variance5)) ? "" : variance5.toFixed(2),
                    },
                  ]}
                  pagination={false}
                />
              </Col>
              <Col span={8}>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                  <div style={{ border: "solid 1px black", width: 150 }}>
                    <div style={{ display: "flex", justifyContent: "space-between", padding: 20 }}>
                      <span>2</span>
                      <span>4</span>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <span>5</span>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between", padding: 20 }}>
                      <span>1</span>
                      <span>3</span>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Form.Item label="Disposition" name="pass" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} labelAlign={"left"}>
              <YesNoNASwitch checkedChildren="Pass" unCheckedChildren="Fail" disabled={readonly} />
            </Form.Item>
          </Space>
        </Card>
      </Space>
      <Form.Item
        name="comment"
        label="Comment"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        rules={[{ required: hasWarnings, message: "Please add a comment to explain warnings" }]}
      >
        <TextArea disabled={readonly} />
      </Form.Item>
      <Space direction="vertical" style={{ width: "100%" }} size="large">
        {!readonly && (
          <div style={{ display: "flex", justifyContent: "right" }}>
            <Space>
              {supervisorMode ? (
                <Popconfirm
                  title="Save your change"
                  description="To make a change, you must be trained to do so and you MUST add an explanation in the comment box, below the existing comments. If you have done so, you can click on Save."
                  onConfirm={async () => {
                    try {
                      const api = await getAPI();
                      await api.put(`/flows/${flow?.id}/edit`, {
                        data: form.getFieldsValue(),
                      });
                      await refetchFlow();
                      notification.success({
                        message: "Changes saved",
                      });
                      setFormHasUnsavedChanges && setFormHasUnsavedChanges(false);
                    } catch (error: any) {
                      notification.error({
                        message: "Error saving changes",
                        description: error?.message,
                      });
                    }
                  }}
                  okText="Save"
                  cancelText="Cancel"
                >
                  <Button size="large" icon={<SaveOutlined />} type="primary">
                    Save changes
                  </Button>
                </Popconfirm>
              ) : (
                <>
                  <Button
                    disabled={flowGetStatus(flow) === Status.confirmed}
                    size="large"
                    icon={<SaveOutlined />}
                    onClick={async () => {
                      try {
                        const api = await getAPI();
                        await api.put(`/flows/${flow?.id}`, {
                          data: form.getFieldsValue(),
                        });
                        await refetchFlow();
                        notification.success({
                          message: "Draft saved",
                        });
                        setFormHasUnsavedChanges && setFormHasUnsavedChanges(false);
                      } catch (error: any) {
                        notification.error({
                          message: `There was an error saving the draft: ${error?.message}`,
                        });
                      }
                    }}
                  >
                    Save draft
                  </Button>
                  <Button size="large" icon={<SendOutlined />} htmlType="submit" type="primary" disabled={!flowIsEditable(flow) || readonly}>
                    {hasWarnings ? "Submit with warnings" : "Submit"}
                  </Button>
                </>
              )}
            </Space>
          </div>
        )}
        <FlowStatus flow={flow} />
      </Space>
    </Form>
  );
};

export default ScaleTestFlowForm;
