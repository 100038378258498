import { createSlice } from "@reduxjs/toolkit";
import { Area, IShift, Product, Team } from "shared/interfaces";
import { shiftDayDateTimeToString, shiftDayStringToDateTime, nowDateTime } from "shared/utils";

const initialState: IShift = {
  day: shiftDayDateTimeToString(nowDateTime())!,
  team: Team.D,
  area: Area.Pack,
  product: Product.Ca,
};

const slice = createSlice({
  name: "shift",
  initialState,
  reducers: {
    set(state, action) {
      const { day, team, area, product } = action.payload;
      const dispatch = slice.actions;
      day && dispatch.setDay({ day });
      team && dispatch.setTeam({ team });
      area && dispatch.setArea({ area });
      product && dispatch.setProduct({ product });
    },
    setDay(state, action) {
      const { day } = action.payload;
      if (shiftDayStringToDateTime(day) === null) throw new Error("Invalid date");
      state.day = day;
    },
    setTeam(state, action) {
      const { team } = action.payload;
      if (!Object.values(Team).includes(team)) throw new Error("Invalid team");
      state.team = team;
    },
    setArea(state, action) {
      const { area } = action.payload;
      if (!Object.values(Area).includes(area)) throw new Error("Invalid area");
      state.area = area;
    },
    setProduct(state, action) {
      const { product } = action.payload;
      if (!Object.values(Product).includes(product)) throw new Error("Invalid product");
      state.product = product;
    },
  },
});

export default slice;
