import { useQuery, UseQueryResult } from "@tanstack/react-query";
import getAPI from "../services/api";

const useBatchLineHistory = ({
  page = 1,
  pageSize = 100,
  filters = {},
  sort = {},
}: {
  page?: number;
  pageSize?: number;
  filters?: any;
  sort?: any;
}): UseQueryResult<any> => {
  return useQuery(["batch-line-histories", page, pageSize, filters, sort], async () => {
    const api = await getAPI();
    const { data } = await api.get("/batch-line-histories", {
      params: {
        pagination: { pageSize, page },
        filters,
        sort,
      },
    });
    return data;
  });
};

export default useBatchLineHistory;
