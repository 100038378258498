import React, { useState } from "react";
import { useNavigate, useParams } from "react-router";
import InspectionFlowForm from "./FlowForms/InspectionFlowForm";
import { flowGetFlowName, flowGetLabel, flowIsEditable, FlowType } from "shared/interfaces";

import useFlow from "../../hooks/useFlow";
import { Button, Layout, Modal, Spin, Space } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { ArrowLeftOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { useLocalizedText } from "../../hooks/LocalizedText";
import ShiftCheckInFlowForm from "./FlowForms/ShiftCheckInFlowForm";
import BatchRecordFlowForm from "./FlowForms/BatchRecordFlowForm";
import ShiftCheckOutFlowForm from "./FlowForms/ShiftCheckOutFlowForm";
import ScaleTestFlowForm from "./FlowForms/ScaleTestFlowForm";
import MetalDetectorFlowForm from "./FlowForms/MetalDetectorFlowForm";
import DustCollectorFlowForm from "./FlowForms/DustCollectorFlowForm";
import InventoryReadingFlowForm from "./FlowForms/InventoryReadingFlowForm";
import PackageInspectionFlowForm from "./FlowForms/PackageInspectionFlowForm";
import LogFlowForm from "./FlowForms/LogFlowForm";
import MasterSanitationFlowForm from "./FlowForms/MasterSanitationFlowForm";
import SafetyShowerFlowForm from "./FlowForms/SafetyShowerFlowForm";
import { useSearchParams } from "react-router-dom";
import FlagsPopover from "../UI/FlagsPopover";
import { ZoomContext } from "../../App";
import MarkdownHelp from "../UI/MarkdownHelp";

const { Content } = Layout;

const FlowViewScreen = () => {
  const { flowId } = useParams();
  const navigate = useNavigate();
  const { data: flow, isLoading: isFlowLoading } = useFlow(flowId);
  let [searchParams] = useSearchParams();
  const [zoom] = React.useContext(ZoomContext);
  const zoomRatio = parseInt(zoom) / 100;

  let readonly = flow ? !flowIsEditable(flow) : true;

  if (searchParams.get("mode") === "supervisor") {
    readonly = false;
  }

  if (searchParams.get("readonly") === "true") {
    readonly = true;
  }

  const help = useLocalizedText((flow && flowGetFlowName(flow)) ?? "");

  const [formHasUnsavedChanges, setFormHasUnsavedChanges] = useState(false);

  const confirmNavigation = () => {
    if (formHasUnsavedChanges) {
      Modal.confirm({
        title: "Are you sure you want to leave this page?",
        content: "You have unsaved changes that will be lost.",
        onOk: () => navigate(-1),
      });
    } else {
      navigate(-1);
    }
  };

  const onSuccess = () => {
    setFormHasUnsavedChanges(false);
    navigate(-1);
  };

  return (
    <Content
      style={{
        height: "calc(100vh - 64px)",
        overflowY: "auto",
        position: "relative",
        padding: 20,
      }}
    >
      <PageHeader
        style={{ marginBottom: 16 }}
        title={
          isFlowLoading || !flow ? (
            "Loading..."
          ) : (
            <Space size="large">
              <div>{flowGetLabel(flow, true)}</div>
              {flow.flags && <FlagsPopover flags={flow.flags} zoomRatio={zoomRatio} flowId={flow.id} />}
              {help && (
                <Button
                  type="link"
                  style={{ marginLeft: 10 }}
                  onClick={() => {
                    Modal.info({
                      title: "",
                      content: MarkdownHelp({ help }),
                      width: "80vw",
                    });
                  }}
                  icon={<InfoCircleOutlined />}
                />
              )}
            </Space>
          )
        }
        backIcon={<ArrowLeftOutlined />}
        // @ts-ignore
        onBack={confirmNavigation}
      />
      <Spin spinning={!flow}>
        {flow?.type === FlowType.shiftCheckIn && (
          <ShiftCheckInFlowForm flowId={flow.id} readonly={readonly} setFormHasUnsavedChanges={setFormHasUnsavedChanges} onSuccess={onSuccess} />
        )}
        {flow?.type === FlowType.shiftCheckOut && (
          <ShiftCheckOutFlowForm flowId={flow.id} readonly={readonly} setFormHasUnsavedChanges={setFormHasUnsavedChanges} onSuccess={onSuccess} />
        )}
        {flow?.type === FlowType.batchRecord && (
          <BatchRecordFlowForm flowId={flow.id} readonly={readonly} setFormHasUnsavedChanges={setFormHasUnsavedChanges} onSuccess={onSuccess} />
        )}
        {flow?.type === FlowType.inspection && (
          <InspectionFlowForm flowId={flow.id} readonly={readonly} setFormHasUnsavedChanges={setFormHasUnsavedChanges} onSuccess={onSuccess} />
        )}
        {flow?.type === FlowType.scaleTest && (
          <ScaleTestFlowForm flowId={flow.id} readonly={readonly} setFormHasUnsavedChanges={setFormHasUnsavedChanges} onSuccess={onSuccess} />
        )}
        {flow?.type === FlowType.metalDetector && (
          <MetalDetectorFlowForm flowId={flow.id} readonly={readonly} setFormHasUnsavedChanges={setFormHasUnsavedChanges} onSuccess={onSuccess} />
        )}
        {flow?.type === FlowType.dustCollector && (
          <DustCollectorFlowForm flowId={flow.id} readonly={readonly} setFormHasUnsavedChanges={setFormHasUnsavedChanges} onSuccess={onSuccess} />
        )}
        {flow?.type === FlowType.inventoryReading && (
          <InventoryReadingFlowForm flowId={flow.id} readonly={readonly} setFormHasUnsavedChanges={setFormHasUnsavedChanges} onSuccess={onSuccess} />
        )}
        {flow?.type === FlowType.packageInspection && (
          <PackageInspectionFlowForm flowId={flow.id} readonly={readonly} setFormHasUnsavedChanges={setFormHasUnsavedChanges} onSuccess={onSuccess} />
        )}
        {flow?.type === FlowType.log && (
          <LogFlowForm flowId={flow.id} readonly={readonly} setFormHasUnsavedChanges={setFormHasUnsavedChanges} onSuccess={onSuccess} />
        )}
        {flow?.type === FlowType.safetyShower && (
          <SafetyShowerFlowForm flowId={flow.id} readonly={readonly} setFormHasUnsavedChanges={setFormHasUnsavedChanges} onSuccess={onSuccess} />
        )}
        {flow?.type === FlowType.masterSanitation && (
          <MasterSanitationFlowForm flowId={flow.id} readonly={readonly} setFormHasUnsavedChanges={setFormHasUnsavedChanges} onSuccess={onSuccess} />
        )}
      </Spin>
    </Content>
  );
};

export default FlowViewScreen;
