import { Button, Modal, Table, DatePicker, Space } from "antd";
import { CheckOutlined, EyeOutlined, EditOutlined, HistoryOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { DateTime } from "luxon";
import "./BatchTable.css";
import { BusinessRole } from "shared/interfaces";
import { ColumnsType } from "antd/es/table";
import { useNavigate } from "react-router";
import { ZoomContext } from "../../../App";
import { useUser } from "../../../services/auth";
import { ArrayParam, NumberParam, useQueryParams, StringParam } from "use-query-params";
import FlagsPopover from "../../UI/FlagsPopover";
import useBatches from "../../../hooks/useBatches";
import MaterialDetailsModal from "../../ShiftScreen/MaterialDetailsModal";
import OverflowCell from "../../UI/OverflowCell";
import { nicelyDisplayDateFromISOString } from "shared/utils";
import useWindowResize from "../../../hooks/useWindowResize";
import CSVDownloadButton from "../../UI/CSVDownloadButton";
import BatchEditModal from "./BatchEditModal";
import { FilterDropdown, FilterIcon } from "../UI/FilterId";

const BatchTable = () => {
  const user = useUser();
  const navigate = useNavigate();
  const [canEdit, setCanEdit] = useState(false);
  const [zoom] = React.useContext(ZoomContext);
  const zoomRatio = parseInt(zoom) / 100;
  const [batchId, setBatchId] = useState(null);
  const [isMaterialDetailsModalOpen, setIsMaterialDetailsModalOpen] = useState(false);
  const { height } = useWindowResize();
  const [isBatchEditModalOpen, setIsBatchEditModalOpen] = useState(false);
  const [batchEditId, setBatchEditId] = useState(0);

  const [tableHeaderHeight, setTableHeaderHeight] = useState(0);
  useEffect(() => {
    // Function to update state with the height of the th element
    const updateHeight = () => {
      const thElement = document.querySelector("th");
      if (thElement) {
        setTableHeaderHeight(thElement.clientHeight);
      }
    };

    // Set up ResizeObserver
    const resizeObserver = new ResizeObserver(updateHeight);
    const thElement = document.querySelector("th");
    if (thElement) {
      resizeObserver.observe(thElement);
    }

    // Clean up observer on component unmount
    return () => {
      if (thElement) {
        resizeObserver.unobserve(thElement);
      }
    };
  }, []);

  const getSortOrder = (field) => {
    const sortParam = query.sortParams?.find((s) => s?.startsWith(field));
    if (sortParam) {
      return sortParam.endsWith("asc") ? "ascend" : "descend";
    }
    return null;
  };

  // Refactor to use useQueryParams
  const [query, setQuery] = useQueryParams({
    id: NumberParam,
    batchNumber: StringParam,
    materialNumber: NumberParam,
    page: NumberParam,
    pageSize: NumberParam,
    flags: ArrayParam,
    edited: ArrayParam,
    from: NumberParam,
    to: NumberParam,
    type: ArrayParam,
    status: ArrayParam,
    sortParams: ArrayParam,
  });

  const [comment, setComment] = useState(null);
  // Default times to one week ago if not set
  const [defaultTimeSpan] = useState([dayjs().subtract(1, "week").valueOf(), dayjs().valueOf()]);
  const timeSpan = query.from && query.to ? [query.from, query.to] : defaultTimeSpan;
  useEffect(() => {
    if (user) {
      setCanEdit(user?.businessRole === BusinessRole.Supervisor);
    }
  }, [user]);

  const sortParams = query.sortParams || [];

  if (!sortParams.find((s) => s?.startsWith("createdAt"))) {
    sortParams.push("createdAt:desc");
  }

  const batchQueryConfig = {
    page: query.page || 1,
    pageSize: query.pageSize || 100,
    filters: {
      ...(timeSpan && timeSpan[0] && timeSpan[1] ? { createdAt: { $gte: timeSpan[0], $lte: timeSpan[1] } } : {}),
      ...(query.id ? { id: query.id } : {}),
      ...(query.batchNumber ? { batchNumber: { $containsi: query.batchNumber } } : {}),
      ...(query.type && query.type.length ? { type: { $in: query.type } } : {}),
      ...(query.edited && query.edited.length === 1 ? { edited: { [query.edited[0] === "true" ? "$eq" : "$null"]: "true" } } : {}),
      ...(query.flags && query.flags.length === 1 ? { flags: { id: { $null: query.flags[0] === "false" } } } : {}),
      ...(query.status && query.status.length ? { status: { $in: query.status } } : {}),
      ...(query.materialNumber
        ? {
            material: {
              number: query.materialNumber,
            },
          }
        : {}),
    },
    ...(sortParams ? { sort: sortParams } : {}),
  };
  const { data, refetch: refetchBatches } = useBatches(batchQueryConfig);

  const handleEditSuccess = () => {
    refetchBatches();
  };

  const { data: batches = [] } = data || {};

  const total = data?.meta?.pagination?.total;
  const page = data?.meta?.pagination?.page;
  const pageSize = data?.meta?.pagination?.pageSize;

  const columns: ColumnsType<any> = [
    {
      title: "Batch id",
      dataIndex: "id",
      width: 85 * Math.max(1, zoomRatio),
      sorter: { multiple: 1 },
      defaultSortOrder: getSortOrder("id"),
      onHeaderCell: (column) => {
        if (query.id) {
          return {
            className: "ant-table-column-sort",
          };
        }
        return {};
      },
      filterIcon: (filtered) => <FilterIcon queryItem={query.id} />,
      filterDropdown: FilterDropdown({
        queryKey: "id",
        queryValue: query.id,
        setQuery: setQuery,
        resetQuery: () => setQuery({ id: null }),
        isString: false,
      }),
    },
    {
      title: "Creation date",
      dataIndex: "createdAt",
      width: 200 * Math.max(1, zoomRatio),
      sorter: { multiple: 1 },
      render: (createdAt) => nicelyDisplayDateFromISOString(createdAt),
      defaultFilteredValue: timeSpan,
      filterDropdown: () => (
        <DatePicker.RangePicker
          value={[dayjs(timeSpan[0]), dayjs(timeSpan[1])]}
          showTime={false}
          onChange={(dates) => {
            if (dates && dates.length > 1 && dates[0] && dates[1]) {
              const from = DateTime.fromJSDate(dates[0].toDate()).startOf("day").toMillis();
              const to = DateTime.fromJSDate(dates[1].toDate()).endOf("day").toMillis();
              setQuery({ from: from, to: to, page: 1 });
            }
          }}
          disabledDate={(current) => current && current > dayjs()}
          allowClear={false}
        />
      ),
      defaultSortOrder: getSortOrder("createdAt"),
    },
    {
      title: "Number",
      dataIndex: "batchNumber",
      width: 130 * Math.max(1, zoomRatio),
      sorter: { multiple: 1 },
      render: (batchNumber, record) => {
        return (
          <>
            {batchNumber}
            <Button
              type="link"
              icon={<EyeOutlined />}
              style={{ float: "right", margin: 0, padding: 0 }}
              onClick={() => {
                navigate("/batch/" + record.id);
              }}
            />
          </>
        );
      },
      defaultSortOrder: getSortOrder("batchNumber"),
      filterIcon: (filtered) => <FilterIcon queryItem={query.batchNumber} />,
      filterDropdown: FilterDropdown({
        queryKey: "batchNumber",
        queryValue: query.batchNumber,
        setQuery: setQuery,
        resetQuery: () => setQuery({ batchNumber: null }),
        isString: true,
      }),
    },
    {
      title: "# batch lines",
      dataIndex: "batchLines",
      width: 80 * Math.max(1, zoomRatio),
      sorter: { multiple: 1 },
      render: (batchLines, record) => {
        return (
          <Button
            type="link"
            style={{ margin: 0, padding: 0 }}
            onClick={() => {
              navigate("/batch-lines?batchId=" + record.id);
            }}
          >
            {batchLines.length}
          </Button>
        );
      },
    },
    {
      title: "Comment",
      dataIndex: "comment",
      width: 200 * Math.max(1, zoomRatio),
      render: (value) => {
        return value ? <OverflowCell content={value} onMore={() => setComment(value)} /> : null;
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      width: 100 * Math.max(1, zoomRatio),
      sorter: { multiple: 1 },
      defaultFilteredValue: query?.type?.filter((type): type is string => type !== null) || [],
      filters: [
        { text: "Regular", value: "regular" },
        { text: "Rework", value: "rework" },
      ],
      defaultSortOrder: getSortOrder("type"),
    },
    {
      title: "Material",
      dataIndex: ["material", "number"],
      width: 150 * Math.max(1, zoomRatio),
      sorter: { multiple: 1 },
      render: (materialNumber, record) => {
        return (
          <>
            {materialNumber}
            <Button
              icon={<EyeOutlined />}
              type="link"
              style={{ float: "right", margin: 0, padding: 0 }}
              onClick={() => {
                setIsMaterialDetailsModalOpen(true);
                setBatchId(record.id);
              }}
            />
          </>
        );
      },
      defaultSortOrder: getSortOrder("material.number"),
      filterIcon: (filtered) => <FilterIcon queryItem={query.materialNumber} />,
      filterDropdown: FilterDropdown({
        queryKey: "materialNumber",
        queryValue: query.materialNumber,
        setQuery: setQuery,
        resetQuery: () => setQuery({ materialNumber: null }),
        isString: false,
      }),
    },
    {
      title: "Process Order",
      dataIndex: "sapProcessOrder",
      width: 75 * Math.max(1, zoomRatio),
      sorter: { multiple: 1 },
    },
    {
      title: "Flags",
      dataIndex: "flags",
      width: 75 * Math.max(1, zoomRatio),
      render: (flags, record) => {
        return <FlagsPopover flags={flags} zoomRatio={zoomRatio} batchId={record.id} />;
      },
      filters: [
        { text: "Yes", value: true },
        { text: "No", value: false },
      ],
      defaultFilteredValue: query?.flags?.filter((flags): flags is string => flags !== null) || [],
      defaultSortOrder: getSortOrder("flags"),
    },
    {
      title: "Latest version",
      dataIndex: "version",
      width: 75 * Math.max(1, zoomRatio),
      sorter: { multiple: 1 },
    },
    {
      title: "Edited",
      dataIndex: "edited",
      width: 100 * Math.max(1, zoomRatio),
      sorter: { multiple: 1 },
      filters: [
        { text: "Yes", value: "true" },
        { text: "No", value: "false" },
      ],
      defaultFilteredValue: query?.edited?.filter((edited): edited is string => edited !== null) || [],
      render: (edited) => (edited ? <CheckOutlined /> : ""),
    },
    {
      title: "Edited by",
      width: 150 * Math.max(1, zoomRatio),
      dataIndex: ["editedBy", "email"],
      sorter: { multiple: 1 },
      defaultSortOrder: getSortOrder("editedBy.email"),
    },
    {
      title: "Edited date",
      dataIndex: "editedAt",
      sorter: { multiple: 1 },
      width: 150 * Math.max(1, zoomRatio),
      render: (editedAt) => (editedAt ? nicelyDisplayDateFromISOString(editedAt) : ""),
      defaultSortOrder: getSortOrder("editedAt"),
    },
    {
      title: "Last modified date",
      dataIndex: "updatedAt",
      width: 150 * Math.max(1, zoomRatio),
      render: (updatedAt) => nicelyDisplayDateFromISOString(updatedAt),
      defaultSortOrder: getSortOrder("updatedAt"),
    },
    {
      title: "Edit",
      width: 100 * Math.max(1.2, zoomRatio),
      dataIndex: "id",
      fixed: "right",
      render: (id) => {
        return (
          <Space>
            {canEdit && (
              <Button
                type="link"
                icon={<EditOutlined />}
                onClick={() => {
                  setBatchEditId(id);
                  setIsBatchEditModalOpen(true);
                }}
              />
            )}
            <Button
              type="link"
              icon={<HistoryOutlined />}
              onClick={() => {
                navigate(id + "/history");
              }}
            />
          </Space>
        );
      },
    },
  ];

  return (
    <div
      style={{
        padding: 20,
      }}
      className={"flow-table"}
    >
      <CSVDownloadButton fetchDataConfig={batchQueryConfig} queryHook={useBatches} filename={"batches.csv"} />
      <Table
        size={"small"}
        scroll={{ x: 1500 * Math.max(1, zoomRatio), ...(height ? { y: height - 125 - 25 * zoomRatio - tableHeaderHeight } : {}) }}
        onChange={(pagination, filters, sorter) => {
          const sorterArray = (Array.isArray(sorter) ? sorter : [sorter]).filter((s) => s.column);
          const newSortParams = sorterArray
            .filter((s) => s.order)
            .filter((s) => s.field)
            .map((s) => {
              return `${s.field && s.field.toString().replace(/flags/g, "flags.id").replace(/,/, ".")}${
                s.order ? ":" + (s.order === "ascend" ? "asc" : "desc") : ""
              }`;
            })
            .reverse();

          if (!newSortParams.find((s) => s.startsWith("createdAt"))) {
            newSortParams.push("createdAt:desc");
          }

          setQuery({
            page: pagination.current,
            pageSize: pagination.pageSize,
            sortParams: newSortParams,
            ...filters,
          });
        }}
        pagination={{
          current: page,
          total,
          pageSize,
        }}
        columns={columns}
        dataSource={batches}
        bordered={true}
      />
      {batchId && <MaterialDetailsModal batchId={batchId} open={isMaterialDetailsModalOpen} setOpen={setIsMaterialDetailsModalOpen} />}
      <Modal
        open={!!comment}
        footer={null}
        onCancel={() => {
          setComment(null);
        }}
        title={"Comment"}
      >
        {comment}
      </Modal>
      <BatchEditModal open={isBatchEditModalOpen} setOpen={setIsBatchEditModalOpen} batchId={batchEditId} onEditSuccess={() => handleEditSuccess()} />
    </div>
  );
};

export default BatchTable;
