import React from "react";
import { Layout } from "antd";

import HeaderMenu from "../../HeaderMenu/HeaderMenu";
import BatchLineHistoryTable from "./BatchLineHistoryTable";
import { PageHeader } from "@ant-design/pro-layout";
import { useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";

const { Content } = Layout;

const BatchLineHistoryPage = () => {
  const navigate = useNavigate();

  return (
    <>
      <HeaderMenu>
        <PageHeader title={<h1>Batch Line history</h1>} onBack={() => navigate(-1)} backIcon={<ArrowLeftOutlined />} />
      </HeaderMenu>

      <Content
        style={{
          height: "calc(100vh - 64px)",
          overflowY: "auto",
          position: "relative",
        }}
      >
        <BatchLineHistoryTable />
      </Content>
    </>
  );
};

export default BatchLineHistoryPage;
