import _ from "lodash";
import React from "react";
import { IUser } from "shared/interfaces";
import { useUsers } from "../../hooks/Users";

const Employees: React.FC<{
  employees: Array<{
    userId: IUser["id"];
    relief: boolean;
  }>;
}> = ({ employees }) => {
  const { data: employeesData = [] } = useUsers();

  return (
    <div>
      {_.sortBy(employees, "relief").map((employee, index, array) => (
        <React.Fragment key={employee.userId}>
          <span style={{ color: employee.relief ? "grey" : undefined }}>
            {employeesData.find((e) => e.id === employee.userId)?.username}
            {employee.relief && ` (on relief)`}
          </span>
          {index < array.length - 1 && ", "}
        </React.Fragment>
      ))}
    </div>
  );
};

export default Employees;
