import { IUser } from "shared/interfaces";

export type User = Pick<
  IUser,
  "id" | "businessRole" | "email" | "username" | "firstname" | "lastname" | "blocked" | "confirmed" | "defaultArea" | "defaultProduct"
>;

export type AuthState = {
  jwt: string;
  user: User;
};

export const authenticate = (authState: AuthState) => {
  localStorage.setItem("authState", JSON.stringify(authState));
};

export const logout = (authState: AuthState) => {
  localStorage.removeItem("authState");
};

export const getAuthState = (): AuthState | null => {
  return JSON.parse(localStorage.getItem("authState") || "{}");
};

export const useUser = (): User | null | undefined => {
  const authState = getAuthState();
  return authState?.user || null;
};
