import { Modal } from "antd";
import React from "react";
import useBatch from "../../hooks/useBatch";
import NewBatchLineForm from "./NewBatchLineForm";

const NewBatchLineModal: React.FC<{
  batchId: number | undefined;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ open, setOpen, batchId }) => {
  const { data: batch, refetch: refetchBatch } = useBatch(batchId);

  return (
    <>
      {batch && (
        <Modal
          title="New set of pallets"
          footer={null}
          open={open}
          onCancel={() => {
            setOpen(false);
          }}
          maskClosable={false}
          destroyOnClose
          width={600}
        >
          <NewBatchLineForm
            batchId={batchId}
            onSuccess={() => {
              setOpen(false);
              refetchBatch();
            }}
            onCancel={() => setOpen(false)}
          />
        </Modal>
      )}
    </>
  );
};

export default NewBatchLineModal;
