"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.flowShiftCheckInValidate = exports.flowShiftCheckInGetFlowName = exports.flowShiftCheckInGetGenericLabel = exports.flowShiftCheckInGetLabel = void 0;
const index_1 = require("./index");
function flowShiftCheckInGetLabel() {
    return flowShiftCheckInGetGenericLabel();
}
exports.flowShiftCheckInGetLabel = flowShiftCheckInGetLabel;
function flowShiftCheckInGetGenericLabel() {
    return "Shift Check In";
}
exports.flowShiftCheckInGetGenericLabel = flowShiftCheckInGetGenericLabel;
function flowShiftCheckInGetFlowName() {
    return "FlowShiftCheckIn";
}
exports.flowShiftCheckInGetFlowName = flowShiftCheckInGetFlowName;
function flowShiftCheckInValidate(flow) {
    let errors = [];
    let warnings = [];
    const labelSampleToolAccCheck = "Sample Tool Acc Check";
    const labelSampleToolAccCheckId = "Sample Tool Acc Check ID";
    const labelReviewedPreviousShift = "Reviewed Previous Shift";
    const labelReviewedDailyNotes = "Reviewed Daily Notes";
    const labelPalletsOrdered = "Pallets ordered";
    const labelBagsOrdered = "Bags ordered";
    const labelOnShift = "On shift";
    warnings = (0, index_1.validationMissingValue)(warnings, flow.sampleToolAccCheck, "sampleToolAccCheck", labelSampleToolAccCheck);
    warnings = (0, index_1.validationMissingValue)(warnings, flow.reviewedPreviousShift, "reviewedPreviousShift", labelReviewedPreviousShift);
    warnings = (0, index_1.validationMissingValue)(warnings, flow.reviewedDailyNotes, "reviewedDailyNotes", labelReviewedDailyNotes);
    warnings = (0, index_1.validationMissingValue)(warnings, flow.palletsOrdered, "palletsOrdered", labelPalletsOrdered);
    warnings = (0, index_1.validationMissingValue)(warnings, flow.bagsOrdered, "bagsOrdered", labelBagsOrdered);
    warnings = (0, index_1.validationMissingValue)(warnings, flow.onShift, "onShift", labelOnShift);
    warnings = (0, index_1.validationBooleanValue)(warnings, flow.sampleToolAccCheck, true, "sampleToolAccCheck", labelSampleToolAccCheck);
    if (flow.sampleToolAccCheck === true) {
        warnings = (0, index_1.validationMissingValue)(warnings, flow.sampleToolAccCheckId, "sampleToolAccCheckId", labelSampleToolAccCheckId);
        warnings = (0, index_1.validationStringNotEmpty)(warnings, flow.sampleToolAccCheckId, "sampleToolAccCheckId", labelSampleToolAccCheckId);
    }
    warnings = (0, index_1.validationNAValue)(warnings, flow.reviewedPreviousShift, true, "reviewedPreviousShift", labelReviewedPreviousShift);
    warnings = (0, index_1.validationNAValue)(warnings, flow.reviewedDailyNotes, true, "reviewedDailyNotes", labelReviewedDailyNotes);
    warnings = (0, index_1.validationNAValue)(warnings, flow.palletsOrdered, true, "palletsOrdered", labelPalletsOrdered);
    warnings = (0, index_1.validationNAValue)(warnings, flow.bagsOrdered, true, "bagsOrdered", labelBagsOrdered);
    if (flow.onShift && flow.onShift.filter((user) => !user.relief).length === 0) {
        warnings.push({
            property: "onShift",
            message: "At least one employee is required",
        });
    }
    return {
        errors,
        warnings,
    };
}
exports.flowShiftCheckInValidate = flowShiftCheckInValidate;
