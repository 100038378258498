import { Form, Modal, notification, Row, Button, Input, Popconfirm, Select } from "antd";
import React, { useState } from "react";
import useBatchLine from "../../../hooks/useBatchLine";
import useBatch from "../../../hooks/useBatch";
import useSources from "../../../hooks/useSources";
import useBlends from "../../../hooks/useBlends";
import getAPI from "../../../services/api";
import { BatchLineType, PkgType } from "shared/interfaces";

const BatchLineEditModal: React.FC<{
  batchLineId: number | undefined;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onEditSuccess: () => void;
}> = ({ open, setOpen, batchLineId, onEditSuccess }) => {
  const { data: batchLine, refetch: refetchBatchLine } = useBatchLine(batchLineId);
  const { data: batch } = useBatch(batchLine?.batch?.id);

  const { data: sources } = useSources({ product: batch?.material?.product, materialId: batch?.material?.id });
  const { data: blends } = useBlends({ product: batch?.material?.product, sourceId: 0 });

  const [form] = Form.useForm();

  const [modifiedFields, setModifiedFields] = useState<string[]>([]);

  const handleFieldsChange = (_, allFields) => {
    const modifiedFieldNames = allFields.filter((field) => field.touched).map((field) => field.name[0]);
    setModifiedFields(modifiedFieldNames);
  };

  const resetModal = () => {
    form.resetFields();
    setModifiedFields([]);
  };

  const submit = async (values: any) => {
    if (!batchLine) return;

    try {
      const api = await getAPI();

      const updatedBatchLine: any = modifiedFields.reduce((acc, fieldName) => {
        acc[fieldName] = values[fieldName];
        return acc;
      }, {});

      if ("sourceId" in updatedBatchLine) {
        if (updatedBatchLine.sourceId !== "") {
          updatedBatchLine.source = { id: updatedBatchLine.sourceId as number };
        } else {
          updatedBatchLine.source = null;
        }
        delete updatedBatchLine.sourceId;
      }

      if ("blendId" in updatedBatchLine) {
        if (updatedBatchLine.blendId !== "") {
          updatedBatchLine.blend = { id: updatedBatchLine.blendId as number };
        } else {
          updatedBatchLine.blend = null;
        }
        delete updatedBatchLine.blendId;
      }

      if (Object.keys(updatedBatchLine).length === 0) {
        notification.info({
          message: "No changes",
          description: "No changes were made.",
        });
        return;
      }

      await api.put(`/batch-lines/${batchLineId}/edit`, { data: updatedBatchLine });

      notification.success({
        message: "Success",
        description: "The batch line was successfully updated",
      });
      await refetchBatchLine();
      onEditSuccess();
      setOpen(false);
      form.resetFields();
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: `${error.message}`,
      });
    }
  };

  const customFooter = () => (
    <Row justify="end">
      <Button
        key="cancel"
        onClick={() => {
          setOpen(false);
          form.resetFields();
        }}
      >
        Cancel
      </Button>
      <Popconfirm
        title="Save your change"
        description="Make such edits only if you need to correct a mistake."
        onConfirm={async () => {
          await form.submit();
          setOpen(false);
          form.resetFields();
        }}
        okText="Save"
        cancelText="Cancel"
      >
        <Button key="submit" type="primary">
          Update
        </Button>
      </Popconfirm>
    </Row>
  );

  return (
    <>
      {batchLine && sources && sources.length > 0 && blends && blends.length > 0 && (
        <Modal
          title="Batch line edit"
          open={open}
          onCancel={() => {
            setOpen(false);
            form.resetFields();
          }}
          afterClose={() => resetModal()}
          maskClosable={true}
          destroyOnClose={true}
          width={600}
          footer={customFooter()}
        >
          <Row justify="center">
            <Form
              key={batchLineId}
              form={form}
              name="basic"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 8 }}
              style={{ width: "100%" }}
              onFinish={submit}
              autoComplete="off"
              onFieldsChange={handleFieldsChange}
            >
              <Form.Item
                label={<>{modifiedFields.includes("sourceId") && <span style={{ color: "red" }}>•&nbsp;&nbsp; </span>}Source</>}
                name="sourceId"
              >
                <Select
                  showSearch
                  filterOption={(input, option) => (option?.reference ?? "").toLowerCase().includes(input.toLowerCase())}
                  onDropdownVisibleChange={(open) => {
                    return !open;
                  }}
                  options={[
                    { label: "", value: "", reference: "" },
                    ...sources.map(({ id, reference }) => ({
                      value: id,
                      reference,
                      label: (
                        <>
                          <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                            <span>{reference}</span>
                          </div>
                        </>
                      ),
                    })),
                  ]}
                  defaultValue={batchLine.source?.id}
                />
              </Form.Item>
              <Form.Item label={<>{modifiedFields.includes("blendId") && <span style={{ color: "red" }}>•&nbsp;&nbsp; </span>}Path</>} name="blendId">
                <Select
                  showSearch
                  filterOption={(input, option) => (option?.reference ?? "").toLowerCase().includes(input.toLowerCase())}
                  onDropdownVisibleChange={(open) => {
                    return !open;
                  }}
                  options={[
                    { label: "", value: "", reference: "" },
                    ...blends.map(({ id, reference }) => ({
                      value: id,
                      reference,
                      label: (
                        <>
                          <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                            <span>{reference}</span>
                          </div>
                        </>
                      ),
                    })),
                  ]}
                  defaultValue={batchLine.blend?.id}
                />
              </Form.Item>
              <Form.Item
                label={<>{modifiedFields.includes("blendNumber") && <span style={{ color: "red" }}>•&nbsp;&nbsp; </span>}Blend number</>}
                name="blendNumber"
                initialValue={batchLine.blendNumber ?? ""}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={<>{modifiedFields.includes("pkgPalletId") && <span style={{ color: "red" }}>•&nbsp;&nbsp; </span>}Pkg supply pallet id</>}
                name="pkgPalletId"
                initialValue={batchLine.pkgPalletId ?? ""}
              >
                <Input
                  onChange={(e) => {
                    const capitalizedValue = e.target.value.toUpperCase();
                    form.setFieldsValue({ batchNumber: capitalizedValue });
                  }}
                />
              </Form.Item>
              {batchLine.type === BatchLineType.regular && (
                <>
                  <Form.Item
                    label={<>{modifiedFields.includes("fromPallet") && <span style={{ color: "red" }}>•&nbsp;&nbsp; </span>}From pallet</>}
                    name="fromPallet"
                    initialValue={batchLine.fromPallet ?? ""}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label={<>{modifiedFields.includes("toPallet") && <span style={{ color: "red" }}>•&nbsp;&nbsp; </span>}To pallet</>}
                    name="toPallet"
                    initialValue={batchLine.toPallet ?? ""}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label={<>{modifiedFields.includes("qtyPallets") && <span style={{ color: "red" }}>•&nbsp;&nbsp; </span>}Qty pallets</>}
                    name="qtyPallets"
                    initialValue={batchLine.qtyPallets ?? ""}
                  >
                    <Input />
                  </Form.Item>
                  {batch?.material?.pkgType === PkgType.Bag && (
                    <>
                      <Form.Item
                        label={<>{modifiedFields.includes("qtyBags") && <span style={{ color: "red" }}>•&nbsp;&nbsp; </span>}Qty bags</>}
                        name="qtyBags"
                        initialValue={batchLine.qtyBags ?? ""}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label={
                          <>
                            {modifiedFields.includes("qtyBagsPerPallet") && <span style={{ color: "red" }}>•&nbsp;&nbsp; </span>}Qty bags per pallet
                          </>
                        }
                        name="qtyBagsPerPallet"
                        initialValue={batchLine.qtyBagsPerPallet ?? ""}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label={
                          <>
                            {modifiedFields.includes("qtyBagsLastPallet") && <span style={{ color: "red" }}>•&nbsp;&nbsp; </span>}Qty bags last pallet
                          </>
                        }
                        name="qtyBagsLastPallet"
                        initialValue={batchLine.qtyBagsLastPallet ?? ""}
                      >
                        <Input />
                      </Form.Item>
                    </>
                  )}
                </>
              )}
              {batchLine.type === BatchLineType.metalDetection && (
                <>
                  <Form.Item
                    label={<>{modifiedFields.includes("palletId") && <span style={{ color: "red" }}>•&nbsp;&nbsp; </span>}Pallet Id</>}
                    name="palletId"
                    initialValue={batchLine.palletId ?? ""}
                  >
                    <Input />
                  </Form.Item>
                </>
              )}
            </Form>
          </Row>
        </Modal>
      )}
    </>
  );
};

export default BatchLineEditModal;
