"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.flowInspectionImmutableValuesWereNotChanged = exports.flowInspectionValidate = exports.flowInspectionGetFlowName = exports.flowInspectionGetGenericLabel = exports.flowInspectionGetLabel = void 0;
const index_1 = require("./index");
function flowInspectionGetLabel(flow) {
    return flowInspectionGetGenericLabel(flow.typeInspected);
}
exports.flowInspectionGetLabel = flowInspectionGetLabel;
function flowInspectionGetGenericLabel(type) {
    if (type) {
        return `${type === index_1.InspectedType.magnet ? "Magnet" : "Screen"} Inspection`;
    }
    return `Inspection`;
}
exports.flowInspectionGetGenericLabel = flowInspectionGetGenericLabel;
function flowInspectionGetFlowName(flow) {
    return `FlowInspection${flow.typeInspected === index_1.InspectedType.magnet ? "Magnet" : "Screen"}`;
}
exports.flowInspectionGetFlowName = flowInspectionGetFlowName;
function flowInspectionValidate(flow) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9;
    let errors = [];
    let warnings = [];
    const labelTypeInspected = "Type of inspection";
    const labelChecks = "Check";
    const labelProtocol = "Protocol";
    const labelSamplesToLab = "Samples to lab";
    warnings = (0, index_1.validationMissingValue)(warnings, flow.typeInspected, "typeInspected", labelTypeInspected);
    warnings = (0, index_1.validationMissingValue)(warnings, flow.checks, "checks", labelChecks);
    warnings = (0, index_1.validationMissingValue)(warnings, flow.protocol, "protocol", labelProtocol);
    if (flow.typeInspected) {
        const checks = (_a = flow.checks) !== null && _a !== void 0 ? _a : [];
        if (flow.typeInspected === index_1.InspectedType.magnet) {
            warnings = (0, index_1.validationMissingValue)(warnings, flow.samplesToLab, "samplesToLab", labelSamplesToLab);
            warnings = (0, index_1.validationNAValue)(warnings, flow.samplesToLab, true, "samplesToLab", labelSamplesToLab);
            for (const check of checks) {
                if (check.required || check.selected) {
                    if (check.conditionAcceptable == null) {
                        warnings.push({
                            property: "checks",
                            message: `Condition of magnet ${(_b = check.inspected) === null || _b === void 0 ? void 0 : _b.reference} (${(_c = check.inspected) === null || _c === void 0 ? void 0 : _c.tag}) is missing`,
                            subPropertyId: (_d = check.inspected) === null || _d === void 0 ? void 0 : _d.id,
                        });
                    }
                    if (check.conditionAcceptable === index_1.YesNoNA.No) {
                        warnings.push({
                            property: "checks",
                            message: `Condition of magnet ${(_e = check.inspected) === null || _e === void 0 ? void 0 : _e.reference} (${(_f = check.inspected) === null || _f === void 0 ? void 0 : _f.tag}) is not acceptable`,
                            subPropertyId: (_g = check.inspected) === null || _g === void 0 ? void 0 : _g.id,
                        });
                    }
                    if ((_h = check.inspected) === null || _h === void 0 ? void 0 : _h.needCheck2) {
                        if (check.check2 == null) {
                            warnings.push({
                                property: "checks",
                                message: `Unusual objects for magnet ${(_j = check.inspected) === null || _j === void 0 ? void 0 : _j.reference} (${(_k = check.inspected) === null || _k === void 0 ? void 0 : _k.tag}) is missing`,
                                subPropertyId: (_l = check.inspected) === null || _l === void 0 ? void 0 : _l.id,
                            });
                        }
                        if (check.check2 === index_1.YesNoNA.Yes) {
                            warnings.push({
                                property: "checks",
                                message: `There is unusual objects on magnet ${(_m = check.inspected) === null || _m === void 0 ? void 0 : _m.reference} (${(_o = check.inspected) === null || _o === void 0 ? void 0 : _o.tag})`,
                                subPropertyId: (_p = check.inspected) === null || _p === void 0 ? void 0 : _p.id,
                            });
                        }
                    }
                }
            }
        }
        else if (flow.typeInspected === index_1.InspectedType.screen) {
            for (const check of checks) {
                if (check.required || check.selected) {
                    if (check.conditionAcceptable == null) {
                        warnings.push({
                            property: "checks",
                            message: `Condition of screen ${(_q = check.inspected) === null || _q === void 0 ? void 0 : _q.reference} (${(_r = check.inspected) === null || _r === void 0 ? void 0 : _r.tag}) is missing`,
                            subPropertyId: (_s = check.inspected) === null || _s === void 0 ? void 0 : _s.id,
                        });
                    }
                    if (check.conditionAcceptable === index_1.YesNoNA.No) {
                        warnings.push({
                            property: "checks",
                            message: `Condition of screen ${(_t = check.inspected) === null || _t === void 0 ? void 0 : _t.reference} (${(_u = check.inspected) === null || _u === void 0 ? void 0 : _u.tag}) is not acceptable`,
                            subPropertyId: (_v = check.inspected) === null || _v === void 0 ? void 0 : _v.id,
                        });
                    }
                    if ((_w = check.inspected) === null || _w === void 0 ? void 0 : _w.needCheck2) {
                        if (check.check2 == null) {
                            warnings.push({
                                property: "checks",
                                message: `5 balls per tray for screen ${(_x = check.inspected) === null || _x === void 0 ? void 0 : _x.reference} (${(_y = check.inspected) === null || _y === void 0 ? void 0 : _y.tag}) is missing`,
                                subPropertyId: (_z = check.inspected) === null || _z === void 0 ? void 0 : _z.id,
                            });
                        }
                        if (check.check2 === index_1.YesNoNA.No) {
                            warnings.push({
                                property: "checks",
                                message: `5 balls per tray of screen ${(_0 = check.inspected) === null || _0 === void 0 ? void 0 : _0.reference} (${(_1 = check.inspected) === null || _1 === void 0 ? void 0 : _1.tag}) is "No"`,
                                subPropertyId: (_2 = check.inspected) === null || _2 === void 0 ? void 0 : _2.id,
                            });
                        }
                    }
                    if ((_3 = check.inspected) === null || _3 === void 0 ? void 0 : _3.needScreenSize) {
                        if (check.screenSize == null) {
                            warnings.push({
                                property: "checks",
                                message: `Screen size of screen ${(_4 = check.inspected) === null || _4 === void 0 ? void 0 : _4.reference} (${(_5 = check.inspected) === null || _5 === void 0 ? void 0 : _5.tag}) is missing`,
                                subPropertyId: (_6 = check.inspected) === null || _6 === void 0 ? void 0 : _6.id,
                            });
                        }
                        if (check.screenSize != null) {
                            if (check.screenSize !== 2 && check.screenSize !== 4 && check.screenSize !== 9) {
                                warnings.push({
                                    property: "checks",
                                    message: `Screen size of ${(_7 = check.inspected) === null || _7 === void 0 ? void 0 : _7.reference} (${(_8 = check.inspected) === null || _8 === void 0 ? void 0 : _8.tag}) is not 2, 4 or 9`,
                                    subPropertyId: (_9 = check.inspected) === null || _9 === void 0 ? void 0 : _9.id,
                                });
                            }
                        }
                    }
                }
            }
        }
    }
    return {
        errors,
        warnings,
    };
}
exports.flowInspectionValidate = flowInspectionValidate;
function flowInspectionImmutableValuesWereNotChanged(flow1, flow2) {
    if (!flow1.checks || !flow2.checks) {
        return false;
    }
    if (flow1.checks.length !== flow2.checks.length) {
        return false;
    }
    if (flow1.typeInspected !== flow2.typeInspected) {
        return false;
    }
    const idsAndRequired1 = flow1.checks
        .map((check) => {
        var _a;
        return ({
            id: (_a = check.inspected) === null || _a === void 0 ? void 0 : _a.id,
            required: check.required,
        });
    })
        .sort((a, b) => a.id - b.id);
    const idsAndRequired2 = flow2.checks
        .map((check) => {
        var _a;
        return ({
            id: (_a = check.inspected) === null || _a === void 0 ? void 0 : _a.id,
            required: check.required,
        });
    })
        .sort((a, b) => a.id - b.id);
    for (let i = 0; i < idsAndRequired1.length; i++) {
        if (idsAndRequired1[i].id !== idsAndRequired2[i].id || idsAndRequired1[i].required !== idsAndRequired2[i].required) {
            return false;
        }
    }
    return true;
}
exports.flowInspectionImmutableValuesWereNotChanged = flowInspectionImmutableValuesWereNotChanged;
