import React, { useState } from "react";
import { Avatar, Divider, Popover } from "antd";
import { useUser } from "../../services/auth";
import classNames from "./UserMenu.module.css";
import { LogoutOutlined, UserOutlined } from "@ant-design/icons";
import * as auth from "../../services/auth";
import FontSizePicker from "./FontSizePicker";

const UserDropdown = ({ logOut, businessRole }) => [
  <FontSizePicker key={0} />,
  <Divider key={1} />,
  <div key={2} tabIndex={0} className={classNames.userMenuMenuItemRole}>
    <UserOutlined style={{ marginRight: 8 }} />
    {businessRole}
  </div>,
  <Divider key={3} />,
  <div role="button" key={4} tabIndex={0} className={`${classNames.userMenuMenuItem}`} onClick={logOut} onKeyDown={logOut}>
    <LogoutOutlined style={{ marginRight: 8 }} />
    Log out
  </div>,
];

const UserMenu = () => {
  const [open, setOpen] = useState(false);
  const user = useUser();

  const logout = async () => {
    await auth.logout();
    window.location.reload();
  };
  if (user) {
    return (
      <Popover
        className={classNames.userMenuPopover}
        trigger="click"
        placement="bottomRight"
        content={<UserDropdown logOut={logout} businessRole={user.businessRole} />}
        open={open}
        onOpenChange={setOpen}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <p style={{ marginBottom: 0, marginRight: 8 }}>
            {user?.firstname && user?.lastname ? `${user?.firstname} ${user?.lastname}` : user?.email}
          </p>
          <Avatar icon={<UserOutlined />} size="large" />
        </div>
      </Popover>
    );
  }
  return null;
};

export default UserMenu;
