import React from "react";
import { IFlow, Status } from "shared/interfaces";
import { nicelyDisplayDateFromISOString } from "shared/utils";
import { displayUserName } from "../../hooks/Users";

interface FlowStatusProps {
  flow: IFlow;
}

const FlowStatus: React.FC<FlowStatusProps> = ({ flow }) => {
  if (!flow) return null;
  let displayedStatus = "";
  if (flow.edited) {
    displayedStatus = `last edited on ${flow.editedAt && nicelyDisplayDateFromISOString(flow.editedAt)} by ${
      flow.editedBy && displayUserName(flow.editedBy)
    }`;
  } else {
    const lastStatus = flow?.statuses && flow.statuses.length > 0 && flow.statuses[flow.statuses.length - 1];
    if (!lastStatus || lastStatus.status === Status.toDo || lastStatus.status === Status.notReady || !lastStatus.onDate || !lastStatus.byUser) {
      return null;
    }
    displayedStatus = `last ${lastStatus.status} on ${nicelyDisplayDateFromISOString(lastStatus.onDate)} by ${displayUserName(lastStatus.byUser)}`;
  }

  return <div style={{ display: "flex", justifyContent: "right", color: "darkgray" }}>({displayedStatus})</div>;
};

export default FlowStatus;
