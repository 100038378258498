import React from "react";

interface TextDisplayProps {
  value?: string;
}

const TextDisplay: React.FC<TextDisplayProps> = ({ value }) => {
  return <div style={{ padding: "2.6px 11px 2.6px 11px" }}>{value}</div>;
};

export default TextDisplay;
