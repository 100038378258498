"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.userRoleCanDoOperation = exports.OperationType = exports.BusinessRole = exports.UserRole = void 0;
var UserRole;
(function (UserRole) {
    UserRole["Authenticated"] = "authenticated";
    UserRole["Public"] = "public";
    UserRole["Admin"] = "admin";
})(UserRole = exports.UserRole || (exports.UserRole = {}));
var BusinessRole;
(function (BusinessRole) {
    BusinessRole["Blocked"] = "Blocked";
    BusinessRole["Reader"] = "Reader";
    BusinessRole["Operator"] = "Operator";
    BusinessRole["Supervisor"] = "Supervisor";
    BusinessRole["Quality"] = "Quality";
    BusinessRole["Admin"] = "Admin";
})(BusinessRole = exports.BusinessRole || (exports.BusinessRole = {}));
var OperationType;
(function (OperationType) {
    OperationType["Read"] = "Read";
    OperationType["Input"] = "Input";
    OperationType["Edit"] = "Edit";
    OperationType["ResolveFlag"] = "ResolveFlag";
    OperationType["SignSupervisor"] = "SignSupervisor";
    OperationType["SignQuality"] = "SignQuality";
    OperationType["Delete"] = "Delete";
})(OperationType = exports.OperationType || (exports.OperationType = {}));
function userRoleCanDoOperation(businessRole, operationType) {
    if (businessRole === BusinessRole.Blocked)
        return false;
    if (businessRole === BusinessRole.Admin)
        return true;
    if (operationType === OperationType.Read)
        return true;
    if (operationType === OperationType.Input) {
        return businessRole === BusinessRole.Operator || businessRole === BusinessRole.Supervisor;
    }
    if (operationType === OperationType.Edit) {
        return businessRole === BusinessRole.Operator || businessRole === BusinessRole.Supervisor;
    }
    if (operationType === OperationType.ResolveFlag) {
        return businessRole === BusinessRole.Supervisor;
    }
    if (operationType === OperationType.SignSupervisor) {
        return businessRole === BusinessRole.Supervisor;
    }
    if (operationType === OperationType.SignQuality) {
        return businessRole === BusinessRole.Quality;
    }
    return false;
}
exports.userRoleCanDoOperation = userRoleCanDoOperation;
