"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.shiftGetInfoQualitySignature = exports.shiftGetInfoSupervisorSignature = exports.shiftIsCheckedOut = exports.shiftIsCheckedIn = exports.shiftIsSignedBySupervisorAndQuality = exports.shiftIsSignedByQuality = exports.shiftIsSignedBySupervisor = exports.shiftIsSigned = exports.shiftGetReadableLabel = exports.StatusShift = exports.Product = exports.Team = exports.Area = void 0;
var Area;
(function (Area) {
    Area["Pack"] = "Pack";
    Area["Plant"] = "Plant";
    Area["Yard"] = "Yard";
})(Area = exports.Area || (exports.Area = {}));
var Team;
(function (Team) {
    Team["D"] = "D";
    Team["N"] = "N";
})(Team = exports.Team || (exports.Team = {}));
var Product;
(function (Product) {
    Product["Ca"] = "Ca";
    Product["NaK"] = "NaK";
})(Product = exports.Product || (exports.Product = {}));
var StatusShift;
(function (StatusShift) {
    StatusShift["notCheckedIn"] = "notCheckedIn";
    StatusShift["checkedIn"] = "checkedIn";
    StatusShift["checkedOut"] = "checkedOut";
    StatusShift["signSupervisor"] = "signSupervisor";
    StatusShift["signQuality"] = "signQuality";
    StatusShift["signSupervisorAndQuality"] = "signSupervisorAndQuality";
})(StatusShift = exports.StatusShift || (exports.StatusShift = {}));
function shiftGetReadableLabel(shift) {
    return `${shift.day} ${shift.team} ${shift.area} ${shift.product} `;
}
exports.shiftGetReadableLabel = shiftGetReadableLabel;
function shiftIsSigned(shift) {
    return (shift.statusShift === StatusShift.signQuality ||
        shift.statusShift === StatusShift.signSupervisor ||
        shift.statusShift === StatusShift.signSupervisorAndQuality);
}
exports.shiftIsSigned = shiftIsSigned;
function shiftIsSignedBySupervisor(shift) {
    return shift.statusShift === StatusShift.signSupervisor || shift.statusShift === StatusShift.signSupervisorAndQuality;
}
exports.shiftIsSignedBySupervisor = shiftIsSignedBySupervisor;
function shiftIsSignedByQuality(shift) {
    return shift.statusShift === StatusShift.signQuality || shift.statusShift === StatusShift.signSupervisorAndQuality;
}
exports.shiftIsSignedByQuality = shiftIsSignedByQuality;
function shiftIsSignedBySupervisorAndQuality(shift) {
    return shift.statusShift === StatusShift.signSupervisorAndQuality;
}
exports.shiftIsSignedBySupervisorAndQuality = shiftIsSignedBySupervisorAndQuality;
function shiftIsCheckedIn(shift) {
    if (!shift)
        return false;
    if (shift.statusShift === StatusShift.checkedIn ||
        shift.statusShift === StatusShift.checkedOut ||
        shift.statusShift === StatusShift.signQuality ||
        shift.statusShift === StatusShift.signSupervisor ||
        shift.statusShift === StatusShift.signSupervisorAndQuality ||
        (shift.statusesShift && shift.statusesShift.some((status) => status.statusShift === StatusShift.checkedIn))) {
        return true;
    }
    return false;
}
exports.shiftIsCheckedIn = shiftIsCheckedIn;
function shiftIsCheckedOut(shift) {
    if (!shift)
        return false;
    if (shift.statusShift === StatusShift.checkedOut ||
        shift.statusShift === StatusShift.signQuality ||
        shift.statusShift === StatusShift.signSupervisor ||
        shift.statusShift === StatusShift.signSupervisorAndQuality ||
        (shift.statusesShift && shift.statusesShift.some((status) => status.statusShift === StatusShift.checkedOut))) {
        return true;
    }
    return false;
}
exports.shiftIsCheckedOut = shiftIsCheckedOut;
function shiftGetInfoSupervisorSignature(shift) {
    var _a, _b;
    let status = (_a = shift.statusesShift) === null || _a === void 0 ? void 0 : _a.find((s) => s.statusShift === StatusShift.signSupervisor);
    if (status) {
        return {
            user: status.byUser,
            date: status.onDate,
        };
    }
    status = (_b = shift.statusesShift) === null || _b === void 0 ? void 0 : _b.find((s) => s.statusShift === StatusShift.signSupervisorAndQuality);
    if (status) {
        return {
            user: status.byUser,
            date: status.onDate,
        };
    }
    return undefined;
}
exports.shiftGetInfoSupervisorSignature = shiftGetInfoSupervisorSignature;
function shiftGetInfoQualitySignature(shift) {
    var _a, _b;
    let status = (_a = shift.statusesShift) === null || _a === void 0 ? void 0 : _a.find((s) => s.statusShift === StatusShift.signQuality);
    if (status) {
        return {
            user: status.byUser,
            date: status.onDate,
        };
    }
    status = (_b = shift.statusesShift) === null || _b === void 0 ? void 0 : _b.find((s) => s.statusShift === StatusShift.signSupervisorAndQuality);
    if (status) {
        return {
            user: status.byUser,
            date: status.onDate,
        };
    }
    return undefined;
}
exports.shiftGetInfoQualitySignature = shiftGetInfoQualitySignature;
