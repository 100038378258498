import { Button, Form, Input, Modal, notification, Result, Space, Spin, Popconfirm } from "antd";
import React from "react";
import { useShiftUsers } from "../../../hooks/Users";
import { useAppSelector } from "../../../hooks/redux";
import YesNoNASwitch from "../../UI/YesNoNASwitch";
import EmployeeShiftPicker from "../../UI/EmployeeShiftPicker";
import { EyeOutlined, SaveOutlined, SendOutlined } from "@ant-design/icons";
import getAPI from "../../../services/api";
import { useNavigate, useSearchParams } from "react-router-dom";
import useFlow from "../../../hooks/useFlow";
import YesNoSwitch from "../../UI/YesNoSwitch";
import {
  flowGetStatus,
  flowIsEditable,
  flowValidateFE as flowValidate,
  IFlow,
  IFlowShiftCheckIn,
  Status,
  Team,
  BusinessRole,
  YesNoNA,
} from "shared/interfaces";
import BatchTable from "../BatchFrame/BatchTable";
import dayjs from "dayjs";
import useShift from "../../../hooks/useShift";
import axios from "axios";
import { useUser } from "../../../services/auth";
import FlowStatus from "../../UI/FlowStatus";

const { TextArea } = Input;

interface ShiftCheckInFlowProps {
  flowId: IFlow["id"];
  version?: IFlow["version"];
  readonly?: boolean;
  onSuccess: (flow) => void;
  setFormHasUnsavedChanges?: (hasUnsavedChanges: boolean) => void;
}

const ShiftCheckInFlowForm: React.FC<ShiftCheckInFlowProps> = ({
  flowId,
  version,
  readonly: propReadonly = false,
  onSuccess,
  setFormHasUnsavedChanges,
}) => {
  const user = useUser();
  const [readonly, setReadonly] = React.useState(propReadonly);
  const navigate = useNavigate();
  const { data, isLoading: isFlowLoading, refetch: refetchFlow, error: flowError } = useFlow(flowId, version);
  const flow = data as IFlowShiftCheckIn;
  const currentShift = useAppSelector((state) => state.shift);
  const { data: employees = [] } = useShiftUsers(currentShift);
  const [form] = Form.useForm();
  const [hasWarnings, setHasWarnings] = React.useState<boolean>(false);
  const [sampleToolAccCheck, setSampleToolAccCheck] = React.useState<boolean>(false);
  const [isShiftUpdated, setIsShiftUpdated] = React.useState<boolean>(false);
  const [previousBatchesModalOpen, setPreviousBatchesModalOpen] = React.useState<boolean>();
  const [hasOpenedPreviousShift, setHasOpenedPreviousBatch] = React.useState<boolean>(false);
  let [searchParams] = useSearchParams();
  const initialValues = flow;
  const { data: shift } = useShift(flow?.shift?.id);
  let { day, team, area, product } = shift || {};

  React.useEffect(() => {
    const userIsReader = user?.businessRole === BusinessRole.Reader || user?.businessRole === BusinessRole.Quality;
    setReadonly((propReadonly || userIsReader) ?? false);
  }, [propReadonly, user]);

  const supervisorMode = searchParams.get("mode") === "supervisor";

  const getPreviousShiftId = async () => {
    const api = await getAPI();
    if (team === Team.D) {
      day = dayjs(day).subtract(1, "day").format("YYYY-MM-DD");
      team = Team.N;
    } else if (team === Team.N) {
      team = Team.D;
    }
    try {
      const resp = await api.get(`/shifts/specific/${day}/${team}/${area}/${product}`);
      const { data } = resp;
      const { id } = data;
      return id;
    } catch (e) {
      if (axios.isAxiosError(e) && e.response?.status === 404) {
        notification.error({
          message: "Error",
          description: `Previous shift does not exist`,
        });
      } else {
        notification.error({
          message: "Error",
          description: `${e}`,
        });
      }
      return null;
    }
  };

  const onChangePreviousShift = async (value) => {
    if (value === YesNoNA.Yes && !hasOpenedPreviousShift) {
      notification.warning({
        message: "Check previous shift",
        description: "It appears you did not review previous shift. Please check previous shift before submitting.",
      });
    }
  };

  const [previousShiftId, setPreviousShiftId] = React.useState<number | undefined>(undefined);

  if (initialValues && employees) {
    initialValues.onShift = initialValues.onShift?.map((employeeOnShift) => {
      const matchedEmployee = employees.find((employee) => employeeOnShift.userId === employee.id);
      return {
        ...employeeOnShift,
        username: matchedEmployee?.username,
        name: matchedEmployee?.username,
      };
    });
    !isShiftUpdated && setIsShiftUpdated(true);
  }

  if (!shift) return null;

  if (flowError) {
    return (
      <Result
        status="500"
        title="Mmmmh... something went wrong."
        subTitle="More luck next time?"
        extra={
          <Button type="primary" onClick={() => navigate("/")}>
            Back Home
          </Button>
        }
      />
    );
  }

  if (isFlowLoading || !isShiftUpdated || !flow) {
    return <Spin>Loading...</Spin>;
  }

  return (
    <Form
      initialValues={initialValues}
      disabled={readonly}
      form={form}
      labelCol={{ span: 10 }}
      labelAlign={"left"}
      labelWrap
      wrapperCol={{ span: 12 }}
      onFinish={async (values) => {
        const shiftCheckInFlow: IFlowShiftCheckIn = {
          ...flow,
          ...values,
        };
        shiftCheckInFlow.onShift = shiftCheckInFlow.onShift?.map((item) => ({
          userId: item.userId,
          relief: item.relief,
        }));

        const { warnings } = await flowValidate(shiftCheckInFlow);

        if (warnings.length > 0 && !hasWarnings && !supervisorMode) {
          setHasWarnings(true);
          notification.warning({
            message: "The form has warnings",
            description: "Please check the form for warnings and enter a comment to submit despite warnings",
          });
          !hasWarnings && setHasWarnings(true);
          form.setFields(
            warnings.map((warning) => ({
              name: warning.property,
              warnings: warning.messages,
            }))
          );
          return;
        }

        try {
          const api = await getAPI();
          await api.put(`/flows/${flow?.id}`, {
            data: {
              ...shiftCheckInFlow,
            },
          });
          await api.put(`/flows/${flowId}/submit`);
          onSuccess && onSuccess(flow);
          await refetchFlow();
          notification.success({
            message: "The shift check in was done successfully",
          });
        } catch (error: any) {
          notification.error({
            message: "There was an error submitting the form",
            description: error?.message,
          });
        }
      }}
      onValuesChange={async () => {
        setFormHasUnsavedChanges && setFormHasUnsavedChanges(true);
        if (form.getFieldValue("sampleToolAccCheck") !== sampleToolAccCheck) {
          setSampleToolAccCheck(!sampleToolAccCheck);
        }
        const values = form.getFieldsValue();
        // reset warnings
        Object.keys(values).forEach((key) => {
          form.setFields([
            {
              name: key,
              warnings: [],
            },
          ]);
        });
        const shiftCheckInFlow: IFlowShiftCheckIn = {
          ...flow,
          ...values,
        };
        shiftCheckInFlow.onShift = shiftCheckInFlow.onShift?.map((item) => ({
          userId: item.userId,
          relief: item.relief,
        }));

        const { warnings } = await flowValidate(shiftCheckInFlow);

        if (warnings.length > 0) {
          !hasWarnings && setHasWarnings(true);
          form.setFields(
            warnings.map((warning) => ({
              name: warning.property,
              warnings: warning.messages,
            }))
          );
          setHasWarnings(true);
        } else if (warnings.length === 0) {
          setHasWarnings(false);
        }
      }}
    >
      <Button
        ghost={true}
        type={"primary"}
        style={{ marginBottom: 16, marginTop: 16 }}
        disabled={false}
        icon={<EyeOutlined />}
        onClick={async () => {
          setHasOpenedPreviousBatch(true);
          let shiftId = previousShiftId;
          const receivedPreviousShiftId = await getPreviousShiftId();
          if (receivedPreviousShiftId) {
            shiftId = receivedPreviousShiftId;
          }
          if (shiftId) {
            setPreviousShiftId(shiftId);
            setPreviousBatchesModalOpen(true);
          }
        }}
      >
        View batches from previous shift
      </Button>
      <Form.Item label="Employees on shift" name="onShift" labelCol={{ span: 24 }}>
        <EmployeeShiftPicker
          disabled={readonly}
          options={employees.map((employee) => ({ ...employee, key: employee.id, name: employee.username }))}
        />
      </Form.Item>
      <Form.Item label="Pallets ordered" name="palletsOrdered">
        <YesNoNASwitch disabled={readonly} />
      </Form.Item>
      <Form.Item label="Bags ordered" name="bagsOrdered">
        <YesNoNASwitch disabled={readonly} />
      </Form.Item>
      <Form.Item label="Did you perform turnover with previous shift and reviewed the previous shift documentation?" name="reviewedPreviousShift">
        <YesNoNASwitch disabled={readonly} onChange={onChangePreviousShift} />
      </Form.Item>
      <Form.Item label="Reviewed daily notes" name="reviewedDailyNotes">
        <YesNoNASwitch disabled={readonly} />
      </Form.Item>
      <Form.Item label="Sample tool accountability check" name="sampleToolAccCheck">
        <YesNoSwitch disabled={readonly} />
      </Form.Item>
      {(initialValues?.sampleToolAccCheck || sampleToolAccCheck) && (
        <Form.Item label="Sample tool accountability check ID" name="sampleToolAccCheckId" wrapperCol={{ span: 4 }}>
          <Input disabled={readonly} />
        </Form.Item>
      )}
      <Form.Item
        name="comment"
        label="Comment"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        rules={[{ required: hasWarnings, message: "Please add a comment to explain warnings" }]}
      >
        <TextArea disabled={readonly} />
      </Form.Item>
      <Space direction="vertical" style={{ width: "100%" }} size="large">
        {!readonly && (
          <div style={{ display: "flex", justifyContent: "right" }}>
            <Space>
              {supervisorMode ? (
                <Popconfirm
                  title="Save your change"
                  description="To make a change, you must be trained to do so and you MUST add an explanation in the comment box, below the existing comments. If you have done so, you can click on Save."
                  onConfirm={async () => {
                    try {
                      const shiftCheckInFlow: IFlowShiftCheckIn = {
                        ...flow,
                        ...form.getFieldsValue(),
                      };
                      shiftCheckInFlow.onShift = shiftCheckInFlow.onShift?.map((item) => ({
                        userId: item.userId,
                        relief: item.relief,
                      }));
                      const api = await getAPI();
                      await api.put(`/flows/${flow?.id}/edit`, {
                        data: shiftCheckInFlow,
                      });
                      await refetchFlow();
                      notification.success({
                        message: "Changes saved",
                      });
                      setFormHasUnsavedChanges && setFormHasUnsavedChanges(false);
                    } catch (error: any) {
                      notification.error({
                        message: "Error saving changes",
                        description: error?.message,
                      });
                    }
                  }}
                  okText="Save"
                  cancelText="Cancel"
                >
                  <Button size="large" icon={<SaveOutlined />} type="primary">
                    Save changes
                  </Button>
                </Popconfirm>
              ) : (
                <>
                  <Button
                    disabled={flowGetStatus(flow) === Status.confirmed}
                    size="large"
                    icon={<SaveOutlined />}
                    onClick={async () => {
                      try {
                        const shiftCheckInFlow: IFlowShiftCheckIn = {
                          ...flow,
                          ...form.getFieldsValue(),
                        };
                        shiftCheckInFlow.onShift = shiftCheckInFlow.onShift?.map((item) => ({
                          userId: item.userId,
                          relief: item.relief,
                        }));
                        const api = await getAPI();
                        await api.put(`/flows/${flow?.id}`, {
                          data: shiftCheckInFlow,
                        });
                        await refetchFlow();
                        notification.success({
                          message: "Draft saved",
                        });
                        setFormHasUnsavedChanges && setFormHasUnsavedChanges(false);
                      } catch (error: any) {
                        notification.error({
                          message: "There was an error saving the draft",
                          description: error?.message,
                        });
                      }
                    }}
                  >
                    Save draft
                  </Button>
                  <Button size="large" icon={<SendOutlined />} htmlType="submit" type="primary" disabled={!flowIsEditable(flow, shift)}>
                    {hasWarnings ? "Submit with warnings" : "Submit"}
                  </Button>
                </>
              )}
            </Space>
          </div>
        )}
        <FlowStatus flow={flow} />
      </Space>
      <Modal
        width={"90%"}
        open={previousBatchesModalOpen}
        title={"Previous shift"}
        okText={"Close"}
        onCancel={() => {
          setPreviousBatchesModalOpen(false);
        }}
        cancelButtonProps={{ style: { display: "none" } }}
        onOk={() => {
          setPreviousBatchesModalOpen(false);
        }}
      >
        <BatchTable shiftId={previousShiftId} readonly={true} withNavigation={false} />
      </Modal>
    </Form>
  );
};

export default ShiftCheckInFlowForm;
