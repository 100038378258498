import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { IUser, IShift } from "shared/interfaces";
import getAPI from "../services/api";

const getUsers = async () => {
  const api = await getAPI();
  const response = await api.get(`/users`, {
    params: {
      fields: ["firstname", "lastname", "email"],
    },
  });

  const users = response.data;
  return users;
};

export const useUsers = (): UseQueryResult<Pick<IUser, "id" | "username">[]> => {
  return useQuery(["users"], async () => {
    const users = await getUsers();
    return users.map((user) => ({
      id: user.id,
      username: user.firstname || user.lastname ? user.firstname + " " + user.lastname : user.email,
    }));
  });
};

const getShiftUsers = async (shift: Pick<IShift, "product" | "area" | "team">) => {
  const api = await getAPI();
  const response = await api.get(`/flows/flowShiftCheckInSortedUsers`, {
    params: {
      product: shift.product,
      area: shift.area,
      team: shift.team,
    },
  });

  const users = response.data;
  return users;
};

export const useShiftUsers = (shift: Pick<IShift, "product" | "area" | "team">): UseQueryResult<Pick<IUser, "id" | "username">[]> => {
  return useQuery(["users", shift], async () => {
    const users = await getShiftUsers(shift);
    return users.map((user) => ({
      id: user.id,
      username: user.firstname || user.lastname ? user.firstname + " " + user.lastname : user.email,
    }));
  });
};

export const displayUserName = (user: Pick<IUser, "firstname" | "lastname" | "email">) => {
  return user.firstname || user.lastname ? user.firstname + " " + user.lastname : user.email;
};
