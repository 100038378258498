import { Button, Form, Input, InputNumber, notification, Space, Spin, Popconfirm } from "antd";
import React from "react";
import getAPI from "../../../services/api";
import YesNoNASwitch from "../../UI/YesNoNASwitch";
import useFlow from "../../../hooks/useFlow";
import { CloseOutlined, SaveOutlined, SendOutlined } from "@ant-design/icons";
import { BusinessRole, flowGetStatus, flowIsEditable, flowValidateFE as flowValidate, IFlow, IFlowBatchRecord, Status } from "shared/interfaces";
import PictureUpload from "../../UI/PictureUpload";
import FilePictureVideoUpload from "../../UI/AssetUpload";
import { useSearchParams } from "react-router-dom";
import { useUser } from "../../../services/auth";
import { ZoomContext } from "../../../App";
import FlowStatus from "../../UI/FlowStatus";

const { TextArea } = Input;

const BatchRecordFlowForm: React.FC<{
  flowId: IFlow["id"];
  version?: IFlow["version"];
  readonly?: boolean;
  onSuccess?: () => void;
  onCancel?: () => void;
  cancelText?: string;
  setFormHasUnsavedChanges?: (hasUnsavedChanges: boolean) => void;
}> = ({ flowId, version, readonly: propReadonly = false, onSuccess, cancelText, onCancel, setFormHasUnsavedChanges }) => {
  const user = useUser();
  const [readonly, setReadonly] = React.useState(propReadonly);
  const { data, isLoading: isFlowLoading, refetch: refetchFlow } = useFlow(flowId, version);
  const flow = data as IFlowBatchRecord;
  const [form] = Form.useForm();
  const [hasWarnings, setHasWarnings] = React.useState<boolean>(false);
  let [searchParams] = useSearchParams();
  const supervisorMode = searchParams.get("mode") === "supervisor";
  const initialValues = flow;
  const [zoom] = React.useContext(ZoomContext);
  const zoomRatio = parseInt(zoom) / 100;

  React.useEffect(() => {
    const userIsReader = user?.businessRole === BusinessRole.Reader || user?.businessRole === BusinessRole.Quality;
    setReadonly((propReadonly || userIsReader) ?? false);
  }, [propReadonly, user]);

  if (isFlowLoading || !initialValues) {
    return <Spin>Loading...</Spin>;
  }

  if (initialValues) {
    initialValues.fromPallet = initialValues.fromPallet || 0;
    initialValues.toPallet = initialValues.toPallet || 0;
  }

  return (
    <Form
      initialValues={initialValues}
      disabled={readonly}
      form={form}
      labelCol={{ span: zoomRatio > 1.0 ? 15 : 12 }}
      wrapperCol={{ span: 9 }}
      labelAlign={"left"}
      onFinish={async (values) => {
        const batchRecordFlow: IFlowBatchRecord = {
          ...flow,
          ...values,
        };

        const { warnings } = await flowValidate(batchRecordFlow);

        if (warnings.length > 0 && !hasWarnings) {
          setHasWarnings(true);
          notification.warning({
            message: "The form has warnings",
            description: "Please check the form for warnings and enter a comment to submit despite warnings",
          });
          !hasWarnings && setHasWarnings(true);
          form.setFields(
            warnings.map((warning) => ({
              name: warning.property,
              warnings: warning.messages,
            }))
          );
          return;
        }
        try {
          const api = await getAPI();
          await api.put(`/flows/${flowId}`, {
            data: {
              ...flow,
              ...form.getFieldsValue(),
            },
          });
          await api.put(`/flows/${flowId}/submit`);
          await refetchFlow();
          onSuccess && onSuccess();
          notification.success({
            message: "The batch record was submitted successfully",
          });
        } catch (error: any) {
          notification.error({
            message: "There was an error submitting the form",
            description: error?.message,
          });
        }
      }}
      onFieldsChange={async () => {
        setFormHasUnsavedChanges && setFormHasUnsavedChanges(true);
        if (hasWarnings) {
          const values = form.getFieldsValue();
          // reset warnings
          Object.keys(values).forEach((key) => {
            form.setFields([
              {
                name: key,
                warnings: [],
              },
            ]);
          });
          // const batchRecordFlow = new BatchRecordFlow({ ...initialValues, flowBatchRecord: values });
          const batchRecordFlow: IFlowBatchRecord = {
            ...flow,
            ...values,
          };
          const { warnings } = await flowValidate(batchRecordFlow);
          // if we have warnings (or rather warnings), we set them in the form, the user can still submit)
          if (warnings.length > 0) {
            form.setFields(
              warnings.map((warning) => ({
                name: warning.property,
                warnings: warning.messages,
              }))
            );
            setHasWarnings(true);
          } else if (warnings.length === 0) {
            setHasWarnings(false);
          }
        }
      }}
    >
      <Form.Item label="From pallet" name="fromPallet">
        <InputNumber min={1} inputMode="numeric" />
      </Form.Item>
      <Form.Item
        label="To pallet"
        name="toPallet"
        rules={[
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("fromPallet") <= value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error("The last pallet must be greater than the from pallet!"));
            },
          }),
        ]}
      >
        <InputNumber min={initialValues.fromPallet} inputMode="numeric" />
      </Form.Item>
      <Form.Item label="Proper Hooder/Stretchwrap in place" name="properHooders">
        <YesNoNASwitch checkedChildren={"Pass"} unCheckedChildren={"Fail"} disabled={readonly} />
      </Form.Item>
      <Form.Item label="Proper package and label, if applicable" name="properPackage">
        <YesNoNASwitch checkedChildren={"Pass"} unCheckedChildren={"Fail"} disabled={readonly} />
      </Form.Item>
      <Form.Item label="Proper and legible stencilling or special instruction from Daily Notes" name="properStenciling">
        <YesNoNASwitch checkedChildren={"Pass"} unCheckedChildren={"Fail"} disabled={readonly} />
      </Form.Item>
      <Form.Item label="Proper date on package and labels (DOM and/or EXP)" name="properDate">
        <YesNoNASwitch checkedChildren={"Pass"} unCheckedChildren={"Fail"} disabled={readonly} />
      </Form.Item>
      <Form.Item label="Proper Pallets and slipsheet" name="properPallets">
        <YesNoNASwitch checkedChildren={"Pass"} unCheckedChildren={"Fail"} disabled={readonly} />
      </Form.Item>
      <Form.Item label="Leakage" name="noLeakage">
        <YesNoNASwitch checkedChildren={"Pass"} unCheckedChildren={"Fail"} disabled={readonly} />
      </Form.Item>
      <Form.Item label="Damage" name="noDamage">
        <YesNoNASwitch checkedChildren={"Pass"} unCheckedChildren={"Fail"} disabled={readonly} />
      </Form.Item>
      <Form.Item label="Pallets in Bin and Scanned" name="palletsInBin">
        <YesNoNASwitch checkedChildren={"Pass"} unCheckedChildren={"Fail"} disabled={readonly} />
      </Form.Item>
      <Form.Item label='Metal Detector Rejects ("Pass" if no metal detector rejects)' name="noMetalDetectorRejects">
        <YesNoNASwitch checkedChildren={"Pass"} unCheckedChildren={"Fail"} disabled={readonly} />
      </Form.Item>
      <Form.Item label="Samples to Lab" name="samplesToLab">
        <YesNoNASwitch checkedChildren={"Pass"} unCheckedChildren={"Fail"} disabled={readonly} />
      </Form.Item>
      <Form.Item label="Bin #" name="binNumber">
        <Input disabled={readonly} />
      </Form.Item>
      <Form.Item label="LT24 / Confirmations" name="lt24Files" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
        <FilePictureVideoUpload disabled={readonly} noVideo={true} />
      </Form.Item>
      <Form.Item label="Pictures" name="files" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
        <PictureUpload disabled={readonly} />
      </Form.Item>

      <Form.Item
        name="comment"
        label="Comment"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        rules={[{ required: hasWarnings, message: "Please add a comment to explain warnings" }]}
      >
        <TextArea disabled={readonly} />
      </Form.Item>

      <Space direction="vertical" style={{ width: "100%" }} size="large">
        {!readonly && (
          <div style={{ display: "flex", justifyContent: "right" }}>
            <Space>
              {onCancel && (
                <Button size="large" icon={<CloseOutlined />} onClick={() => onCancel()}>
                  {cancelText ? cancelText : flow && flowGetStatus(flow) === Status.confirmed ? "Close" : "Cancel"}
                </Button>
              )}

              {supervisorMode ? (
                <Popconfirm
                  title="Save your change"
                  description="To make a change, you must be trained to do so and you MUST add an explanation in the comment box, below the existing comments. If you have done so, you can click on Save."
                  onConfirm={async () => {
                    try {
                      const api = await getAPI();
                      await api.put(`/flows/${flow?.id}/edit`, {
                        data: {
                          ...flow,
                          ...form.getFieldsValue(),
                        },
                      });
                      await refetchFlow();
                      notification.success({
                        message: "Changes saved",
                      });
                      setFormHasUnsavedChanges && setFormHasUnsavedChanges(false);
                    } catch (error: any) {
                      notification.error({
                        message: "Error saving changes",
                        description: error?.message,
                      });
                    }
                  }}
                  okText="Save"
                  cancelText="Cancel"
                >
                  <Button size="large" icon={<SaveOutlined />} type="primary">
                    Save changes
                  </Button>
                </Popconfirm>
              ) : (
                <Button size="large" icon={<SendOutlined />} htmlType="submit" type="primary" disabled={!flowIsEditable(flow) || readonly}>
                  {hasWarnings ? "Submit with warnings" : "Submit"}
                </Button>
              )}
            </Space>
          </div>
        )}
        <FlowStatus flow={flow} />
      </Space>
    </Form>
  );
};

export default BatchRecordFlowForm;
