import getAPI from "../services/api";
import { IShift } from "shared/interfaces";
import { useQuery, UseQueryResult } from "@tanstack/react-query";

const useShift = (id: string | number | undefined): UseQueryResult<IShift | undefined> => {
  return useQuery(["shift", Number(id)], async () => {
    if (!id) {
      return null;
    }
    const api = await getAPI();
    const { data: shift } = await api.get<IShift>(`/shifts/${id}`, {
      params: {
        includeFlows: true,
        includeBatches: true,
      },
    });
    return shift;
  });
};

export default useShift;
