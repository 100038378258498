import React from "react";
import { Layout } from "antd";

import HeaderMenu from "../../HeaderMenu/HeaderMenu";
import FlagTable from "./FlagTable";
import { PageHeader } from "@ant-design/pro-layout";

const { Content } = Layout;

const FlagTablePage = () => {
  return (
    <>
      <HeaderMenu>
        <PageHeader title={<h1>Flags</h1>} />
      </HeaderMenu>

      <Content
        style={{
          height: "calc(100vh - 64px)",
          overflowY: "hidden",
          position: "relative",
        }}
      >
        <FlagTable />
      </Content>
    </>
  );
};

export default FlagTablePage;
