import { Menu, Divider, notification } from "antd";
import { Link } from "react-router-dom";
import { navigationMenuRoutes } from "../../routes";
import { useMatches } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useUser } from "../../services/auth";
import { useEffect } from "react";
import { BusinessRole } from "shared/interfaces";

function NavigationMenu({ collapsed, onNavigate }) {
  const matches = useMatches();
  const { businessRole } = useUser();

  useEffect(() => {
    if (businessRole === BusinessRole.Blocked) {
      notification.warning({
        message: "You are currently blocked",
        description: `As a new user, you are blocked from using the app. Please contact the site admin in manufacturing to be granted access`,
        duration: 0,
      });
    }
  }, [businessRole]);

  const { data: items = [] } = useQuery(["navigationMenu"], async () => {
    const results = await Promise.all(
      navigationMenuRoutes.map(async (route) => {
        if (route.id === "divider") {
          return <Divider key="menu-divider" />;
        }
        if (route.roles && !route.roles.some((role) => role === businessRole)) {
          return null;
        }
        return {
          disabled: route.disabled || false,
          key: route.id,
          icon: <route.icon />,
          label: (
            <Link to={route.path}>
              <span>{route.title}</span>
            </Link>
          ),
        };
      })
    );
    return results.filter((item) => item);
  });

  return (
    <Menu
      items={items}
      mode="inline"
      selectedKeys={[matches[matches.length - 1]?.id]}
      onSelect={onNavigate}
      style={{ borderRight: collapsed ? "1px solid #e8e8e8" : "none" }} // right border fix
    />
  );
}

export default NavigationMenu;
