import { Modal, Row, Steps } from "antd";
import React, { useEffect } from "react";
import useBatch from "../../hooks/useBatch";
import useBatchLineClosingInfo from "../../hooks/useBatchLineClosingInfo";
import CloseBatchLineForm from "./CloseBatchLineForm";
import NewBatchLineForm from "./NewBatchLineForm";
import { batchGetOpenBatchLine } from "shared/interfaces";

const ChangeBatchFeaturesModal: React.FC<{
  batchId: number | undefined;
  open: boolean;
  // nextStep is an id for the type of operation for which the batch line is being closed (e.g. "changeSource", "changeBlend", etc.)
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onChangeSuccess?: () => void;
}> = ({ open, setOpen, batchId, onChangeSuccess }) => {
  const { data: batch, refetch: refetchBatch } = useBatch(batchId);

  const batchLine = batch && batchGetOpenBatchLine(batch);
  const batchLineId = batchLine?.id;
  const [currentStep, setCurrentStep] = React.useState(0);

  const { data: closeBatchLineInfos, refetch: refetchBatchLineInfos } = useBatchLineClosingInfo({
    batchLineId,
    batchId,
  });

  useEffect(() => {
    if (open) {
      refetchBatchLineInfos().then((r) => {});
      setCurrentStep(batchLineId ? 0 : 1);
    }
  }, [open]);

  const steps = [
    {
      title: "Log latest set of pallets",
    },
    {
      title: "Change features",
    },
  ];

  return (
    <>
      {batch && (
        <Modal
          okText={currentStep === 0 ? "Next" : "Finish"}
          title="Log then change batch features"
          footer={null}
          open={open}
          onCancel={() => {
            setCurrentStep(0);
            setOpen(false);
          }}
          maskClosable={false}
          destroyOnClose
          width={600}
        >
          <Row justify="center">
            <Steps size="small" style={{ margin: "20px 0" }} current={currentStep} items={steps} />
            {currentStep === 0 && closeBatchLineInfos && (
              <CloseBatchLineForm
                onSuccess={() => setCurrentStep(1)}
                onCancel={() => {
                  setCurrentStep(0);
                  setOpen(false);
                }}
              />
            )}
            {currentStep === 1 && (
              <NewBatchLineForm
                batchId={batchId}
                onCancel={() => {
                  setCurrentStep(0);
                  setOpen(false);
                }}
                onSuccess={async () => {
                  setCurrentStep(0);
                  await refetchBatch();
                  onChangeSuccess && onChangeSuccess();
                  setOpen(false);
                }}
              />
            )}
          </Row>
        </Modal>
      )}
    </>
  );
};

export default ChangeBatchFeaturesModal;
