import { useQuery, UseQueryResult } from "@tanstack/react-query";
import getAPI from "../services/api";
import { IProductFamily } from "shared/interfaces";

export const useProductFamilies = (product): UseQueryResult<IProductFamily[]> => {
  return useQuery(["productFamilies", product], async () => {
    const api = await getAPI();
    const { data } = await api.get("/product-families", { params: product ? { filters: { product } } : {} });
    return data.data
      .map(({ id, reference }) => ({
        id,
        reference: reference,
      }))
      .sort((a, b) => a.reference.localeCompare(b.reference));
  });
};

export default useProductFamilies;
