"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.flowBatchRecordValidate = exports.flowBatchRecordGetFlowName = exports.flowBatchRecordGetGenericLabel = exports.flowBatchRecordGetLabel = void 0;
const index_1 = require("./index");
function flowBatchRecordGetLabel(flow) {
    var _a;
    return `${flowBatchRecordGetGenericLabel()}${((_a = flow.batch) === null || _a === void 0 ? void 0 : _a.batchNumber) ? " - " + flow.batch.batchNumber : ""}`;
}
exports.flowBatchRecordGetLabel = flowBatchRecordGetLabel;
function flowBatchRecordGetGenericLabel() {
    return "Batch Record";
}
exports.flowBatchRecordGetGenericLabel = flowBatchRecordGetGenericLabel;
function flowBatchRecordGetFlowName() {
    return "FlowBatchRecord";
}
exports.flowBatchRecordGetFlowName = flowBatchRecordGetFlowName;
function flowBatchRecordValidate(flow) {
    let errors = [];
    let warnings = [];
    const labelBatch = "Batch";
    const labelFromPallet = "first pallet id";
    const labelToPallet = "last pallet id";
    const labelProperHooders = "Proper Hooders";
    const labelProperPackage = "Proper package and label";
    const labelProperStenciling = "Proper and legible stenciling";
    const labelProperDate = "Proper date on package";
    const labelProperPallets = "Proper pallets and slipsheet";
    const labelNoLeakage = "Leakage";
    const labelNoDamage = "Damage";
    const labelPalletsInBin = "Pallets in bin and scanned";
    const labelNoMetalDetectorRejects = "No metal detector rejects";
    const labelSamplesToLab = "Samples to lab";
    warnings = (0, index_1.validationMissingValue)(warnings, flow.batch, "batch", labelBatch);
    warnings = (0, index_1.validationMissingValue)(warnings, flow.fromPallet, "fromPallet", labelFromPallet);
    warnings = (0, index_1.validationMissingValue)(warnings, flow.toPallet, "toPallet", labelToPallet);
    warnings = (0, index_1.validationMissingValue)(warnings, flow.properHooders, "properHooders", labelProperHooders);
    warnings = (0, index_1.validationMissingValue)(warnings, flow.properPackage, "properPackage", labelProperPackage);
    warnings = (0, index_1.validationMissingValue)(warnings, flow.properStenciling, "properStenciling", labelProperStenciling);
    warnings = (0, index_1.validationMissingValue)(warnings, flow.properDate, "properDate", labelProperDate);
    warnings = (0, index_1.validationMissingValue)(warnings, flow.properPallets, "properPallets", labelProperPallets);
    warnings = (0, index_1.validationMissingValue)(warnings, flow.noLeakage, "noLeakage", labelNoLeakage);
    warnings = (0, index_1.validationMissingValue)(warnings, flow.noDamage, "noDamage", labelNoDamage);
    warnings = (0, index_1.validationMissingValue)(warnings, flow.palletsInBin, "palletsInBin", labelPalletsInBin);
    warnings = (0, index_1.validationMissingValue)(warnings, flow.noMetalDetectorRejects, "noMetalDetectorRejects", labelNoMetalDetectorRejects);
    warnings = (0, index_1.validationMissingValue)(warnings, flow.samplesToLab, "samplesToLab", labelSamplesToLab);
    warnings = (0, index_1.validationNAValue)(warnings, flow.properHooders, true, "properHooders", labelProperHooders, "Pass", "Fail");
    warnings = (0, index_1.validationNAValue)(warnings, flow.properPackage, true, "properPackage", labelProperPackage, "Pass", "Fail");
    warnings = (0, index_1.validationNAValue)(warnings, flow.properStenciling, true, "properStenciling", labelProperStenciling, "Pass", "Fail");
    warnings = (0, index_1.validationNAValue)(warnings, flow.properDate, true, "properDate", labelProperDate, "Pass", "Fail");
    warnings = (0, index_1.validationNAValue)(warnings, flow.properPallets, true, "properPallets", labelProperPallets, "Pass", "Fail");
    warnings = (0, index_1.validationNAValue)(warnings, flow.noLeakage, true, "noLeakage", labelNoLeakage, "Pass", "Fail");
    warnings = (0, index_1.validationNAValue)(warnings, flow.noDamage, true, "noDamage", labelNoDamage, "Pass", "Fail");
    warnings = (0, index_1.validationNAValue)(warnings, flow.palletsInBin, true, "palletsInBin", labelPalletsInBin, "Pass", "Fail");
    warnings = (0, index_1.validationNAValue)(warnings, flow.noMetalDetectorRejects, true, "noMetalDetectorRejects", labelNoMetalDetectorRejects, "Pass", "Fail");
    warnings = (0, index_1.validationNAValue)(warnings, flow.samplesToLab, true, "samplesToLab", labelSamplesToLab, "Pass", "Fail");
    if (flow.metalDetectorRejectsShouldBeFail) {
        if (flow.noMetalDetectorRejects != null) {
            if (flow.noMetalDetectorRejects !== index_1.YesNoNA.No) {
                warnings.push({
                    property: "noMetalDetectorRejects",
                    message: `${labelNoMetalDetectorRejects} should be 'Fail' as there has been a metal detection`,
                });
            }
        }
    }
    if (flow.fromPallet && flow.toPallet && flow.fromPallet > flow.toPallet) {
        warnings.push({
            property: "toPallet",
            message: "The last pallet id must be greater or equal than the first",
        });
    }
    if (!flow.lt24Files || flow.lt24Files.length === 0) {
        warnings.push({
            property: "lt24Files",
            message: `LT24 File is missing`,
        });
    }
    return {
        errors,
        warnings,
    };
}
exports.flowBatchRecordValidate = flowBatchRecordValidate;
