import { createMachine } from "xstate";

export const machine = createMachine({
  id: "prayon",
  initial: "checkedOut",
  context: {
    count: 0,
  },
  states: {
    checkedOut: {
      on: { CHECKIN: "checkedIn" },
    },
    checkedIn: {
      on: { CHECKOUT: "checkedOut" },
    },
  },
  predictableActionArguments: true,
});
