import { Form, Modal, notification, Row, Button, Input, Popconfirm, Select } from "antd";
import React, { useState } from "react";
import useBatch from "../../../hooks/useBatch";
import useMaterials from "../../../hooks/useMaterials";
import getAPI from "../../../services/api";
import { BatchType } from "shared/interfaces";

const { TextArea } = Input;

const BatchEditModal: React.FC<{
  batchId: number | undefined;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onEditSuccess?: () => void;
}> = ({ open, setOpen, batchId, onEditSuccess }) => {
  const { data: batch, refetch: refetchBatch } = useBatch(batchId);
  const { data: materials } = useMaterials({ rework: batch?.type === BatchType.rework });

  const [form] = Form.useForm();

  const [modifiedFields, setModifiedFields] = useState<string[]>([]);

  const handleFieldsChange = (_, allFields) => {
    const modifiedFieldNames = allFields.filter((field) => field.touched).map((field) => field.name[0]);
    setModifiedFields(modifiedFieldNames);
  };

  const resetModal = () => {
    form.resetFields();
    setModifiedFields([]);
  };

  const submit = async (values: any) => {
    try {
      const api = await getAPI();

      const updatedBatch: any = modifiedFields.reduce((acc, fieldName) => {
        acc[fieldName] = values[fieldName];
        return acc;
      }, {});

      if ("materialId" in updatedBatch) {
        updatedBatch.material = { id: updatedBatch.materialId as number };
        delete updatedBatch.materialId;
      }

      if (Object.keys(updatedBatch).length === 0) {
        notification.info({
          message: "No changes",
          description: "No changes were made.",
        });
        return;
      }

      await api.put(`/batches/${batchId}/edit`, { data: updatedBatch });

      notification.success({
        message: "Success",
        description: "The batch was successfully updated",
      });
      await refetchBatch();
      if (onEditSuccess) onEditSuccess();
      setOpen(false);
      form.resetFields();
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: `${error.message}`,
      });
    }
  };

  const customFooter = () => (
    <Row justify="end">
      <Button
        key="cancel"
        onClick={() => {
          setOpen(false);
          form.resetFields();
        }}
      >
        Cancel
      </Button>
      <Popconfirm
        title="Save your change"
        description="Make such edits only if you need to correct a mistake."
        onConfirm={async () => {
          await form.submit();
          setOpen(false);
          form.resetFields();
        }}
        okText="Save"
        cancelText="Cancel"
      >
        <Button key="submit" type="primary">
          Update
        </Button>
      </Popconfirm>
    </Row>
  );

  return (
    <>
      {batch && materials && materials.length > 0 && (
        <Modal
          title="Batch edit"
          open={open}
          onCancel={() => {
            setOpen(false);
            form.resetFields();
          }}
          afterClose={() => resetModal()}
          maskClosable={false}
          destroyOnClose
          width={600}
          footer={customFooter()}
        >
          <Row justify="center">
            <Form
              key={batchId}
              form={form}
              name="basic"
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 16 }}
              style={{ width: "100%" }}
              onFinish={submit}
              autoComplete="off"
              onFieldsChange={handleFieldsChange}
            >
              <Form.Item
                label={<>{modifiedFields.includes("materialId") && <span style={{ color: "red" }}>•&nbsp;&nbsp; </span>}Material</>}
                name="materialId"
              >
                <Select
                  showSearch
                  filterOption={(input, option) => (option?.label ?? "").toString().toLowerCase().includes(input.toLowerCase())}
                  onDropdownVisibleChange={(open) => {
                    return !open;
                  }}
                  options={materials.map((material) => ({
                    label: material.number + (material.description ? " - " + material.description : ""),
                    value: material.id,
                  }))}
                  defaultValue={batch.material?.id}
                />
              </Form.Item>
              <Form.Item
                label={<>{modifiedFields.includes("sapProcessOrder") && <span style={{ color: "red" }}>•&nbsp;&nbsp; </span>}Process order</>}
                name="sapProcessOrder"
                initialValue={batch.sapProcessOrder}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={<>{modifiedFields.includes("batchNumber") && <span style={{ color: "red" }}>•&nbsp;&nbsp; </span>}Batch number</>}
                name="batchNumber"
                initialValue={batch.batchNumber}
              >
                <Input
                  onChange={(e) => {
                    const capitalizedValue = e.target.value.toUpperCase();
                    form.setFieldsValue({ batchNumber: capitalizedValue });
                  }}
                />
              </Form.Item>
              <Form.Item
                label={<>{modifiedFields.includes("comment") && <span style={{ color: "red" }}>•&nbsp;&nbsp; </span>}Comment</>}
                name="comment"
                initialValue={batch.comment}
              >
                <TextArea rows={4} />
              </Form.Item>
            </Form>
          </Row>
        </Modal>
      )}
    </>
  );
};

export default BatchEditModal;
