import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { getAuthState } from "./auth";

const customParamsSerializer = (params: Record<string, any>): string => {
  const buildQueryString = (obj: any, parentKey?: string): string => {
    const parts: string[] = [];

    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const value = obj[key];
        const fullKey = parentKey ? `${parentKey}[${key}]` : key;

        if (typeof value === "object" && value !== null) {
          parts.push(buildQueryString(value, fullKey));
        } else {
          parts.push(`${fullKey}=${value}`);
        }
      }
    }

    const response = parts.join("&").replace(/&&/g, "&");

    if (response.endsWith("&")) {
      return response.slice(0, -1);
    }
    return response;
  };

  return buildQueryString(params);
};

const getAPI = async (config: AxiosRequestConfig = {}): Promise<AxiosInstance> => {
  const authState = await getAuthState();
  const token = authState?.jwt || "";

  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    ...config,
    headers: {
      "Content-Type": "application/json",
      Authorization: token && `Bearer ${token}`,
      ...(config.headers || {}),
    },
    paramsSerializer: customParamsSerializer, // Use the custom serializer
  });

  // if there is an error, the message is data.error.message
  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response?.data?.error?.message) {
        error.message = error.response.data.error.message;
      }
      return Promise.reject(error);
    }
  );

  return instance;
};

export default getAPI;
