import { useQuery, UseQueryResult } from "@tanstack/react-query";
import getAPI from "../services/api";
import { IReasonForMovement } from "shared/interfaces";
import { useAppSelector } from "./redux";

export const useReasonsForMovement = (): UseQueryResult<IReasonForMovement[]> => {
  const area = useAppSelector((state) => state.shift.area);
  return useQuery(["reasons-for-movement"], async () => {
    const api = await getAPI();
    const { data } = await api.get("/reasons-for-movement", { params: { filters: { area } } });
    return data.data
      .map(({ id, attributes }) => ({
        id,
        ...attributes,
      }))
      .sort((a, b) => a.reason.localeCompare(b.reason));
  });
};

export default useReasonsForMovement;
