import React, { useState } from "react";
import { Button, Input } from "antd";
import { ScanOutlined } from "@ant-design/icons";
import Code128ScannerModal from "./Code128ScannerModal/Code128ScannerModal";

const Code128Input: React.FC<{
  value?: number | undefined;
  onChange?: (value: string | undefined) => void;
}> = ({ value, onChange }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleScan = (code) => {
    console.log("Handling scan: ", code);
    if (onChange) {
      onChange(code);
    }
    setIsModalOpen(false);
  };

  const handleInputChange = (e) => {
    if (onChange) {
      onChange(e.target.value.toUpperCase());
    }
  };

  return (
    <div style={{ display: "flex", gap: 8, justifyContent: "space-between", alignItems: "stretch", position: "relative" }}>
      <Input value={value} onChange={handleInputChange} />
      {/*Not sure why, but stretch will not work directly on the Button component so wrapping it in a div*/}
      <div>
        <Button icon={<ScanOutlined />} onClick={() => setIsModalOpen(true)} style={{ minWidth: 35, height: "100%" }} />
      </div>
      <Code128ScannerModal open={isModalOpen} setOpen={setIsModalOpen} setCode={handleScan} />
    </div>
  );
};

export default Code128Input;
