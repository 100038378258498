import { Modal, Descriptions } from "antd";
import React from "react";
import useBatch from "../../hooks/useBatch";
import { BatchType } from "shared/interfaces";

const MaterialDetailsModal: React.FC<{
  batchId: number | undefined;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ open, setOpen, batchId }) => {
  const { data: batch } = useBatch(batchId);

  return (
    <>
      {batch && (
        <Modal
          title="Material Details"
          footer={null}
          open={open}
          onCancel={() => {
            setOpen(false);
          }}
          maskClosable={false}
          destroyOnClose
          width={600}
        >
          <Descriptions className="batch-descriptions" bordered={false}>
            <Descriptions.Item style={{ display: "block", height: "min-content" }} label="Material">
              {batch.material?.number ?? ""}
            </Descriptions.Item>
            <Descriptions.Item style={{ display: "block", height: "min-content" }} label="Material description">
              {batch.material?.description ?? ""}
            </Descriptions.Item>
            <Descriptions.Item style={{ display: "block", height: "min-content" }} label="Product">
              {batch.material?.product ?? ""}
            </Descriptions.Item>
            <Descriptions.Item style={{ display: "block", height: "min-content" }} label="Product Code">
              {batch.material?.productCode ?? ""}
            </Descriptions.Item>
            {batch.type === BatchType.regular && (
              <Descriptions.Item style={{ display: "block", height: "min-content" }} label="Expiry format">
                {batch.material?.expiryFormat ?? ""}
              </Descriptions.Item>
            )}
            <Descriptions.Item style={{ display: "block", height: "min-content" }} label="Label Requirements">
              {batch.material?.labelRequirements ?? ""}
            </Descriptions.Item>
            <Descriptions.Item style={{ display: "block", height: "min-content" }} label="Package Description">
              {batch.material?.packageDescr ?? ""}
            </Descriptions.Item>
            <Descriptions.Item style={{ display: "block", height: "min-content" }} label="Package Reference">
              {batch.material?.packageRef ?? ""}
            </Descriptions.Item>
            <Descriptions.Item style={{ display: "block", height: "min-content" }} label="Pallet Description">
              {batch.material?.palletDescr ?? ""}
            </Descriptions.Item>
            <Descriptions.Item style={{ display: "block", height: "min-content" }} label="Pallet Reference">
              {batch.material?.palletRef ?? ""}
            </Descriptions.Item>
            <Descriptions.Item style={{ display: "block", height: "min-content" }} label="Package Type">
              {batch.material?.pkgType ?? ""}
            </Descriptions.Item>
            <Descriptions.Item style={{ display: "block", height: "min-content" }} label="Shelf Life">
              {batch.material?.shelfLife ?? ""}
            </Descriptions.Item>
            <Descriptions.Item style={{ display: "block", height: "min-content" }} label="Slip Sheet Description">
              {batch.material?.slipSheetDescr ?? ""}
            </Descriptions.Item>
            <Descriptions.Item style={{ display: "block", height: "min-content" }} label="Slip Sheet Reference">
              {batch.material?.slipSheetRef ?? ""}
            </Descriptions.Item>
            <Descriptions.Item style={{ display: "block", height: "min-content" }} label="Stencil Requirements">
              {batch.material?.stencilRequirements ?? ""}
            </Descriptions.Item>
            <Descriptions.Item style={{ display: "block", height: "min-content" }} label="Unit Weight">
              {batch.material?.unitWeight ?? ""}
            </Descriptions.Item>
            <Descriptions.Item style={{ display: "block", height: "min-content" }} label="Units Per Pallet">
              {batch.material?.unitsPerPallet ?? ""}
            </Descriptions.Item>
            <Descriptions.Item style={{ display: "block", height: "min-content" }} label="Weight Unit">
              {batch.material?.weightUnit ?? ""}
            </Descriptions.Item>
            <Descriptions.Item style={{ display: "block", height: "min-content" }} label="Other Batch Record Info">
              {batch.material?.otherBatchRecordInfo ?? ""}
            </Descriptions.Item>
          </Descriptions>
        </Modal>
      )}
    </>
  );
};

export default MaterialDetailsModal;
