import { Modal } from "antd";
import React from "react";
import NewReworkForm from "./NewReworkForm";
import { IShift } from "shared/interfaces";

const NewReworkModal: React.FC<{
  shiftId: NonNullable<IShift["id"]>;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ shiftId, open, setOpen }) => {
  return (
    <Modal
      open={open}
      title={"Create new rework"}
      maskClosable={false}
      footer={null}
      destroyOnClose={true}
      width={1250}
      onCancel={() => setOpen(false)}
    >
      <NewReworkForm onCancel={() => setOpen(false)} onSuccess={() => setOpen(false)} shiftId={shiftId} />
    </Modal>
  );
};

export default NewReworkModal;
