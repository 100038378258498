import React, { useEffect, useState } from "react";
import { FloatButton } from "antd";
import { DownloadOutlined, LoadingOutlined } from "@ant-design/icons";

function triggerDownload(data, filename) {
  // Create a Blob from the data
  let blob = new Blob([data], { type: "text/csv;charset=utf-8;" });

  // Create an anchor element and set the URL to the blob
  let link = document.createElement("a");
  if (link.download !== undefined) {
    // feature detection
    // Browsers that support HTML5 download attribute
    let url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    link.style.visibility = "hidden";

    // Append to the document and trigger the download
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  }
}

function CSVDownloadButton({ fetchDataConfig, queryHook, filename }) {
  const [enabled, setEnabled] = useState(false);
  const { data, isLoading } = queryHook({ ...fetchDataConfig, csv: true, enabled });

  useEffect(() => {
    if (data && enabled) {
      triggerDownload(data, filename);
      setEnabled(false);
    }
  }, [data, enabled]);

  return (
    <FloatButton
      type={"primary"}
      style={{
        bottom: 80,
        right: 30,
        height: 50,
        width: 50,
      }}
      icon={enabled && isLoading ? <LoadingOutlined /> : <DownloadOutlined />}
      onClick={async () => {
        if (enabled) return;
        await setEnabled(true);
      }}
    />
  );
}

export default CSVDownloadButton;
