import { useQuery, UseQueryResult } from "@tanstack/react-query";
import getAPI from "../services/api";

export const useAssetUrl = (id): UseQueryResult<string> => {
  return useQuery(
    ["asset", id],
    async () => {
      const api = await getAPI();
      const { data } = await api.get(`/upload/files/${id}`);
      return `${process.env.REACT_APP_STORAGE_URL ?? ""}${data.url}`;
    },
    { enabled: !!id }
  );
};

export default useAssetUrl;
