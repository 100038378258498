import React, { useEffect, useRef, useState } from "react";
import { Button, Modal, Space, Spin } from "antd";
import "./PhotoCaptureModal.css";
import { CameraOutlined } from "@ant-design/icons";
import Webcam from "react-webcam";
import useUpload from "../../../hooks/useUpload";

function CapturePhotoModal({ open, setOpen, setAsset }) {
  const webcamRef = useRef(null);
  const [url, setUrl] = useState(null);

  const [isWebcamLoading, setIsWebcamLoading] = useState(true);

  useEffect(() => {
    setUrl(null);
    if (!open) setIsWebcamLoading(true);
  }, [open]);

  const { upload } = useUpload();

  const handleCaptureClick = async () => {
    // @ts-ignore
    const imageSrc = webcamRef?.current?.getScreenshot();
    setUrl(imageSrc);
    const resp = await fetch(imageSrc);
    const blob = await resp.blob();
    const file = new File([blob], new Date().getTime().toString(), {
      type: "image/jpeg",
    });
    const asset = { rawFile: file };

    const { data } = await upload(asset);
    const [picture] = data;
    setAsset(picture);
  };

  return (
    <Modal
      className={"photo-capture-modal"}
      destroyOnClose={true}
      footer={null}
      styles={{ body: { position: "relative", minWidth: 640 } }}
      title={
        <Space>
          <CameraOutlined />
          Take a picture
        </Space>
      }
      open={open}
      width={"690px"}
      onCancel={() => setOpen(false)}
    >
      <Spin spinning={true} style={{ position: "absolute", height: 64, marginTop: 16, width: "100%", justifyContent: "center" }} />
      <div style={{ position: "relative" }}>
        {
          // @ts-ignore
          <Webcam
            ref={webcamRef}
            audio={false}
            screenshotFormat="image/jpeg"
            videoConstraints={{
              width: 900,
              height: 600,
              facingMode: "environment",
            }}
            onUserMedia={() => {
              setIsWebcamLoading(false);
            }}
          />
        }
      </div>
      {url && !isWebcamLoading && (
        <div style={{ position: "absolute", top: 0, left: 0 }}>
          <img id="screenshot" src={url} alt="Screenshot" />
        </div>
      )}
      <div className="photo-capture-button-container">
        <Button
          style={{
            backgroundColor: "white",
            borderRadius: 100,
            height: 60,
            width: 60,
            margin: 15,
            outline: "white 5px solid",
            border: "solid 2px black",
          }}
          onClick={handleCaptureClick}
        ></Button>
      </div>
    </Modal>
  );
}

export default CapturePhotoModal;
