import React from "react";
import { Layout } from "antd";

import UserMenu from "../UserMenu/UserMenu";
import "./HeaderMenu.css";

const { Header } = Layout;

const styles = {
  header: {
    background: "#fff",
    padding: "0 0  0 20px",
    display: "flex",
    boxShadow: "0 3px 6px lightgrey",
    zIndex: 4,
    clipPath: "inset(0px 0px -6px 0px)",
    justifyContent: "space-between",
  },
  title: {
    margin: 0,
  },
};

const HeaderMenu: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  return (
    <Header style={styles.header} className={"header-menu"}>
      <div className="header-menu-left">{children}</div>
      <div className="header-menu-right" style={{ gap: 20 }}>
        <UserMenu />
      </div>
    </Header>
  );
};

export default HeaderMenu;
