"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.flowDustCollectorImmutableValuesWereNotChanged = exports.flowDustCollectorValidate = exports.flowDustCollectorGetFlowName = exports.flowDustCollectorGetGenericLabel = exports.flowDustCollectorGetLabel = void 0;
const index_1 = require("./index");
function flowDustCollectorGetLabel() {
    return flowDustCollectorGetGenericLabel();
}
exports.flowDustCollectorGetLabel = flowDustCollectorGetLabel;
function flowDustCollectorGetGenericLabel() {
    return "Dust Collector";
}
exports.flowDustCollectorGetGenericLabel = flowDustCollectorGetGenericLabel;
function flowDustCollectorGetFlowName(flow) {
    var _a, _b;
    return `FlowDustCollector${(_b = (_a = flow.shift) === null || _a === void 0 ? void 0 : _a.product) !== null && _b !== void 0 ? _b : index_1.Product.Ca}`;
}
exports.flowDustCollectorGetFlowName = flowDustCollectorGetFlowName;
function flowDustCollectorValidate(flow) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    let errors = [];
    let warnings = [];
    warnings = (0, index_1.validationMissingValue)(warnings, flow.readings, "readings", "readings");
    if (flow.readings) {
        for (const reading of flow.readings) {
            if (!reading.down) {
                if (reading.pressureReading == null) {
                    warnings.push({
                        property: "readings",
                        message: `Pressure reading of ${(_a = reading.dustCollector) === null || _a === void 0 ? void 0 : _a.reference} is missing`,
                        subPropertyId: (_b = reading.dustCollector) === null || _b === void 0 ? void 0 : _b.id,
                    });
                }
                else {
                    if (((_c = reading.dustCollector) === null || _c === void 0 ? void 0 : _c.minPressure) == null || ((_d = reading.dustCollector) === null || _d === void 0 ? void 0 : _d.maxPressure) == null) {
                        warnings.push({
                            property: "readings",
                            message: `minPressure or maxPressure is missing in request`,
                            subPropertyId: (_e = reading.dustCollector) === null || _e === void 0 ? void 0 : _e.id,
                        });
                    }
                    else {
                        if (reading.pressureReading < reading.dustCollector.minPressure || reading.pressureReading > reading.dustCollector.maxPressure) {
                            warnings.push({
                                property: "readings",
                                message: `Pressure reading of ${(_f = reading.dustCollector) === null || _f === void 0 ? void 0 : _f.reference} should be between ${reading.dustCollector.minPressure} and ${reading.dustCollector.maxPressure}`,
                                subPropertyId: (_g = reading.dustCollector) === null || _g === void 0 ? void 0 : _g.id,
                            });
                        }
                    }
                }
                if (flow.includeOpacity) {
                    if (reading.opacityVE == null) {
                        warnings.push({
                            property: "readings",
                            message: `Opacity of ${(_h = reading.dustCollector) === null || _h === void 0 ? void 0 : _h.reference} is missing`,
                            subPropertyId: (_j = reading.dustCollector) === null || _j === void 0 ? void 0 : _j.id,
                        });
                    }
                    if (reading.opacityVE === index_1.YesNoNA.Yes) {
                        warnings.push({
                            property: "readings",
                            message: `Opacity of ${(_k = reading.dustCollector) === null || _k === void 0 ? void 0 : _k.reference} has VE`,
                            subPropertyId: (_l = reading.dustCollector) === null || _l === void 0 ? void 0 : _l.id,
                        });
                    }
                }
            }
        }
    }
    return {
        errors,
        warnings,
    };
}
exports.flowDustCollectorValidate = flowDustCollectorValidate;
function flowDustCollectorImmutableValuesWereNotChanged(flow1, flow2) {
    if (!flow1.readings || !flow2.readings) {
        return false;
    }
    if (flow1.readings.length !== flow2.readings.length) {
        return false;
    }
    const ids1 = flow1.readings.map((reading) => { var _a; return (_a = reading.dustCollector) === null || _a === void 0 ? void 0 : _a.id; }).sort();
    const ids2 = flow2.readings.map((reading) => { var _a; return (_a = reading.dustCollector) === null || _a === void 0 ? void 0 : _a.id; }).sort();
    for (let i = 0; i < ids1.length; i++) {
        if (ids1[i] !== ids2[i]) {
            return false;
        }
    }
    return true;
}
exports.flowDustCollectorImmutableValuesWereNotChanged = flowDustCollectorImmutableValuesWereNotChanged;
