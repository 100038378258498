import { useQuery, UseQueryResult } from "@tanstack/react-query";
import getAPI from "../services/api";
import { IFlowBatchRecord } from "shared/interfaces";

export const useBatchRecords = (filters): UseQueryResult<IFlowBatchRecord[]> => {
  const { batchId, latest } = filters;

  return useQuery(["sources", batchId, latest], async () => {
    const api = await getAPI();
    if (batchId === undefined || latest === undefined) return [];
    const { data } = await api.get(`/flows/flowBatchRecordGetForOneBatch/${batchId}`);
    return data || [];
  });
};

export default useBatchRecords;
