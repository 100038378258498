import { Button, Card, Col, Descriptions, Form, Input, notification, Row, Select, Space, Spin } from "antd";
import { CheckCircleFilled, CloseOutlined, SendOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import {
  IMaterial,
  IBlendPossible,
  ISourcePossible,
  PkgType,
  Product,
  IShift,
  batchValidate,
  IBatchLine,
  batchLineValidate,
  BatchLineType,
} from "shared/interfaces";
import { isTemporarySS } from "shared/materialRules";
import getAPI from "../../services/api";
import useBlends from "../../hooks/useBlends";
import useMaterials from "../../hooks/useMaterials";
import useSources from "../../hooks/useSources";
import useShift from "../../hooks/useShift";
import useBatchLatestUsedValues from "../../hooks/useBatchLatestUsedValues";
import Code128Input from "../UI/Code128Input";
import BatchNumberInput from "../UI/BatchNumberInput";

const { TextArea } = Input;

const NewBatchForm: React.FC<{
  shiftId: NonNullable<IShift["id"]>;
  onSuccess: () => void;
  onCancel: () => void;
}> = ({ shiftId, onSuccess, onCancel }) => {
  const { data: shift, refetch: refetchShift } = useShift(shiftId);
  const [materialId, setMaterialId] = useState<IMaterial["id"]>();
  const { data: sources } = useSources({ product: shift?.product, materialId });
  const [sourceId, setSourceId] = useState<ISourcePossible["id"] | undefined>(undefined);
  const { data: blends } = useBlends({ product: shift?.product, sourceId: sourceId });
  const [blendId, setBlendId] = useState<IBlendPossible["id"] | undefined>(undefined);
  const [initialFormRendered, setInitialFormRendered] = useState<boolean>(false);

  const { data: materials } = useMaterials({ product: shift?.product });
  const [form] = Form.useForm();
  const [hasWarnings, setHasWarnings] = useState<boolean>(false);
  const { data: latestUsedValues, isFetching: isFetchingLatestUsedValues } = useBatchLatestUsedValues();

  const [pkgType, setPkgType] = useState<PkgType>();
  if (latestUsedValues) latestUsedValues.materialId = latestUsedValues.materialId || materials?.[0]?.id;

  useEffect(() => {
    // If the user has not selected a material, select the first material in the list
    // Get the package type from the material
    let defaultMaterial = materials && materials.find((material) => material.id === latestUsedValues?.materialId);
    if (pkgType && defaultMaterial?.pkgType !== pkgType) {
      defaultMaterial = materials?.filter((material) => material.pkgType === pkgType)[0];
    } else if (!pkgType) {
      defaultMaterial = materials?.[0];
    }

    if (latestUsedValues && !initialFormRendered) {
      setPkgType(defaultMaterial?.pkgType);
      setMaterialId(defaultMaterial?.id);

      form.setFieldValue("materialId", defaultMaterial?.id);
      form.setFieldValue("pkgType", defaultMaterial?.pkgType);

      setSourceId(latestUsedValues?.sourceId);
      setBlendId(latestUsedValues?.blendId);
    }

    if (hasWarnings) {
      const fieldIssues = form.getFieldsError();
      if (fieldIssues.reduce((acc, { warnings }) => acc + warnings.length, 0) === 0) {
        setHasWarnings(false);
      }
    }

    if (materials && blends && sources && materialId && latestUsedValues && sourceId && blendId && !initialFormRendered) {
      setInitialFormRendered(true);
    }
  }, [
    latestUsedValues,
    sources,
    materials,
    blends,
    sourceId,
    blendId,
    pkgType,
    isFetchingLatestUsedValues,
    initialFormRendered,
    form,
    hasWarnings,
    materialId,
  ]);

  const material = materials?.find((m) => m.id === materialId);
  if (((!materials || !blends || !sources || !materialId || !latestUsedValues) && !initialFormRendered) || !material) return <Spin spinning={true} />;

  return (
    <Form
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      labelAlign={"left"}
      form={form}
      initialValues={latestUsedValues}
      onFinish={async (values) => {
        values.batchNumber = !values.batchNumber ? "" : values.batchNumber;

        const api = await getAPI();
        let similarBatchExists = false;
        try {
          const resp = await api.get(`/shifts/${shiftId}/checkExistingOpenBatchesInShift/${pkgType}`);
          similarBatchExists = resp.data;
        } catch (err) {
          notification.error({
            message: "Error",
            description: "There was an error checking for existing batches",
          });
        }

        let { warnings } = await batchValidate(values);

        const batchLine: IBatchLine = {
          type: BatchLineType.regular,
          source: sources?.find((s) => s.id === values.sourceId),
          blend: blends?.find((b) => b.id === values.blendId),
          pkgPalletId: form.getFieldValue("pkgPalletId") || "",
          blendNumber: values.blendNumber,
        };

        const { warnings: batchLineWarnings } = await batchLineValidate(batchLine, material);
        warnings = warnings.concat(batchLineWarnings);

        if ((warnings.length > 0 || (similarBatchExists && !isTemporarySS(material.number ?? 0))) && !hasWarnings) {
          const fieldWarnings: { name: string; warnings: string[] }[] = [];
          if (!isTemporarySS(material.number ?? 0) && similarBatchExists) {
            fieldWarnings.push({
              name: "pkgType",
              warnings: [`There is already ${pkgType === PkgType.Bag ? "a" : "an"} ${pkgType} open batch`],
            });
            notification.warning({
              duration: null,
              message: "Warning",
              description: `There is already ${pkgType === PkgType.Bag ? "a" : "an"} ${pkgType} open batch`,
            });
          }

          fieldWarnings.push(
            ...warnings.map((warning) => ({
              name: replaceNAProperties(warning.property),
              warnings: [warning.message],
            }))
          );

          form.setFields(fieldWarnings);
          setHasWarnings(true);
          return;
        } else {
          setHasWarnings(false);
        }

        await api
          .post("/batches", {
            data: values,
          })
          .then((res) => {
            if (res.status === 200) {
              notification.success({
                message: "Batch created",
              });
              setHasWarnings(false);
              onSuccess && onSuccess();
            }
          })
          .then(() => {
            refetchShift();
          })
          .catch((err) => {
            notification.error({
              message: "Error creating batch",
              description: err.message,
            });
          });
      }}
      onFinishFailed={async ({ values }) => {
        // we reset the warnings if the user has not set the comment
        // const blendPossible = blends?.find((b) => b.id === values.blendId)?.possible;
        // const sourcePossible = sources?.find((s) => s.id === values.sourceId)?.possible;

        let { warnings } = await batchValidate(values);

        const batchLine: IBatchLine = {
          type: BatchLineType.regular,
          source: sources?.find((s) => s.id === values.sourceId),
          blend: blends?.find((b) => b.id === values.blendId),
          pkgPalletId: form.getFieldValue("pkgPalletId") || "",
          blendNumber: values.blendNumber,
        };

        const { warnings: batchLineWarnings } = await batchLineValidate(batchLine, material);
        warnings = warnings.concat(batchLineWarnings);

        if (warnings.length > 0) {
          !hasWarnings && setHasWarnings(true);
          const fieldWarnings: { name: string; warnings: string[] }[] = [];

          fieldWarnings.push(
            ...warnings.map((warning) => ({
              name: replaceNAProperties(warning.property),
              warnings: [warning.message],
            }))
          );

          form.setFields(fieldWarnings);
        }
      }}
      onValuesChange={async (changedValues, allValues) => {
        if (changedValues.pkgType) {
          setPkgType(changedValues.pkgType);
        }

        if (sources && changedValues.sourceId) {
          setSourceId(changedValues.sourceId);
        }

        if (changedValues.blendId) {
          setBlendId(changedValues.blendId);
        }

        if (materials && changedValues.materialId) {
          setMaterialId(changedValues.materialId);
        }

        // reset warnings
        Object.keys(allValues).forEach((key) => {
          form.setFields([
            {
              name: key,
              warnings: [],
            },
          ]);
        });
        // if we have errors (or rather warnings), we set them in the form, the user can still submit)
        let { warnings } = await batchValidate(allValues);

        const batchLine: IBatchLine = {
          type: BatchLineType.regular,
          source: sources?.find((s) => s.id === allValues.sourceId),
          blend: blends?.find((b) => b.id === allValues.blendId),
          pkgPalletId: form.getFieldValue("pkgPalletId") || "",
          blendNumber: allValues.blendNumber,
        };

        const { warnings: batchLineWarnings } = await batchLineValidate(batchLine, material);
        warnings = warnings.concat(batchLineWarnings);

        if (warnings.length > 0 && hasWarnings) {
          const fieldWarnings: { name: string; warnings: string[] }[] = [];

          fieldWarnings.push(
            ...warnings.map((warning) => ({
              name: replaceNAProperties(warning.property),
              warnings: [warning.message],
            }))
          );
          form.setFields(fieldWarnings);
        } else if (warnings.length === 0) {
          setHasWarnings(false);
          form.setFields([
            {
              name: "comment",
              errors: [],
            },
          ]);
        }
      }}
    >
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Pkg type" initialValue={material?.pkgType} name="pkgType">
            <Select
              options={[
                { label: "SS", value: "SS" },
                { label: "Bag", value: "Bag" },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Material"
            name="materialId"
            rules={[
              {
                required: true,
                message: "Please select a material",
              },
            ]}
          >
            <Select
              showSearch
              filterOption={(input, option) => (option?.label ?? "").toString().toLowerCase().includes(input.toLowerCase())}
              onDropdownVisibleChange={(open) => {
                return !open;
              }}
              options={materials
                ?.filter((m) => m.product === shift?.product)
                .filter((m) => (pkgType ? m.pkgType === pkgType : true))
                .map((material) => ({
                  label: material.number + (material.description ? " - " + material.description : ""),
                  value: material.id,
                }))}
            />
          </Form.Item>
          <Form.Item name="shiftId" hidden initialValue={shiftId}>
            <Input hidden />
          </Form.Item>
          <Form.Item name="sapProcessOrder" label="Process Order">
            <Input />
          </Form.Item>
          <Form.Item label="Batch number" name="batchNumber">
            <BatchNumberInput />
          </Form.Item>
          <Form.Item name="sourceId" label="Source">
            <Select
              showSearch
              filterOption={(input, option) => (option?.reference ?? "").toLowerCase().includes(input.toLowerCase())}
              onDropdownVisibleChange={(open) => {
                return !open;
              }}
              options={[
                { label: "", value: null, reference: "" },
                ...(sources || []).map(({ id, reference, possible }) => {
                  return {
                    value: id,
                    reference,
                    label: (
                      <>
                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                          <span>{reference}</span>
                          {possible && <CheckCircleFilled style={{ fontSize: "0.7rem" }} />}
                        </div>
                      </>
                    ),
                  };
                }),
              ]}
            />
          </Form.Item>

          <Form.Item label="Path" name="blendId">
            <Select
              showSearch
              filterOption={(input, option) => (option?.reference ?? "").toLowerCase().includes(input.toLowerCase())}
              onDropdownVisibleChange={(open) => {
                return !open;
              }}
              options={[
                { label: "", value: null, reference: "" },
                ...(blends || []).map(({ id, reference, possible }) => {
                  return {
                    value: id,
                    reference,
                    label: (
                      <>
                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                          <span>{reference}</span>
                          {possible && <CheckCircleFilled style={{ fontSize: "0.7rem" }} />}
                        </div>
                      </>
                    ),
                  };
                }),
              ]}
            />
          </Form.Item>

          {shift?.product === Product.Ca && (
            <Form.Item label="Blend number" name="blendNumber">
              <Input />
            </Form.Item>
          )}
          <Form.Item label="Pkg supply pallet id" name="pkgPalletId">
            <Code128Input />
          </Form.Item>
          <Form.Item label="Comment" name="comment" rules={[{ required: hasWarnings, message: "Please add a comment to explain warnings" }]}>
            <TextArea />
          </Form.Item>
        </Col>
        <Col span={12}>
          {materialId && (
            // eslint-disable-next-line react/jsx-no-undef
            <Card>
              <Descriptions title="Batch Material Details" column={1} size="small">
                <Descriptions.Item label="Material description">{material.description}</Descriptions.Item>
                <Descriptions.Item label="Product">{material.product}</Descriptions.Item>
                <Descriptions.Item label="Product Code">{material.productCode}</Descriptions.Item>
                <Descriptions.Item label="Packaging Type">{material.pkgType}</Descriptions.Item>
                <Descriptions.Item label="Unit Weight">{material.unitWeight}</Descriptions.Item>
                <Descriptions.Item label="Units Per Pallet">{material.unitsPerPallet}</Descriptions.Item>
                <Descriptions.Item label="Weight Unit">{material.weightUnit}</Descriptions.Item>
                <Descriptions.Item label="Package Ref">{material.packageRef}</Descriptions.Item>
                <Descriptions.Item label="Package Description">{material.packageDescr}</Descriptions.Item>
                <Descriptions.Item label="Pallet Ref.">{material.palletRef}</Descriptions.Item>
                <Descriptions.Item label="Pallet Description">{material.palletDescr}</Descriptions.Item>
                <Descriptions.Item label="Slip Sheet Ref">{material.slipSheetRef}</Descriptions.Item>
                <Descriptions.Item label="Slip Sheet Description">{material.slipSheetDescr}</Descriptions.Item>
                <Descriptions.Item label="Expiry Format">{material.expiryFormat}</Descriptions.Item>
                <Descriptions.Item label="Label Requirements">{material.labelRequirements}</Descriptions.Item>
                <Descriptions.Item label="Stencil Requirements">{material.stencilRequirements}</Descriptions.Item>
                <Descriptions.Item label="Shelf Life">{material.shelfLife}</Descriptions.Item>
                <Descriptions.Item label="Other Batch Record Info">{material.otherBatchRecordInfo}</Descriptions.Item>
              </Descriptions>
            </Card>
          )}
        </Col>
        <div style={{ display: "flex", justifyContent: "right", width: "100%", marginTop: 8 }}>
          <Space>
            <Button
              size="large"
              icon={<CloseOutlined />}
              onClick={async () => {
                onCancel && onCancel();
              }}
            >
              Cancel
            </Button>
            <Button size="large" icon={<SendOutlined />} htmlType="submit" type="primary">
              {hasWarnings ? "Submit with warnings" : "Submit"}
            </Button>
          </Space>
        </div>
      </Row>
    </Form>
  );
};

export default NewBatchForm;

let replacements = {
  source: "sourceId",
  blend: "blendId",
};

function replaceNAProperties(property: string) {
  let replacedProperty = property;
  for (let [key, value] of Object.entries(replacements)) {
    let regex = new RegExp(`\\b${key}\\b`, "g"); // 'g' flag for global replacement
    replacedProperty = replacedProperty.replace(regex, value);
  }
  return replacedProperty;
}
