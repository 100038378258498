import { Segmented } from "antd";
import React from "react";
import { ZoomContext } from "../../App";

const FontSizePicker = () => {
  const [zoom, setZoom] = React.useContext(ZoomContext);
  const values = new Map<string, string>([
    ["S", "80%"],
    ["M", "100%"],
    ["L", "120%"],
    ["XL", "140%"],
  ]);

  const getLabelFromZoom = (zoomValue: string): string => {
    for (let [label, value] of values.entries()) {
      if (value === zoomValue) {
        return label;
      }
    }
    return "M"; // default to M if not found
  };

  return (
    <>
      <h4>Layout</h4>
      <Segmented
        defaultValue={getLabelFromZoom(zoom)}
        options={["S", "M", "L", "XL"]}
        onChange={(label) => {
          setZoom(values.get(label.toString()) as string);
        }}
      />
    </>
  );
};
//document.body.style.zoom = "80%";

export default FontSizePicker;
