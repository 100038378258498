import { Button, Form, Input, notification, Result, Spin, Popconfirm, Space } from "antd";
import React from "react";
import useFlow from "../../../hooks/useFlow";
import getAPI from "../../../services/api";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SaveOutlined, SendOutlined } from "@ant-design/icons";
import YesNoSwitch from "../../UI/YesNoSwitch";
import YesNoNASwitch from "../../UI/YesNoNASwitch";
import useShift from "../../../hooks/useShift";
import {
  batchHasOpenBatchLine,
  BatchType,
  BusinessRole,
  flowGetStatus,
  flowIsEditable,
  FlowType,
  flowValidateFE as flowValidate,
  IFlow,
  IFlowShiftCheckOut,
  Status,
} from "shared/interfaces";
import { useUser } from "../../../services/auth";
import FlowStatus from "../../UI/FlowStatus";
import { ZoomContext } from "../../../App";

const { TextArea } = Input;

interface ShiftCheckOutFlowFormProps {
  flowId: IFlow["id"];
  version?: IFlow["version"];
  readonly?: boolean;
  onSuccess: (flow) => void;
  setFormHasUnsavedChanges?: (hasUnsavedChanges: boolean) => void;
}

const ShiftCheckOutFlowForm: React.FC<ShiftCheckOutFlowFormProps> = ({
  flowId,
  version,
  readonly: propReadonly = false,
  onSuccess,
  setFormHasUnsavedChanges,
}) => {
  const user = useUser();
  const [readonly, setReadonly] = React.useState(propReadonly);
  const { data, isLoading: isFlowLoading, error: flowError, refetch: refetchFlow } = useFlow(flowId, version);
  const flow = data as IFlowShiftCheckOut;
  const [hasWarnings, setHasWarnings] = React.useState<boolean>(false);
  const initialValues = flow;
  let [searchParams] = useSearchParams();
  const supervisorMode = searchParams.get("mode") === "supervisor";
  const { data: shift } = useShift(flow?.shift?.id);
  const [zoom] = React.useContext(ZoomContext);
  const zoomRatio = parseInt(zoom) / 100;

  const [form] = Form.useForm();
  const navigate = useNavigate();

  React.useEffect(() => {
    const userIsReader = user?.businessRole === BusinessRole.Reader || user?.businessRole === BusinessRole.Quality;
    setReadonly((propReadonly || userIsReader) ?? false);
  }, [propReadonly, user]);

  if (isFlowLoading) {
    return <Spin>Loading...</Spin>;
  }

  if (flowError) {
    return (
      <Result
        status="500"
        title="Mmmmh... something went wrong."
        subTitle="More luck next time?"
        extra={
          <Button type="primary" onClick={() => navigate("/")}>
            Back Home
          </Button>
        }
      />
    );
  }

  if (isFlowLoading || !flow) {
    return <Spin>Loading...</Spin>;
  }

  return (
    <Form
      form={form}
      labelCol={{ span: zoomRatio > 1.0 ? 11 : 9 }}
      wrapperCol={{ span: 9 }}
      labelAlign={"left"}
      onFinish={async (values) => {
        const shiftCheckOutFlow: IFlowShiftCheckOut = {
          ...flow,
          ...values,
        };

        const { warnings } = await flowValidate(shiftCheckOutFlow, shift);

        if (warnings.length > 0 && !hasWarnings) {
          setHasWarnings(true);
          notification.warning({
            message: "The form has warnings",
            description: "Please check the form for warnings and enter a comment to submit despite warnings",
          });
          !hasWarnings && setHasWarnings(true);
          form.setFields(
            warnings.map((warning) => ({
              name: warning.property,
              warnings: warning.messages,
            }))
          );
          return;
        }

        let abort = false;
        shift?.batches?.forEach((batch) => {
          if (batch.type === BatchType.rework) return;
          if (batchHasOpenBatchLine(batch)) {
            notification.error({
              message: "Error",
              description: `Batch ${batch.batchNumber} has still an open set of pallets`,
            });
            abort = true;
          }
          let hasBatchRecord = false;
          shift.flows &&
            shift.flows.forEach((flow) => {
              if (flow.type === FlowType.batchRecord && flow.batch?.id === batch.id && flowGetStatus(flow) === Status.confirmed) {
                hasBatchRecord = true;
              }
            });
          if (shiftCheckOutFlow.allBatchRecordsSubmittedSuccessfully && !hasBatchRecord) {
            notification.error({
              message: "Error",
              description: `Batch ${batch.batchNumber} has no batch record and you said Yes to 'All batch records submitted successfully'`,
            });
            abort = true;
          }
        });
        if (abort) return;

        try {
          const api = await getAPI();
          await api.put(`/flows/${flow?.id}`, {
            data: {
              ...flow,
              ...form.getFieldsValue(),
            },
          });
          await api.put(`/flows/${flowId}/submit`);
          onSuccess && onSuccess(flow);
          await refetchFlow();
          notification.success({
            message: "The shift check out was done successfully",
          });
        } catch (error: any) {
          notification.error({
            message: "There was an error submitting the form",
            description: error?.message,
          });
        }
      }}
      onFieldsChange={async () => {
        setFormHasUnsavedChanges && setFormHasUnsavedChanges(true);
        if (hasWarnings) {
          const values = form.getFieldsValue();
          // reset warnings
          Object.keys(values).forEach((key) => {
            form.setFields([
              {
                name: key,
                warnings: [],
              },
            ]);
          });
          const shiftCheckOutFlow: IFlowShiftCheckOut = {
            ...flow,
            ...values,
          };

          const { warnings } = await flowValidate(shiftCheckOutFlow, shift);

          if (warnings.length > 0) {
            !hasWarnings && setHasWarnings(true);
            form.setFields(
              warnings.map((warning) => ({
                name: warning.property,
                warnings: warning.messages,
              }))
            );
            setHasWarnings(true);
          } else if (warnings.length === 0) {
            setHasWarnings(false);
          }
        }
      }}
      initialValues={initialValues}
    >
      <Form.Item label="Make sure your finalized all flows" name="allFlowsFinalized">
        <YesNoSwitch disabled={readonly} />
      </Form.Item>
      <Form.Item label="Make sure you closed all batches you needed to close" name="allBatchesClosed">
        <YesNoSwitch disabled={readonly} />
      </Form.Item>
      <Form.Item label="All batch records submitted successfully?" name="allBatchRecordsSubmittedSuccessfully">
        <YesNoSwitch disabled={readonly} />
      </Form.Item>
      <Form.Item label="Extra labels destroyed" name="labelsExtraDestroyed">
        <YesNoNASwitch disabled={readonly} />
      </Form.Item>
      <Form.Item label="Were Overs and Unders cut in?" name="oversAndUndersCutIn">
        <YesNoNASwitch disabled={readonly} />
      </Form.Item>
      <Form.Item label="Were packaging materials consumed to SAP properly?" name="pkgMaterialsConsumedSap">
        <YesNoNASwitch disabled={readonly} />
      </Form.Item>
      <Form.Item
        name="comment"
        label="Comment"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        rules={[{ required: hasWarnings, message: "Please add a comment to explain warnings" }]}
      >
        <TextArea disabled={readonly} />
      </Form.Item>
      <Space direction="vertical" style={{ width: "100%" }} size="large">
        {!readonly && (
          <div style={{ display: "flex", justifyContent: "right" }}>
            <Form.Item>
              {supervisorMode ? (
                <Popconfirm
                  title="Save your change"
                  description="To make a change, you must be trained to do so and you MUST add an explanation in the comment box, below the existing comments. If you have done so, you can click on Save."
                  onConfirm={async () => {
                    try {
                      const api = await getAPI();
                      await api.put(`/flows/${flow?.id}/edit`, {
                        data: form.getFieldsValue(),
                      });
                      await refetchFlow();
                      notification.success({
                        message: "Changes saved",
                      });
                      setFormHasUnsavedChanges && setFormHasUnsavedChanges(false);
                    } catch (error: any) {
                      notification.error({
                        message: "Error saving changes",
                        description: error?.message,
                      });
                    }
                  }}
                  okText="Save"
                  cancelText="Cancel"
                >
                  <Button size="large" icon={<SaveOutlined />} type="primary">
                    Save changes
                  </Button>
                </Popconfirm>
              ) : (
                <Button size="large" icon={<SendOutlined />} htmlType="submit" type="primary" disabled={!flowIsEditable(flow) || readonly}>
                  {hasWarnings ? "Submit with warnings" : "Submit"}
                </Button>
              )}
            </Form.Item>
          </div>
        )}
        <FlowStatus flow={flow} />
      </Space>
    </Form>
  );
};

export default ShiftCheckOutFlowForm;
