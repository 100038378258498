"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.flowGetConfimingUserId = exports.flowIsEditable = exports.flowIsNotFinalized = exports.flowGetStatus = exports.flowGetFlowName = exports.flowGetLabel = exports.Status = exports.FlowType = void 0;
const index_1 = require("./index");
/**
 * Model definition for Flow
 */
var FlowType;
(function (FlowType) {
    FlowType["batchRecord"] = "batchRecord";
    FlowType["dustCollector"] = "dustCollector";
    FlowType["inspection"] = "inspection";
    FlowType["inventoryReading"] = "inventoryReading";
    FlowType["log"] = "log";
    FlowType["masterSanitation"] = "masterSanitation";
    FlowType["safetyShower"] = "safetyShower";
    FlowType["metalDetector"] = "metalDetector";
    FlowType["packageInspection"] = "packageInspection";
    FlowType["scaleTest"] = "scaleTest";
    FlowType["shiftCheckIn"] = "shiftCheckIn";
    FlowType["shiftCheckOut"] = "shiftCheckOut";
})(FlowType = exports.FlowType || (exports.FlowType = {}));
var Status;
(function (Status) {
    Status["notReady"] = "notReady";
    Status["toDo"] = "toDo";
    Status["draft"] = "draft";
    Status["confirmed"] = "confirmed";
    Status["deleted"] = "deleted";
})(Status = exports.Status || (exports.Status = {}));
function flowGetLabel(flow, short = false) {
    switch (flow.type) {
        case FlowType.batchRecord:
            return (0, index_1.flowBatchRecordGetLabel)(flow);
        case FlowType.dustCollector:
            return (0, index_1.flowDustCollectorGetLabel)();
        case FlowType.inspection:
            return (0, index_1.flowInspectionGetLabel)(flow);
        case FlowType.inventoryReading:
            return (0, index_1.flowInventoryReadingGetLabel)();
        case FlowType.log:
            return (0, index_1.flowLogGetLabel)(flow, short);
        case FlowType.masterSanitation:
            return (0, index_1.flowMasterSanitationGetLabel)(flow);
        case FlowType.safetyShower:
            return (0, index_1.flowSafetyShowerGetLabel)(flow);
        case FlowType.metalDetector:
            return (0, index_1.flowMetalDetectorGetLabel)(flow);
        case FlowType.packageInspection:
            return (0, index_1.flowPackageInspectionGetLabel)(flow);
        case FlowType.scaleTest:
            return (0, index_1.flowScaleTestGetLabel)();
        case FlowType.shiftCheckIn:
            return (0, index_1.flowShiftCheckInGetLabel)();
        case FlowType.shiftCheckOut:
            return (0, index_1.flowShiftCheckOutGetLabel)();
        default:
            return "FLOW LABEL TO BE IMPLEMENTED";
    }
}
exports.flowGetLabel = flowGetLabel;
function flowGetFlowName(flow) {
    switch (flow.type) {
        case FlowType.batchRecord:
            return (0, index_1.flowBatchRecordGetFlowName)();
        case FlowType.dustCollector:
            return (0, index_1.flowDustCollectorGetFlowName)(flow);
        case FlowType.inspection:
            return (0, index_1.flowInspectionGetFlowName)(flow);
        case FlowType.inventoryReading:
            return (0, index_1.flowInventoryReadingGetFlowName)();
        case FlowType.log:
            return (0, index_1.flowLogGetFlowName)(flow);
        case FlowType.masterSanitation:
            return (0, index_1.flowMasterSanitationGetFlowName)();
        case FlowType.safetyShower:
            return (0, index_1.flowSafetyShowerGetFlowName)();
        case FlowType.metalDetector:
            return (0, index_1.flowMetalDetectorGetFlowName)();
        case FlowType.packageInspection:
            return (0, index_1.flowPackageInspectionGetFlowName)();
        case FlowType.scaleTest:
            return (0, index_1.flowScaleTestGetFlowName)();
        case FlowType.shiftCheckIn:
            return (0, index_1.flowShiftCheckInGetFlowName)();
        case FlowType.shiftCheckOut:
            return (0, index_1.flowShiftCheckOutGetFlowName)();
        default:
            return "FLOW NAME TO BE IMPLEMENTED";
    }
}
exports.flowGetFlowName = flowGetFlowName;
function flowGetStatus(flow) {
    if (flow.statuses) {
        if (flow.statuses.some((status) => status.status === Status.deleted)) {
            return Status.deleted;
        }
        if (flow.statuses.some((status) => status.status === Status.confirmed)) {
            return Status.confirmed;
        }
        if (flow.statuses.some((status) => status.status === Status.draft)) {
            return Status.draft;
        }
        if (flow.statuses.some((status) => status.status === Status.toDo)) {
            return Status.toDo;
        }
    }
    return Status.notReady;
}
exports.flowGetStatus = flowGetStatus;
function flowIsNotFinalized(flow) {
    if (flow.status === Status.notReady || flow.status === Status.toDo || flow.status === Status.draft) {
        return true;
    }
    return false;
}
exports.flowIsNotFinalized = flowIsNotFinalized;
function flowIsEditable(flow, shift) {
    if (flow.status === Status.deleted || flow.status === Status.notReady) {
        return false;
    }
    if (!(0, index_1.shiftIsCheckedOut)(shift !== null && shift !== void 0 ? shift : flow.shift)) {
        return true;
    }
    return false;
}
exports.flowIsEditable = flowIsEditable;
function flowGetConfimingUserId(flow) {
    var _a, _b;
    const confirmStatus = (_a = flow.statuses) === null || _a === void 0 ? void 0 : _a.find((status) => status.status === Status.confirmed);
    return (_b = confirmStatus === null || confirmStatus === void 0 ? void 0 : confirmStatus.byUser) === null || _b === void 0 ? void 0 : _b.id;
}
exports.flowGetConfimingUserId = flowGetConfimingUserId;
