import { AuditOutlined, BarsOutlined, CarryOutOutlined, ClockCircleOutlined, FlagOutlined, GoldOutlined, SendOutlined } from "@ant-design/icons";
import { Layout, Result, Spin } from "antd";
import React, { lazy } from "react";
import { Route, useRouteError } from "react-router-dom";
import AppLayout from "./AppLayout";
import HomeButton from "./components/UI/HomeButton";
import RetryButton from "./components/UI/RetryButton";
import ShiftHeader from "./components/ShiftScreen/ShiftHeader";
import ShiftView from "./components/ShiftScreen/ShiftView";
import HeaderMenu from "./components/HeaderMenu/HeaderMenu";
import ShiftPickerScreen from "./components/ShiftScreen/ShiftPickerScreen";
import FlowViewScreen from "./components/ShiftScreen/FlowViewScreen";
import BatchDetails from "./components/ShiftScreen/BatchFrame/BatchDetails";
import FlowTablePage from "./components/SupervisorScreen/Flows/FlowTablePage";
import FlowHistoryPage from "./components/SupervisorScreen/Flows/FlowHistoryPage";
import BatchHistoryPage from "./components/SupervisorScreen/Batches/BatchHistoryPage";
import BatchLineHistoryPage from "./components/SupervisorScreen/BatchLines/BatchLineHistoryPage";
import FlagTablePage from "./components/SupervisorScreen/Flags/FlagTablePage";
import FlowVersionViewScreen from "./components/ShiftScreen/FlowVersionViewScreen";
import BatchPage from "./components/SupervisorScreen/Batches/BatchPage";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import AuditTrailTablePage from "./components/SupervisorScreen/AuditTrail/AuditTrailTablePage";
import BatchTablePage from "./components/SupervisorScreen/Batches/BatchTablePage";
import BatchLineTablePage from "./components/SupervisorScreen/BatchLines/BatchLineTablePage";
import ShiftTablePage from "./components/SupervisorScreen/Shifts/ShiftTablePage";
import { BusinessRole } from "shared/interfaces";

const { Content } = Layout;

const LoadingSpin = () => <Spin spinning={true} style={{ textAlign: "center", width: "100%", marginTop: "100px" }} />;

export const NotFound = () => <Result status="404" title="404" subTitle="Sorry, the page you visited does not exist." extra={<HomeButton />} />;
export const ErrorBoundary = () => {
  const error = useRouteError();
  // @ts-ignore
  return <Result status="500" title={error?.toString()} subTitle="Sorry, something went wrong" extra={<RetryButton />} />;
};

export const routes = [
  {
    path: "/",
    element: (
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <AppLayout />
      </QueryParamProvider>
    ),
    errorElement: <ErrorBoundary />,
    children: [
      {
        id: "home",
        path: "/",
        notInMenu: true,
        index: true,
        element: (
          <>
            <HeaderMenu />
            <React.Suspense fallback={<LoadingSpin />}>
              {React.createElement(
                lazy(() => import("./components/Home/Home")),
                {}
              )}
            </React.Suspense>
          </>
        ),
        errorElement: <ErrorBoundary />,
        title: "Home",
        handle: {
          title: "Home",
        },
      },
      {
        id: "shift",
        path: "/shift/:shiftId",
        element: (
          <>
            <HeaderMenu>
              <ShiftHeader />
            </HeaderMenu>
            <Content
              style={{
                height: "calc(100vh - 64px)",
                overflowY: "auto",
                position: "relative",
              }}
            >
              <ShiftView />
            </Content>
          </>
        ),
        title: "Shift",
        notInMenu: true,
        children: [
          {
            id: "shift-flow",
            path: "flow/:flowId",
            element: <FlowViewScreen />,
            title: "Task",
            handle: {
              title: "Task",
            },
            icon: CarryOutOutlined,
            notInMenu: true,
            children: [
              {
                id: "shift-previous-shift",
                path: "previous-shift/:shiftId",
                element: <ShiftView readonly={true} />,
                notInMenu: true,
                children: [
                  {
                    id: "shift-previous-shift-batch",
                    path: "batch/:batchId",
                    notInMenu: true,
                    index: true,
                    element: <BatchDetails readonly={true} />,
                    errorElement: <ErrorBoundary />,
                    title: "Batch",
                    handle: {
                      title: "Batch",
                    },
                  },
                ],
              },
            ],
          },
          {
            id: "shift-batch",
            path: "batch/:batchId",
            notInMenu: true,
            index: true,
            element: <BatchDetails />,
            errorElement: <ErrorBoundary />,
            title: "Batch",
            handle: {
              title: "Batch",
            },
          },
        ],
      },
      {
        id: "shift-picker",
        path: "/shift-picker",
        element: (
          <>
            <HeaderMenu>
              <h1>Shift Picker</h1>
            </HeaderMenu>
            <Content
              style={{
                height: "calc(100vh - 64px)",
                overflowY: "auto",
                position: "relative",
              }}
            >
              <ShiftPickerScreen />
            </Content>
          </>
        ),
        title: "Shift",
        handle: {
          title: "Shift",
        },
        icon: CarryOutOutlined,
      },
      {
        id: "flow",
        path: "/flow/:flowId",
        element: <FlowViewScreen />,
        title: "Task",
        handle: {
          title: "Task",
        },
        icon: CarryOutOutlined,
        notInMenu: true,
        children: [
          {
            id: "previous-shift",
            path: "previous-shift/:shiftId",
            element: <ShiftView readonly={true} />,
            notInMenu: true,
            children: [
              {
                id: "previous-shift-batch",
                path: "batch/:batchId",
                notInMenu: true,
                index: true,
                element: <BatchDetails readonly={true} />,
                errorElement: <ErrorBoundary />,
                title: "Batch",
                handle: {
                  title: "Batch",
                },
              },
            ],
          },
        ],
      },
      {
        id: "divider",
      },
      {
        id: "shifts",
        path: "/shifts",
        icon: ClockCircleOutlined,
        index: true,
        element: <ShiftTablePage />,
        errorElement: <ErrorBoundary />,
        roles: [BusinessRole.Supervisor, BusinessRole.Quality, BusinessRole.Reader, BusinessRole.Operator, BusinessRole.Admin],
        title: "Shift table",
        handle: {
          title: "shifts",
        },
      },
      {
        id: "batches",
        path: "/batches",
        icon: GoldOutlined,
        element: <BatchTablePage />,
        title: "Batch table",
        roles: [BusinessRole.Supervisor, BusinessRole.Quality, BusinessRole.Reader, BusinessRole.Operator, BusinessRole.Admin],
        handle: {
          title: "Batches",
        },
      },
      {
        id: "batch-history",
        path: "/batches/:batchId/history",
        notInMenu: true,
        element: <BatchHistoryPage />,
        roles: [BusinessRole.Supervisor, BusinessRole.Quality, BusinessRole.Reader, BusinessRole.Operator, BusinessRole.Admin],
        title: "Batch history",
        handle: {
          title: "Batch history",
        },
      },
      {
        id: "batch-lines",
        path: "/batch-lines",
        icon: BarsOutlined,
        element: <BatchLineTablePage />,
        title: "Batch line table",
        roles: [BusinessRole.Supervisor, BusinessRole.Quality, BusinessRole.Reader, BusinessRole.Operator, BusinessRole.Admin],
        handle: {
          title: "Batch lines",
        },
      },
      {
        id: "batch-line-history",
        path: "/batch-lines/:batchLineId/history",
        notInMenu: true,
        element: <BatchLineHistoryPage />,
        roles: [BusinessRole.Supervisor, BusinessRole.Quality, BusinessRole.Reader, BusinessRole.Operator, BusinessRole.Admin],
        title: "Batch line history",
        handle: {
          title: "Batch line history",
        },
      },
      {
        id: "flows",
        path: "/flows",
        icon: SendOutlined,
        element: <FlowTablePage />,
        title: "Flow table",
        roles: [BusinessRole.Supervisor, BusinessRole.Quality, BusinessRole.Reader, BusinessRole.Operator, BusinessRole.Admin],
        handle: {
          title: "Flows",
        },
      },
      {
        id: "flow-history",
        path: "/flows/:flowId/history",
        notInMenu: true,
        element: <FlowHistoryPage />,
        roles: [BusinessRole.Supervisor, BusinessRole.Quality, BusinessRole.Reader, BusinessRole.Operator, BusinessRole.Admin],
        title: "Flow history",
        handle: {
          title: "Flow history",
        },
      },
      {
        id: "flow-version",
        path: "/flow/:flowId/version/:version",
        element: <FlowVersionViewScreen />,
        roles: [BusinessRole.Supervisor, BusinessRole.Quality, BusinessRole.Reader, BusinessRole.Operator, BusinessRole.Admin],
        title: "Task",
        handle: {
          title: "Task",
        },
        icon: CarryOutOutlined,
        notInMenu: true,
      },
      {
        id: "flags",
        path: "/flags",
        element: <FlagTablePage />,
        title: "Flag table",
        handle: {
          title: "Flags",
        },
        roles: [BusinessRole.Supervisor, BusinessRole.Quality, BusinessRole.Reader, BusinessRole.Operator, BusinessRole.Admin],
        icon: FlagOutlined,
        notInMenu: false,
      },
      {
        id: "audit-trail",
        path: "/audit-trail",
        icon: AuditOutlined,
        element: <AuditTrailTablePage />,
        title: "Audit Trail",
        roles: [BusinessRole.Supervisor, BusinessRole.Quality, BusinessRole.Reader, BusinessRole.Operator, BusinessRole.Admin],
        handle: {
          title: "Audit Trail",
        },
      },
      {
        id: "batch",
        path: "/batch/:batchId",
        notInMenu: true,
        index: true,
        element: <BatchPage />,
        errorElement: <ErrorBoundary />,
        title: "Batch",
        handle: {
          title: "Batch",
        },
      },
      {
        id: "not-found",
        notInMenu: true,
        path: "*",
        element: <NotFound />,
      },
    ],
  },
];

export const generateRoutes = (routes) => {
  return routes.map((route) => {
    if (route.children && route.children.length > 0) {
      // Generate nested routes
      return (
        <Route key={route.id} path={route.path} element={route.element}>
          {generateRoutes(route.children)}
        </Route>
      );
    } else {
      // Generate a single route
      return <Route key={route.id} path={route.path} element={route.element} />;
    }
  });
};

export const navigationMenuRoutes = routes[0].children
  .filter((route) => !route.notInMenu)
  .map((route) => ({
    id: route.id,
    path: route.path,
    title: route.title,
    icon: route.icon,
    roles: route.roles,
  }));
