import { Button, DatePicker, Modal, Space, Table } from "antd";
import { EyeOutlined, FilterFilled } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { DateTime } from "luxon";
import "./ShiftTable.css";
import { ColumnsType } from "antd/es/table";
import { useNavigate } from "react-router";
import { ZoomContext } from "../../../App";
import { ArrayParam, NumberParam, useQueryParams, StringParam } from "use-query-params";
import useShifts from "../../../hooks/useShifts";
import useWindowResize from "../../../hooks/useWindowResize";
import CSVDownloadButton from "../../UI/CSVDownloadButton";
import { Area, Product, Team, StatusShift } from "shared/interfaces";
import { nicelyDisplayDateFromISOString, shiftDayDateTimeToString } from "shared/utils";
import { FilterDropdown, FilterIcon } from "../UI/FilterId";
import OverflowCell from "../../UI/OverflowCell";

const ShiftTable = () => {
  const navigate = useNavigate();
  const [zoom] = React.useContext(ZoomContext);
  const zoomRatio = parseInt(zoom) / 100;
  const { height } = useWindowResize();
  const [tableHeaderHeight, setTableHeaderHeight] = useState(0);
  const [signSupervisorComment, setSignSupervisorComment] = useState(null);
  const [signQualityComment, setSignQualityComment] = useState(null);

  useEffect(() => {
    // Function to update state with the height of the th element
    const updateHeight = () => {
      const thElement = document.querySelector("th");
      if (thElement) {
        setTableHeaderHeight(thElement.clientHeight);
      }
    };

    // Set up ResizeObserver
    const resizeObserver = new ResizeObserver(updateHeight);
    const thElement = document.querySelector("th");
    if (thElement) {
      resizeObserver.observe(thElement);
    }

    // Clean up observer on component unmount
    return () => {
      if (thElement) {
        resizeObserver.unobserve(thElement);
      }
    };
  }, []);

  // Refactor to use useQueryParams
  const [query, setQuery] = useQueryParams({
    id: NumberParam,
    page: NumberParam,
    pageSize: NumberParam,
    statusShift: ArrayParam,
    team: ArrayParam,
    product: ArrayParam,
    area: ArrayParam,
    from: NumberParam,
    to: NumberParam,
    shiftDayFrom: StringParam,
    shiftDayTo: StringParam,
    sortParams: ArrayParam,
  });

  // Default times to one week ago if not set
  const [defaultTimeSpan] = useState([dayjs().subtract(1, "week").valueOf(), dayjs().valueOf()]);
  const timeSpan = query.from && query.to ? [query.from, query.to] : defaultTimeSpan;

  const getSortOrder = (field) => {
    const sortParam = query.sortParams?.find((s) => s?.startsWith(field));
    if (sortParam) {
      return sortParam.endsWith("asc") ? "ascend" : "descend";
    }
    return null;
  };

  const sortParams = query.sortParams || [];

  if (!sortParams.find((s) => s?.startsWith("createdAt"))) {
    sortParams.push("createdAt:desc");
  }

  const shiftQueryConfig = {
    page: query.page || 1,
    pageSize: query.pageSize || 100,
    filters: {
      ...(query.id ? { id: query.id } : {}),
      ...(timeSpan && timeSpan[0] && timeSpan[1]
        ? {
            createdAt: {
              $gte: timeSpan[0].valueOf(),
              $lte: timeSpan[1].valueOf(),
            },
          }
        : {}),
      ...(query.team && query.team.length ? { team: { $in: query.team } } : {}),
      ...(query.area && query.area.length ? { area: { $in: query.area } } : {}),
      ...(query.product && query.product.length ? { product: { $in: query.product } } : {}),
      ...(query.shiftDayFrom && query.shiftDayTo ? { day: { $gte: query.shiftDayFrom, $lte: query.shiftDayTo } } : {}),
      ...(query.statusShift && query.statusShift.length ? { statusShift: { $in: query.statusShift } } : {}),
    },
    ...(sortParams ? { sort: sortParams } : {}),
  };
  const { data } = useShifts(shiftQueryConfig);

  const { data: shifts = [] } = data || {};

  const total = data?.meta?.pagination?.total;
  const page = data?.meta?.pagination?.page;
  const pageSize = data?.meta?.pagination?.pageSize;

  const columns: ColumnsType<any> = [
    {
      title: "Shift id",
      dataIndex: "id",
      width: 65 * Math.max(1, zoomRatio),
      sorter: { multiple: 1 },
      defaultSortOrder: getSortOrder("id"),
      fixed: "left",
      onHeaderCell: (column) => {
        if (query.id) {
          return {
            className: "ant-table-column-sort",
          };
        }
        return {};
      },
      filterIcon: (filtered) => <FilterIcon queryItem={query.id} />,
      filterDropdown: FilterDropdown({
        queryKey: "id",
        queryValue: query.id,
        setQuery: setQuery,
        resetQuery: () => setQuery({ id: null }),
        isString: false,
      }),
    },
    {
      title: "Creation date",
      dataIndex: "createdAt",
      width: 130 * Math.max(1, zoomRatio),
      sorter: { multiple: 1 },
      defaultSortOrder: getSortOrder("createdAt"),
      filterDropdown: () => (
        <DatePicker.RangePicker
          value={[dayjs(timeSpan[0]), dayjs(timeSpan[1])]}
          showTime={false}
          onChange={(dates) => {
            if (dates && dates.length > 1 && dates[0] && dates[1]) {
              const from = DateTime.fromJSDate(dates[0].toDate()).startOf("day").toMillis();
              const to = DateTime.fromJSDate(dates[1].toDate()).endOf("day").toMillis();
              setQuery({ from: from, to: to, page: 1 });
            }
          }}
          disabledDate={(current) => current && current > dayjs()}
          allowClear={false}
        />
      ),
      defaultFilteredValue: defaultTimeSpan,
      render: (createdAt) => nicelyDisplayDateFromISOString(createdAt),
    },
    {
      title: "Area",
      dataIndex: "area",
      sorter: { multiple: 1 },
      filters: Object.keys(Area).map((area) => {
        return {
          value: area,
          text: area,
        };
      }),
      defaultFilteredValue: query?.area?.filter((area): area is string => area !== null) || [],
      defaultSortOrder: getSortOrder("area"),
      width: 65 * Math.max(1, zoomRatio),
    },
    {
      title: "Product",
      dataIndex: "product",
      sorter: { multiple: 1 },
      defaultSortOrder: getSortOrder("product"),
      filters: Object.keys(Product).map((product) => {
        return {
          value: product,
          text: product,
        };
      }),
      defaultFilteredValue: query?.product?.filter((product): product is string => product !== null) || [],
      width: 70 * Math.max(1, zoomRatio),
    },
    {
      title: "Day",
      dataIndex: "day",
      sorter: { multiple: 1 },
      defaultSortOrder: getSortOrder("day"),
      width: 95 * Math.max(1, zoomRatio),
      filterIcon: (filtered) => (
        <span>{query.shiftDayFrom && query.shiftDayTo ? <FilterFilled style={{ color: "var(--prayon-primary-color)" }} /> : <FilterFilled />}</span>
      ),
      filterDropdown: () => (
        <DatePicker.RangePicker
          onChange={(dates) => {
            if (dates && dates.length > 1 && dates[0] && dates[1]) {
              setQuery({
                shiftDayFrom: shiftDayDateTimeToString(DateTime.fromJSDate(dates[0].toDate())),
                shiftDayTo: shiftDayDateTimeToString(DateTime.fromJSDate(dates[1].toDate())),
              });
            } else {
              setQuery({ shiftDayFrom: null, shiftDayTo: null });
            }
          }}
          disabledDate={(current) => current && current > dayjs()}
          allowClear={true}
        />
      ),
    },
    {
      title: "Team",
      dataIndex: "team",
      sorter: { multiple: 1 },
      defaultSortOrder: getSortOrder("team"),
      width: 65 * Math.max(1, zoomRatio),
      filters: [
        {
          value: Team.D,
          text: Team.D,
        },
        {
          value: Team.N,
          text: Team.N,
        },
      ],
      defaultFilteredValue: query?.team?.filter((team): team is string => team !== null) || [],
    },
    {
      title: "Status",
      dataIndex: "statusShift",
      sorter: { multiple: 1 },
      defaultSortOrder: getSortOrder("statusShift"),
      width: 100 * Math.max(1, zoomRatio),
      filters: Object.keys(StatusShift).map((statusShift) => {
        return {
          value: statusShift,
          text: statusShift,
        };
      }),
      defaultFilteredValue: query?.statusShift?.filter((statusShift): statusShift is string => statusShift !== null) || [],
    },
    {
      title: "Comment supervisor signature",
      dataIndex: "signSupervisorComment",
      width: 200 * Math.max(1, zoomRatio),
      render: (value) => {
        return value ? <OverflowCell content={value} onMore={() => setSignSupervisorComment(value)} /> : null;
      },
    },
    {
      title: "Comment quality signature",
      dataIndex: "signQualityComment",
      width: 200 * Math.max(1, zoomRatio),
      render: (value) => {
        return value ? <OverflowCell content={value} onMore={() => setSignQualityComment(value)} /> : null;
      },
    },
    {
      title: "Last modified date",
      dataIndex: "updatedAt",
      sorter: { multiple: 1 },
      defaultSortOrder: getSortOrder("updatedAt"),
      // width: 105 * Math.max(1, zoomRatio),
      render: (updatedAt) => nicelyDisplayDateFromISOString(updatedAt),
    },
    {
      title: "Details",
      width: 70 * Math.max(1, zoomRatio),
      dataIndex: "id",
      fixed: "right",
      render: (id) => {
        return (
          <Space>
            <Button
              type="link"
              icon={<EyeOutlined />}
              onClick={() => {
                navigate("/shift/" + id);
              }}
            />
          </Space>
        );
      },
    },
  ];

  return (
    <div
      style={{
        padding: 20,
      }}
      className={"flow-table"}
    >
      <CSVDownloadButton fetchDataConfig={shiftQueryConfig} queryHook={useShifts} filename={"shifts.csv"} />
      <Table
        size={"small"}
        onChange={(pagination, filters, sorter) => {
          const sorterArray = (Array.isArray(sorter) ? sorter : [sorter]).filter((s) => s.column);
          const newSortParams = sorterArray
            .filter((s) => s.field)
            .filter((s) => s.order)
            .map((s) => {
              return `${s.field && s.field.toString().replace(/,/, ".")}${s.order ? ":" + (s.order === "ascend" ? "asc" : "desc") : ""}`;
            })
            .reverse();

          if (!newSortParams.find((s) => s.startsWith("createdAt"))) {
            newSortParams.push("createdAt:desc");
          }

          setQuery({
            page: pagination.current,
            pageSize: pagination.pageSize,
            sortParams: newSortParams,
            ...filters,
          });
        }}
        scroll={{
          x: 1650 * Math.max(1, zoomRatio),
          ...(height ? { y: height - 125 - 25 * zoomRatio - tableHeaderHeight } : {}),
        }}
        pagination={{
          current: page,
          total,
          pageSize,
        }}
        columns={columns}
        dataSource={shifts}
        bordered={true}
      />
      <Modal
        open={!!signSupervisorComment}
        footer={null}
        onCancel={() => {
          setSignSupervisorComment(null);
        }}
        title={"Comment supervisor signature"}
      >
        {signSupervisorComment}
      </Modal>
      <Modal
        open={!!signQualityComment}
        footer={null}
        onCancel={() => {
          setSignQualityComment(null);
        }}
        title={"Comment quality signature"}
      >
        {signQualityComment}
      </Modal>
    </div>
  );
};

export default ShiftTable;
