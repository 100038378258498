import { Button, List, notification, Popconfirm, Space, Tag } from "antd";
import React from "react";
import map from "../../helpers/map";
import { useNavigate } from "react-router";
import { flowGetStatus, flowIsEditable, flowGetLabel, Status, FlowType, InspectedType, LogType, BusinessRole } from "shared/interfaces";
import { DeleteOutlined, EditOutlined, EyeOutlined, WarningOutlined } from "@ant-design/icons";
import getAPI from "../../services/api";
import useShift from "../../hooks/useShift";
import FlagsPopover from "../UI/FlagsPopover";
import { ZoomContext } from "../../App";
import AnimatedIcon from "../UI/AnimatedIcon";
import FlowStatus from "../UI/FlowStatus";
import { useUser } from "../../services/auth";

type FlowListProps = {
  shiftId: number | string | undefined;
  logs: boolean;
  readonly?: boolean;
};

const FlowList: React.FC<FlowListProps> = ({ shiftId, logs, readonly }) => {
  const navigate = useNavigate();
  const { data: shift, refetch: refetchShift } = useShift(shiftId);
  const flows = shift?.flows || [];
  const user = useUser();

  const [zoom] = React.useContext(ZoomContext);
  const zoomRatio = parseInt(zoom) / 100;

  let sortedFlows = flows.filter((flow) => {
    if (logs) {
      return flow.type === FlowType.log;
    }
    return flow.type !== FlowType.log;
  });
  sortedFlows = sortedFlows.filter((flow) => flowGetStatus(flow) !== Status.deleted);
  const flowOrder = [
    FlowType.shiftCheckIn,
    FlowType.batchRecord,
    FlowType.inspection,
    FlowType.scaleTest,
    FlowType.metalDetector,
    FlowType.dustCollector,
    FlowType.inventoryReading,
    FlowType.packageInspection,
    FlowType.safetyShower,
    FlowType.masterSanitation,
    FlowType.shiftCheckOut,
    FlowType.log,
  ];
  sortedFlows = sortedFlows
    .sort((a, b) => {
      const aUpdatedAt = a.updatedAt ? new Date(a.updatedAt).getTime() : 0;
      const bUpdatedAt = b.updatedAt ? new Date(b.updatedAt).getTime() : 0;
      return bUpdatedAt - aUpdatedAt;
    })
    .sort((a, b) => {
      // Sort by status
      const statusOrder: { [key in Status]: number } = {
        toDo: 1,
        draft: 2,
        notReady: 3,
        confirmed: 4,
        deleted: 5,
      };

      if (flowGetStatus(a) !== flowGetStatus(b)) {
        return statusOrder[flowGetStatus(a)] - statusOrder[flowGetStatus(b)];
      }

      // Sort by presence of shouldBeSubmittedByDate
      if (a.shouldBeSubmittedByDate && !b.shouldBeSubmittedByDate) return -1;
      if (!a.shouldBeSubmittedByDate && b.shouldBeSubmittedByDate) return 1;

      // Sort by shouldBeSubmittedByDate in ascending order
      if (a.shouldBeSubmittedByDate && b.shouldBeSubmittedByDate) {
        return new Date(a.shouldBeSubmittedByDate).getTime() - new Date(b.shouldBeSubmittedByDate).getTime();
      }

      return 0;
    })
    .sort((a, b) => {
      if (a.type === FlowType.log && b.type === FlowType.log) {
        if (a.logType === LogType.dailyInstruction && b.logType === LogType.log) {
          return -1;
        } else if (a.logType === LogType.log && b.logType === LogType.dailyInstruction) {
          return 1;
        }
        return 0;
      }
      if (a.type === FlowType.inspection && b.type === FlowType.inspection) {
        if (a.typeInspected === InspectedType.magnet && b.typeInspected === InspectedType.screen) {
          return -1;
        } else if (a.typeInspected === InspectedType.screen && b.typeInspected === InspectedType.magnet) {
          return 1;
        }
        return 0; // If both have the same typeInspected or do not have typeInspected
      } else {
        const orderA = flowOrder.indexOf(a.type);
        const orderB = flowOrder.indexOf(b.type);
        if (orderA !== orderB) {
          return orderA - orderB;
        }
        return flowGetLabel(a).localeCompare(flowGetLabel(b));
      }
    });

  return (
    <List>
      {sortedFlows.map((flow) => (
        <List.Item key={flow.id}>
          <List.Item.Meta
            title={
              <Space>
                <div>{flowGetLabel(flow)}</div>
                {flow.flags && <FlagsPopover flags={flow.flags} zoomRatio={zoomRatio} flowId={flow.id} />}
                {(flowGetStatus(flow) === Status.toDo || flowGetStatus(flow) === Status.draft) &&
                flow.shouldBeSubmittedByDate &&
                new Date(flow.shouldBeSubmittedByDate) < new Date() ? (
                  <AnimatedIcon icon={WarningOutlined} style={{ color: "#FCD602" }} />
                ) : null}
              </Space>
            }
            description={
              <Space>
                <Tag
                  color={map({
                    notReady: "blue",
                    toDo: "red",
                    draft: "orange",
                    confirmed: "green",
                    deleted: "grey",
                  })(flowGetStatus(flow), "grey")}
                >
                  {map({
                    notReady: "Not Ready",
                    toDo: "To do",
                    draft: "Draft",
                    confirmed: "Done",
                    deleted: "Deleted",
                  })(flowGetStatus(flow))}
                </Tag>
                <FlowStatus flow={flow} />
              </Space>
            }
          />
          <Space>
            {flow.manuallyCreated && flowGetStatus(flow) !== Status.confirmed && !readonly && (
              <Popconfirm
                title="Delete the flow"
                description="Are you sure you want to delete this flow?"
                onConfirm={async () => {
                  try {
                    const api = await getAPI();
                    await api.delete(`/flows/${flow.id}/manuallyCreated`);
                    await refetchShift();
                  } catch (error: any) {
                    notification.error({
                      message: "Error",
                      description: `${error.message}`,
                    });
                  }
                }}
                okText="Yes"
                cancelText="No"
              >
                <Button icon={<DeleteOutlined />} size="large" shape="circle" />
              </Popconfirm>
            )}

            {(() => {
              const canEditDailyInstruction = user?.businessRole === BusinessRole.Supervisor || user?.businessRole === BusinessRole.Quality;
              if (
                flowIsEditable(flow, shift) &&
                !readonly &&
                (flow.type === FlowType.log && flow.logType === LogType.dailyInstruction ? canEditDailyInstruction : true)
              ) {
                return <Button icon={<EditOutlined />} size="large" shape="circle" onClick={() => navigate(`flow/${flow.id}`)} />;
              } else {
                return <Button icon={<EyeOutlined />} size="large" shape="circle" onClick={() => navigate(`flow/${flow.id}`)} />;
              }
            })()}
          </Space>
        </List.Item>
      ))}
    </List>
  );
};

export default FlowList;
