// @flow
import React, { useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { Layout, Button } from "antd";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import logo from "./assets/logo-digilog-500x500.png";

import NavigationMenu from "./components/NavigationMenu/NavigationMenu";

const { Sider } = Layout;

const AppLayout = () => {
  const [collapsed, setCollapsed] = useState(true);

  const toggleCollapse = () => setCollapsed(!collapsed);

  return (
    <Layout style={{ height: "100vh" }}>
      <Sider
        collapsible
        theme="light"
        collapsed={collapsed}
        collapsedWidth={70}
        width={250}
        onCollapse={toggleCollapse}
        trigger={null}
        style={{
          zIndex: 5,
          position: "relative",
          boxShadow: "2px 0px 3px lightgrey",
        }}
      >
        <Link to="/" onClick={() => setCollapsed(true)}>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: 65,
              padding: 5,
            }}
          >
            <img
              src={collapsed ? logo : logo}
              alt="logo"
              style={{
                maxWidth: "100%",
                maxHeight: 70,
                height: "100%",
                padding: 0,
              }}
            />
          </div>
        </Link>
        <div style={{ width: "100%", padding: 5 }}>
          <Button style={{ width: "100%" }} icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />} onClick={toggleCollapse} />
        </div>
        <NavigationMenu collapsed={collapsed} onNavigate={() => setCollapsed(true)} />
        <div
          style={{
            position: "absolute",
            bottom: 50,
            width: "100%",
            textAlign: "center",
          }}
        >
          <a
            href="https://app.powerbi.com/Redirect?action=OpenApp&appId=12b8ce38-57a9-42b1-a78e-fa82a1452d27&ctid=cff318ed-ed6a-4c5d-a146-ef6f27ed3883"
            target="_blank"
            rel="noreferrer"
          >
            Power BI
          </a>
        </div>
        <div
          style={{
            position: "absolute",
            bottom: 10,
            width: "100%",
            color: "grey",
            textAlign: "center",
          }}
        >
          v{process.env.REACT_APP_VERSION}
        </div>
      </Sider>
      <Layout>
        <Outlet />
      </Layout>
    </Layout>
  );
};

export default AppLayout;
