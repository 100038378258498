/**
 * @param {string} params eg. "?error%5Berror%5D=invalid_client&error%5Berror_description%5D=AADSTS7000215%3A%20Invalid%20client%20secret%20provided.%20Ensure%20the%20secret%20being%20sent%20in%20the%20request%20is%20the%20client%20secret%20value%2C%20not%20the%20client%20secret%20ID%2C%20for%20a%20secret%20added%20to%20app%20%27f20e07d6-d265-4bcb-aeb3-532c3d893e8c%27.%0D%0ATrace%20ID%3A%208688d511-add4-4a2a-ad69-b7bae76e4600%0D%0ACorrelation%20ID%3A%208230ab6a-56d1-433b-b1f2-3207a4146084%0D%0ATimestamp%3A%202023-05-31%2014%3A52%3A52Z&error%5Berror_codes%5D%5B0%5D=7000215&error%5Btimestamp%5D=2023-05-31%2014%3A52%3A52Z&error%5Btrace_id%5D=8688d511-add4-4a2a-ad69-b7bae76e4600&error%5Bcorrelation_id%5D=8230ab6a-56d1-433b-b1f2-3207a4146084&error%5Berror_uri%5D=https%3A%2F%2Flogin.microsoftonline.com%2Ferror%3Fcode%3D7000215"
 * @returns {Record<string, string>}
 */
export function parseURLArguments(params: string): Record<string, string> {
  const ret = {};
  const paramsArray = params
    .substring(1)
    .split("&")
    .filter((param) => param);
  for (const param of paramsArray) {
    const [key, value] = param.split("=");
    ret[decodeURIComponent(key)] = decodeURIComponent(value);
  }
  return ret;
}
