import React from "react";
import Code128Scanner from "./Code128Scanner";
import { Modal, Space } from "antd";
import "./Code128ScannerModal.css";
import { ScanOutlined } from "@ant-design/icons";

function Code128ScannerModal({ open, setOpen, setCode }) {
  function formatCode(code) {
    if (/^[a-zA-Z0-9]{7,8}-\d{4}$/.test(code)) {
      return code;
    }
    if (/^[a-zA-Z0-9]{7}\d{4}$/.test(code)) {
      const part1 = code.slice(0, 7);
      const part2 = code.slice(7, 11);

      return `${part1}-${part2}`;
    }
    if (/^[a-zA-Z0-9]{8}\d{4}$/.test(code)) {
      const part1 = code.slice(0, 8);
      const part2 = code.slice(8, 12);

      return `${part1}-${part2}`;
    }
    return code;
  }

  return open ? (
    <Modal
      destroyOnClose={true}
      footer={null}
      styles={{ body: { position: "relative" } }}
      title={
        <Space>
          <ScanOutlined />
          Scan the barcode
        </Space>
      }
      className={"code128-scanner-modal"}
      open={open}
      width={"690px"}
      onCancel={() => setOpen(false)}
    >
      <Code128Scanner
        onDetected={(code) => {
          try {
            const formattedCode = formatCode(code);
            setCode(formattedCode);
            setOpen(false);
          } catch (e) {
            // do nothing
          }
        }}
      />
    </Modal>
  ) : null;
}

export default Code128ScannerModal;
