import React from "react";
import { Button, InputNumber, Input, Space } from "antd";
import { FilterFilled } from "@ant-design/icons";

export const FilterIcon = ({ queryItem }) => (
  <span>{queryItem ? <FilterFilled style={{ color: "var(--prayon-primary-color)" }} /> : <FilterFilled />}</span>
);

export const FilterDropdown = ({ queryKey, queryValue, setQuery, resetQuery, isString }) => {
  const isFilterActive = queryValue != null && queryValue !== "";
  return ({ confirm, clearFilters, close }) => (
    <div onKeyDown={(e) => e.stopPropagation()}>
      {isString ? (
        <Input
          value={queryValue || null}
          onPressEnter={() => {
            confirm();
            close();
          }}
          onChange={(e) => {
            setQuery({ [queryKey]: e.target.value ? e.target.value : null });
          }}
        />
      ) : (
        <InputNumber
          min={0}
          value={queryValue || null}
          onPressEnter={() => {
            confirm();
            close();
          }}
          onChange={(value) => setQuery({ [queryKey]: value ? Number(value) : null })}
          style={{ margin: 8, display: "block" }}
          inputMode="numeric"
        />
      )}
      <div className="ant-table-filter-dropdown-btns">
        <Space>
          <Button
            type="link"
            ghost={!isFilterActive}
            style={{
              margin: 0,
              padding: 0,
              color: isFilterActive ? "#1890ff" : "rgba(0, 0, 0, 0.25)",
            }}
            onClick={() => {
              clearFilters && resetQuery();
              close();
            }}
            size="small"
          >
            Reset
          </Button>
          <Button
            type="primary"
            size="small"
            onClick={() => {
              close();
            }}
          >
            OK
          </Button>
        </Space>
      </div>
    </div>
  );
};
