"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.flowInventoryReadingValidate = exports.flowInventoryReadingGetFlowName = exports.flowInventoryReadingGetGenericLabel = exports.flowInventoryReadingGetLabel = void 0;
const index_1 = require("./index");
function flowInventoryReadingGetLabel() {
    return flowInventoryReadingGetGenericLabel();
}
exports.flowInventoryReadingGetLabel = flowInventoryReadingGetLabel;
function flowInventoryReadingGetGenericLabel() {
    return "Inventory Reading";
}
exports.flowInventoryReadingGetGenericLabel = flowInventoryReadingGetGenericLabel;
function flowInventoryReadingGetFlowName() {
    return "FlowInventoryReading";
}
exports.flowInventoryReadingGetFlowName = flowInventoryReadingGetFlowName;
function flowInventoryReadingValidate(flow) {
    let errors = [];
    let warnings = [];
    const labelCaProductFamily = "(Ca) Product Family";
    const labelCaBlendBin1 = "(Ca) Blend Bin 1";
    const labelCaBlendBin1NA = "(Ca) Blend Bin 1 N/A";
    const labelCaBlendBin2 = "(Ca) Blend Bin 2";
    const labelCaBlendBin2NA = "(Ca) Blend Bin 2 N/A";
    const labelNaKMiddleBinProductFamily = "(NaK) Middle Bin Product Family";
    const labelNaKMiddleBinProductReading = "(NaK) Middle Bin Product Reading";
    const labelNaKMiddleBinProductReadingNA = "(NaK) Middle Bin Product Reading N/A";
    const labelNaKSSBinProductFamily = "(NaK) SS Bin Product Family";
    const labelNaKSSBinProductReading = "(NaK) SS Bin Product Reading";
    const labelNaKSSBinProductReadingNA = "(NaK) SS Bin Product Reading N/A";
    const labelNaKBlender = "(NaK) Blender";
    const labelNakBlenderNA = "(NaK) Blender N/A";
    if (!flow.product) {
        warnings.push({
            property: "product",
            message: "product is missing",
        });
        return {
            errors,
            warnings,
        };
    }
    if (flow.product === index_1.Product.Ca) {
        warnings = (0, index_1.validationMissingValue)(warnings, flow.caProductFamily, "caProductFamily", labelCaProductFamily);
        warnings = (0, index_1.validationMissingValue)(warnings, flow.caBlendBin1NA, "caBlendBin1NA", labelCaBlendBin1NA);
        warnings = (0, index_1.validationMissingValue)(warnings, flow.caBlendBin2NA, "caBlendBin2NA", labelCaBlendBin2NA);
        if (flow.caBlendBin1NA != null && !flow.caBlendBin1NA) {
            warnings = (0, index_1.validationMissingValue)(warnings, flow.caBlendBin1, "caBlendBin1", labelCaBlendBin1);
            if (flow.caBlendBin1 && (flow.caBlendBin1 < 0 || flow.caBlendBin1 > 99999)) {
                warnings.push({
                    property: "caBlendBin1",
                    message: `${labelCaBlendBin1} is out of range`,
                });
            }
        }
        if (flow.caBlendBin2NA != null && !flow.caBlendBin2NA) {
            warnings = (0, index_1.validationMissingValue)(warnings, flow.caBlendBin2, "caBlendBin2", labelCaBlendBin2);
            if (flow.caBlendBin2 && (flow.caBlendBin2 < 0 || flow.caBlendBin2 > 99999)) {
                warnings.push({
                    property: "caBlendBin2",
                    message: `${labelCaBlendBin2} is out of range`,
                });
            }
        }
    }
    else if (flow.product === index_1.Product.NaK) {
        warnings = (0, index_1.validationMissingValue)(warnings, flow.naKMiddleBinProductFamily, "naKMiddleBinProductFamily", labelNaKMiddleBinProductFamily);
        warnings = (0, index_1.validationMissingValue)(warnings, flow.naKMiddleBinProductReadingNA, "naKMiddleBinProductReadingNA", labelNaKMiddleBinProductReadingNA);
        warnings = (0, index_1.validationMissingValue)(warnings, flow.naKSSBinProductFamily, "naKSSBinProductFamily", labelNaKSSBinProductFamily);
        warnings = (0, index_1.validationMissingValue)(warnings, flow.naKSSBinProductReadingNA, "naKSSBinProductReadingNA", labelNaKSSBinProductReadingNA);
        warnings = (0, index_1.validationMissingValue)(warnings, flow.nakBlenderNA, "nakBlenderNA", labelNakBlenderNA);
        if (flow.naKMiddleBinProductReadingNA != null && !flow.naKMiddleBinProductReadingNA) {
            warnings = (0, index_1.validationMissingValue)(warnings, flow.naKMiddleBinProductReading, "naKMiddleBinProductReading", labelNaKMiddleBinProductReading);
            if (flow.naKMiddleBinProductReading && (flow.naKMiddleBinProductReading < 0 || flow.naKMiddleBinProductReading > 100)) {
                warnings.push({
                    property: "naKMiddleBinProductReading",
                    message: `${labelNaKMiddleBinProductReading} is out of range`,
                });
            }
        }
        if (flow.naKSSBinProductReadingNA != null && !flow.naKSSBinProductReadingNA) {
            warnings = (0, index_1.validationMissingValue)(warnings, flow.naKSSBinProductReading, "naKSSBinProductReading", labelNaKSSBinProductReading);
            if (flow.naKSSBinProductReading && (flow.naKSSBinProductReading < 0 || flow.naKSSBinProductReading > 100)) {
                warnings.push({
                    property: "naKSSBinProductReading",
                    message: `${labelNaKSSBinProductReading} is out of range`,
                });
            }
        }
        if (flow.nakBlenderNA != null && !flow.nakBlenderNA) {
            warnings = (0, index_1.validationMissingValue)(warnings, flow.naKBlender, "naKBlender", labelNaKBlender);
            if (flow.naKBlender && (flow.naKBlender < 0 || flow.naKBlender > 99999)) {
                warnings.push({
                    property: "naKBlender",
                    message: `${labelNaKBlender} is out of range`,
                });
            }
        }
    }
    return {
        errors,
        warnings,
    };
}
exports.flowInventoryReadingValidate = flowInventoryReadingValidate;
