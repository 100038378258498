"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.batchGetOpenBatchLine = exports.batchHasOpenBatchLine = exports.batchGetCurrentPkgPalletId = exports.batchGetCurrentBlend = exports.batchGetCurrentSource = exports.batchValidate = exports.BatchType = void 0;
const _1 = require(".");
var BatchType;
(function (BatchType) {
    BatchType["regular"] = "regular";
    BatchType["rework"] = "rework";
})(BatchType = exports.BatchType || (exports.BatchType = {}));
function batchValidate(batch) {
    let errors = [];
    let warnings = [];
    warnings = (0, _1.validationMissingValue)(warnings, batch.sapProcessOrder, "sapProcessOrder", "SAP process order");
    if (batch.sapProcessOrder != null) {
        warnings = (0, _1.validationStringNotEmpty)(warnings, batch.sapProcessOrder, "sapProcessOrder", "SAP process order");
    }
    warnings = (0, _1.validationMissingValue)(warnings, batch.batchNumber, "batchNumber", "Batch number");
    if (batch.batchNumber != null) {
        warnings = (0, _1.validationStringNotEmpty)(warnings, batch.batchNumber, "batchNumber", "Batch number");
        if (!/^[a-zA-Z0-9]{7,8}$|^$/.test(batch.batchNumber)) {
            warnings.push({
                property: "batchNumber",
                message: `Format should be 7/8 characters`,
            });
        }
    }
    return {
        errors,
        warnings,
    };
}
exports.batchValidate = batchValidate;
function batchGetCurrentSource(batch) {
    if (batch.batchLines && batch.batchLines.length > 0) {
        return batch.batchLines[batch.batchLines.length - 1].source;
    }
    return;
}
exports.batchGetCurrentSource = batchGetCurrentSource;
function batchGetCurrentBlend(batch) {
    if (batch.batchLines && batch.batchLines.length > 0) {
        return batch.batchLines[batch.batchLines.length - 1].blend;
    }
    return;
}
exports.batchGetCurrentBlend = batchGetCurrentBlend;
function batchGetCurrentPkgPalletId(batch) {
    if (batch.batchLines && batch.batchLines.length > 0) {
        return batch.batchLines[batch.batchLines.length - 1].pkgPalletId;
    }
    return;
}
exports.batchGetCurrentPkgPalletId = batchGetCurrentPkgPalletId;
function batchHasOpenBatchLine(batch) {
    if (!batch.batchLines)
        return false;
    return batch.batchLines.some((batchLine) => !batchLine.closedAt);
}
exports.batchHasOpenBatchLine = batchHasOpenBatchLine;
function batchGetOpenBatchLine(batch) {
    if (!batch.batchLines)
        return;
    return batch.batchLines.sort((a, b) => (b.id || 0) - (a.id || 0)).find((batchLine) => !batchLine.closedAt);
}
exports.batchGetOpenBatchLine = batchGetOpenBatchLine;
