import { useQuery, UseQueryResult } from "@tanstack/react-query";
import getAPI from "../services/api";
import { IRequiredMasterSanitation } from "shared/interfaces";
import { useAppSelector } from "./redux";

export const useRequiredMasterSanitations = (): UseQueryResult<IRequiredMasterSanitation[]> => {
  const { area, product } = useAppSelector((state) => state.shift);
  return useQuery(["master-sanitation", area, product], async () => {
    const api = await getAPI();
    const { data } = await api.get("/mss-tasks/requiredMasterSanitations", { params: { product, area } });
    return data.sort((a, b) => a.daysUntilDueDate - b.daysUntilDueDate);
  });
};

export default useRequiredMasterSanitations;
