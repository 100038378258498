"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.batchLineValidate = exports.BatchLineType = void 0;
const _1 = require(".");
const materialRules_1 = require("../materialRules");
var BatchLineType;
(function (BatchLineType) {
    BatchLineType["regular"] = "regular";
    BatchLineType["metalDetection"] = "metalDetection";
})(BatchLineType = exports.BatchLineType || (exports.BatchLineType = {}));
function batchLineValidate(batchLine, material, forRework = false) {
    let errors = [];
    let warnings = [];
    warnings = (0, _1.validationMissingValue)(warnings, batchLine.source, "source", "source");
    warnings = (0, _1.validationMissingValue)(warnings, batchLine.blend, "blend", "path");
    if (!forRework) {
        warnings = (0, _1.validationMissingValue)(warnings, batchLine.pkgPalletId, "pkgPalletId", "Pkg pallet id");
        if (batchLine.pkgPalletId != null) {
            warnings = (0, _1.validationStringNotEmpty)(warnings, batchLine.pkgPalletId, "pkgPalletId", "Pkg pallet id");
            if (!/^[a-zA-Z0-9]{7,8}-\d{4}$|^$/.test(batchLine.pkgPalletId)) {
                warnings.push({
                    property: "pkgPalletId",
                    message: `Format should be 7/8 characters + "-" + 4 digits`,
                });
            }
        }
    }
    if ((material === null || material === void 0 ? void 0 : material.product) === _1.Product.Ca) {
        warnings = (0, _1.validationMissingValue)(warnings, batchLine.blendNumber, "blendNumber", "Blend number");
        if (batchLine.blendNumber != null) {
            warnings = (0, _1.validationStringNotEmpty)(warnings, batchLine.blendNumber, "blendNumber", "Blend number");
        }
    }
    if (material === null || material === void 0 ? void 0 : material.productCode) {
        if (batchLine.source) {
            const acceptableSources = (0, materialRules_1.getListOfPossibleSourcesIDListFromProductCode)(material.productCode);
            if (batchLine.source.idList) {
                if (!acceptableSources.includes(batchLine.source.idList)) {
                    warnings.push({
                        property: "source",
                        message: `Source ${batchLine.source.reference} is not acceptable for product code ${material.productCode}`,
                    });
                }
                if (batchLine.blend) {
                    const acceptableBlends = (0, materialRules_1.getListOfPossibleBlendsIDListFromSourceIDList)(batchLine.source.idList);
                    if (batchLine.blend.idList && !acceptableBlends.includes(batchLine.blend.idList)) {
                        warnings.push({
                            property: "blend",
                            message: `Path ${batchLine.blend.reference} is not acceptable for source ${batchLine.source.reference}`,
                        });
                    }
                }
            }
        }
    }
    return {
        errors,
        warnings,
    };
}
exports.batchLineValidate = batchLineValidate;
