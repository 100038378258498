import React, { useState } from "react";
import { Switch } from "antd";
import { YesNoNA } from "shared/interfaces";

interface YesNoSwitchProps {
  value?: any;
  onChange?: (value: any) => void;
  disabled?: boolean;
  checkedChildren?: string;
  unCheckedChildren?: string;
}

const YesNoSwitch: React.FC<YesNoSwitchProps> = ({ onChange, value, disabled, checkedChildren = YesNoNA.Yes, unCheckedChildren = YesNoNA.No }) => {
  const [isYes, setIsYes] = useState(value);

  const handleSwitchChange = (checked) => {
    setIsYes(checked);
    onChange && onChange(checked);
  };

  return (
    <>
      <Switch
        checkedChildren={checkedChildren}
        unCheckedChildren={unCheckedChildren}
        defaultChecked={isYes}
        checked={isYes}
        onChange={handleSwitchChange}
        disabled={disabled}
      />
    </>
  );
};

export default YesNoSwitch;
