import React from "react";
import { diffLines } from "diff";
import _ from "lodash";

export enum DiffDisplayItemType {
  flow,
  batch,
}

const JsonDiffDisplay = ({ currentItem, previousItem, type }) => {
  let item1 = omitUnnecessaryProperties(currentItem, type);
  let item2 = omitUnnecessaryProperties(previousItem, type);

  const strJson1 = JSON.stringify(item1, null, 2);
  const strJson2 = JSON.stringify(item2, null, 2);

  const diffResult = diffLines(strJson2, strJson1);

  const formattedDiff = diffResult.map((part, index) => (
    <div key={index} style={{ color: part.added ? "green" : part.removed ? "red" : "grey" }}>
      {part.value.split("\n").map((line, lineIndex) => {
        if (line.trim().length > 0) {
          return (
            <div key={lineIndex}>
              {part.added ? "+" : part.removed ? "-" : " "}
              {line}
            </div>
          );
        } else {
          return null;
        }
      })}
    </div>
  ));

  return <div style={{ whiteSpace: "pre-wrap", fontFamily: "monospace", backgroundColor: "black", padding: 10 }}>{formattedDiff}</div>;
};
export default JsonDiffDisplay;

function omitUnnecessaryProperties(obj, type: DiffDisplayItemType) {
  let newObj = {};
  switch (type) {
    case DiffDisplayItemType.flow:
      newObj = _.omit(obj, [
        "id",
        "type",
        "version",
        "flow",
        "flags",
        "statuses",
        "createdAt",
        "shift",
        "canGoToNextShift",
        "shouldBeSubmittedByDate",
        "manuallyCreated",
        "openedBy",
      ]);
      break;
    case DiffDisplayItemType.batch:
      newObj = _.omit(obj, ["id", "version"]);
      break;
  }
  return omitId(newObj);
}

function omitId(obj) {
  if (_.isArray(obj)) {
    return obj.map(omitId);
  } else if (_.isObject(obj)) {
    let newObj = _.omit(obj, "id");
    _.keys(newObj).forEach((key) => {
      newObj[key] = omitId(newObj[key]);
    });
    return newObj;
  } else {
    return obj;
  }
}
