import { Button, Form, Modal, notification, Steps } from "antd";
import React, { useEffect } from "react";
import useBatch from "../../hooks/useBatch";
import BatchRecordFlowForm from "./FlowForms/BatchRecordFlowForm";
import TextArea from "antd/es/input/TextArea";
import getAPI from "../../services/api";
import useShift from "../../hooks/useShift";
import { useParams } from "react-router";
import { nowDateTimeISOString } from "shared/utils";
import useBatchRecords from "../../hooks/useBatchRecords";

const CloseBatchModal: React.FC<{
  batchId: number | undefined;
  flowId: number | undefined;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ open, setOpen, batchId, flowId }) => {
  const { data: batch, refetch: refetchBatch } = useBatch(batchId);
  const { shiftId } = useParams();
  const { refetch: refetchShift } = useShift(shiftId);
  const { refetch: refetchBatchRecords } = useBatchRecords({ batchId, latest: true });

  const steps = [
    {
      title: "Batch record",
    },
    {
      title: "Close batch",
    },
  ];
  const [current, setCurrent] = React.useState(flowId ? 0 : 1);

  useEffect(() => {
    if (flowId) {
      setCurrent(0);
    }
  }, [flowId]);

  const [form] = Form.useForm();

  return (
    <>
      {batch && (
        <Modal
          okText={"Submit"}
          title="Close Batch"
          open={open}
          onCancel={() => {
            setOpen(false);
          }}
          maskClosable={false}
          footer={null}
          destroyOnClose
          width={1200}
          style={{ padding: 30 }}
        >
          <Steps current={current} items={steps} style={{ width: 400, marginTop: 20, marginBottom: 20 }} />
          {current === 0 && (
            <BatchRecordFlowForm
              flowId={flowId}
              onSuccess={async () => {
                await refetchBatchRecords();
                setCurrent(1);
              }}
            />
          )}
          {current === 1 && (
            <Form
              onFinish={async (values) => {
                try {
                  const api = await getAPI();
                  await api.put(`/batches/${batch.id}/close`, {
                    data: {
                      comment: values.comment,
                      closedAt: nowDateTimeISOString(),
                    },
                  });
                  await refetchBatch();
                  await refetchShift();
                  await refetchBatchRecords();
                  setOpen(false);
                } catch (error: any) {
                  notification.error({
                    message: "Error",
                    description: `${error.message}`,
                  });
                }
              }}
              form={form}
            >
              <Form.Item label="Comment" name="comment">
                <TextArea />
              </Form.Item>
              <div style={{ display: "flex", justifyContent: "right" }}>
                <Button type="primary" htmlType="submit">
                  Close Batch
                </Button>
              </div>
            </Form>
          )}
        </Modal>
      )}
    </>
  );
};

export default CloseBatchModal;
