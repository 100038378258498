import { useQuery, UseQueryResult } from "@tanstack/react-query";
import getAPI from "../services/api";
import { BatchType, IBatch, IBatchRegular, IBatchRework } from "shared/interfaces";

const useBatch = (id: number | string | undefined): UseQueryResult<IBatch, Error> => {
  return useQuery(
    ["batch", id],
    async () => {
      const api = await getAPI();
      if (id === undefined) return;
      const { data } = await api.get(`/batches/${id}`, {});

      let batch: IBatch;

      switch (data.type) {
        case BatchType.regular:
          batch = data as IBatchRegular;
          break;
        case BatchType.rework:
          batch = data as IBatchRework;
          break;
        default:
          batch = data as IBatch;
      }
      return batch;
    },
    { enabled: !!id }
  );
};

export default useBatch;
