import { useQuery } from "@tanstack/react-query";
import getAPI from "../services/api";

export const getLocalizedText = async (key: string): Promise<string | null> => {
  const api = await getAPI();
  const response = await api.get<string | null>(`/localized-texts/getOne/${key}`);
  if (response.status !== 200) return null;
  return response.data;
};

export const useLocalizedText = (key: string) =>
  useQuery<string | null>(
    ["localized-text", key],
    async () => {
      return getLocalizedText(key);
    },
    {
      enabled: !!key,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
    }
  ).data;
