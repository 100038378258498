import { useQuery, UseQueryResult } from "@tanstack/react-query";
import getAPI from "../services/api";
import { IBlendPossible } from "shared/interfaces";

export const useBlends = (filters): UseQueryResult<IBlendPossible[]> => {
  const { sourceId, product } = filters;

  return useQuery(
    ["blend", product, sourceId],
    async () => {
      if (sourceId === undefined || product === undefined) return undefined;
      const api = await getAPI();
      const { data } = await api.get(`/blends/forProductAndSource/${product}/${sourceId}`);
      const transformedData = data.blends.map((blend) => {
        return {
          ...blend, // Spread operator to copy all properties of the source
          possible: data.possibleBlends.includes(blend.id),
        };
      });
      return transformedData?.sort((a, b) => b.reference.localeCompare(a.reference))?.sort((a, b) => Number(b.possible) - Number(a.possible)) || [];
    },
    { enabled: sourceId !== undefined && product !== undefined }
  );
};

export default useBlends;
