import React, { useState } from "react";
import { useNavigate, useParams } from "react-router";
import InspectionFlowForm from "./FlowForms/InspectionFlowForm";
import MarkdownHelp from "../UI/MarkdownHelp";
import { flowGetFlowName, flowGetLabel, FlowType } from "shared/interfaces";

import useFlow from "../../hooks/useFlow";
import { Button, Layout, Modal, Spin } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { ArrowLeftOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { useLocalizedText } from "../../hooks/LocalizedText";
import ShiftCheckInFlowForm from "./FlowForms/ShiftCheckInFlowForm";
import BatchRecordFlowForm from "./FlowForms/BatchRecordFlowForm";
import ShiftCheckOutFlowForm from "./FlowForms/ShiftCheckOutFlowForm";
import ScaleTestFlowForm from "./FlowForms/ScaleTestFlowForm";
import MetalDetectorFlowForm from "./FlowForms/MetalDetectorFlowForm";
import DustCollectorFlowForm from "./FlowForms/DustCollectorFlowForm";
import InventoryReadingFlowForm from "./FlowForms/InventoryReadingFlowForm";
import PackageInspectionFlowForm from "./FlowForms/PackageInspectionFlowForm";
import LogFlowForm from "./FlowForms/LogFlowForm";
import MasterSanitationFlowForm from "./FlowForms/MasterSanitationFlowForm";
import SafetyShowerFlowForm from "./FlowForms/SafetyShowerFlowForm";
import { useMatches } from "react-router-dom";

const { Content } = Layout;

const FlowViewScreen = () => {
  const { flowId } = useParams();
  const navigate = useNavigate();
  const { data: flow, isLoading: isFlowLoading } = useFlow(flowId);
  const matches = useMatches();
  const back = matches.length > 2 ? matches[matches.length - 2].pathname : -1;
  const params = useParams();
  const version = parseInt(params.version || "");

  const help = useLocalizedText((flow && flowGetFlowName(flow)) ?? "");

  const [formHasUnsavedChanges, setFormHasUnsavedChanges] = useState(false);

  const confirmNavigation = () => {
    if (formHasUnsavedChanges) {
      Modal.confirm({
        title: "Are you sure you want to leave this page?",
        content: "You have unsaved changes that will be lost.",
        // @ts-ignore
        onOk: () => navigate(back),
      });
    } else {
      // @ts-ignore
      navigate(back);
    }
  };

  const onSuccess = () => {
    setFormHasUnsavedChanges(false);
    navigate(-1);
  };

  return (
    <Content
      style={{
        height: "calc(100vh - 64px)",
        overflowY: "auto",
        position: "relative",
        padding: 20,
      }}
    >
      <PageHeader
        style={{ marginBottom: 16 }}
        title={
          isFlowLoading || !flow ? (
            "Loading..."
          ) : (
            <>
              {flowGetLabel(flow)}
              {version > 0 && <span style={{ marginLeft: 10 }}>(v{version})</span>}
              {help && (
                <Button
                  type="link"
                  style={{ marginLeft: 20 }}
                  onClick={() => {
                    Modal.info({
                      title: "",
                      content: MarkdownHelp({ help }),
                      width: "80vw",
                    });
                  }}
                  icon={<InfoCircleOutlined />}
                />
              )}
            </>
          )
        }
        backIcon={<ArrowLeftOutlined />}
        // @ts-ignore
        onBack={confirmNavigation}
      />
      <Spin spinning={!flow}>
        {flow?.type === FlowType.shiftCheckIn && (
          <ShiftCheckInFlowForm
            flowId={flow.id}
            version={version}
            readonly={true}
            setFormHasUnsavedChanges={setFormHasUnsavedChanges}
            onSuccess={onSuccess}
          />
        )}
        {flow?.type === FlowType.shiftCheckOut && (
          <ShiftCheckOutFlowForm
            flowId={flow.id}
            version={version}
            readonly={true}
            setFormHasUnsavedChanges={setFormHasUnsavedChanges}
            onSuccess={onSuccess}
          />
        )}
        {flow?.type === FlowType.batchRecord && (
          <BatchRecordFlowForm
            flowId={flow.id}
            version={version}
            readonly={true}
            setFormHasUnsavedChanges={setFormHasUnsavedChanges}
            onSuccess={onSuccess}
          />
        )}
        {flow?.type === FlowType.inspection && (
          <InspectionFlowForm
            flowId={flow.id}
            version={version}
            readonly={true}
            setFormHasUnsavedChanges={setFormHasUnsavedChanges}
            onSuccess={onSuccess}
          />
        )}
        {flow?.type === FlowType.scaleTest && (
          <ScaleTestFlowForm
            flowId={flow.id}
            version={version}
            readonly={true}
            setFormHasUnsavedChanges={setFormHasUnsavedChanges}
            onSuccess={onSuccess}
          />
        )}
        {flow?.type === FlowType.metalDetector && (
          <MetalDetectorFlowForm
            flowId={flow.id}
            version={version}
            readonly={true}
            setFormHasUnsavedChanges={setFormHasUnsavedChanges}
            onSuccess={onSuccess}
          />
        )}
        {flow?.type === FlowType.dustCollector && (
          <DustCollectorFlowForm
            flowId={flow.id}
            version={version}
            readonly={true}
            setFormHasUnsavedChanges={setFormHasUnsavedChanges}
            onSuccess={onSuccess}
          />
        )}
        {flow?.type === FlowType.inventoryReading && (
          <InventoryReadingFlowForm
            flowId={flow.id}
            version={version}
            readonly={true}
            setFormHasUnsavedChanges={setFormHasUnsavedChanges}
            onSuccess={onSuccess}
          />
        )}
        {flow?.type === FlowType.packageInspection && (
          <PackageInspectionFlowForm
            flowId={flow.id}
            version={version}
            readonly={true}
            setFormHasUnsavedChanges={setFormHasUnsavedChanges}
            onSuccess={onSuccess}
          />
        )}
        {flow?.type === FlowType.log && (
          <LogFlowForm flowId={flow.id} version={version} readonly={true} setFormHasUnsavedChanges={setFormHasUnsavedChanges} onSuccess={onSuccess} />
        )}
        {flow?.type === FlowType.safetyShower && (
          <SafetyShowerFlowForm
            flowId={flow.id}
            version={version}
            readonly={true}
            setFormHasUnsavedChanges={setFormHasUnsavedChanges}
            onSuccess={onSuccess}
          />
        )}
        {flow?.type === FlowType.masterSanitation && (
          <MasterSanitationFlowForm
            flowId={flow.id}
            version={version}
            readonly={true}
            setFormHasUnsavedChanges={setFormHasUnsavedChanges}
            onSuccess={onSuccess}
          />
        )}
      </Spin>
    </Content>
  );
};

export default FlowViewScreen;
