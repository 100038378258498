import { useQuery, UseQueryResult } from "@tanstack/react-query";
import getAPI from "../services/api";
import { IBatchLineRegularClose } from "shared/interfaces";

const useBatchLineClosingInfo = ({
  batchId,
  batchLineId,
}: {
  batchId: number | undefined;
  batchLineId: number | undefined;
}): UseQueryResult<IBatchLineRegularClose> => {
  return useQuery(
    ["batchLineClosingInfo", batchId, batchLineId],
    async () => {
      const api = await getAPI();
      const { data } = await api.get(`/batches/${batchId}/batchLine/${batchLineId}/closingInfo`, {});
      return { ...data, openedAt: new Date(data.openedAt), closedAt: new Date(data.closedAt) };
    },
    { enabled: !!batchLineId && !!batchId }
  );
};

export default useBatchLineClosingInfo;
