import { Button, Card, Checkbox, Col, Form, Input, notification, Result, Row, Segmented, Space, Spin, Popconfirm, Divider } from "antd";
import React, { useEffect } from "react";
import YesNoNASwitch from "../../UI/YesNoNASwitch";
import getAPI from "../../../services/api";
import { useNavigate, useSearchParams } from "react-router-dom";
import useFlow from "../../../hooks/useFlow";
import { SaveOutlined, SendOutlined } from "@ant-design/icons";
import {
  BusinessRole,
  flowGetStatus,
  flowInspectionGetGenericLabel,
  flowIsEditable,
  flowValidateFE as flowValidate,
  IFlow,
  IFlowInspection,
  InspectedType,
  Product,
  Status,
  YesNoNA,
  Line,
} from "shared/interfaces";
import { useUser } from "../../../services/auth";
import FlowStatus from "../../UI/FlowStatus";

const { TextArea } = Input;

interface InspectionFlowProps {
  flowId: IFlow["id"];
  version?: IFlow["version"];
  readonly: boolean;
  onSuccess: (flow) => void;
  setFormHasUnsavedChanges?: (hasUnsavedChanges: boolean) => void;
}

const InspectionFlowForm: React.FC<InspectionFlowProps> = ({
  flowId,
  version,
  readonly: propReadonly = false,
  onSuccess,
  setFormHasUnsavedChanges,
}) => {
  const user = useUser();
  const [readonly, setReadonly] = React.useState(propReadonly);
  const navigate = useNavigate();
  const { data, isLoading: isFlowLoading, refetch: refetchFlow, error: flowError } = useFlow(flowId, version);
  const flow = data as IFlowInspection;
  const [form] = Form.useForm();
  const [hasWarnings, setHasWarnings] = React.useState<boolean>(false);
  const initialValues = flow;
  let [searchParams] = useSearchParams();
  const supervisorMode = searchParams.get("mode") === "supervisor";

  const [selectedIds, setSelectedIds] = React.useState<(number | undefined)[]>([]);

  if (initialValues) {
    if (initialValues.checks) {
      for (let i = 0; i < initialValues.checks.length; i++) {
        initialValues.checks[i].conditionAcceptable = initialValues.checks[i].conditionAcceptable ?? YesNoNA.No;
        initialValues.checks[i].check2 = initialValues.checks[i].check2 ?? YesNoNA.No;
      }
    }
  }

  useEffect(() => {
    if (initialValues?.checks) {
      setSelectedIds(initialValues.checks.filter((check) => check.selected).map((check) => check?.inspected?.id));
    }
  }, [initialValues?.checks]);

  useEffect(() => {
    const userIsReader = user?.businessRole === BusinessRole.Reader || user?.businessRole === BusinessRole.Quality;
    setReadonly((propReadonly || userIsReader) ?? false);
  }, [propReadonly, user]);

  // Little helper to get the checks from the form in the proper format for the API
  const getFormChecks = (initialValues, values) => {
    const checks = initialValues?.checks?.map((check) => {
      const { id, ...checkWithoutId } = check;
      const inspectedId = check?.inspected?.id;

      return {
        ...checkWithoutId,
        selected: values[inspectedId + "_selected"],
        conditionAcceptable: values[inspectedId + "_conditionAcceptable"],
        ...(check?.inspected.needCheck2 ? { check2: values[inspectedId + "_check2"] } : {}),
        ...(values[inspectedId + "_screen_size"] ? { screenSize: values[inspectedId + "_screen_size"] } : {}),
        inspected: {
          ...check.inspected,
        },
      };
    });
    return checks;
  };

  if (flowError) {
    return (
      <Result
        status="500"
        title="Mmmmh... something went wrong."
        subTitle="More luck next time?"
        extra={
          <Button type="primary" onClick={() => navigate("/")}>
            Back Home
          </Button>
        }
      />
    );
  }

  if (isFlowLoading || !flow) {
    return <Spin>Loading...</Spin>;
  }

  const span =
    flow.typeInspected === InspectedType.screen && (flow.checks?.filter((check) => check?.inspected?.product === Product.NaK).length || 0) > 0
      ? 6
      : 8;

  return (
    <Form
      initialValues={initialValues}
      disabled={readonly}
      form={form}
      onFinish={async (values) => {
        const checks = getFormChecks(initialValues, values);
        const inspectionFlow: IFlowInspection = {
          ...flow,
          ...initialValues,
          checks,
          protocol: values.protocol,
          samplesToLab: values.samplesToLab,
        };

        const { warnings } = await flowValidate(inspectionFlow);

        if (warnings.length > 0 && !hasWarnings) {
          setHasWarnings(true);
          notification.warning({
            message: "The form has warnings",
            description: "Please check the form for warnings and enter a comment to submit despite warnings",
          });
          !hasWarnings && setHasWarnings(true);
          form.setFields(
            warnings.map((warning) => {
              if (warning.property === "checks" && warning.subPropertyId != null) {
                return {
                  name: `${warning.subPropertyId}_warning`,
                  warnings: warning.messages,
                };
              }
              return {
                name: warning.property,
                warnings: warning.messages,
              };
            })
          );
          return;
        }

        try {
          const api = await getAPI();
          await api.put(`/flows/${flowId}`, {
            data: inspectionFlow,
          });
          await api.put(`/flows/${flowId}/submit`);
          await refetchFlow();
          onSuccess && flow && onSuccess(flow);
          notification.success({
            message: `The ${flowInspectionGetGenericLabel(initialValues?.typeInspected).toLowerCase()} was submitted successfully`,
          });
        } catch (error: any) {
          notification.error({
            message: "There was an error submitting the form",
            description: error?.message,
          });
        }
      }}
      onFieldsChange={async (changedFields, allFields) => {
        setFormHasUnsavedChanges && setFormHasUnsavedChanges(true);
        if (changedFields.length === 1 && changedFields[0].name[0].includes("selected")) {
          const inspectedId = changedFields[0].name[0].split("_")[0];
          form.setFieldValue(inspectedId + "_conditionAcceptable", YesNoNA.No);
          form.setFieldValue(inspectedId + "_check2", YesNoNA.No);
          form.setFieldValue(inspectedId + "_screen_size", 2);
          if (changedFields[0].value === true) {
            setSelectedIds([...selectedIds, Number(inspectedId)]);
          } else {
            setSelectedIds(selectedIds.filter((id) => id !== Number(inspectedId)));
          }
        }

        if (hasWarnings) {
          const values = form.getFieldsValue();
          // reset warnings
          Object.keys(values).forEach((key) => {
            form.setFields([
              {
                name: key,
                warnings: [],
              },
            ]);
          });
          const checks = getFormChecks(initialValues, values);
          const inspectionFlow: IFlowInspection = {
            ...flow,
            ...initialValues,
            checks,
            protocol: values.protocol,
            samplesToLab: values.samplesToLab,
          };

          const { warnings } = await flowValidate(inspectionFlow);
          // if we have warnings, we set them in the form, the user can still submit)
          if (warnings.length > 0) {
            form.setFields(
              warnings.map((warning) => {
                if (warning.property === "checks" && warning.subPropertyId != null) {
                  return {
                    name: `${warning.subPropertyId}_warning`,
                    warnings: warning.messages,
                  };
                }
                return {
                  name: warning.property,
                  warnings: warning.messages,
                };
              })
            );
            setHasWarnings(true);
          } else if (warnings.length === 0) {
            setHasWarnings(false);
          }
        }
      }}
    >
      <Form.Item name="protocol" label="Protocol" valuePropName="checked">
        <Checkbox />
      </Form.Item>
      <Row>
        {flow.typeInspected === InspectedType.screen ? (
          <Col span={24} style={{ margin: "16px 0" }}>
            <strong>Acceptable Condition of Screen:</strong> No damages (i.e., no tears, sagging or separation from the frame); No foreign
            contamination or objects found; No missing parts from the screen system.
          </Col>
        ) : (
          <Col span={24} style={{ margin: "16px 0" }}>
            <strong>Acceptable Condition of Magnet:</strong> No excessive metal residue. No contamination and/or foreign objects found.
          </Col>
        )}
        <Col span={span} />
      </Row>
      <Space direction="vertical" style={{ width: "100%" }}>
        {flow.checks?.filter((check) => check.required).length ? (
          <Card title={"Required"} size="small">
            <Row>
              {flow.typeInspected === InspectedType.screen && flow.checks?.filter((check) => check?.inspected?.product === Product.NaK).length > 0 && (
                <Col span={span} offset={span}>
                  <h3>Screen size</h3>
                </Col>
              )}
              <Col
                span={span}
                offset={
                  flow.typeInspected === InspectedType.screen && flow.checks?.filter((check) => check?.inspected?.product === Product.NaK).length > 0
                    ? 0
                    : span
                }
              >
                {flow.typeInspected === InspectedType.magnet && <h3>Condition of magnet acceptable</h3>}
                {flow.typeInspected === InspectedType.screen && <h3>Condition of screen acceptable</h3>}
              </Col>
              <Col span={span}>
                {flow.typeInspected === InspectedType.magnet && <h3>Unusual objects on magnet (parts/grease)</h3>}
                {flow.typeInspected === InspectedType.screen &&
                  flow?.checks?.filter((check) => check.required).reduce((acc, check) => acc || !!check.inspected?.needCheck2, false) && (
                    <h3>5 balls per tray</h3>
                  )}
              </Col>
              <Col span={24} style={{ marginTop: "20px" }} />
              {(() => {
                let alreadyDividerLine1 = false;
                let alreadyDividerLine2 = false;
                return flow.checks
                  ?.filter((check) => check.required)
                  .sort(lineComparator)
                  .map((check) => {
                    let divider: React.ReactNode | null = null;

                    if (check.inspected?.line === Line.line1 && !alreadyDividerLine1) {
                      divider = (
                        <Divider orientation="left" orientationMargin="15">
                          Line 1
                        </Divider>
                      );
                      alreadyDividerLine1 = true;
                    }

                    // Check if this is the first check for line2
                    if (check.inspected?.line === Line.line2 && !alreadyDividerLine2) {
                      divider = (
                        <Divider orientation="left" orientationMargin="15">
                          Line 2
                        </Divider>
                      );
                      alreadyDividerLine2 = true;
                    }

                    return (
                      <>
                        {divider}
                        <Col span={span}>
                          <Form.Item name={check?.inspected?.id + "_selected"} valuePropName={"checked"} hidden>
                            <Checkbox checked={true} />
                          </Form.Item>
                          <label style={{ position: "absolute", top: "0.2rem" }}>
                            {check.inspected?.reference} ({check.inspected?.tag})
                          </label>
                          <Form.Item name={check.inspected?.id + "_warning"}></Form.Item>
                        </Col>
                        {flow.typeInspected === InspectedType.screen && check?.inspected?.product === Product.NaK && (
                          <Col span={span}>
                            {check?.inspected?.needScreenSize ? (
                              <Form.Item name={check?.inspected?.id + "_screen_size"} initialValue={check?.screenSize || 2}>
                                <Segmented
                                  disabled={readonly}
                                  options={[
                                    { label: "2mm", value: 2 },
                                    { label: "4mm", value: 4 },
                                    { label: "9mm", value: 9 },
                                  ]}
                                />
                              </Form.Item>
                            ) : null}
                          </Col>
                        )}
                        <Col span={span}>
                          <Form.Item name={check.inspected?.id + "_conditionAcceptable"} initialValue={check.conditionAcceptable}>
                            <YesNoNASwitch disabled={readonly} />
                          </Form.Item>
                        </Col>
                        {check.inspected?.needCheck2 && (
                          <Col span={span}>
                            <Form.Item name={check.inspected?.id + "_check2"} initialValue={check.check2}>
                              <YesNoNASwitch disabled={readonly} />
                            </Form.Item>
                          </Col>
                        )}
                        <Col span={24} />
                      </>
                    );
                  });
              })()}
            </Row>
          </Card>
        ) : (
          <></>
        )}
        {flow.checks?.filter((check) => !check.required).length && (
          <Card title={"Optional"} size="small">
            <Row>
              {flow.typeInspected === InspectedType.screen && flow.checks?.filter((check) => check?.inspected?.product === Product.NaK).length > 0 && (
                <Col span={span} offset={span}>
                  <h3>Screen size</h3>
                </Col>
              )}
              <Col
                span={span}
                offset={
                  flow.typeInspected === InspectedType.screen && flow.checks?.filter((check) => check?.inspected?.product === Product.NaK).length > 0
                    ? 0
                    : span
                }
              >
                {flow.typeInspected === InspectedType.magnet && <h3>Condition of magnet acceptable</h3>}
                {flow.typeInspected === InspectedType.screen && <h3>Condition of screen acceptable</h3>}
              </Col>
              <Col span={span}>
                {flow.typeInspected === InspectedType.magnet && <h3>Unusual objects on magnet (parts/grease)</h3>}
                {flow.typeInspected === InspectedType.screen &&
                  flow.checks?.filter((check) => !check.required).reduce((acc, check) => acc || !!check.inspected?.needCheck2, false) && (
                    <h3>5 balls per tray</h3>
                  )}
              </Col>
              <Col span={24} style={{ marginTop: "20px" }} />
              {(() => {
                let alreadyDividerLine1 = false;
                let alreadyDividerLine2 = false;
                return flow.checks
                  ?.filter((check) => !check.required)
                  .sort(lineComparator)
                  .map((check) => {
                    const isSelected = selectedIds.includes(Number(check.inspected?.id));

                    let divider: React.ReactNode | null = null;

                    if (check.inspected?.line === Line.line1 && !alreadyDividerLine1) {
                      divider = (
                        <Divider orientation="left" orientationMargin="15">
                          Line 1
                        </Divider>
                      );
                      alreadyDividerLine1 = true;
                    }

                    // Check if this is the first check for line2
                    if (check.inspected?.line === Line.line2 && !alreadyDividerLine2) {
                      divider = (
                        <Divider orientation="left" orientationMargin="15">
                          Line 2
                        </Divider>
                      );
                      alreadyDividerLine2 = true;
                    }

                    return (
                      <>
                        {divider}
                        <Col span={span}>
                          <Space size="large">
                            <Form.Item name={check?.inspected?.id + "_selected"} valuePropName={"checked"} initialValue={check?.selected}>
                              <Checkbox />
                            </Form.Item>
                            <label style={{ position: "absolute", top: "0.2rem" }}>
                              {check.inspected?.reference} ({check.inspected?.tag})
                            </label>
                            <Form.Item name={check.inspected?.id + "_warning"}></Form.Item>
                          </Space>
                        </Col>
                        {flow.typeInspected === InspectedType.screen && check?.inspected?.product === Product.NaK && (
                          <Col span={span}>
                            {check?.inspected?.needScreenSize ? (
                              <Form.Item name={check?.inspected?.id + "_screen_size"} initialValue={check?.screenSize || 2}>
                                <Segmented
                                  disabled={readonly || !isSelected}
                                  options={[
                                    { label: "2mm", value: 2 },
                                    { label: "4mm", value: 4 },
                                    { label: "9mm", value: 9 },
                                  ]}
                                />
                              </Form.Item>
                            ) : null}
                          </Col>
                        )}
                        <Col span={span}>
                          <Form.Item name={check.inspected?.id + "_conditionAcceptable"} initialValue={check?.conditionAcceptable}>
                            <YesNoNASwitch disabled={readonly || !isSelected} />
                          </Form.Item>
                        </Col>
                        {check.inspected?.needCheck2 && (
                          <Col span={span}>
                            <Form.Item name={check.inspected?.id + "_check2"} initialValue={check.check2}>
                              <YesNoNASwitch disabled={readonly || !isSelected} />
                            </Form.Item>
                          </Col>
                        )}
                        <Col span={24} />
                      </>
                    );
                  });
              })()}
            </Row>
          </Card>
        )}
        <Form.Item name="checks"></Form.Item>
        {flow.typeInspected === InspectedType.magnet && (
          <Form.Item label="Samples to Lab" name="samplesToLab">
            <YesNoNASwitch disabled={readonly} />
          </Form.Item>
        )}
        <Form.Item
          name="comment"
          label="Comment"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          rules={[{ required: hasWarnings, message: "Please add a comment to explain warnings" }]}
        >
          <TextArea disabled={readonly} />
        </Form.Item>
      </Space>
      <Space direction="vertical" style={{ width: "100%" }} size="large">
        {!readonly && (
          <div style={{ display: "flex", justifyContent: "right" }}>
            <Space>
              {supervisorMode ? (
                <Popconfirm
                  title="Save your change"
                  description="To make a change, you must be trained to do so and you MUST add an explanation in the comment box, below the existing comments. If you have done so, you can click on Save."
                  onConfirm={async () => {
                    try {
                      const checks = getFormChecks(initialValues, form.getFieldsValue());
                      const updatedFlow = {
                        ...flow,
                        typeInspected: initialValues?.typeInspected,
                        comment: form.getFieldsValue().comment,
                        checks,
                        protocol: form.getFieldsValue().protocol,
                        samplesToLab: form.getFieldsValue().samplesToLab,
                      };
                      const api = await getAPI();
                      await api.put(`/flows/${flowId}/edit`, {
                        data: updatedFlow,
                      });
                      await refetchFlow();
                      notification.success({
                        message: "Changes saved",
                      });
                      setFormHasUnsavedChanges && setFormHasUnsavedChanges(false);
                    } catch (error: any) {
                      notification.error({
                        message: "Error saving changes",
                        description: error?.message,
                      });
                    }
                  }}
                  okText="Save"
                  cancelText="Cancel"
                >
                  <Button size="large" icon={<SaveOutlined />} type="primary">
                    Save changes
                  </Button>
                </Popconfirm>
              ) : (
                <>
                  <Button
                    disabled={flowGetStatus(flow) === Status.confirmed}
                    size="large"
                    icon={<SaveOutlined />}
                    onClick={async () => {
                      try {
                        const checks = getFormChecks(initialValues, form.getFieldsValue());
                        const updatedFlow = {
                          ...flow,
                          typeInspected: initialValues?.typeInspected,
                          comment: form.getFieldsValue().comment,
                          checks,
                          protocol: form.getFieldsValue().protocol,
                          samplesToLab: form.getFieldsValue().samplesToLab,
                        };
                        const api = await getAPI();
                        await api.put(`/flows/${flowId}`, {
                          data: updatedFlow,
                        });
                        await refetchFlow();
                        notification.success({
                          message: "Inspection draft saved",
                        });
                        setFormHasUnsavedChanges && setFormHasUnsavedChanges(false);
                      } catch (error: any) {
                        notification.error({
                          message: "Error saving inspection draft",
                          description: error?.message,
                        });
                      }
                    }}
                  >
                    Save Draft
                  </Button>
                  <Button size="large" icon={<SendOutlined />} htmlType="submit" type="primary" disabled={!flowIsEditable(flow) || readonly}>
                    {hasWarnings ? "Submit with warnings" : "Submit"}
                  </Button>
                </>
              )}
            </Space>
          </div>
        )}
        <FlowStatus flow={flow} />
      </Space>
    </Form>
  );
};

export default InspectionFlowForm;

function lineComparator(checkA, checkB) {
  const lineOrder = [Line.other, Line.line1, Line.line2];

  // assign line other if there is no line
  let lineA = lineOrder.includes(checkA.inspected.line) ? checkA.inspected.line : Line.other;
  let lineB = lineOrder.includes(checkB.inspected.line) ? checkB.inspected.line : Line.other;

  if (lineOrder.indexOf(lineA) !== lineOrder.indexOf(lineB)) {
    return lineOrder.indexOf(lineA) - lineOrder.indexOf(lineB);
  }
  return checkA.inspected.reference.localeCompare(checkB.inspected.reference);
}
