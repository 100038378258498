"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.flowMasterSanitationValidate = exports.flowMasterSanitationGetLabelPeriod = exports.flowMasterSanitationGetFlowName = exports.flowMasterSanitationGetGenericLabel = exports.flowMasterSanitationGetLabel = exports.Frequency = void 0;
const utils_1 = require("../utils");
const luxon_1 = require("luxon");
const index_1 = require("./index");
var Frequency;
(function (Frequency) {
    Frequency["Weekly"] = "Weekly";
    Frequency["Monthly"] = "Monthly";
    Frequency["Quarterly"] = "Quarterly";
    Frequency["Annually"] = "Annually";
})(Frequency = exports.Frequency || (exports.Frequency = {}));
function flowMasterSanitationGetLabel(flow) {
    var _a;
    return `${flowMasterSanitationGetGenericLabel()} - ${(_a = flow.mssTask) === null || _a === void 0 ? void 0 : _a.title}`;
}
exports.flowMasterSanitationGetLabel = flowMasterSanitationGetLabel;
function flowMasterSanitationGetGenericLabel() {
    return `MSS Task`;
}
exports.flowMasterSanitationGetGenericLabel = flowMasterSanitationGetGenericLabel;
function flowMasterSanitationGetFlowName() {
    return "FlowMasterSanitation";
}
exports.flowMasterSanitationGetFlowName = flowMasterSanitationGetFlowName;
function flowMasterSanitationGetLabelPeriod(flow) {
    let labelPeriod = "";
    if (flow.mssTask && flow.mssTask.frequency && flow.year && flow.periodNumber && flow.period) {
        if (flow.mssTask.frequency === Frequency.Weekly) {
            let date = luxon_1.DateTime.fromObject({ year: flow.year, month: 1, day: 1 });
            date = date.set({ weekYear: flow.year, weekNumber: flow.periodNumber });
            labelPeriod = `Week ${flow.periodNumber} (${date.startOf("week").toFormat("LLL d")} - ${date.endOf("week").toFormat("LLL d")}) of ${flow.year}`;
        }
        if (flow.mssTask.frequency === Frequency.Monthly) {
            let date = luxon_1.DateTime.fromObject({ year: flow.year, month: flow.periodNumber, day: 1 });
            labelPeriod = `${date.toFormat("MMMM")} ${flow.year}`;
        }
        if (flow.mssTask.frequency === Frequency.Quarterly) {
            labelPeriod = `Quarter ${flow.periodNumber} of ${flow.year}`;
        }
        if (flow.mssTask.frequency === Frequency.Annually) {
            labelPeriod = `Year ${flow.year}`;
        }
    }
    return labelPeriod;
}
exports.flowMasterSanitationGetLabelPeriod = flowMasterSanitationGetLabelPeriod;
function flowMasterSanitationValidate(flow) {
    let errors = [];
    let warnings = [];
    const labelMSSTask = "MSS Task";
    const labelDone = "Done";
    const labelPeriod = "Period";
    const labelPeriodNumber = "Period number";
    const labelYear = "Year";
    warnings = (0, index_1.validationMissingValue)(warnings, flow.mssTask, "mssTask", labelMSSTask);
    warnings = (0, index_1.validationMissingValue)(warnings, flow.done, "done", labelDone);
    warnings = (0, index_1.validationMissingValue)(warnings, flow.period, "period", labelPeriod);
    warnings = (0, index_1.validationMissingValue)(warnings, flow.periodNumber, "periodNumber", labelPeriodNumber);
    warnings = (0, index_1.validationMissingValue)(warnings, flow.year, "year", labelYear);
    warnings = (0, index_1.validationBooleanValue)(warnings, flow.done, true, "done", labelDone);
    if (flow.mssTask && flow.year && flow.periodNumber) {
        const now = (0, utils_1.nowDateTime)();
        if (flow.mssTask.frequency === Frequency.Weekly) {
            if (flow.year === now.year && flow.periodNumber > now.weekNumber) {
                warnings.push({
                    property: "periodNumber",
                    message: `${labelPeriodNumber} is in the future`,
                });
            }
        }
        if (flow.mssTask.frequency === Frequency.Monthly) {
            if (flow.year === now.year && flow.periodNumber > now.month) {
                warnings.push({
                    property: "periodNumber",
                    message: `${labelPeriodNumber} is in the future`,
                });
            }
        }
        if (flow.mssTask.frequency === Frequency.Quarterly) {
            if (flow.year === now.year && flow.periodNumber > now.quarter) {
                warnings.push({
                    property: "periodNumber",
                    message: `${labelPeriodNumber} is in the future`,
                });
            }
        }
    }
    return {
        errors,
        warnings,
    };
}
exports.flowMasterSanitationValidate = flowMasterSanitationValidate;
