"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.flowShiftCheckOutValidate = exports.flowShiftCheckOutGetFlowName = exports.flowShiftCheckOutGetGenericLabel = exports.flowShiftCheckOutGetLabel = void 0;
const index_1 = require("./index");
function flowShiftCheckOutGetLabel() {
    return flowShiftCheckOutGetGenericLabel();
}
exports.flowShiftCheckOutGetLabel = flowShiftCheckOutGetLabel;
function flowShiftCheckOutGetGenericLabel() {
    return "Shift Check Out";
}
exports.flowShiftCheckOutGetGenericLabel = flowShiftCheckOutGetGenericLabel;
function flowShiftCheckOutGetFlowName() {
    return "FlowShiftCheckOut";
}
exports.flowShiftCheckOutGetFlowName = flowShiftCheckOutGetFlowName;
function flowShiftCheckOutValidate(flow, theShift) {
    var _a;
    let shift = theShift;
    if (shift == null) {
        shift = flow.shift;
    }
    let errors = [];
    let warnings = [];
    const labelAllFlowsFinalized = "All flows finalized";
    const labelAllBatchesClosed = "All necessary batches closed";
    const labelAllBatchRecordsSubmittedSuccessfully = "All batch records submitted successfully";
    const labelLabelsExtraDestroyed = "Labels Extra Destroyed";
    const labelOversAndUndersCutIn = "Overs and Unders cut in";
    const labelPkgMaterialsConsumedSap = "Packaging materials consumed to SAP";
    warnings = (0, index_1.validationMissingValue)(warnings, flow.allFlowsFinalized, "allFlowsFinalized", labelAllFlowsFinalized);
    warnings = (0, index_1.validationBooleanValue)(warnings, flow.allFlowsFinalized, true, "allFlowsFinalized", labelAllFlowsFinalized);
    warnings = (0, index_1.validationMissingValue)(warnings, flow.allBatchesClosed, "allBatchesClosed", labelAllBatchesClosed);
    warnings = (0, index_1.validationBooleanValue)(warnings, flow.allBatchesClosed, true, "allBatchesClosed", labelAllBatchesClosed);
    warnings = (0, index_1.validationMissingValue)(warnings, flow.allBatchRecordsSubmittedSuccessfully, "allBatchRecordsSubmittedSuccessfully", labelAllBatchRecordsSubmittedSuccessfully);
    warnings = (0, index_1.validationBooleanValue)(warnings, flow.allBatchRecordsSubmittedSuccessfully, true, "allBatchRecordsSubmittedSuccessfully", labelAllBatchRecordsSubmittedSuccessfully);
    warnings = (0, index_1.validationMissingValue)(warnings, flow.labelsExtraDestroyed, "labelsExtraDestroyed", labelLabelsExtraDestroyed);
    warnings = (0, index_1.validationNAValue)(warnings, flow.labelsExtraDestroyed, true, "labelsExtraDestroyed", labelLabelsExtraDestroyed);
    warnings = (0, index_1.validationMissingValue)(warnings, flow.oversAndUndersCutIn, "oversAndUndersCutIn", labelOversAndUndersCutIn);
    warnings = (0, index_1.validationNAValue)(warnings, flow.oversAndUndersCutIn, true, "oversAndUndersCutIn", labelOversAndUndersCutIn);
    warnings = (0, index_1.validationMissingValue)(warnings, flow.pkgMaterialsConsumedSap, "pkgMaterialsConsumedSap", labelPkgMaterialsConsumedSap);
    warnings = (0, index_1.validationNAValue)(warnings, flow.pkgMaterialsConsumedSap, true, "pkgMaterialsConsumedSap", labelPkgMaterialsConsumedSap);
    if (shift && flow.allFlowsFinalized) {
        const flowsNotAllFinalized = (_a = shift.flows) === null || _a === void 0 ? void 0 : _a.some((f) => f.type !== index_1.FlowType.shiftCheckOut && (0, index_1.flowIsNotFinalized)(f));
        if (flowsNotAllFinalized) {
            warnings.push({
                property: "allFlowsFinalized",
                message: `${labelAllFlowsFinalized} is 'Yes' but it seems all flows are not finalized`,
            });
        }
    }
    return {
        errors,
        warnings,
    };
}
exports.flowShiftCheckOutValidate = flowShiftCheckOutValidate;
