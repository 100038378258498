import { useQuery, UseQueryResult } from "@tanstack/react-query";
import getAPI from "../services/api";
import { BatchLineType, IBatchLine, IBatchLineRegular } from "shared/interfaces";

const useBatchLine = (id: number | string | undefined): UseQueryResult<IBatchLine, Error> => {
  return useQuery(
    ["batchLine", id],
    async () => {
      const api = await getAPI();
      if (id === undefined) return;
      const { data } = await api.get(`/batch-lines/${id}`, {});

      let batchLine: IBatchLine;

      switch (data.type) {
        case BatchLineType.regular:
          batchLine = data as IBatchLineRegular;
          break;
        default:
          batchLine = data as IBatchLine;
      }
      return batchLine;
    },
    { enabled: !!id }
  );
};

export default useBatchLine;
