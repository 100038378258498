import { Button, Card, Checkbox, Form, Input, InputNumber, notification, Result, Space, Spin, Popconfirm } from "antd";
import React, { useEffect } from "react";
import getAPI from "../../../services/api";
import { useNavigate, useSearchParams } from "react-router-dom";
import useFlow from "../../../hooks/useFlow";
import { SaveOutlined, SendOutlined } from "@ant-design/icons";
import {
  BusinessRole,
  flowGetStatus,
  flowIsEditable,
  flowValidateFE as flowValidate,
  IFlow,
  IFlowPackageInspection,
  Status,
  YesNoNA,
} from "shared/interfaces";
import YesNoNASwitch from "../../UI/YesNoNASwitch";
import PictureUpload from "../../UI/PictureUpload";
import BatchNumberInput from "../../UI/BatchNumberInput";
import { useUser } from "../../../services/auth";
import FlowStatus from "../../UI/FlowStatus";

const { TextArea } = Input;

interface PackageInspectionFlowProps {
  flowId: IFlow["id"];
  version?: IFlow["version"];
  readonly: boolean;
  onSuccess: (flow) => void;
  setFormHasUnsavedChanges?: (hasUnsavedChanges: boolean) => void;
}

const PackageInspectionFlowForm: React.FC<PackageInspectionFlowProps> = ({
  flowId,
  version,
  readonly: propReadonly = false,
  onSuccess,
  setFormHasUnsavedChanges,
}) => {
  const user = useUser();
  const [readonly, setReadonly] = React.useState(propReadonly);
  const navigate = useNavigate();
  const { data, isLoading: isFlowLoading, refetch: refetchFlow, error: flowError } = useFlow(flowId, version);
  const flow = data as IFlowPackageInspection;
  const [form] = Form.useForm();
  const [hasWarnings, setHasWarnings] = React.useState<boolean>(false);
  const initialValues: any = flow;
  let [searchParams] = useSearchParams();
  const supervisorMode = searchParams.get("mode") === "supervisor";
  const [bagSelected, setBagSelected] = React.useState<boolean>(!!flow?.bagSelected);
  const [ssSelected, setIsSsSelected] = React.useState<boolean>(!!flow?.ssSelected);

  useEffect(() => {
    flow && setBagSelected(!!flow?.bagSelected);
    flow && setIsSsSelected(!!flow?.ssSelected);
  }, [flow]);

  useEffect(() => {
    const userIsReader = user?.businessRole === BusinessRole.Reader || user?.businessRole === BusinessRole.Quality;
    setReadonly((propReadonly || userIsReader) ?? false);
  }, [propReadonly, user]);

  useEffect(() => {
    if (bagSelected) {
      form.setFieldValue("bagLegible", form.getFieldValue("bagLegible") ?? YesNoNA.No);
      form.setFieldValue("bagMatch", form.getFieldValue("bagMatch") ?? YesNoNA.No);
      form.setFieldValue("bagClean", form.getFieldValue("bagClean") ?? YesNoNA.No);
    }
  }, [bagSelected, form]);

  useEffect(() => {
    if (ssSelected) {
      form.setFieldValue("ssLegible", form.getFieldValue("ssLegible") ?? YesNoNA.No);
      form.setFieldValue("ssMatch", form.getFieldValue("ssMatch") ?? YesNoNA.No);
      form.setFieldValue("ssClean", form.getFieldValue("ssClean") ?? YesNoNA.No);
    }
  }, [ssSelected, form]);

  if (initialValues) {
    if (bagSelected) {
      initialValues.bagLegible = initialValues.bagLegible == null ? YesNoNA.No : initialValues.bagLegible;
      initialValues.bagMatch = initialValues.bagMatch == null ? YesNoNA.No : initialValues.bagMatch;
      initialValues.bagClean = initialValues.bagClean == null ? YesNoNA.No : initialValues.bagClean;
    }
    if (ssSelected) {
      initialValues.ssLegible = initialValues.ssLegible == null ? YesNoNA.No : initialValues.ssLegible;
      initialValues.ssMatch = initialValues.ssMatch == null ? YesNoNA.No : initialValues.ssMatch;
      initialValues.ssClean = initialValues.ssClean == null ? YesNoNA.No : initialValues.ssClean;
    }
  }

  const bagBatchNumberScannedWithCode2 = (code) => {
    form.setFieldValue("bagPalletId", code);
  };

  const ssBatchNumberScannedWithCode2 = (code) => {
    form.setFieldValue("ssPalletId", code);
  };

  if (flowError) {
    return (
      <Result
        status="500"
        title="Mmmmh... something went wrong."
        subTitle="More luck next time?"
        extra={
          <Button type="primary" onClick={() => navigate("/")}>
            Back Home
          </Button>
        }
      />
    );
  }

  if (isFlowLoading || !flow) {
    return <Spin>Loading...</Spin>;
  }

  // @ts-ignore
  return (
    <Form
      disabled={readonly}
      form={form}
      wrapperCol={{ span: 12 }}
      labelCol={{ span: 12 }}
      labelAlign={"left"}
      onFinish={async (values) => {
        const packageInspectionFlow: IFlowPackageInspection = {
          ...flow,
          ...values,
        };

        const { warnings } = await flowValidate(packageInspectionFlow);

        if (warnings.length > 0 && !hasWarnings) {
          setHasWarnings(true);
          notification.warning({
            message: "The form has warnings",
            description: "Please check the form for warnings and enter a comment to submit despite warnings",
          });
          !hasWarnings && setHasWarnings(true);
          form.setFields(
            warnings.map((warning) => ({
              name: warning.property,
              warnings: warning.messages,
            }))
          );
          return;
        }
        try {
          const api = await getAPI();
          await api.put(`/flows/${flowId}`, {
            data: {
              ...values,
            },
          });
          await api.put(`/flows/${flowId}/submit`);
          await refetchFlow();
          onSuccess && flow && onSuccess(flow);
          notification.success({
            message: "The inventory reading was submitted successfully",
          });
        } catch (error: any) {
          notification.error({
            message: "There was an error submitting the form",
            description: error?.message,
          });
        }
      }}
      onValuesChange={async (changedValues, allValues) => {
        setFormHasUnsavedChanges && setFormHasUnsavedChanges(true);
        if (changedValues.bagSelected !== undefined) {
          setBagSelected(changedValues.bagSelected);
        }
        if (changedValues.ssSelected !== undefined) {
          setIsSsSelected(changedValues.ssSelected);
        }

        if (hasWarnings) {
          // reset warnings
          Object.keys(allValues).forEach((key) => {
            form.setFields([
              {
                name: key,
                warnings: [],
              },
            ]);
          });

          const packageInspectionFlow: IFlowPackageInspection = {
            ...flow,
            ...allValues,
          };

          const { warnings } = await flowValidate(packageInspectionFlow);

          // if we have warnings or errors, we set them in the form, the user can still submit)
          if (warnings.length > 0) {
            form.setFields(
              warnings.map((warning) => ({
                name: warning.property,
                warnings: warning.messages,
              }))
            );
            setHasWarnings(true);
          } else if (warnings.length === 0) {
            setHasWarnings(false);
          }
        }
      }}
      initialValues={initialValues}
    >
      <Form.Item label="Product" name="product" labelCol={{ span: 3 }}>
        <Input disabled={true} className="non-editable-input" />
      </Form.Item>
      <Form.Item label="Bag" name="bagSelected" valuePropName="checked" labelCol={{ span: 3 }}>
        <Checkbox />
      </Form.Item>
      <Form.Item label="SS" name="ssSelected" valuePropName="checked" labelCol={{ span: 3 }}>
        <Checkbox />
      </Form.Item>
      <Space direction="vertical" style={{ width: "100%" }}>
        {bagSelected && (
          <Card title="Bag" style={{ width: "100%" }}>
            <Form.Item label="Is the inkjet printing and/or stencil legible?" name="bagLegible">
              <YesNoNASwitch disabled={readonly} />
            </Form.Item>
            <Form.Item label="Do the barcode label and inkjet printing and/or stencil match?" name="bagMatch">
              <YesNoNASwitch disabled={readonly} />
            </Form.Item>
            <Form.Item label="Is the pallet and slip sheet clean?" name="bagClean">
              <YesNoNASwitch disabled={readonly} />
            </Form.Item>
            <Form.Item label="Bag batch number" name="bagBatchNumber">
              <BatchNumberInput disabled={readonly} setCode2={bagBatchNumberScannedWithCode2} />
            </Form.Item>
            <Form.Item label="Bag pallet id" name="bagPalletId">
              <InputNumber disabled={readonly} controls={false} inputMode="numeric" />
            </Form.Item>
            <Form.Item label="Bag pictures" name="bagPictures">
              <PictureUpload disabled={readonly} />
            </Form.Item>
          </Card>
        )}
        {ssSelected && (
          <Card title="SS" style={{ width: "100%" }}>
            <Form.Item label="Is the inkjet printing and/or stencil legible?" name="ssLegible">
              <YesNoNASwitch disabled={readonly} />
            </Form.Item>
            <Form.Item label="Do the barcode label and inkjet printing and/or stencil match?" name="ssMatch">
              <YesNoNASwitch disabled={readonly} />
            </Form.Item>
            <Form.Item label="Is the pallet and slip sheet clean?" name="ssClean">
              <YesNoNASwitch disabled={readonly} />
            </Form.Item>
            <Form.Item label="SS batch number" name="ssBatchNumber">
              <BatchNumberInput disabled={readonly} setCode2={ssBatchNumberScannedWithCode2} />
            </Form.Item>
            <Form.Item label="SS pallet id" name="ssPalletId">
              <InputNumber disabled={readonly} inputMode="numeric" />
            </Form.Item>
            <Form.Item label="SS Pictures" name="ssPictures">
              <PictureUpload disabled={readonly} />
            </Form.Item>
          </Card>
        )}
        <Form.Item
          name="comment"
          label="Comment"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          rules={[{ required: hasWarnings, message: "Please add a comment to explain warnings" }]}
        >
          <TextArea disabled={readonly} />
        </Form.Item>
      </Space>
      <Space direction="vertical" style={{ width: "100%" }} size="large">
        {!readonly && (
          <div style={{ display: "flex", justifyContent: "right" }}>
            <Space>
              {supervisorMode ? (
                <Form.Item>
                  <Popconfirm
                    title="Save your change"
                    description="To make a change, you must be trained to do so and you MUST add an explanation in the comment box, below the existing comments. If you have done so, you can click on Save."
                    onConfirm={async () => {
                      try {
                        const api = await getAPI();
                        await api.put(`/flows/${flowId}/edit`, {
                          data: form.getFieldsValue(),
                        });
                        await refetchFlow();
                        notification.success({
                          message: "Changes saved",
                        });
                        setFormHasUnsavedChanges && setFormHasUnsavedChanges(false);
                      } catch (error: any) {
                        notification.error({
                          message: "Error saving changes",
                          description: error?.message,
                        });
                      }
                    }}
                    okText="Save"
                    cancelText="Cancel"
                  >
                    <Button size="large" icon={<SaveOutlined />} type="primary">
                      Save changes
                    </Button>
                  </Popconfirm>
                </Form.Item>
              ) : (
                <>
                  <Form.Item>
                    <Button
                      disabled={flowGetStatus(flow) === Status.confirmed}
                      size="large"
                      icon={<SaveOutlined />}
                      onClick={async () => {
                        try {
                          const api = await getAPI();
                          await api.put(`/flows/${flowId}`, {
                            data: form.getFieldsValue(),
                          });
                          await refetchFlow();
                          notification.success({
                            message: "Package inspection draft saved",
                          });
                          setFormHasUnsavedChanges && setFormHasUnsavedChanges(false);
                        } catch (error: any) {
                          notification.error({
                            message: `Error saving package inspection draft: ${error?.message}`,
                          });
                        }
                      }}
                    >
                      Save Draft
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <Button size="large" icon={<SendOutlined />} htmlType="submit" type="primary" disabled={!flowIsEditable(flow) || readonly}>
                      {hasWarnings ? "Submit with warnings" : "Submit"}
                    </Button>
                  </Form.Item>
                </>
              )}
            </Space>
          </div>
        )}
        <FlowStatus flow={flow} />
      </Space>
    </Form>
  );
};
export default PackageInspectionFlowForm;
