import React from "react";
import { Modal, Space, Spin } from "antd";
import "./VideoViewModal.css";
import { PictureOutlined } from "@ant-design/icons";
import useAssetUrl from "../../../hooks/useAssetUrl";

function VideoViewModal({ open, setOpen, id }) {
  const { data: url, isLoading } = useAssetUrl(id);

  return (
    <Modal
      className={"video-view-modal"}
      destroyOnClose={true}
      footer={null}
      styles={{ body: { position: "relative" } }}
      title={
        <Space>
          <PictureOutlined />
          Video
        </Space>
      }
      open={open}
      width={"690px"}
      onCancel={() => setOpen(false)}
    >
      {isLoading && <Spin spinning={true} style={{ position: "absolute", top: "50%", width: "100%" }} />}
      {url && (
        <video width={900} height={600} controls>
          <source src={url} />
        </video>
      )}
    </Modal>
  );
}

export default VideoViewModal;
