"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isTemporarySS = exports.getListOfPossibleBlendsIDListFromSourceIDList = exports.getListOfPossibleSourcesIDListFromProductCode = exports.getListOfRequiredMagnetsFromSourcePathPkgType = exports.getScreenWithSpecialRuleOncePerShift = exports.getListOfRequiredScreensFromSourceAndPath = exports.getProductFamilyIdListFromProductFamily = exports.getProductFamilyIdListFromProductCode = exports.getProductFamilyFromProductCode = void 0;
const interfaces_1 = require("./interfaces");
function getProductFamilyFromProductCode(productCode) {
    switch (productCode) {
        case "3SPP":
            return "3SPP";
        case "DCPA":
            return "DCPA";
        case "DCPD":
            return "DCPD";
        case "SCPP000":
        case "SCPP599":
            return "SCPP";
        case "DSPA GR":
        case "DSPA PWD":
            return "DSPA";
        case "MCPM":
            return "MCPM";
        case "SAPP GR":
        case "SAPP PWD":
        case "SAPP28":
            return "SAPP";
        case "STMP GR":
        case "STMP PWD":
            return "STMP";
        case "STPP GR":
        case "STPP BLEND":
        case "STPP PWD":
            return "STPP";
        case "TCP":
            return "TCP";
        case "TKPP GR":
        case "TKPP SOL":
            return "TKPP";
        case "TSPP GR":
        case "TSPP PWD":
            return "TSPP";
        case "BED":
            return "BED";
        default:
            return "";
    }
}
exports.getProductFamilyFromProductCode = getProductFamilyFromProductCode;
function getProductFamilyIdListFromProductCode(productCode) {
    return getProductFamilyIdListFromProductFamily(getProductFamilyFromProductCode(productCode));
}
exports.getProductFamilyIdListFromProductCode = getProductFamilyIdListFromProductCode;
function getProductFamilyIdListFromProductFamily(productFamily) {
    switch (productFamily) {
        case "3SPP":
            return 1;
        case "DCPA":
            return 2;
        case "DCPD":
            return 3;
        case "SCPP":
            return 4;
        case "DSPA":
            return 5;
        case "MCPM":
            return 6;
        case "SAPP":
            return 7;
        case "STMP":
            return 8;
        case "STPP":
            return 9;
        case "TCP":
            return 10;
        case "TKPP":
            return 11;
        case "TSPP":
            return 12;
        case "BED":
            return 13;
        default:
            return 0;
    }
}
exports.getProductFamilyIdListFromProductFamily = getProductFamilyIdListFromProductFamily;
function getListOfRequiredScreensFromSourceAndPath(sourceIDList, pathIDList) {
    let list = [];
    if ((sourceIDList === 2 || sourceIDList === 11 || sourceIDList === 9 || sourceIDList === 10) && pathIDList === 1) {
        list.push(7);
    }
    if ((sourceIDList === 2 || sourceIDList === 11 || sourceIDList === 9 || sourceIDList === 10) && pathIDList === 2) {
        list.push(8);
    }
    if ((sourceIDList === 1 || sourceIDList === 7) && pathIDList === 2) {
        list.push(8);
    }
    if ((sourceIDList === 3 || sourceIDList === 4) && pathIDList === 1) {
        list.push(7);
    }
    if (sourceIDList === 4 && pathIDList === 2) {
        list.push(8);
    }
    if ((sourceIDList === 5 || sourceIDList === 6 || sourceIDList === 12) && pathIDList === 1) {
        list.push(7);
    }
    if ((sourceIDList === 5 || sourceIDList === 6 || sourceIDList === 12) && pathIDList === 2) {
        list.push(8);
    }
    if (sourceIDList === 13 && pathIDList === 1) {
        list.push(9);
        list.push(7);
    }
    if (sourceIDList === 13 && pathIDList === 2) {
        list.push(9);
        list.push(8);
    }
    if (sourceIDList === 14 && pathIDList === 3) {
        list.push(1);
    }
    if ((sourceIDList === 14 || sourceIDList === 15) && pathIDList === 4) {
        list.push(2);
    }
    if (sourceIDList === 15 && pathIDList === 5) {
        list.push(4);
    }
    if (sourceIDList === 16 && pathIDList === 3) {
        list.push(5);
        list.push(1);
    }
    if (sourceIDList === 16 && pathIDList === 4) {
        list.push(5);
        list.push(2);
    }
    if ((sourceIDList === 17 || sourceIDList === 8) && pathIDList === 1) {
        list.push(7);
    }
    return [...new Set(list)].sort((a, b) => a - b);
}
exports.getListOfRequiredScreensFromSourceAndPath = getListOfRequiredScreensFromSourceAndPath;
function getScreenWithSpecialRuleOncePerShift() {
    // Sweco screen
    return 4;
}
exports.getScreenWithSpecialRuleOncePerShift = getScreenWithSpecialRuleOncePerShift;
function getListOfRequiredMagnetsFromSourcePathPkgType(sourceIDList, pathIDList, pkgType) {
    let list = [];
    if ((sourceIDList === 2 || sourceIDList === 11 || sourceIDList === 9 || sourceIDList === 10) && pathIDList === 1) {
        list.push(24);
        list.push(10);
        list.push(11);
    }
    if ((sourceIDList === 2 || sourceIDList === 11 || sourceIDList === 9 || sourceIDList === 10) && pathIDList === 2) {
        list.push(16);
        list.push(19);
        list.push(20);
    }
    if ((sourceIDList === 1 || sourceIDList === 7) && pathIDList === 2) {
        list.push(19);
        list.push(20);
    }
    if ((sourceIDList === 3 || sourceIDList === 4) && pathIDList === 1) {
        list.push(24);
        list.push(10);
        list.push(11);
    }
    if (sourceIDList === 4 && pathIDList === 2) {
        list.push(17);
        list.push(19);
        list.push(20);
    }
    if ((sourceIDList === 5 || sourceIDList === 6 || sourceIDList === 12) && pathIDList === 1) {
        list.push(8);
        list.push(10);
        list.push(11);
    }
    if ((sourceIDList === 5 || sourceIDList === 6 || sourceIDList === 12) && pathIDList === 2) {
        list.push(18);
        list.push(19);
        list.push(20);
    }
    if (sourceIDList === 13 && pathIDList === 1) {
        list.push(15);
        list.push(10);
        list.push(11);
    }
    if (sourceIDList === 13 && pathIDList === 2) {
        list.push(15);
        list.push(19);
        list.push(20);
    }
    if (sourceIDList === 15 && pathIDList === 5) {
        list.push(2);
    }
    if (sourceIDList === 16 && pathIDList === 3) {
        list.push(6);
    }
    if (sourceIDList === 16 && pathIDList === 4) {
        list.push(6);
    }
    if (pathIDList === 1 && pkgType === interfaces_1.PkgType.Bag) {
        list.push(14);
        list.push(15);
    }
    if (pathIDList === 1 && pkgType === interfaces_1.PkgType.SS) {
        list.push(12);
        list.push(13);
    }
    if (pathIDList === 2 && pkgType === interfaces_1.PkgType.Bag) {
        list.push(22);
        list.push(15);
    }
    if (pathIDList === 2 && pkgType === interfaces_1.PkgType.SS) {
        list.push(21);
        list.push(13);
    }
    if (pathIDList === 3 && pkgType === interfaces_1.PkgType.Bag) {
        list.push(1);
        list.push(3);
        list.push(4);
    }
    if (pathIDList === 3 && pkgType === interfaces_1.PkgType.SS) {
        list.push(2);
    }
    if (pathIDList === 4 && pkgType === interfaces_1.PkgType.Bag) {
        list.push(1);
        list.push(3);
        list.push(4);
    }
    if (pathIDList === 4 && pkgType === interfaces_1.PkgType.SS) {
        list.push(2);
    }
    return [...new Set(list)].sort((a, b) => a - b);
}
exports.getListOfRequiredMagnetsFromSourcePathPkgType = getListOfRequiredMagnetsFromSourcePathPkgType;
function getListOfPossibleSourcesIDListFromProductCode(productCode) {
    switch (productCode) {
        case "DCPA":
            return [14, 16];
        case "DCPD":
            return [14, 16];
        case "MCPM":
            return [14, 16];
        case "SCPP599":
            return [14, 16];
        case "SCPP000":
            return [15, 16];
        case "TCP":
            return [14, 16];
        case "3SPP":
            return [1, 13];
        case "BED":
            return [2, 13];
        case "DSPA GR":
            return [2, 13];
        case "DSPA PWD":
            return [1, 13];
        case "SAPP GR":
            return [2, 9, 13];
        case "SAPP PWD":
            return [1, 3, 4, 13];
        case "SAPP28":
            return [1, 13];
        case "STMP GR":
            return [2, 13];
        case "STMP PWD":
            return [1, 13];
        case "STPP BLEND":
            return [12, 13];
        case "STPP GR":
            return [2, 11, 13];
        case "STPP PWD":
            return [1, 6, 5, 13];
        case "TKPP GR":
            return [17, 8, 13];
        case "TSPP GR":
            return [2, 10, 13];
        case "TSPP PWD":
            return [1, 7, 13];
        case "RWK":
            return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17];
        default:
            return [];
    }
}
exports.getListOfPossibleSourcesIDListFromProductCode = getListOfPossibleSourcesIDListFromProductCode;
function getListOfPossibleBlendsIDListFromSourceIDList(sourceIDList) {
    switch (sourceIDList) {
        case 2:
            return [1, 2];
        case 1:
            return [2];
        case 3:
            return [1];
        case 4:
            return [1, 2];
        case 5:
            return [1, 2];
        case 6:
            return [1, 2];
        case 7:
            return [2];
        case 8:
            return [1];
        case 9:
            return [1, 2];
        case 10:
            return [1, 2];
        case 11:
            return [1, 2];
        case 12:
            return [1, 2];
        case 13:
            return [1, 2];
        case 14:
            return [3, 4];
        case 15:
            return [5, 4];
        case 16:
            return [3, 4];
        case 17:
            return [1];
        default:
            return [];
    }
}
exports.getListOfPossibleBlendsIDListFromSourceIDList = getListOfPossibleBlendsIDListFromSourceIDList;
function isTemporarySS(materialNumber) {
    return materialNumber === 400311 || materialNumber === 2006068;
}
exports.isTemporarySS = isTemporarySS;
