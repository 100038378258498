import { Button, DatePicker, Modal, Space, Table } from "antd";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { DateTime } from "luxon";
import "./BatchLineTable.css";
import { BusinessRole, Area, Product, Team } from "shared/interfaces";
import { ColumnsType } from "antd/es/table";
import { ZoomContext } from "../../../App";
import { useUser } from "../../../services/auth";
import { ArrayParam, BooleanParam, NumberParam, useQueryParams, StringParam } from "use-query-params";
import useBatchLines from "../../../hooks/useBatchLines";
import { nicelyDisplayDateFromISOString, camelCaseToSentence, shiftDayDateTimeToString } from "shared/utils";
import useWindowResize from "../../../hooks/useWindowResize";
import { FilterFilled, CheckOutlined, EditOutlined, HistoryOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router";
import OverflowCell from "../../UI/OverflowCell";
import CSVDownloadButton from "../../UI/CSVDownloadButton";
import BatchLineEditModal from "./BatchLineEditModal";
import { FilterDropdown, FilterIcon } from "../UI/FilterId";

const BatchLineTable = () => {
  const user = useUser();
  const [canEdit, setCanEdit] = useState(false);
  const [zoom] = React.useContext(ZoomContext);
  const zoomRatio = parseInt(zoom) / 100;
  const { height } = useWindowResize();
  const navigate = useNavigate();
  const [comment, setComment] = useState(null);
  const [isBatchLineEditModalOpen, setIsBatchLineEditModalOpen] = useState(false);
  const [batchLineEditId, setBatchLineEditId] = useState(0);

  const [tableHeaderHeight, setTableHeaderHeight] = useState(0);
  useEffect(() => {
    // Function to update state with the height of the th element
    const updateHeight = () => {
      const thElement = document.querySelector("th");
      if (thElement) {
        setTableHeaderHeight(thElement.clientHeight);
      }
    };

    // Set up ResizeObserver
    const resizeObserver = new ResizeObserver(updateHeight);
    const thElement = document.querySelector("th");
    if (thElement) {
      resizeObserver.observe(thElement);
    }

    // Clean up observer on component unmount
    return () => {
      if (thElement) {
        resizeObserver.unobserve(thElement);
      }
    };
  }, []);

  const getSortOrder = (field) => {
    const sortParam = query.sortParams?.find((s) => s?.startsWith(field));
    if (sortParam) {
      return sortParam.endsWith("asc") ? "ascend" : "descend";
    }
    return null;
  };

  // Refactor to use useQueryParams
  const [query, setQuery] = useQueryParams({
    id: NumberParam,
    batchId: NumberParam,
    page: NumberParam,
    pageSize: NumberParam,
    hasFlags: BooleanParam,
    edited: ArrayParam,
    times: ArrayParam,
    type: ArrayParam,
    status: ArrayParam,
    team: ArrayParam,
    product: ArrayParam,
    area: ArrayParam,
    shiftDayFrom: StringParam,
    shiftDayTo: StringParam,
    to: NumberParam,
    from: NumberParam,
    sortParams: ArrayParam,
    source: StringParam,
    blend: StringParam,
    pkgPalletId: StringParam,
  });

  // Default times to one week ago if not set
  const [defaultTimes] = useState([dayjs().subtract(1, "week").valueOf(), dayjs().valueOf()]);
  const timeSpan = query.from && query.to ? [query.from, query.to] : defaultTimes;

  useEffect(() => {
    if (user) {
      setCanEdit(user?.businessRole === BusinessRole.Supervisor);
    }
  }, [user]);

  const sortParams = query.sortParams || [];

  if (!sortParams.find((s) => s?.startsWith("createdAt"))) {
    sortParams.push("createdAt:desc");
  }

  const batchLineQueryConfig = {
    page: query.page || 1,
    pageSize: query.pageSize || 100,
    filters: {
      ...(query.id ? { id: query.id } : {}),
      ...(query.batchId ? { batch: { id: query.batchId } } : {}),
      ...(query.pkgPalletId ? { pkgPalletId: { $containsi: query.pkgPalletId } } : {}),
      ...(timeSpan && timeSpan[0] && timeSpan[1]
        ? {
            createdAt: {
              $gte: timeSpan[0].valueOf(),
              $lte: timeSpan[1].valueOf(),
            },
          }
        : {}),
      ...(query.type && query.type.length ? { type: { $in: query.type } } : {}),
      ...(query.edited && query.edited.length === 1 ? { edited: { [query.edited[0] === "true" ? "$eq" : "$null"]: "true" } } : {}),
      ...((query.team && query.team.length) || (query.area && query.area.length) || (query.product && query.product.length) || query.shiftDayFrom
        ? {
            shift: {
              ...(query.team && query.team.length ? { team: { $in: query.team } } : {}),
              ...(query.area && query.area.length ? { area: { $in: query.area } } : {}),
              ...(query.product && query.product.length ? { product: { $in: query.product } } : {}),
              ...(query.shiftDayFrom && query.shiftDayTo ? { day: { $gte: query.shiftDayFrom, $lte: query.shiftDayTo } } : {}),
            },
          }
        : {}),
      ...(query.source
        ? {
            source: {
              reference: { $containsi: query.source },
            },
          }
        : {}),
      ...(query.blend
        ? {
            blend: {
              reference: { $containsi: query.blend },
            },
          }
        : {}),
    },
    ...(sortParams ? { sort: sortParams } : {}),
  };

  const { data, refetch: refetchBatchLines } = useBatchLines(batchLineQueryConfig);

  const handleEditSuccess = () => {
    refetchBatchLines();
  };

  const { data: batchLines = [] } = data || {};

  const total = data?.meta?.pagination?.total;
  const page = data?.meta?.pagination?.page;
  const pageSize = data?.meta?.pagination?.pageSize;

  const columns: ColumnsType<any> = [
    {
      title: "Batch line Id",
      dataIndex: "id",
      width: 75 * Math.max(1, zoomRatio),
      sorter: { multiple: 1 },
      fixed: "left",
      defaultFilteredValue: query.id ? [query.id] : [],
      onHeaderCell: (column) => {
        if (query.id) {
          return {
            className: "ant-table-column-sort",
          };
        }
        return {};
      },
      filterIcon: (filtered) => <FilterIcon queryItem={query.id} />,
      filterDropdown: FilterDropdown({
        queryKey: "id",
        queryValue: query.id,
        setQuery: setQuery,
        resetQuery: () => setQuery({ id: null }),
        isString: false,
      }),
    },
    {
      title: "Creation date",
      dataIndex: "createdAt",
      width: 150 * Math.max(1, zoomRatio),
      sorter: { multiple: 1 },
      defaultFilteredValue: timeSpan,
      filterDropdown: () => (
        <DatePicker.RangePicker
          value={[dayjs(timeSpan[0]), dayjs(timeSpan[1])]}
          showTime={false}
          onChange={(dates) => {
            if (dates && dates.length > 1 && dates[0] && dates[1]) {
              const from = DateTime.fromJSDate(dates[0].toDate()).startOf("day").toMillis();
              const to = DateTime.fromJSDate(dates[1].toDate()).endOf("day").toMillis();
              setQuery({ from: from, to: to, page: 1 });
            }
          }}
          disabledDate={(current) => current && current > dayjs()}
          allowClear={false}
        />
      ),
      render: (createdAt) => nicelyDisplayDateFromISOString(createdAt),
    },
    {
      title: "Batch",
      dataIndex: ["batch", "batchNumber"],
      defaultFilteredValue: query.batchId ? [query.batchId] : [],
      onHeaderCell: (column) => {
        if (query.id) {
          return {
            className: "ant-table-column-sort",
          };
        }
        return {};
      },
      filterIcon: (filtered) => <FilterIcon queryItem={query.batchId} />,
      filterDropdown: FilterDropdown({
        queryKey: "batchId",
        queryValue: query.batchId,
        setQuery: setQuery,
        resetQuery: () => setQuery({ batchId: null }),
        isString: false,
      }),
      sorter: { multiple: 1 },
      width: 120 * Math.max(1, zoomRatio),
      render: (batchNumber, record) => {
        return (
          <Button
            style={{ padding: 0, margin: 0 }}
            type="link"
            onClick={() => {
              navigate(`/batch/${record.batch.id}`);
            }}
          >
            ({record.batch.id}) {batchNumber}
          </Button>
        );
      },
    },
    {
      title: "Type",
      width: 75 * Math.max(1, zoomRatio),
      dataIndex: "type",
      render: (type) => {
        return camelCaseToSentence(type);
      },
      sorter: { multiple: 1 },
      filters: [
        { text: "Regular", value: "regular" },
        { text: "Metal detection", value: "metalDetection" },
      ],
    },
    {
      title: "Day",
      dataIndex: ["shift", "day"],
      width: 100 * Math.max(1, zoomRatio),
      sorter: { multiple: 1 },
      defaultSortOrder: getSortOrder("shift.day"),
      filterIcon: (filtered) => (
        <span>{query.shiftDayFrom && query.shiftDayTo ? <FilterFilled style={{ color: "var(--prayon-primary-color)" }} /> : <FilterFilled />}</span>
      ),
      filterDropdown: () => (
        <DatePicker.RangePicker
          onChange={(dates) => {
            if (dates && dates.length > 1 && dates[0] && dates[1]) {
              setQuery({
                shiftDayFrom: shiftDayDateTimeToString(DateTime.fromJSDate(dates[0].toDate())),
                shiftDayTo: shiftDayDateTimeToString(DateTime.fromJSDate(dates[1].toDate())),
              });
            } else {
              setQuery({ shiftDayFrom: null, shiftDayTo: null });
            }
          }}
          disabledDate={(current) => current && current > dayjs()}
          allowClear={true}
        />
      ),
    },
    {
      title: "Team",
      width: 80 * Math.max(1, zoomRatio),
      dataIndex: ["shift", "team"],
      sorter: { multiple: 1 },
      filters: [
        {
          value: Team.D,
          text: Team.D,
        },
        {
          value: Team.N,
          text: Team.N,
        },
      ],
      defaultFilteredValue: query?.team?.filter((team): team is string => team !== null) || [],
      defaultSortOrder: getSortOrder("shift.team"),
    },
    {
      title: "Area",
      width: 80 * Math.max(1, zoomRatio),
      dataIndex: ["shift", "area"],
      sorter: { multiple: 1 },
      filters: Object.keys(Area).map((area) => {
        return {
          value: area,
          text: area,
        };
      }),
      defaultFilteredValue: query?.area?.filter((area): area is string => area !== null) || [],
      defaultSortOrder: getSortOrder("shift.area"),
    },
    {
      title: "Product",
      width: 90 * Math.max(1, zoomRatio),
      dataIndex: ["shift", "product"],
      sorter: { multiple: 1 },
      filters: Object.keys(Product).map((product) => {
        return {
          value: product,
          text: product,
        };
      }),
      defaultFilteredValue: query?.product?.filter((product): product is string => product !== null) || [],
      defaultSortOrder: getSortOrder("shift.product"),
    },
    {
      title: "Source",
      dataIndex: ["source", "reference"],
      width: 150 * Math.max(1, zoomRatio),
      sorter: { multiple: 1 },
      render: (reference) => {
        return <>{reference}</>;
      },
      defaultSortOrder: getSortOrder("source.reference"),
      filterIcon: (filtered) => <FilterIcon queryItem={query.source} />,
      filterDropdown: FilterDropdown({
        queryKey: "source",
        queryValue: query.source,
        setQuery: setQuery,
        resetQuery: () => setQuery({ source: null }),
        isString: true,
      }),
    },
    {
      title: "Path",
      dataIndex: ["blend", "reference"],
      width: 150 * Math.max(1, zoomRatio),
      sorter: { multiple: 1 },
      render: (reference) => {
        return <>{reference}</>;
      },
      defaultSortOrder: getSortOrder("blend.reference"),
      filterIcon: (filtered) => <FilterIcon queryItem={query.blend} />,
      filterDropdown: FilterDropdown({
        queryKey: "blend",
        queryValue: query.blend,
        setQuery: setQuery,
        resetQuery: () => setQuery({ blend: null }),
        isString: true,
      }),
    },
    {
      title: "Blend number",
      width: 75 * Math.max(1, zoomRatio),
      dataIndex: "blendNumber",
    },
    {
      title: "Package pallet id",
      width: 120 * Math.max(1, zoomRatio),
      dataIndex: "pkgPalletId",
      sorter: { multiple: 1 },
      filterIcon: (filtered) => <FilterIcon queryItem={query.pkgPalletId} />,
      filterDropdown: FilterDropdown({
        queryKey: "pkgPalletId",
        queryValue: query.pkgPalletId,
        setQuery: setQuery,
        resetQuery: () => setQuery({ pkgPalletId: null }),
        isString: true,
      }),
    },
    {
      title: "Pallet qty",
      width: 75 * Math.max(1, zoomRatio),
      dataIndex: "qtyPallets",
      sorter: { multiple: 1 },
    },
    {
      title: "Bag qty",
      width: 75 * Math.max(1, zoomRatio),
      dataIndex: "qtyBags",
    },
    {
      title: "From pallet",
      width: 75 * Math.max(1, zoomRatio),
      dataIndex: "fromPallet",
    },
    {
      title: "To pallet",
      width: 75 * Math.max(1, zoomRatio),
      dataIndex: "toPallet",
    },
    {
      title: "Bags per pallet",
      width: 75 * Math.max(1, zoomRatio),
      dataIndex: "qtyBagsPerPallet",
    },
    {
      title: "Comment",
      dataIndex: "comment",
      width: 200 * Math.max(1, zoomRatio),
      render: (value) => {
        return value ? <OverflowCell content={value} onMore={() => setComment(value)} /> : null;
      },
    },
    {
      title: "Status",
      width: 75 * Math.max(1, zoomRatio),
      dataIndex: "status",
      sorter: { multiple: 1 },
    },
    {
      title: "Latest version",
      dataIndex: "version",
      width: 75 * Math.max(1, zoomRatio),
      sorter: { multiple: 1 },
    },
    {
      title: "Edited",
      dataIndex: "edited",
      width: 100 * Math.max(1, zoomRatio),
      sorter: { multiple: 1 },
      filters: [
        { text: "Yes", value: "true" },
        { text: "No", value: "false" },
      ],
      defaultFilteredValue: query?.edited?.filter((edited): edited is string => edited !== null) || [],
      render: (edited) => (edited ? <CheckOutlined /> : ""),
    },
    {
      title: "Edited by",
      width: 150 * Math.max(1, zoomRatio),
      dataIndex: ["editedBy", "email"],
      sorter: { multiple: 1 },
      defaultSortOrder: getSortOrder("editedBy.email"),
    },
    {
      title: "Edited date",
      dataIndex: "editedAt",
      sorter: { multiple: 1 },
      width: 150 * Math.max(1, zoomRatio),
      render: (editedAt) => (editedAt ? nicelyDisplayDateFromISOString(editedAt) : ""),
      defaultSortOrder: getSortOrder("editedAt"),
    },
    {
      title: "Last modified date",
      dataIndex: "updatedAt",
      sorter: { multiple: 1 },
      width: 200 * Math.max(1, zoomRatio),
      render: (updatedAt) => nicelyDisplayDateFromISOString(updatedAt),
    },
    {
      title: "Edit",
      width: 100 * Math.max(1.2, zoomRatio),
      dataIndex: "id",
      fixed: "right",
      render: (id) => {
        return (
          <Space>
            {canEdit && (
              <Button
                type="link"
                icon={<EditOutlined />}
                onClick={() => {
                  setBatchLineEditId(id);
                  setIsBatchLineEditModalOpen(true);
                }}
              />
            )}
            <Button
              type="link"
              icon={<HistoryOutlined />}
              onClick={() => {
                navigate(id + "/history");
              }}
            />
          </Space>
        );
      },
    },
  ];

  return (
    <div
      style={{
        padding: 20,
      }}
      className={"flow-table"}
    >
      <CSVDownloadButton fetchDataConfig={batchLineQueryConfig} queryHook={useBatchLines} filename={"batch_lines.csv"} />
      <Table
        size={"small"}
        onChange={(pagination, filters, sorter) => {
          const sorterArray = (Array.isArray(sorter) ? sorter : [sorter]).filter((s) => s.column);
          const newSortParams = sorterArray
            .filter((s) => s.order)
            .filter((s) => s.field)
            .map((s) => {
              return `${s.field && s.field.toString().replace(/,/, ".")}${s.order ? ":" + (s.order === "ascend" ? "asc" : "desc") : ""}`;
            })
            .reverse();

          if (!newSortParams.find((s) => s.startsWith("createdAt"))) {
            newSortParams.push("createdAt:desc");
          }

          filters.team = filters["shift.team"];
          delete filters["shift.team"];
          filters.area = filters["shift.area"];
          delete filters["shift.area"];
          filters.product = filters["shift.product"];
          delete filters["shift.product"];

          setQuery({
            page: pagination.current,
            pageSize: pagination.pageSize,
            sortParams: newSortParams,
            ...filters,
          });
        }}
        scroll={{ x: 1200 * Math.max(1, zoomRatio), ...(height ? { y: height - 125 - 25 * zoomRatio - tableHeaderHeight } : {}) }}
        pagination={{
          current: page,
          total,
          pageSize,
        }}
        columns={columns}
        dataSource={batchLines}
        bordered={true}
      />
      <Modal
        open={!!comment}
        footer={null}
        onCancel={() => {
          setComment(null);
        }}
        title={"Comment"}
      >
        <>{comment}</>
      </Modal>
      <BatchLineEditModal
        open={isBatchLineEditModalOpen}
        setOpen={setIsBatchLineEditModalOpen}
        batchLineId={batchLineEditId}
        onEditSuccess={() => handleEditSuccess()}
      />
    </div>
  );
};

export default BatchLineTable;
